import React from 'react';
import { Button, FileUploader, LoadPanel, Popup, ScrollView, Validator } from 'devextreme-react';
import { useScreenSize } from '../../utils/media-query';
import PopupHeader from '../../layouts/popup-header-footer/PopupHeader';
import CandidateCard from './CandidateCard';
import { RequiredRule } from 'devextreme-react/validator';
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { ShowAlert } from '../../utils/common-methods';

const maxFileSizeLength = 5 * 1024 * 1024;
const ValidationGroupName = "appointmentLetterPopupValidation";
const candidateControlServices = new CandidateControlServices();

const AppointmentLetterPopup = ({
    showPopup,
    HidePopup,
    actionPopupName,
    candidateRating,
    candidateApplicationStatus,
    candidateId,
    jobProfileName,
    lastRoundScheduleDate,
    GetApplication,
    dsInteractionRounds,
    lastInteractionRoundName,
    appointmentLetterFile,
}) => {
    const [appointmentLetter, setAppointmentLetter] = React.useState(null);
    const [filename, setFilename] = React.useState(null);
    const [updateAppointmentLetter, setUpdateAppointmentLetter] = React.useState(null);
    const [showLoadPanel, setShowLoadPanel] = React.useState(false);

    const uploadAppointmentLetterRef = React.useRef();
    const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall } = useScreenSize();

    React.useEffect(() => {
        if (appointmentLetterFile) {
            setAppointmentLetter(new File([appointmentLetterFile], 'appointment_letter.pdf', { type: 'application/pdf' }));
            setFilename(appointmentLetterFile.name);
        }
    }, [appointmentLetterFile]);

    const onClosePopup = () => {
        HidePopup();
    };

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={`${actionPopupName == "AttachAppointment" ? "Attach" : "Update"} Appointment Letter`}
                    ValidationGroupName={ValidationGroupName}
                    onSubmit={onSubmit}
                    isWithoutValidationPopup={true}
                />
            </>
        );
    };

    const DownloadAppointmentLetter = async () => {
        setShowLoadPanel(true);
        try {
            const response = await candidateControlServices.getFileUrl(candidateId, appointmentLetterFile.name);
            const elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(response.data);
            elem.download = appointmentLetterFile.name;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        catch (err) {
            console.error("An error occurred:", err);
        } finally {
            setShowLoadPanel(false);
        }
    };

    const onSubmit = async () => {
        if (!appointmentLetter) {
            ShowAlert(`Appointment Letter is required`, "Vakency");
            return;
        }
        if (!updateAppointmentLetter) {
            onClosePopup();
            return
        }
        if (appointmentLetterFile) {
            await candidateControlServices.deleteFile(candidateId, appointmentLetterFile.name);
        }
        const formData = new FormData();
        const file = new File([appointmentLetter], "appointment_letter.pdf", { type: appointmentLetter.type });
        formData.append("ID", candidateId);
        formData.append("attachments", file);
        const result = await candidateControlServices.uploadFiles(formData);
        if (!result.isOk) {
            if (result.statusCode === 400 || result.statusCode === 409) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            GetApplication();
            onClosePopup();
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        onSubmit();
    }

    const onClickClearFile = async (e) => {
        uploadAppointmentLetterRef.current?.instance.reset();
        setAppointmentLetter(null);
        setFilename(null);
    };

    const onUploadLetterValueChanged = (e) => {
        const file = e[0];
        if (file && file?.size > maxFileSizeLength) {
            ShowAlert(`Appointment Letter of more than 5 MB size cannot be uploaded`, "Vakency");
            uploadAppointmentLetterRef?.current?.instance.reset();
            setAppointmentLetter(null);
        } else if (
            file &&
            // file["type"]?.split("/")[1] !== "doc" &&
            // file["type"]?.split("/")[1] !== "docx" &&
            // file["type"]?.split("/")[1] !== "jpg" &&
            // file["type"]?.split("/")[1] !== "png" &&
            file["type"]?.split("/")[1] !== "pdf"
            // &&
            // file["type"]?.split("/")[1] !== "webp" &&
            // file["type"]?.split("/")[1] !== "jpeg" &&
            // file["type"]?.split("/")[1] !== "xlsx" &&
            // file["type"]?.split("/")[1] !== "xls" &&
            // file["type"]?.split("/")[1] !== "msword" &&
            // file["type"]?.split("/")[1] !== "vnd.ms-excel" &&
            // file["type"]?.split("/")[1] !==
            //   "vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            // file["type"]?.split("/")[1] !==
            //   "vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
            ShowAlert(`This file is not accepted`, "Vakency");
            uploadAppointmentLetterRef?.current?.instance.reset();
            setAppointmentLetter(null);
        } else {
            if (file) {
                setFilename("");
                setAppointmentLetter(file);
                setUpdateAppointmentLetter(file);
            }
        }
    };

    return (
        <>
            <Popup
                visible={showPopup}
                onHiding={HidePopup}
                width={
                    isXSmall || isXXSmall || isExSmall
                        ? "95vw"
                        : isMedium || isSmall
                            ? "70vw"
                            : "50vw"
                }
                height={"auto"}
                maxHeight={"92vh"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
            >
                <LoadPanel
                    visible={showLoadPanel}
                    shading={true}
                    shadingColor="rgba(0,0,0,0.4)"
                />
                <ScrollView width={"100%"} height={"100%"}>
                    <form onSubmit={handleSubmit}>
                        <div className="row pt-2 px-1 mb-1">
                            <div className="">
                                <CandidateCard
                                    candidateRating={candidateRating}
                                    candidateApplicationStatus={candidateApplicationStatus}
                                    candidateId={candidateId}
                                    PopupType={actionPopupName}
                                    jobProfileName={jobProfileName}
                                    lastRoundScheduleDate={lastRoundScheduleDate}
                                    dsInteractionRounds={dsInteractionRounds}
                                    lastInteractionRoundName={lastInteractionRoundName}
                                />
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-auto editor-label m-auto">Appointment Letter</div>
                            <div className="col-md col-lg mx-3 mt-2 resumeUpload d-flex border rounded align-items-center">
                                <label htmlFor="upload-appointment-letter" className='ms-1'>
                                    <span className='text-danger'>*</span>
                                </label>
                                <FileUploader
                                    ref={uploadAppointmentLetterRef}
                                    name="appointment letter"
                                    accept="application/pdf"
                                    id="upload-appointment-letter"
                                    multiple={false}
                                    uploadMode={"useForm"}
                                    selectButtonText="Browse"
                                    allowCanceling={false}
                                    labelText=""
                                    hoverStateEnabled={false}
                                    activeStateEnabled={false}
                                    focusStateEnabled={false}
                                    uploadedMessage="Resume Uploaded"
                                    readyToUploadMessage=""
                                    className=""
                                    inputAttr={{ 'aria-label': 'Browse' }}
                                    onValueChange={onUploadLetterValueChanged}
                                    value={appointmentLetter ? [appointmentLetter] : []}
                                    // hint={appointmentLetter ? appointmentLetter?.name : ""}
                                />
                                {appointmentLetter && (
                                    <>
                                        <Button
                                            icon="close"
                                            id="Close"
                                            onClick={onClickClearFile}
                                            stylingMode="text"
                                            type="default"
                                            hint="Close"
                                        />
                                        {
                                            filename && (
                                                <Button
                                                    icon="download"
                                                    id="Download"
                                                    onClick={() => DownloadAppointmentLetter(candidateId)}
                                                    stylingMode="text"
                                                    type="default"
                                                    hint="Download"
                                                />
                                            )
                                        }
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end py-2 pe-3">
                            <Button
                                validationGroup={ValidationGroupName}
                                text="Submit"
                                type="default"
                                height={33}
                                width={120}
                                className="rounded-3"
                                useSubmitBehavior={true}
                            />
                        </div>
                    </form>
                </ScrollView>
            </Popup>
        </>
    )
}

export default AppointmentLetterPopup;
import React from 'react';
import './JobOpening.scss';
import { NoImage } from '../../utils/base-64-Icons';
import { Button, DataGrid, Tooltip } from 'devextreme-react';
import { GetFormattedDate, handleNavigateToMap, HideDatagridLoader, ShowAlert } from '../../utils/common-methods';
import { Column } from 'devextreme-react/data-grid';
import { JobOpeningOpenService } from '../../api/services/JobOpeningOpenService';
import ListSkeleton from '../../layouts/templates/ListSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyProfileServices } from '../../api/services/CompanyProfileServices';
import { useScreenSize } from '../../utils/media-query';
import CardSkeleton from '../../layouts/templates/CardSkeleton';
import PopupSkeletonOneLine from '../../layouts/templates/PopupSkeletonOneLine';

const JobOpeningDescription = "We are looking for curious, dynamic people who think out of the box for everyday problems. {companyName} provides a thriving culture around a healthy life-work balance and creates a relaxing working place for employees. As an initiative, we welcome our employees and constantly reward them for their hard work.";

const jobOpeningOpenServices = new JobOpeningOpenService();
const companyProfileServices = new CompanyProfileServices();

export default function JobOpeningPublicList() {

    const { openaccesscode } = useParams();
    const [dsJobOpening, setDsJobOpening] = React.useState([]);
    const [focusedRowKey, setfocusedRowKey] = React.useState(null);
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [companyImg, setCompanyImg] = React.useState({ fileData: null });
    const [companyData, setCompanyData] = React.useState({});
    const [description, setDescription] = React.useState(JobOpeningDescription);

    const NavigateTo = useNavigate();
    const { isExSmall, isSmall, isXXSmall, isMedium, isLarge, isExLarge } = useScreenSize();
    const JobOpeningDataGridRef = React.useRef(null);

    React.useEffect(() => {
        GetList();
    }, []);

    const GetCompanyLogo = async () => {
        const result = await companyProfileServices.GetCompanyLogo(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    };

    const GetByOpenAccessCode = async () => {
        const result = await companyProfileServices.GetByOpenAccessCode(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    }

    const GetList = async () => {
        setShowSkeleton(true);
        const result = await jobOpeningOpenServices.GetLiveOpenings(openaccesscode);
        const res = result.data;
        if (!result.isOk) {
            if (result.statusCode === 400) {
                NavigateTo('/error-page', {
                    state: {
                        message: 'Job openings link is expired or invalid.',
                    }
                });
            }
            setShowSkeleton(false);
        } else {
            const companyLogo = await GetCompanyLogo();
            setCompanyImg({
                fileData: companyLogo?.size > 0 ? window.URL.createObjectURL(companyLogo) : 'NoData',
            });
            const companyData = await GetByOpenAccessCode();
            setCompanyData(companyData);
            if (companyData?.companyName) {
                const text = description.replace("{companyName}", companyData?.companyName)
                setDescription(text);
            }
            const JobOpeningData = res?.map(item => {
                const jobOpeningDate = GetFormattedDate(item.jobOpeningDate);
                return {
                    ...item,
                    jobOpeningDate: jobOpeningDate ? jobOpeningDate : null,
                };
            });
            setDsJobOpening(JobOpeningData);
            setfocusedRowKey(res.length > 0 ? res[0].primaryKeyID : null);
            setShowSkeleton(false);
        }
    };

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }

    const openJobApplicationPage = (cellData) => {
        NavigateTo(`/JobApplication/Public/${cellData.data.openAccessCode}`, {
            state: {
                showBackButton: true,
                companyData: companyData,
            }
        });
    };

    const onPhoneButtonClick = () => {
        if (companyData?.phoneNo) {
            window.location.href = `tel:${companyData?.phoneNo}`;
        }
    }

    const jobOpeningNameCellRender = (cellData) => {
        const i = (cellData?.rowIndex % 5) + 1;
        let techSkills = null;
        if (cellData.data?.majorSkills) {
            techSkills = cellData.data?.majorSkills;
        }
        if (cellData.data?.minorSkills) {
            if (techSkills) {
                techSkills += `,${cellData.data?.minorSkills}`;
            } else {
                techSkills = cellData.data?.minorSkills;
            }
        }
        return (
            <>
                <div className={`row-box ${(isExSmall || isXXSmall) ? 'font-medium-12' : 'font-medium-14'} Current-Opening-Card-${i}`}>
                    <div className="row">
                        <div className="col">
                            <span
                                title={cellData.data.jobProfileName}
                                className={` ${(isExSmall || isXXSmall) ? 'font-medium-16' : 'font-medium-18'} text-decoration-underline pointer-link text-break`}
                                onClick={() => openJobApplicationPage(cellData)}
                            >
                                {cellData.data.jobProfileName}
                            </span>
                        </div>
                    </div>

                    <div className="row py-1 ">
                        <div className="col">
                            <i>
                                {cellData.data.openings || '-'}{" "}
                                <span className='fw-normal'>Openings</span>
                            </i>
                        </div>
                        <div className="col-auto text-end">
                            {cellData.data.requiredExperience && <i className="">
                                <i className="dx-icon-WorkExperinceIcon dx-custom-icon mb-1 cursor-pointer" title=""></i>{" "}
                                {cellData.data.requiredExperience}{" "}<i className='fw-normal'>{cellData.data.requiredExperience > 1 ? 'yrs' : 'yr'}</i>{" "}
                            </i>}
                            {cellData.data.requiredExperience && cellData.data.location && <span className="assessment-pipe px-2">|</span>}
                            {
                                cellData.data.location && (
                                    <i>
                                        <i className="dx-icon-locationIcon dx-custom-icon mb-1 cursor-pointer" title="" onClick={() => handleNavigateToMap(companyData?.address)}></i>
                                        {cellData.data.location}
                                    </i>
                                )}
                        </div>
                    </div>
                    {/* <div className="row pb-2 pt-1 ">
                        <div className="col">
                            <i className="">
                                <span title="Opening Date">{cellData.data.jobOpeningDate}</span>
                            </i>
                        </div>
                        <div className="col-auto" id={`MaxSalary-${cellData?.data?.jobOpeningNo}`}>
                            {cellData.data.maxSalary?.toFixed(2)}{" "}
                            <span className="of-experience">CTC</span>
                            {
                                cellData.data.maxSalary && (
                                    <Tooltip
                                        position={"bottom"}
                                        target={`#MaxSalary-${cellData?.data?.jobOpeningNo}`}
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                    >
                                        <div className="font-medium-14">{((cellData.data.maxSalary * 100000) / 12)?.toFixed(2)}</div>
                                        <div className="top-card-caption">Monthly</div>
                                    </Tooltip>
                                )
                            }
                        </div>
                    </div> */}
                    <div className="row pb-2 pt-1">
                        <div className='col'>
                            <div className="chips-container">
                                {
                                    techSkills && techSkills.split(',').slice(0, 5).map((skill, idx) => (
                                        <div className={`chip chip-${idx + 1}`} title={skill}>{skill}</div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-12 pt-2 col-sm-auto pt-sm-0 text-end">
                            <Button
                                name="apply"
                                hint="Apply Now"
                                visible={true}
                                hoverStateEnabled={false}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                stylingMode="contained"
                                className="rounded-3"
                                type="default"
                                text='Apply Now'
                                onClick={() => openJobApplicationPage(cellData)}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="container-fluid">
            <div className="job-opening-public-section row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7 col-xl-6 my-0 py-0">
                    <div className="">
                        <div className={`${(isSmall || isMedium || isLarge || isExLarge) ? 'job-opening-public-card' : ''} py-2 job-opening-public-content`}>
                            <div className="row mx-0">
                                {
                                    companyImg.fileData ? (
                                        <>
                                            <div className='col-auto'>
                                                <img
                                                    src={companyImg.fileData == 'NoData' ? NoImage : companyImg.fileData}
                                                    alt="candidate"
                                                    height={'50px'}
                                                />
                                            </div>
                                            <div className={`col ps-0`}>
                                                <div className='font-semiBold-18'>{companyData?.companyName}</div>
                                                <div className='row my-1'>
                                                    {companyData?.address &&
                                                        <div className="col-auto">
                                                            <i className="dx-icon-locationIcon dx-custom-icon mb-1 cursor-pointer" title="" onClick={() => handleNavigateToMap(companyData?.address)}></i>{" "}
                                                            {companyData?.address}
                                                        </div>
                                                    }
                                                    {companyData?.phoneNo && companyData?.address && <div className="col-auto px-0 assessment-pipe">|</div>}
                                                    {companyData?.phoneNo &&
                                                        <>
                                                            <div className="col-auto cursor-pointer" onClick={onPhoneButtonClick}>
                                                                <i className="dx-icon-MobileIcon dx-custom-icon mb-1" title=""></i>{" "}
                                                                {companyData?.phoneNo}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <PopupSkeletonOneLine />
                                    )
                                }
                            </div>
                            <hr className='my-2' />
                            <div className='row mx-0 mb-4'>
                                <div className='col text-center position-relative'>
                                    <div className={`${(isExSmall || isXXSmall) ? 'font-medium-20' : 'font-medium-24'}`}>Current Openings</div>
                                    <div className='d-flex justify-content-center'>
                                        <hr className='m-0 p-0 heading-horizontal-line' />
                                    </div>
                                </div>
                                <div className={`${(isExSmall || isXXSmall) ? 'font-medium-12' : 'font-medium-14'} pt-2 text-center current-openings-description`} title={description}>{description}</div>
                            </div>
                            {
                                showSkeleton ?
                                    <CardSkeleton /> :
                                    <DataGrid
                                        ref={JobOpeningDataGridRef}
                                        dataSource={dsJobOpening}
                                        focusedRowEnabled={false}
                                        wordWrapEnabled={true}
                                        allowColumnReordering={false}
                                        allowColumnResizing={false}
                                        autoNavigateToFocusedRow={true}
                                        focusedRowKey={focusedRowKey}
                                        onFocusedRowChanged={onFocusedRowChanged}
                                        keyExpr="jobOpeningNo"
                                        width={"100%"}
                                        className='List_DataGrid job-opening-grid'
                                        showColumnHeaders={false}
                                        loadPanel={HideDatagridLoader}
                                        noDataText='No current job openings.'
                                    >
                                        <Column
                                            dataField='jobOpeningNo'
                                            cellRender={jobOpeningNameCellRender}
                                            alignment='left'
                                            fixed={true}
                                            fixedPosition='left'
                                        />
                                    </DataGrid>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

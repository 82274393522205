import React from 'react';
import { Button, Popup, TextBox } from 'devextreme-react';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import { useScreenSize } from '../../../utils/media-query';
import { JobProfileServices } from '../../../api/services/JobProfileServices';
import { Column, DataGrid, Scrolling, Button as DataGridButton, Item, RowDragging, Paging } from 'devextreme-react/data-grid';
import HtmlEditor, {
    Toolbar as HtmlEditorToolbar,
    Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import Validator, { AsyncRule, CustomRule, RequiredRule } from 'devextreme-react/validator';
import PopupSkeletonOneLine from '../../../layouts/templates/PopupSkeletonOneLine';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { confirm } from 'devextreme/ui/dialog';
import { ConflictPopup, HideDatagridLoader, ShowAlert } from '../../../utils/common-methods';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { JobOpeningServices } from '../../../api/services/JobOpeningServices';
import { NewInteractionRoundIcon } from '../../../utils/base-64-Icons';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import PopupSkeletonButton from '../../../layouts/templates/PopupSkeletonButton';

const ValidationGroupName = "JobInteractionRoundPopupValidation";

const jobProfileServices = new JobProfileServices();
const jobOpeningServices = new JobOpeningServices();

const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
};
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
];

export default function JobInteractionRoundPopup({
    openPopup,
    setOpenPopup,
    jobName,
    primaryKey,
    isJobOpeningPanel,
    isJobProfilePanel,
    SetNewRoundNames
}) {
    const [isResizing, setIsResizing] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const containerRef = React.useRef(null);
    const [dsInteractionRound, setDSInteractionRound] = React.useState([]);
    const [focusedRowKey, setfocusedRowKey] = React.useState(0);
    const [previousFocusedRowKey, setPreviousFocusedRowKey] = React.useState(0);
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [showSkeletonOnFocus, setShowSkeletonOnFocus] = React.useState(true);
    const [interactionObj, setInteractionObj] = React.useState({});
    const [leftPaneWidth, setLeftPaneWidth] = React.useState(50);

    const DataGridRef = React.useRef(null);
    const SelectedHTMLEditorRef = React.useRef(null);
    const FocusedFeild = React.useRef(null);
    const { isXXSmall, isExSmall, isSmall, isXSmall, isMedium, isLarge, isExLarge } = useScreenSize();

    const onShown = () => FocusedFeild.current?.instance.focus();

    // Splitter start
    const handleMouseDown = (event) => {
        setIsResizing(true);
        setStartX(event.clientX);
        document.body.classList.add('disable-selection');
    };

    const handleMouseMove = React.useCallback(
        (event) => {
            if (isResizing && containerRef.current) {
                window.requestAnimationFrame(() => {
                const containerTotalWidth = containerRef.current.offsetWidth;
                const minWidthPercent = 40; // 20% of the total width
                const maxWidthPercent = 65; // 65% of the total width

                const deltaX = event.clientX - startX;
                const deltaPercent = (deltaX / containerTotalWidth) * 100;

                let newWidthPercent = leftPaneWidth + deltaPercent;
                newWidthPercent = Math.max(minWidthPercent, Math.min(maxWidthPercent, newWidthPercent));
                if (newWidthPercent !== leftPaneWidth) {
                    setLeftPaneWidth(newWidthPercent);
                    setStartX(event.clientX);
                }
            });
            }
        },
        [isResizing, leftPaneWidth, startX]
    );
    
    const handleMouseUp = () => {
        SelectedHTMLEditorRef?.current?.instance?.repaint();
        setIsResizing(false);
        document.body.classList.remove('disable-selection');
    };

    React.useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, handleMouseMove]);
    // Splitter end

    React.useEffect(() => {
        if (primaryKey) {
            GetInteractionRoundList();
        }
    }, [primaryKey]);

    React.useEffect(() => {
        if (!showSkeleton && !showSkeletonOnFocus) {
            if (dsInteractionRound.length > 0) {
                let interactionRound = dsInteractionRound.find((item) => item.sortOrder === focusedRowKey);
                interactionRound && setInteractionObj(interactionRound);
                interactionRound && SelectedHTMLEditorRef.current?.instance.option("value", interactionRound?.assessmentTemplateFormattedText);
                FocusedFeild.current?.instance.focus();
            }
        }
    }, [showSkeleton, showSkeletonOnFocus, focusedRowKey]);

    const onClosePopup = () => {
        setOpenPopup(false);
        setInteractionObj();
    }

    const GetInteractionRoundList = async () => {
        // No changes required
        setTimeout(async () => {
            const result = isJobProfilePanel ?
                await jobProfileServices.GetJobProfileInteractionRound(primaryKey) :
                await jobOpeningServices.GetJobOpeningInteractionRound(primaryKey);
            if (result.isOk) {
                let interactionRoundData = [];
                if (result.data?.length > 0) {
                    interactionRoundData = result.data.map(item => ({
                        ...item,
                        isNew: false,
                        isDeleted: false,
                        isEdited: false
                    }));
                    interactionRoundData.sort((ob1, ob2) => ob1.sortOrder - ob2.sortOrder);
                }
                else {
                    setShowSkeletonOnFocus(false);
                }
                setDSInteractionRound(interactionRoundData);
            }
            setShowSkeleton(false);
        }, [300])
    }

    const PopupTitle = () => (
        <PopupHeader
            ValidationGroupName={ValidationGroupName}
            onClosePopup={onClosePopup}
            title={[
                "Interaction Rounds for ",
                <>{(isXSmall || isXXSmall || isExSmall || isSmall) ? <br /> : ''}<span key={"header_title"} className="base-accent-text">{jobName}</span></>
            ]}
            onSubmit={UpdateRecord}
            hideSaveButton={false}
        />
    );

    function onFocusedRowChanged(e) {
        if (dsInteractionRound.length > 0) {
            setShowSkeletonOnFocus(true);
        }
        setPreviousFocusedRowKey(focusedRowKey);
        setfocusedRowKey(e.component.option("focusedRowKey"));
        let plainText = SelectedHTMLEditorRef.current?.instance.getQuillInstance().getText();
        let formattedText = SelectedHTMLEditorRef.current?.instance.option("value");
        let interactionRoundName = FocusedFeild.current?.instance.option('text');
        if (focusedRowKey && interactionRoundName) {
            const interactionData = [...dsInteractionRound];
            const interaction = interactionData.find(item => item.sortOrder === previousFocusedRowKey);
            interaction && interaction.isNew && (interaction.isEdited = true);
            const updatedInteractionData = interactionData?.map((item => {
                if (item.sortOrder === focusedRowKey) {
                    return {
                        ...item,
                        assessmentTemplatePlainText: plainText,
                        assessmentTemplateFormattedText: formattedText,
                    }
                }
                return item;
            }));
            setDSInteractionRound(updatedInteractionData);
        }
        setTimeout(() => {
            setShowSkeletonOnFocus(false);
        }, [300]);
    }

    const interactionRoundNameValidation = (e) => {
        if (e.value) {
            let interaction = dsInteractionRound.find(item => item.interactionRoundName == e.value);
            if (interaction && (focusedRowKey != interaction.sortOrder)) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    const UpdateRecord = async () => {
        // Changes required
        let interactionData = [];
        let plainText = SelectedHTMLEditorRef.current?.instance.getQuillInstance().getText();
        let formattedText = SelectedHTMLEditorRef.current?.instance.option("value");
        let interactionRoundName = FocusedFeild.current?.instance.option('text');
        interactionData = dsInteractionRound?.map((item => {
            if (item.sortOrder === focusedRowKey) {
                return {
                    ...item,
                    interactionRoundName: interactionRoundName,
                    assessmentTemplatePlainText: plainText,
                    assessmentTemplateFormattedText: formattedText,
                }
            }
            return item;
        }));
        setDSInteractionRound(interactionData);
        let finalData = interactionData.filter(item => !item.isDeleted).map((item, idx) => ({
            ...item,
            sortOrder: idx + 1,
        }));
        const result = isJobProfilePanel ?
            await jobProfileServices.UpdateJobProfileInteractionRound(primaryKey, finalData) :
            await jobOpeningServices.UpdateJobOpeningInteractionRound(primaryKey, finalData);
        if (!result.isOk) {
            if (result.statusCode === 409) {
                ConflictPopup(result.data).then((res) => {
                    if (res) {
                        GetInteractionRoundList();
                    } else {
                        return;
                    }
                });
                setShowSkeleton(false);
            } else if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
                setShowSkeleton(false);
            } else {
                ShowAlert(result.data, "Vakency");
                setShowSkeleton(false);
            }
        } else {
            let RoundName = []
            finalData?.map((item)=>{
                if(item.isNew){
                    RoundName.push(item.interactionRoundName) 
                } 
            })
            SetNewRoundNames && SetNewRoundNames(RoundName?.length > 0 ?  RoundName[0] : null)
            onClosePopup();
            setShowSkeleton(false);
        }
    }

    const InsertRecord = (interactionRoundName) => {
        // if (debounceTimeout.current) {
        //     clearTimeout(debounceTimeout.current);
        // }
        // debounceTimeout.current = setTimeout(() => {
        const modifiedInteractionRound = [...dsInteractionRound];
        if (interactionRoundName) {
            let duplicateInteraction = dsInteractionRound.find(item => item.interactionRoundName === interactionRoundName);
            if (!duplicateInteraction) {
                const interaction = modifiedInteractionRound.find(item => item.sortOrder === focusedRowKey);
                if (interaction) {
                    interaction.interactionRoundName = interactionRoundName;
                    interaction.isEdited = true;
                }
                setDSInteractionRound(modifiedInteractionRound);
            }
        }
        // }, 200);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        UpdateRecord();
    }

    const onDeleteButtonClick = (e) => {
        // No changes required
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Vakency"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const key = e.row.data.sortOrder;
        const modifiedInteractionRound = [...dsInteractionRound];
        const interaction = modifiedInteractionRound.find(item => item.sortOrder == key);
        if (interaction) {
            interaction.isDeleted = true;
            setDSInteractionRound(modifiedInteractionRound);
        }
    }

    const onUndoButtonClick = (e) => {
        const key = e.row.data.sortOrder;
        const modifiedInteractionRound = [...dsInteractionRound];
        const interaction = modifiedInteractionRound.find(item => item.sortOrder == key);
        if (interaction) {
            interaction.isDeleted = false;
            setDSInteractionRound(modifiedInteractionRound);
        }
    }

    const onAddButtonClick = () => {

        const interactionData = [...dsInteractionRound];

        let plainText = SelectedHTMLEditorRef.current?.instance.getQuillInstance().getText();
        let formattedText = SelectedHTMLEditorRef.current?.instance.option("value");
        const interactionRoundName = FocusedFeild.current?.instance.option('text');
        const interaction = interactionData.find(item => item.sortOrder == focusedRowKey);
        interaction && (interaction.assessmentTemplatePlainText = plainText);
        interaction && (interaction.assessmentTemplateFormattedText = formattedText);
        const existingInteraction = interactionData.find(item => item.interactionRoundName == interactionRoundName);
        if (!existingInteraction && interactionRoundName) {
            interaction && (interaction.interactionRoundName = interactionRoundName);
        }
        const sortOrder = interactionData.length > 0 ?
            interactionData[interactionData.length - 1]['sortOrder'] + 1
            : 1;
        let count = 1;
        let i = 0;
        while (i < interactionData.length) {
            let interaction = interactionData.find(item => item.interactionRoundName === `New Interaction Round ${count}`);
            if (interaction) {
                count++;
            }
            i++;
        }

        interactionData.push({
            interactionRoundName: `New Interaction Round ${count}`,
            assessmentTemplatePlainText: null,
            assessmentTemplateFormattedText: null,
            sortOrder,
            isNew: true,
            isEdited: false,
            isDeleted: false,
        });
        setShowSkeletonOnFocus(true);
        setDSInteractionRound(interactionData);
        setTimeout(() => {
            setShowSkeletonOnFocus(false);
        }, [200]);
        setPreviousFocusedRowKey(focusedRowKey);
        setfocusedRowKey(sortOrder);
    }

    const onReorder = React.useCallback(
        (e) => {
            const visibleRows = e.component.getVisibleRows();
            const modifiedInteractionRound = [...dsInteractionRound];
            const toIndex = modifiedInteractionRound.findIndex((item) => item.sortOrder === visibleRows[e.toIndex].data.sortOrder);
            const fromIndex = modifiedInteractionRound.findIndex((item) => item.sortOrder === e.itemData.sortOrder);
            const tempSortOrder = modifiedInteractionRound[toIndex].sortOrder;
            modifiedInteractionRound[toIndex].sortOrder = modifiedInteractionRound[fromIndex].sortOrder;
            modifiedInteractionRound[fromIndex].sortOrder = tempSortOrder;

            let tempInteractionRound = {};
            tempInteractionRound = modifiedInteractionRound[toIndex];
            modifiedInteractionRound[toIndex] = modifiedInteractionRound[fromIndex];
            modifiedInteractionRound[fromIndex] = tempInteractionRound;
            setDSInteractionRound(modifiedInteractionRound);
            setfocusedRowKey(modifiedInteractionRound[toIndex].sortOrder);
            setInteractionObj(modifiedInteractionRound[toIndex]);
            SelectedHTMLEditorRef.current?.instance.option("value", modifiedInteractionRound[toIndex]?.assessmentTemplateFormattedText);
        },
        [dsInteractionRound],
    );

    const onRoundNameChanged = (e) => {
        InsertRecord(e);
        setInteractionObj({ ...interactionObj, interactionRoundName: e });
    }

    // cell render
    const interactionRoundNameCellRender = (e) => {
        const cellData = e?.row?.data;
        const isNew = cellData?.isNew;
        const isEdited = cellData?.isEdited;
        const isDeleted = cellData?.isDeleted;

        return (
            <>
                <div className={`text-ellipsis ${(isEdited || isNew) ? 'italic-text' : ''} ${isDeleted ? 'text-decoration-line-through' : ''}`}>
                    <span title={`${cellData?.interactionRoundName}`}> {cellData?.interactionRoundName}</span>
                    <span>
                        {
                            isNew && (
                                <img
                                    src={NewInteractionRoundIcon}
                                    alt="status"
                                    className="ms-1"
                                    title={'New'}
                                />
                            )
                        }
                    </span>
                </div>
            </>
        )
    }

    const onEditorContentReady = (e) => {
        const quillInstance = e.component?.getQuillInstance();

        // Monitor text changes
        quillInstance.on('text-change', (delta, oldDelta, source) => {
            const plainText = quillInstance?.getText();
            if (plainText?.length > 6000) {
                quillInstance?.deleteText(6000, plainText.length);
            }
        });
    }

    return (
        <Popup
            visible={openPopup}
            titleRender={PopupTitle}
            width={(isXSmall || isXXSmall || isExSmall || isSmall) ? "90%" : isMedium ? "82%" :  isExLarge ? " 60%" : "68%"}
            height={'auto'}
            maxHeight={"95vh"}
            wrapperAttr={{ class: 'CustomPopup CommonPopupClass' }}
            onShown={onShown}
        >
                <form onSubmit={handleSubmit}>
                    {
                        (isXSmall || isXXSmall || isExSmall || isSmall) ? (
                            <>
                                <div className='row m-0'>
                                    <div className='col py-2'>
                                        {
                                            showSkeleton ? <ListSkeleton /> : (
                                                <DataGrid
                                                    ref={DataGridRef}
                                                    dataSource={dsInteractionRound}
                                                    showBorders={true}
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    focusedRowEnabled={true}
                                                    wordWrapEnabled={true}
                                                    hoverStateEnabled={true}
                                                    autoNavigateToFocusedRow={true}
                                                    keyExpr="sortOrder"
                                                    height={'300px'}
                                                    width={"100%"}
                                                    defaultFocusedRowIndex={[0]}
                                                    focusedRowKey={focusedRowKey}
                                                    className='List_DataGrid'
                                                    noDataText='No Record Found'
                                                    onFocusedRowChanged={onFocusedRowChanged}
                                                >
                                                    <RowDragging
                                                        allowReordering={true}
                                                        onReorder={onReorder}
                                                        showDragIcons={true}
                                                    />
                                                    <Scrolling mode="virtual" />
                                                    <Column
                                                        caption='Interaction Round'
                                                        dataField='interactionRoundName'
                                                        alignment='left'
                                                        allowHiding={false}
                                                        cellRender={interactionRoundNameCellRender}
                                                        allowSorting={false}
                                                    />
                                                    <Column
                                                        type='buttons'
                                                        fixed={false}
                                                        allowResizing={false}
                                                        allowReordering={false}
                                                    >

                                                        <DataGridButton
                                                            name='delete'
                                                            icon='trash'
                                                            hint='Delete'
                                                            visible={(e) => !e.row.data.isDeleted}
                                                            cssClass='text-danger'
                                                            onClick={onDeleteButtonClick}
                                                        />
                                                        <DataGridButton
                                                            name='undo'
                                                            icon='undo'
                                                            hint='Undo'
                                                            visible={(e) => e.row.data.isDeleted}
                                                            cssClass='text-danger'
                                                            onClick={onUndoButtonClick}
                                                        />
                                                    </Column>
                                                </DataGrid>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    {
                                        showSkeleton || showSkeletonOnFocus ? (
                                            <div className='col py-2'>
                                                <PopupSkeletonOneLine />
                                            </div>
                                        ) : (
                                            <div className='col py-2'>
                                                {
                                                    showSkeleton || showSkeletonOnFocus ? (
                                                        <div>
                                                            <PopupSkeletonOneLine />
                                                        </div>
                                                    ) : (
                                                        <div className='font-semiBold-18 '>
                                                            {
                                                                interactionObj.sortOrder && (interactionObj?.isNew && !interactionObj?.isEdited ? "Adding New Interaction Round"
                                                                    : interactionObj?.isDeleted ?
                                                                        <><span className="Italic-text">{`${interactionObj?.interactionRoundName}`}</span> was <span className="text-danger">{`Deleted`}</span></>
                                                                        : <>Editing <span className="Italic-text">{`${interactionObj?.interactionRoundName}`}</span></>)
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                     {showSkeleton || showSkeletonOnFocus ?
                                            <div className='col py-2 text-end'>
                                                <PopupSkeletonButton />
                                            </div>
                                            :
                                    <div className='col py-2 text-end'>
                                        <Button
                                            text='Add New'
                                            icon='plus'
                                            hint='Add New Interaction Round'
                                            className='font-medium-12'
                                            onClick={onAddButtonClick}
                                            type='default'
                                        />
                                    </div>
                                    }
                                </div>
                                <div className='row m-0'>
                                    <div className='col py-2'>
                                        {showSkeleton || showSkeletonOnFocus ?
                                            <div>
                                                <PopupSkeletonOneLine />
                                            </div>
                                            :
                                            <TextBox
                                                label='Interaction Round Name'
                                                maxLength={50}
                                                value={interactionObj?.interactionRoundName}
                                                ref={FocusedFeild}
                                                labelMode='floating'
                                                onValueChange={onRoundNameChanged}
                                                disabled={interactionObj?.isDeleted || dsInteractionRound.length == 0}
                                            >
                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                    <RequiredRule message='Interaction Round Name is required' />
                                                    <CustomRule
                                                        // reevaluate={true}
                                                        message="Can't accept duplicate Interaction Round Name"
                                                        validationCallback={interactionRoundNameValidation}
                                                    />
                                                </Validator>
                                            </TextBox>
                                        }
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col py-2'>
                                        {showSkeleton || showSkeletonOnFocus ?
                                            <div>
                                                <PopupSkeleton />
                                                <PopupSkeleton />
                                                <PopupSkeletonOneLine />
                                            </div>
                                            :
                                            <HtmlEditor
                                                ref={SelectedHTMLEditorRef}
                                                height={"200px"}
                                                valueType='html'
                                                elementAttr={htmlEditorSecondAttributes}
                                                className='CustomizeHtmlEditor'
                                                tabIndex={-1}
                                                disabled={interactionObj?.isDeleted || dsInteractionRound.length == 0}
                                            >
                                                <HtmlEditorToolbar multiline={false}>
                                                    <HtmlEditorItem name="link"></HtmlEditorItem>
                                                    <HtmlEditorItem name="font" acceptedValues={fontValues} />
                                                    <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                                                    <HtmlEditorItem name="bold" />
                                                    <HtmlEditorItem name="italic" />
                                                    <HtmlEditorItem name="underline" />
                                                    <HtmlEditorItem name="background" />
                                                    <HtmlEditorItem name="color" />
                                                    <HtmlEditorItem name="orderedList" />
                                                    <HtmlEditorItem name="bulletList" />
                                                    <HtmlEditorItem name="clear" />
                                                </HtmlEditorToolbar>
                                            </HtmlEditor>
                                        }
                                    </div>
                                </div>

                            </>
                        ) : (
                            <div className="popup-content d-flex position-relative" ref={containerRef}>
                                <div className="pane pane-left" style={{ width: `${leftPaneWidth}%` }}>
                                    {
                                        showSkeleton ? <ListSkeleton /> : (
                                            <DataGrid
                                                ref={DataGridRef}
                                                dataSource={dsInteractionRound}
                                                showBorders={true}
                                                showColumnLines={false}
                                                showRowLines={true}
                                                focusedRowEnabled={true}
                                                wordWrapEnabled={true}
                                                hoverStateEnabled={true}
                                                autoNavigateToFocusedRow={true}
                                                keyExpr="sortOrder"
                                                height={'500px'}
                                                width={"100%"}
                                                defaultFocusedRowIndex={[0]}
                                                focusedRowKey={focusedRowKey}
                                                loadPanel={HideDatagridLoader}
                                                className='List_DataGrid'
                                                noDataText='No Record Found'
                                                onFocusedRowChanged={onFocusedRowChanged}
                                            >
                                                <RowDragging
                                                    allowReordering={true}
                                                    onReorder={onReorder}
                                                    showDragIcons={true}
                                                />
                                                <Scrolling mode={"virtual"} useNative={true} />
                                                <Paging enabled={false} />
                                                <Column
                                                    caption='Interaction Round'
                                                    dataField='interactionRoundName'
                                                    alignment='left'
                                                    allowHiding={false}
                                                    cellRender={interactionRoundNameCellRender}
                                                    allowSorting={false}
                                                />
                                                <Column
                                                    type='buttons'
                                                    fixed={false}
                                                    allowResizing={false}
                                                    allowReordering={false}
                                                >

                                                    <DataGridButton
                                                        name='delete'
                                                        icon='trash'
                                                        hint='Delete'
                                                        visible={(e) => !e.row.data.isDeleted}
                                                        cssClass='text-danger'
                                                        onClick={onDeleteButtonClick}
                                                    />
                                                    <DataGridButton
                                                        name='undo'
                                                        icon='undo'
                                                        hint='Undo'
                                                        visible={(e) => e.row.data.isDeleted}
                                                        cssClass='text-danger'
                                                        onClick={onUndoButtonClick}
                                                    />
                                                </Column>
                                            </DataGrid>
                                        )
                                    }
                                </div>
                                <div className="resizer" onMouseDown={handleMouseDown} />
                                <div className="pane pane-right" style={{ width: `${100-leftPaneWidth}%` }}>
                                    {/* <div className='w-100'> */}
                                    <div className='row m-0 d-flex align-items-center'>
                                        {
                                            showSkeleton || showSkeletonOnFocus ? (
                                                <div className='col col-md-7  col-lg-8 py-2'>
                                                    <PopupSkeletonOneLine />
                                                </div>
                                            ) : (
                                                <div className='col col-md-7  col-lg-8 py-2'>
                                                    {
                                                        showSkeleton || showSkeletonOnFocus ? (
                                                            <div>
                                                                <PopupSkeletonOneLine />
                                                            </div>
                                                        ) : (
                                                            <div className='font-semiBold-18  text-truncate'>
                                                                {
                                                                    interactionObj.sortOrder && (interactionObj?.isNew && !interactionObj?.isEdited ? "Adding New Interaction Round"
                                                                        : interactionObj?.isDeleted ?
                                                                            <><span className="Italic-text" title={`${interactionObj?.interactionRoundName} was Deleted`}>{`${interactionObj?.interactionRoundName}`}</span> was <span className="text-danger">{`Deleted`}</span></>
                                                                            : <>Editing <span className="Italic-text" title={`${interactionObj?.interactionRoundName}`}>{`${interactionObj?.interactionRoundName}`}</span></>)
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                          {showSkeleton || showSkeletonOnFocus ?
                                            <div className='col-auto col-md-5  col-lg-4 py-2 text-end'>
                                                <PopupSkeletonButton />
                                            </div>
                                            :
                                        <div className='col-auto col-md-5 col-lg-4 py-2 text-end'>
                                            <Button
                                                text='Add New'
                                                icon='plus'
                                                hint='Add New Interaction Round'
                                                className='font-medium-12'
                                                onClick={onAddButtonClick}
                                                type='default'
                                            />
                                        </div>
                                    }
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col py-2'>
                                            {showSkeleton || showSkeletonOnFocus ?
                                                <div>
                                                    <PopupSkeletonOneLine />
                                                </div>
                                                :
                                                <TextBox
                                                    label='Interaction Round Name'
                                                    maxLength={50}
                                                    value={interactionObj?.interactionRoundName}
                                                    ref={FocusedFeild}
                                                    labelMode='floating'
                                                    onValueChange={onRoundNameChanged}
                                                    disabled={interactionObj?.isDeleted || dsInteractionRound.length == 0}
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='Interaction Round Name is required' />
                                                        <CustomRule
                                                            // reevaluate={true}
                                                            message="Can't accept duplicate Interaction Round Name"
                                                            validationCallback={interactionRoundNameValidation}
                                                        />
                                                    </Validator>
                                                </TextBox>
                                            }
                                        </div>
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col py-2'>
                                            {showSkeleton || showSkeletonOnFocus ?
                                                <div>
                                                    <PopupSkeleton />
                                                    <PopupSkeleton />
                                                    <PopupSkeletonOneLine />
                                                </div>
                                                :
                                                <HtmlEditor
                                                    ref={SelectedHTMLEditorRef}
                                                    height={"378.5px"}
                                                    valueType='html'
                                                    elementAttr={htmlEditorSecondAttributes}
                                                    className='CustomizeHtmlEditor Interaction-Round-HTML-Editor'
                                                    tabIndex={-1}
                                                    disabled={interactionObj?.isDeleted || dsInteractionRound.length === 0}
                                                    allowSoftLineBreak={false}
                                                    onContentReady={onEditorContentReady}
                                                >
                                                    <HtmlEditorToolbar multiline={false}>
                                                        <HtmlEditorItem name="link"></HtmlEditorItem>
                                                        <HtmlEditorItem name="font" acceptedValues={fontValues} />
                                                        <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                                                        <HtmlEditorItem name="bold" />
                                                        <HtmlEditorItem name="italic" />
                                                        <HtmlEditorItem name="underline" />
                                                        <HtmlEditorItem name="background" />
                                                        <HtmlEditorItem name="color" />
                                                        <HtmlEditorItem name="orderedList" />
                                                        <HtmlEditorItem name="bulletList" />
                                                        <HtmlEditorItem name="clear" />
                                                    </HtmlEditorToolbar>
                                                </HtmlEditor>
                                            }
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        )
                    }
                    {/* {showSkeleton ?
                    <div className='d-flex justify-content-end pb-3 pe-2 py-5 mt-2'>
                        <div className='skeleton-View Skeleton-popup-footer'></div>
                    </div>
                    : */}
                    <PopupFooter
                        ValidationGroupName={ValidationGroupName}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    />
                    {/* } */}
                </form>
        </Popup>
    );
};
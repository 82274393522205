import { CheckBox, Popup, TextBox, Validator } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { RequiredRule } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import { ConflictPopup, ShowAlert, eCRUDStatus, onKeyDown_withoutComma } from '../../../utils/common-methods';
import { AsyncRule, CustomRule } from 'devextreme-react/validator';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { SkillServices } from '../../../api/services/SkillServices';

const skillServices = new SkillServices();
export default function SkillPopup(props) {

  const ValidationGroupName = "SkillPopupValidation"

  const { isExSmall } = useScreenSize();
  const FocusedFeild = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();

  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);

  let PrimaryKeyID = props.primaryKey;

  useEffect(() => {
    if (PrimaryKeyID !== null && PrimaryKeyID !== 0 && PrimaryKeyID !== undefined) {
      GetModelRecord();
    } else {
      setNewRecord({
        skillName: undefined,
        isTechnicalSkill: false,
        defaultSkillType: 0,
      })
      setShowSkeleton(false);
    }
  }, [PrimaryKeyID]);

  const GetModelRecord = async () => {
    setTimeout(async () => {
    const result = await skillServices.GetModelData(PrimaryKeyID);
    if (result.statusCode === 200) {
      const response = result.data;
      setNewRecord(response);
      setOriginalRecord(response)
      setShowSkeleton(false);
    }
  }, 1000);
  }

  useEffect(()=>{
    !showSkeleton && FocusedFeild.current?.instance.focus()
  },[showSkeleton])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!PrimaryKeyID) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  }

  const UpdateRecord = async () => {
    if(originalRecord == newRecord || (originalRecord !== newRecord)){
    setShowSkeleton(true);
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      skillID: newRecord.skillID,
      skillName: newRecord.skillName,
      isTechnicalSkill: newRecord.isTechnicalSkill,
      defaultSkillType: newRecord.defaultSkillType,
    }

    const result = await skillServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Updated,
        primaryKeyID: result.data.responseData
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  }
  }


  const InsertRecord = async () => {
    setShowSkeleton(true);
    let dataToInsert = {
      skillName: newRecord.skillName,
      isTechnicalSkill: newRecord.isTechnicalSkill,
      defaultSkillType: newRecord.defaultSkillType,
    }
    const result = await skillServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData
      })
      onClosePopup();
      setShowSkeleton(false);
    }
  }

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setNewRecord({});
  }

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          title={[<span key={"header_title"} className="base-accent-text">{PrimaryKeyID ? "Edit" : "New"}</span>, " Skill"]}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  const asyncSkillNameValidation = async (e) => {
    try {
      const Name = encodeURIComponent(e?.value);
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;
      const result = await skillServices.CheckDuplicateData(CheckDuplicateParams);
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncSkillNameValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };
  


  return (
    <div>
      {props.openPopup &&
        <Popup
          visible={props.openPopup}
          titleRender={PopupTitle}
          width={(isExSmall) ? "80%" : 400}
          height={'auto'}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ?
              <div className='p-2 pb-0'>
                <PopupSkeleton />
              </div>
              :
              <>
                <div className=' py-2 px-3'>
                  <TextBox
                    label='Skill Name'
                    maxLength={50}
                    labelMode='floating'
                    ref={FocusedFeild}
                    value={newRecord.skillName}
                    onValueChange={(e) => setNewRecord({ ...newRecord, skillName: e })}
                    onKeyDown={onKeyDown_withoutComma}
                  >
                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                      <RequiredRule message='Skill Name is required' />
                      {/* <CustomRule
                        validationCallback={asyncSkillNameValidation}
                        ignoreEmptyValue={true}
                        message="Can't accept duplicate Skill Name"
                      /> */}
                      <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Skill Name"
                        validationCallback={asyncSkillNameValidation}
                      />
                    </Validator>
                  </TextBox>
                </div>
              </>
            }
            {showSkeleton ?
              <div className='d-flex justify-content-end pb-3 pe-2'>
                <div className='skeleton-View Skeleton-popup-footer'></div>
              </div>
              :
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                openPopup={props.openPopup}
                setOpenPopup={props.setOpenPopup}
              />
            }
          </form>
        </Popup>
      }
    </div>
  )
}

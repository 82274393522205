export const AcademicQualificationControlName = "ApplicationSettings/AcademicQualification"

export const AttitudeFactorControlName = "ApplicationSettings/AttitudeFactor"

export const SkillControlName = "ApplicationSettings/Skill"

export const UserControlName = "UserManagement/User"

export const StateControlName = "CityManagement/CityState"

export const CityControlName = "CityManagement/City"

export const DashboardControlName = "HomeDashboard"

export const CountryControlName = "CityManagement/Country"

export const InteractionRoundControlName = "ApplicationSettings/InteractionRound"

export const CandidateControlName = "CandidateManagement/Candidate"

export const CandidateAssessmentOpenControlName = "CandidateManagement/CandidateAssessmentOpenAPI"

export const CandidateAssessmentControlName = "CandidateManagement/CandidateAssessment"

export const CandidateSourceControlName = "ApplicationSettings/CandidateSource"

export const JobProfileControlName = "ApplicationSettings/JobProfile"

export const RegistrationControlName = "RegistrationManagement/Registration"

export const ForgotPasswordControlName = "UserManagement/ForgotPassword"

export const CurrentUserControlName = "UserManagement/CurrentUser"

export const JobOpeningControlName = "JobManagement/JobOpening"

export const JobApplicationControlName = "JobManagement/JobApplication"

export const JobOpeningControlPanelName = "JobManagement/JobOpeningControlPanel"

export const JobOpeningOpenApi = "JobManagement/JobApplicationOpenAPI"

export const CandidateInteractionControlName ="JobManagement/CandidateInteraction"

export const CandidateInteractionSchedule ="JobManagement/CandidateInteractionSchedule"

export const JobOpeningOpenControlName = "JobManagement/JobOpeningOpenAPI";

export const JobApplicationOpenControlName = "JobManagement/JobApplicationOpenAPI";

export const JobApplicationHistoryName = "JobManagement/JobApplicationHistory";

export const EmailTemplateControlName = "ApplicationSettings/EmailTemplate";

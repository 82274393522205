import {
  Button,
  CheckBox,
  DateBox,
  FileUploader,
  LoadPanel,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  TextBox,
  Tooltip,
  Validator,
} from "devextreme-react";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import "./Candidate.scss";
import CandidatePictureUploader from "./CandidatePictureUploader";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import JobOpeningDropDown from "../job-opening/JobOpeningDropdown";
import { AsyncRule, EmailRule, RangeRule } from "devextreme-react/validator";

import {
  ConflictPopup,
  ShowAlert,
  closeDateBoxOnScroll,
  eCRUDStatus,
  minDate,
  onKeyDown,
  onKeyDown_Withpoint,
  popupAnimation,
} from "../../utils/common-methods";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { SkillServices } from "../../api/services/SkillServices";
import { JobProfileServices } from "../../api/services/JobProfileServices"
import { CurrentUserControlServices } from "../../api/services/CurrentUserControlServices";
import ResumeUploader from "../../components/resume-uploader/ResumeUploader";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { custom } from "devextreme/ui/dialog";
import CityDropDown from "../application-settings/city/CityDropDown";
import AcademicQualificationDropDown from "../application-settings/academic-qualification/AcademicQualificationDropDown";
import { dsMaritalStatus } from "../../utils/contants";
import StateDropDown from "../application-settings/state/StateDropDown";
import CandidateEditorPopup from "./CandidateEditorPopup";

const candidateControlServices = new CandidateControlServices();
const jobApplicationServices = new JobApplicationServices();
const skillServices = new SkillServices();
const jobProfileServices = new JobProfileServices();
const currentUserControlServices = new CurrentUserControlServices();

const ValidationGroupName = "CandidateSourcePopupValidation";
const maxResumeSizeLength = 5 * 1024 * 1024;

export default function CandidateDropDownPopup(props) {

  const { isExSmall, isXSmall, isXXSmall } = useScreenSize();

  const FocusedFeild = useRef(null);
  const UploadResumeRef = useRef(null);
  const DateBoxRef = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();

  const [showMoreInfo, setShowMoreInfo] = useState();
  const [newRecord, setNewRecord] = useState({});
  const [DsMajorTechnicalSkills, setDsMajorTechnicalSkills] = useState([]);
  const [MajorSkillsValue, setMajorSkillsValue] = useState([]);
  const [resume, setResume] = useState({
    uploadedFile: { fileData: null },
    attachedFile: null,
    isUpdated: false
  });
  const [dropDownJobProfileClick, setDropDownJobProfileClick] = useState(false);
  const [dropDownJobOpeningClick, setDropDownJobOpeningClick] = useState(false);
  const [addedJobOpeningID, setAddedJobOpeningID] = useState(0);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [months, setMonths] = useState({
    totalMonth: undefined,
  });
  const [hrManagerId, setHrManagerId] = useState();
  const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = useState();
  const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = useState();
  const [isResumeParsing, setIsResumeParsing] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [filename, setFilename] = useState(null);
  const [openResumeUploader, setOpenResumeUploader] = useState(false);
  const [showCandidatePopup, setShowCandidatePopup] = useState(false);
  const [dropDownCityClick, setDropDownCityClick] = useState(false);
  const [addedCityID, setAddedCityID] = useState(0);
  const [dropDownPermanentCityClick, setDropDownPermanentCityClick] =
      useState(false);
  const [addedPermanentCityID, setAddedPermanentCityID] = useState(0);
  const [DsYearDropdownValues, setDsYearDropdownValues] = useState([]);
  const [
      dropDownAcademicQualificationClick,
      setDropDownAcademicQualificationClick,
    ] = useState(false);
const [addedAcademicQualificationID, setAddedAcademicQualificationID] =
      useState(0);
const [dropDownStateClick, setDropDownStateClick] = useState(false);
const [addedStateID, setAddedStateID] = useState(0);
const [newAddedStateID, setNewAddedStateID] = useState(0);
const [dropDownPermanentStateClick, setDropDownPermanentStateClick] =
    useState(false);
  const [addedPermanentStateID, setAddedPermanentStateID] = useState(0);
  const [showCandidateEditorPopup, setShowCandidateEditorPopup] = useState(false);
  const [isDuplicateEmailFound, setIsDuplicateEmailFound] = useState(false);
  const [showDuplicateEmailTootip, setShowDuplicateEmailTootip] = useState(false);
  const [duplicateEmailCandidateDetail, setDuplicateEmailCandidateDetail] = useState(null);
  const [editEmailStatus, setEditEmailStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [editMobileStatus, setEditMobileStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [isDuplicateMobileFound, setIsDuplicateMobileFound] = useState(false);
  const [showDuplicateMobileTootip, setShowDuplicateMobileTootip] = useState(false);
  const [duplicateMobileCandidateDetail, setDuplicateMobileCandidateDetail] = useState(null);
  const [popupOpenType, showPopupOpenType] = useState(null);

  let PrimaryKeyID = props.primaryKey;

  useEffect(() => {
    if (!PrimaryKeyID) {
      setOpenResumeUploader(true);
    } else {
      setShowCandidatePopup(true);
    }
  }, []);

  useEffect(() => {
      if (addedPermanentStateID !== 0) {
        setNewRecord({ ...newRecord, permanentStateID: addedPermanentStateID });
        setAddedPermanentStateID(0);
      }
    }, [addedPermanentStateID]);

  useEffect(() => {
      if (addedCityID !== 0) {
        setNewRecord({ ...newRecord, currentCityID: addedCityID });
        setAddedCityID(0);
      }
  }, [addedCityID]);

   useEffect(() => {
      if (addedStateID !== 0) {
        setNewRecord({ ...newRecord, currentStateID: addedStateID });
        setAddedCityID(0);
      }
    }, [addedStateID]);

  useEffect(() => {
      if (addedAcademicQualificationID !== 0) {
        setNewRecord({
          ...newRecord,
          academicQualificationID: addedAcademicQualificationID,
        });
        setAddedAcademicQualificationID(0);
      }
    }, [addedAcademicQualificationID]);

  useEffect(() => {
        if (addedPermanentCityID !== 0) {
          setNewRecord({ ...newRecord, permanentCityID: addedPermanentCityID });
          setAddedPermanentCityID(0);
        }
  }, [addedPermanentCityID]);

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      GetSkills(addedJobProfileID);
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedJobOpeningID !== 0) {
      setNewRecord({ ...newRecord, jobOpeningID: addedJobOpeningID });
      setAddedJobOpeningID(0);
    }
  }, [addedJobOpeningID]);

  useEffect(() => {
    if (
      PrimaryKeyID !== null &&
      PrimaryKeyID !== 0 &&
      PrimaryKeyID !== undefined
    ) {
      GetModelRecord();
    } else {
      setOpenResumeUploader(true);
      setNewRecord({});
      GenerateCandidateNo();
    }
  }, [PrimaryKeyID]);

   useEffect(() => {
      GetSkillsLookupList();
      getYearOptions();
    }, []);

  const GetCurrentUser = async () => {
    const result = await currentUserControlServices.GetModelAPI_WithoutId();
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      let userId = result.data.userID;
      setHrManagerId(userId);
    }
  };

  useEffect(() => {
    GetSkillsLookupList();
    GetCurrentUser();
  }, []);

   const getYearOptions = useCallback(() => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1975; year--) {
        years.push(year);
      }
      setDsYearDropdownValues(years);
    }, []);

  const GenerateCandidateNo = async () => {
    const result = await candidateControlServices.GenerateCandidateNoData();
    if (result.isOk) {
      setNewRecord({ ...newRecord, candidateNo: result.data });
    }
  };

  const GetModelRecord = async () => {
    setShowSkeleton(true);
    const result = await candidateControlServices.GetModelData(PrimaryKeyID);
    const response = result.data;
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency");
      setShowSkeleton(false);
    } else {
      GetProfileImage().then((res) => {
        setProfileImg({
          ...result.data,
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
      GetResume();
      GetTagBoxValues(response);
      setNewRecord(response);
      if (response.totalExperience) {
        let splitExperienceString = response.totalExperience?.toFixed(2)?.toString() || "0";
        let splitExperience = splitExperienceString.split(".");
        let totalMonths = parseInt(splitExperience[1] ? splitExperience[1] : 0);
        let totalYears = parseInt(splitExperience[0] ? splitExperience[0] : 0);
        setNewRecord({
          ...response,
          totalExperience: totalYears,
        });
        if (response.dob) {
          setNewRecord((prevState) => ({
            ...prevState,
            dob: new Date(response.dob),
          }));
        }
        setMonths({ ...months, totalMonth: totalMonths });
      }
      if (response.currentCTC != null) {
        setCurrentCTCMonthlyValue((response.currentCTC * 100000) / 12);
      }
      if (response.expectedCTC != null) {
        setExpectedCTCMonthlyValue((response.expectedCTC * 100000) / 12);
      }
      setShowSkeleton(false);
    }
  };


  const GetResume = async () => {
    let params = `?CandidateID=${PrimaryKeyID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      setFilename("Resume.pdf");
      setResume({
        ...resume,
        uploadedFile: { fileData: new File([result.data], 'Resume.pdf', { type: 'application/pdf' }) },
        attachedFile: new File([result.data], 'Resume.pdf', { type: 'application/pdf' }),
      });
    }
  };

  const GetProfileImage = async () => {
    let params = `?CandidateID=${PrimaryKeyID}`;
    const result = await candidateControlServices.GetImage(params);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetTagBoxValues = (data) => {
    if (data.majorTechnicalSkills !== "") {
      let MajorSkillsValue = data.majorTechnicalSkills?.split(",");

      setMajorSkillsValue(MajorSkillsValue);
    }
  };

  const GetSkillsLookupList = async () => {
    const result = await skillServices.GetLookupListData();
    if (result.isOk) {
      setDsMajorTechnicalSkills(result.data);
    }
  };

  function onMajorSkillsValueChange(e) {
    if (e.value === null) {
      setMajorSkillsValue([]);
    } else {
      setMajorSkillsValue(e.value);
    }
  }

  const onTagBoxSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      e.component.field().value = "";
    }
  };

  const onJobProfileValueChange = (e) => {
    setNewRecord({ ...newRecord, jobProfileID: e });
    GetSkills(e);
  };

  const GetSkills = async (id) => {
    const result = await jobProfileServices.GetSkills_JobProfilePage(id);
    if (result.isOk) {
      const MajorfilteredSkills = result.data.majorSkillIds;
      const matchedMajorSkills = DsMajorTechnicalSkills.filter((skill) =>
        MajorfilteredSkills.includes(skill.skillID)
      );
      const matchedMajorSkillNames = matchedMajorSkills.map(
        (skill) => skill.skillName
      );
      setMajorSkillsValue(matchedMajorSkillNames);
    }
  };

  const onCustomMajorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = DsMajorTechnicalSkills;

    const CheckSkillDatSource = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckSkillDatSource) {
      arg.customItem = CheckSkillDatSource;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  function getFormattedTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  }

  const InsertRecord = async () => {
    setShowSkeleton(true);
    var date = newRecord?.dob && new Date(newRecord?.dob);
    var formattedDate = date
      ? date.toDateString()
      : undefined;
    let dataToInsert = {
      candidateNo: newRecord?.candidateNo,
      jobProfileID: parseInt(
        props.jobProfileID ? props.jobProfileID : newRecord?.jobProfileID
      ),
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: null,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: null,
      currentAddress: newRecord?.currentAddress,
      currentStateID:newRecord?.currentStateID,
      currentCityID: newRecord?.currentCityID,
      currentPIN: null,
      permanentAddress: newRecord?.permanentAddress,
      permanentCityID: newRecord?.permanentCityID,
      permanentStateID: newRecord?.permanentStateID,
      permanentPIN: null,
      dob: formattedDate,
      maritalStatusID: newRecord?.maritalStatusID,
      dependentFamilyMember: 0,
      academicQualificationID: newRecord?.academicQualificationID,
      academicQualificationGrade: null,
      academicQualificationYear: newRecord?.academicQualificationYear,
      primaryEducationLanguage: null,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || "",
      minorTechnicalSkills: null,
      otherTechnicalSkills: null,
      attitude: null,
      candidateSourceID: null,
      currentCompanyName: newRecord?.currentCompanyName,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience || 0) + "." + (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth)
      ).toFixed(2),
      relevantExperience: newRecord?.relevantExperience,
      descrPlainText: null,
      descrFormattedText: null,
    };
    // Insert Candidate
    const result = await candidateControlServices.InsertData(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      UpdateResume(result.data.responseData);
      if (profileImg.attachedFile) {
        UpdateImage(result.data.responseData);
      }
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Inserted,
          primaryKeyID: result.data.responseData,
        });
      //Create job application only if jobOpeningID present.
      if (props?.jobOpeningID) {
        CreateJobApplication(result);
      }
      onClosePopup();
      setShowSkeleton(false);
      setNewRecord({});
    }
  };

  const CreateJobApplication = async (result) => {
    let dataForApplication = {
      jobApplicationDate: getFormattedTodayDate(),
      jobOpeningID: parseInt(
        props.jobOpeningID ? props.jobOpeningID : newRecord?.jobOpeningID
      ),
      candidateID: parseFloat(result.data.responseData),
      currentCTC: parseFloat(newRecord?.currentCTC),
      expectedCTC: parseFloat(newRecord?.expectedCTC),
      totalExperience: parseFloat(
        newRecord?.totalExperience + "." + months.totalMonth
      ),
      relevantExperience: null,
      noticePeriod: parseFloat(newRecord?.noticePeriod),
      reasonForLeaving: "",
      hrManagerID: parseInt(hrManagerId),
      descrFormattedText: "",
      descrPlainText: "",
    };
    const response = await jobApplicationServices.InsertData(
      dataForApplication
    );

    if (!response.isOk) {
      if (response.statusCode === 400) {
        ShowAlert(response.data, "Vakency");
      }
    } else {
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Inserted,
          primaryKeyID: response.data.responseData,
        });
    }
  };

  const UpdateRecord = async () => {
    setShowSkeleton(true);
    var date = newRecord?.dob && new Date(newRecord?.dob);
    var formattedDate = date
      ? date.toDateString()
      : undefined;
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      candidateNo: newRecord?.candidateNo,
      candidateID: newRecord?.candidateID,
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: newRecord?.mobileNo2,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: newRecord?.skype,
      currentAddress: newRecord?.currentAddress,
      currentCityID: newRecord?.currentCityID,
      currentStateID: newRecord?.currentStateID,
      currentPIN: newRecord?.currentPIN,
      permanentAddress: newRecord?.permanentAddress,
      permanentCityID: newRecord?.permanentCityID,
      permanentStateID: newRecord?.permanentStateID,
      permanentPIN: newRecord?.permanentPIN,
      dob: formattedDate,
      maritalStatusID: newRecord?.maritalStatusID,
      dependentFamilyMember: newRecord?.dependentFamilyMember,
      academicQualificationID: newRecord?.academicQualificationID,
      academicQualificationGrade: newRecord?.academicQualificationGrade,
      academicQualificationYear: newRecord?.academicQualificationYear,
      primaryEducationLanguage: newRecord?.primaryEducationLanguage,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || "",
      minorTechnicalSkills: newRecord?.minorTechnicalSkills,
      otherTechnicalSkills: newRecord?.otherTechnicalSkills,
      attitude: newRecord?.attitude,
      candidateSourceID: newRecord?.candidateSourceID,
      currentCompanyName: newRecord?.currentCompanyName,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience || 0) + "." + (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth)
      ).toFixed(2),
      relevantExperience: newRecord?.relevantExperience,
      descrPlainText: newRecord?.descrPlainText,
      descrFormattedText: newRecord?.descrFormattedText,
    };

    const result = await candidateControlServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSkeleton(false);
    } else {
      props.setStatus &&
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID: result.data.responseData,
        });
      UpdateResume(newRecord?.candidateID);
      UpdateImage(newRecord?.candidateID);
      onClosePopup();
      setShowSkeleton(false);
      setNewRecord({});
    }
  };

  const UpdateImage = async (candidateID) => {
    props.setImageStatus && props.setImageStatus(false);

    var Datatoupload = {
      candidateID: candidateID,
      attachments: profileImg.attachedFile,
    };
    const result = await candidateControlServices.InsertImage(
      Datatoupload,
      candidateID
    );
    if (result.isOk) {
      props.setImageStatus && props.setImageStatus(true);
      // return;
    } else {
      ShowAlert(result.data);
    }
  };

  const UpdateResume = async (candidateID) => {
    if (resume.isUpdated && resume.attachedFile) {
      props.setResumeStatus &&
        props.setResumeStatus({
          uploading: true,
          uploaded: false
        });
      var Datatoupload = { attachment: resume.attachedFile, candidateID: candidateID };

      let result = await candidateControlServices.UpdateCandidateDocData(
        Datatoupload,
        candidateID
      );

      if (result.isOk) {
        props.setResumeStatus &&
          props.setResumeStatus({
            eStatus: eCRUDStatus.Updated,
            primaryKeyID: result.data.responseData,
            uploading: false,
            uploaded: true
          });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      dropDownAcademicQualificationClick === false &&
      dropDownJobProfileClick === false &&
      dropDownPermanentCityClick === false && 
      dropDownStateClick === false &&
      dropDownPermanentStateClick === false &&
      dropDownCityClick === false &&
      dropDownJobOpeningClick === false
    ) {
      if (!PrimaryKeyID) {
        // return;
        InsertRecord();
      } else {
        UpdateRecord();
      }
    }
  };

  const onSubmit = (e) => {
    if (
      dropDownAcademicQualificationClick === false &&
      dropDownCityClick === false &&
      dropDownJobProfileClick === false &&
      dropDownPermanentStateClick === false &&
      dropDownStateClick === false &&
      dropDownPermanentCityClick === false &&
      dropDownJobOpeningClick === false
    ) {
      if (
        PrimaryKeyID
      ) {
        UpdateRecord();
      } else {
        InsertRecord();
      }
    }
  };

  const onUploadResumeValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxResumeSizeLength) {
      ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
      });
    } else if (
      file &&
      file["type"]?.split("/")[1] !== "pdf"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
      });
    } else {
      if (file) {
        setFilename("");
        const resumeData = {
          ...resume,
          uploadedFile: { fileData: file },
          attachedFile: file,
          isUpdated: true,
        }
        setResume(resumeData);
        if (!PrimaryKeyID) {
          let myDialog = custom({
            title: "Vakency",
            messageHtml: "<div class='font-semiBold-16'>Do you want to auto-fill details from the uploaded resume?</div>",
            buttons: [
              {
                text: "Yes",
                onClick: (e) => {
                  return { buttonText: e.component.option("text") }
                },
                elementAttr: {
                  class: 'btnReload'
                },
                type: 'default',
                focusStateEnabled: false,
              },
              {
                text: "No",
                onClick: (e) => {
                  return { buttonText: e.component.option("text") }
                },
                elementAttr: {
                  class: 'btnCancel'
                },
                type: 'danger',
                focusStateEnabled: false,
              }
            ]
          });
          myDialog.show().then((dialogResult) => {
            if (dialogResult.buttonText == 'Yes') {
              handleResumeParsing(resumeData, true);
            }
          });
        }
      }
    }
  };

  const onClickClearResume = (e) => {
    UploadResumeRef.current?.instance.reset();
    setResume({
      uploadedFile: { fileData: null },
      attachedFile: null,
    });
    setFilename(null);
  };

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setShowCandidatePopup(false);
    setNewRecord({});
  };

  const handlePermanentStateValueChange = (e) => {
    setNewRecord({ ...newRecord, permanentStateID: e, permanentCityID: null });
  };

  const handleCurrentStateValueChange = (e) => {
    setNewRecord({ ...newRecord, currentStateID: e, currentCityID: null });
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header-title"} className="base-accent-text">
              {PrimaryKeyID ? "Edit" : "New"}
            </span>,
            ` Candidate #${newRecord.candidateNo}`,
          ]}
          onSubmit={onSubmit}
        />
      </>
    );
  };

  const handleTotalExperienceChange = (e) => {
    const integerPart = e?.toString().split('.')[0]
    const decimalpart = e?.toString().split('.')[1]
    if (!decimalpart) {
      setNewRecord({ ...newRecord, totalExperience: e ? parseInt(integerPart) : null });
    } else if (decimalpart < 12) {
      setNewRecord({ ...newRecord, totalExperience: e ? (`${integerPart}.${decimalpart}`) : null })
    } else {
      setNewRecord({ ...newRecord, totalExperience: e ? parseInt(integerPart) + 1 : null })
    }
  }

  const handleReleventExperienceChange = (e) => {
    const integerPart = e?.toString().split('.')[0];
    let decimalPart = e?.toString().split('.')[1];
    if (!decimalPart) {
      setNewRecord({ ...newRecord, relevantExperience: e ? parseInt(integerPart) : null });
    } else if (decimalPart < 12) {
      setNewRecord({ ...newRecord, relevantExperience: e ? (`${integerPart}.${decimalPart}`) : null });
    } else {
      setNewRecord({ ...newRecord, relevantExperience: e ? parseInt(integerPart) + 1 : null });
    }
  };


  const asyncEmailIDValidation = useCallback(async (e) => {
    try {
      const EmailID = e.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateEmailData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncEmailIDValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }, [editEmailStatus]);

  const asyncMobileNoValidation = useCallback(async (e) => {
    try {
      const mobileNo = e.value;
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateMobileNoData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncMobileNoValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }, [editMobileStatus]);

  const onValidateEmailField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      getProfileByEmailID(e.value);
    } else {
      clearEmailDuplicationCheckData();
    }
  }, [setDuplicateEmailCandidateDetail, setIsDuplicateEmailFound, setShowDuplicateEmailTootip]);

  const onValidateMobileField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      GetProfileByMobileNo(e.value);
    } else {
      clearMobileDuplicationCheckData();
    }
  }, [setDuplicateMobileCandidateDetail, setIsDuplicateMobileFound, setShowDuplicateMobileTootip]);

  const candidateFullName = () => {
    let fullName = "";

    if (newRecord?.firstName) {
      fullName += newRecord?.firstName;
    }

    if (newRecord?.lastName) {
      fullName += fullName ? ` ${newRecord?.lastName}` : newRecord?.lastName;
    }

    return fullName;
  };

  const currentDate = new Date();
  const DOBRangeRule = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 14)
  );

  const DownloadResume = async (ID) => {
    let params = `?CandidateID=${ID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    const fileData = await result.data;
    if (result.isOk) {
      const fileURL = URL.createObjectURL(fileData);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(fileData);
      downloadLink.download = `resume.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(fileURL);
    } else if (result.statusCode === 204) {
      ShowAlert("No file uploaded", "Vakency");
    }
  };

  const onHidingResumeUploder = (showCandidatePopup = true) => {
    setOpenResumeUploader(false);
    if(!showCandidatePopup){
      setShowCandidatePopup(false);
      props.setOpenPopup && props.setOpenPopup(false)
    } else{
      setShowCandidatePopup(true);
    }
  }

  const handleResumeParsing = async (resume, allowParsing) => {
    if (!resume.uploadedFile.fileData) {
      ShowAlert(`Resume is required`, "Vakency");
      return;
    }
    setShowCandidatePopup(true);
    setOpenResumeUploader(false);
    if (allowParsing) {
      const params = {
        attachment: resume.attachedFile
      }
      setIsResumeParsing(true);
      try {
        const result = await candidateControlServices.ReadCandidateResume(params);
        if (!result.isOk) {
          if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency");
          } else if (result.statusCode === 409) {
            ShowAlert(result.data, "Vakency");
          } else {
            ShowAlert('Unable to parse uploaded resume, please try again', "Vakency");
          }
        } else {
          const response = result.data;
          setNewRecord({
            ...newRecord,
            firstName: response?.FirstName || null,
            lastName: response?.LastName || null,
            emailID: response?.EmailID || null,
            mobileNo1: response?.MobileNo1 ? response?.MobileNo1?.replace(/\D/g, "").slice(-10) : null,
            linkedIn: response?.LinkedIn || null,
            // currentAddress: response?.CurrentCityName || null,
            // totalExperience: response?.TotalExperience ? parseInt(response?.TotalExperience) : null,
          });
          // if (response?.MajorTechnicalSkills && response.MajorTechnicalSkills?.length > 0) {
          //   setMajorSkillsValue(response.MajorTechnicalSkills);
          // }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsResumeParsing(false);
      }
    }
  }

  const getProfileByEmailID = async (emailID) => {
    const result = await candidateControlServices.GetProfileByEmailID(emailID);
    if (!result.isOk) {

    } else {
      setDuplicateEmailCandidateDetail(result.data);
      setIsDuplicateEmailFound(true);
      setShowDuplicateEmailTootip(true);
    }
  }

  const GetProfileByMobileNo = async (mobileNo) => {
    const result = await candidateControlServices.GetProfileByMobileNo(mobileNo);
    if (!result.isOk) {

    } else {
      setDuplicateMobileCandidateDetail(result.data);
      setIsDuplicateMobileFound(true);
      setShowDuplicateMobileTootip(true);
    }
  }

  const clearEmailDuplicationCheckData = () => {
    setDuplicateEmailCandidateDetail(null);
    setIsDuplicateEmailFound(false);
    setShowDuplicateEmailTootip(false);
  }

  const clearMobileDuplicationCheckData = () => {
    setDuplicateMobileCandidateDetail(null);
    setIsDuplicateMobileFound(false);
    setShowDuplicateMobileTootip(false);
  }

  const onHidingEmailTooltip = () => {
    setShowDuplicateEmailTootip(false);
  }

  const onHidingMobileTooltip = () => {
    setShowDuplicateMobileTootip(false);
  }

  const editCandidateEmailID = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateEmailTootip(false);
    showPopupOpenType('EDIT_EMAIL');
  }, [setShowCandidateEditorPopup, setShowDuplicateEmailTootip, showPopupOpenType]);

  const editCandidateMobileNo = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateMobileTootip(false);
    showPopupOpenType('EDIT_MOBILE');
  }, [setShowCandidateEditorPopup, setShowDuplicateMobileTootip, showPopupOpenType]);

  const emailDuplicateButton = {
    elementAttr: {
      id: "duplicate_emailID"
    },
    visible: isDuplicateEmailFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateEmailTootip(!showDuplicateEmailTootip);
    }
  };

  const mobileDuplicateButton = {
    elementAttr: {
      id: "duplicate_mobileNo"
    },
    visible: isDuplicateMobileFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateMobileTootip(!showDuplicateMobileTootip);
    }
  };

  
   
  return (
    <div className="CandidateSource_popup">
      <Popup
        visible={showCandidatePopup}
        showTitle={true}
        titleRender={PopupTitle}
        width={isExSmall || isXXSmall || isXSmall ? "90%" : 750}
        height={"auto"}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"97vh"}
        onShown={onShown}
      >
        <LoadPanel
          visible={isResumeParsing}
          message="Parsing resume, please wait..."
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          wrapperAttr={{
            class: 'custom_loadpanel'
          }}
        />
        <ScrollView onScroll={(e)=>closeDateBoxOnScroll(DateBoxRef,e)} height={"100%"} width={"100%"} useNative={true}>
        <form onSubmit={handleSubmit} id="CandidatePopUp_Dropdown">
          <div className="px-3 pt-2">
            {
              showSkeleton ? (
                <div>
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  {
                    showMoreInfo && (
                      <>
                        <PopupSkeleton />
                        <PopupSkeleton />
                        <PopupSkeleton />
                      </>
                    )
                  }
                </div>
              ) : (
                <>
                  {(isExSmall || isXXSmall || isXSmall) && (
                    <>
                      <div className="candidate-picture-sm">
                        <CandidatePictureUploader
                          newRecord={profileImg}
                          setNewRecord={setProfileImg}
                        />
                      </div>
                    </>
                  )}
                  <div className="row">
                    <div className="col-xxl col-xl col-lg col-md col-sm">
                      <div className="row">
                        <div className="col-xxl col-xl col-lg col-md col-sm pe-1">
                          <JobProfileDropDown
                            FocusedFeild={FocusedFeild}
                            setAddedJobProfileID={setAddedJobProfileID}
                            setDropDownClick={setDropDownJobProfileClick}
                            validationRequired={true}
                            validationGroup={ValidationGroupName}
                            value={
                              props.jobProfileID
                                ? props.jobProfileID
                                : newRecord?.jobProfileID
                            }
                            onValueChange={onJobProfileValueChange}
                            dropdownHeight={180}
                            readOnly={
                              props?.JobProfileDropDownReadOnly ? true : false
                            }
                          />
                        </div>
                        {props?.hideJobOpening ? (
                          <></>
                        ) : (
                          <>
                            <div className="col-auto px-0 pt-3 text-gray font-regular-12">
                              OR
                            </div>
                            <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 ps-1">
                              <JobOpeningDropDown
                                // validationRequired={true}
                                validationGroup={ValidationGroupName}
                                value={
                                  props.jobOpeningID
                                    ? props.jobOpeningID
                                    : newRecord.jobOpeningID
                                }
                                onValueChange={(e) =>
                                  setNewRecord({ ...newRecord, jobOpeningID: e })
                                }
                                dropdownHeight={180}
                                setDropDownClick={setDropDownJobOpeningClick}
                                setAddedJobOpeningID={setAddedJobOpeningID}
                                readOnly={
                                  props?.JobOpeningDropDownReadOnly ? true : false
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row mt-3">
                        <div className="col-xxl col-xl col-lg col-md col-sm">
                          <TextBox
                            label="First Name"
                            labelMode="floating"
                            maxLength={50}
                            value={newRecord.firstName}
                            onValueChange={(e) =>
                              setNewRecord({ ...newRecord, firstName: e })
                            }
                          >
                            <Validator
                              validationGroup={ValidationGroupName}
                              elementAttr={{ class: "ForValidationAstrikMark" }}
                            >
                              <RequiredRule message="First Name is required" />
                            </Validator>
                          </TextBox>
                        </div>
                        <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                          <TextBox
                            label="Last Name"
                            labelMode="floating"
                            maxLength={50}
                            value={newRecord.lastName}
                            onValueChange={(e) =>
                              setNewRecord({ ...newRecord, lastName: e })
                            }
                          >
                            <Validator
                              validationGroup={ValidationGroupName}
                              elementAttr={{ class: "ForValidationAstrikMark" }}
                            >
                              <RequiredRule message="Last Name is required" />
                            </Validator>
                          </TextBox>
                        </div>
                      </div>
                      <div className="row mt-3">
                      <div className="col-md-8">
                        <TextBox
                          label="Candidate Name"
                          labelMode="floating"
                          maxLength={50}
                          readOnly={true}
                          value={candidateFullName()}
                        ></TextBox>
                        </div>
                        <div className="col-md-4 mt-3 mt-md-0">
                        <DateBox
                        ref={DateBoxRef}
                        label="DOB"
                        labelMode="floating"
                        maxLength={50}
                        value={newRecord.dob ? new Date(newRecord.dob) : null}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, dob: e ? new Date(e) : null })
                        }
                        useMaskBehavior={true}
                        displayFormat="dd/MM/yyyy"
                        dateOutOfRangeMessage="Invalid Date"
                        min={minDate}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                        >
                          <RangeRule
                            message="Candidate must be 14 years old"
                            max={DOBRangeRule}
                          />
                        </Validator>
                      </DateBox>
                        </div>
                      </div>
                    </div>
                    {!isExSmall && !isXXSmall && !isXSmall && (
                      <div className="w-auto">
                        <CandidatePictureUploader
                          newRecord={profileImg}
                          setNewRecord={setProfileImg}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row mt-3">
                    <div className="col-xxl col-xl col-lg col-md col-sm">
                      <NumberBox
                        label="Mobile No."
                        labelMode="floating"
                        value={newRecord?.mobileNo1}
                        maxLength={15}
                        format="#"
                        inputAttr={{
                          maxLength: 15,
                        }}
                        step={0}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, mobileNo1: e })
                        }
                      >
                        <NumberBoxButton
                            location="after"
                            name="duplicate_mobileNo"
                            id="duplicate_mobileNo"
                            options={mobileDuplicateButton}
                          />
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                          onValidated={onValidateMobileField}
                        >
                          <RequiredRule message="Mobile No. is required" />
                          <StringLengthRule
                            message="Mobile No. must have 10 digits"
                            min={10}
                          />
                          <AsyncRule
                            reevaluate={false}
                            message="Can't accept duplicate Mobile No."
                            validationCallback={asyncMobileNoValidation}
                          />
                        </Validator>
                      </NumberBox>
                      <Tooltip
                          position={"top"}
                          target={`#duplicate_mobileNo`}
                          className="custom-tooltip"
                          hideOnOutsideClick={true}
                          visible={showDuplicateMobileTootip}
                          onHiding={onHidingMobileTooltip}
                          hideOnParentScroll={true}
                        >
                          <div className="p-2 text-wrap">
                            <div className="font-semiBold-16">
                              {duplicateMobileCandidateDetail?.candidateName}
                              <span>
                                <Button
                                  icon="edit"
                                  stylingMode="text"
                                  hint="Edit Candidate"
                                  type="default"
                                  className="ps-1 resume-edit-btn"
                                  onClick={editCandidateMobileNo}
                                  focusStateEnabled={false}
                                  hoverStateEnabled={false}
                                  activeStateEnabled={false}
                                />
                              </span>
                            </div>
                            <div className="font-semiBold-12 text-gray">
                              {duplicateMobileCandidateDetail?.jobProfileName}
                            </div>
                            <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                              <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                              This mobile no is already assigned to the above candidate.
                            </div>
                          </div>
                        </Tooltip>
                    </div>
                    <div className="col-xxl col-xl col-lg col-md col-sm pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                      <TextBox
                        label="Email ID"
                        maxLength={50}
                        labelMode="floating"
                        value={newRecord?.emailID}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, emailID: e })
                        }
                      >
                         <TextBoxButton
                            location="after"
                            name="duplicate_emailID"
                            id="duplicate_emailID"
                            options={emailDuplicateButton}
                          />
                         <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                            onValidated={onValidateEmailField}
                          >
                          <RequiredRule message="Email ID is required" />
                          <EmailRule message="Email ID is invalid" />
                          <AsyncRule
                            reevaluate={false}
                            message="Can't accept duplicate email ID"
                            validationCallback={asyncEmailIDValidation}
                          />
                        </Validator>
                      </TextBox>
                      <Tooltip
                          position={"top"}
                          target={`#duplicate_emailID`}
                          className="custom-tooltip"
                          hideOnOutsideClick={true}
                          visible={showDuplicateEmailTootip}
                          onHiding={onHidingEmailTooltip}
                          hideOnParentScroll={true}
                        >
                          <div className="p-2 text-wrap">
                            <div className="font-semiBold-16">
                              {duplicateEmailCandidateDetail?.candidateName}
                              <span>
                                <Button
                                  icon="edit"
                                  stylingMode="text"
                                  hint="Edit Candidate"
                                  type="default"
                                  className="ps-1 resume-edit-btn"
                                  onClick={editCandidateEmailID}
                                  focusStateEnabled={false}
                                  hoverStateEnabled={false}
                                  activeStateEnabled={false}
                                />
                              </span>
                            </div>
                            <div className="font-semiBold-12 text-gray">
                              {duplicateEmailCandidateDetail?.jobProfileName}
                            </div>
                            <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                              <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                              This email ID is already assigned to the above candidate.
                            </div>
                          </div>
                        </Tooltip>
                    </div>
                    <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                      <TextBox
                        label="Linkedin"
                        labelMode="floating"
                        maxLength={200}
                        value={newRecord.linkedIn}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, linkedIn: e ? e.includes("https://" ) ? e : "https://" + e : null  })
                        }
                      ></TextBox>
                    </div>
                  </div>
                  <div className="font-semiBold-14 pt-3">
                     Current Address Information
                  </div>
                  <div className="row pt-1">
                     <div className="col-md-4">
                      <TextBox
                        label="Current Address"
                        labelMode="floating"
                        maxLength={50}
                        value={newRecord.currentAddress}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, currentAddress: e })
                        }
                       ></TextBox>
                     </div>
                     <div className="col-md-4 pt-3 pt-md-0">
                       <StateDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.currentStateID}
                          onValueChange={handleCurrentStateValueChange}
                          setDropDownClick={setDropDownStateClick}
                          setAddedCityStateID={setAddedStateID}
                          newAddedStateID={newAddedStateID}
                          setNewAddedStateID={setNewAddedStateID}
                          dropdownHeight={180}
                                              />
                    </div>
                     <div className="col-md-4  pt-3 pt-md-0">
                        <CityDropDown
                             validationRequired={false}
                             validationGroup={ValidationGroupName}
                             value={newRecord?.currentCityID}
                             cityStateID={newRecord?.currentStateID}
                             onValueChange={(e) =>
                              setNewRecord({ ...newRecord, currentCityID: e })
                             }
                             setDropDownClick={setDropDownCityClick}
                             setAddedCityID={setAddedCityID}
                             dropdownHeight={180}
                            />
                     </div>
                  </div>
                  <div className="font-semiBold-14 pt-3">
                  Permanent Address Information
                  </div>
                  <div className="row pt-1">
                     <div className="col-md-4">
                        <TextBox
                          label="Permanent Address"
                          labelMode="floating"
                          maxLength={255}
                          value={newRecord?.permanentAddress}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, permanentAddress: e })
                          }
                          />
                     </div>
                     <div className="col-md-4  pt-3 pt-md-0">
                      <StateDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.permanentStateID}
                          onValueChange={handlePermanentStateValueChange}
                          setDropDownClick={setDropDownPermanentStateClick}
                          setAddedCityStateID={setAddedPermanentStateID}
                          newAddedStateID={newAddedStateID}
                          setNewAddedStateID={setNewAddedStateID}
                          dropdownHeight={180}
                      />
                    </div>
                     <div className="col-md-4  pt-3 pt-md-0">
                         <CityDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.permanentCityID}
                          cityStateID={newRecord?.permanentStateID}
                          onValueChange={(e) =>
                              setNewRecord({ ...newRecord, permanentCityID: e })
                          }
                          setDropDownClick={setDropDownPermanentCityClick}
                          setAddedCityID={setAddedPermanentCityID}
                          dropdownHeight={180}
                        />
                     </div>
                  </div>
                  <div className="row d-flex my-3">
                    <div className="col-md-auto editor-label pt-3 mt-2 pe-3">
                      Resume
                    </div>
                    <div className="col-md-9 col-lg-8  col resumeUpload mx-2 mx-md-0 d-flex border rounded align-items-center">
                      <FileUploader
                        ref={UploadResumeRef}
                        accept="application/pdf"
                        name="candidate resume"
                        multiple={false}
                        uploadMode={"useForm"}
                        selectButtonText="Browse"
                        allowCanceling={false}
                        labelText=""
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        uploadedMessage="Resume Uploaded"
                        readyToUploadMessage=""
                        className=""
                        inputAttr={{ 'aria-label': 'Browse' }}
                        onValueChange={onUploadResumeValueChanged}
                        value={resume?.uploadedFile?.fileData ? [resume?.uploadedFile?.fileData] : []}
                      />
                      {resume?.uploadedFile?.fileData && (
                        <>
                          <Button
                            icon="close"
                            id="Close"
                            onClick={onClickClearResume}
                            stylingMode="text"
                            type="default"
                            hint="Close"
                          />
                          {
                            filename && (
                              <Button
                                icon="download"
                                id="Download"
                                onClick={() => DownloadResume(props?.candidateId)}
                                stylingMode="text"
                                type="default"
                                hint="Download"
                              />
                            )
                          }
                        </>
                      )}
                    </div>
                  </div>
                  <Button
                    text="More Information"
                    stylingMode="text"
                    type="default"
                    className="more-infor-btn"
                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                  />
                  {showMoreInfo && (
                    <>
                      <div className="mt-2">
                        <div className="row">
                          <div className="col-xxl col-xl col-lg ">
                            <div className="row">
                              <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                                Current CTC:
                              </div>
                              <div className="col">
                                <NumberBox
                                  label="Yearly"
                                  labelMode="floating"
                                  maxLength={15}
                                  min={0}
                                  format="##.##"
                                  value={newRecord?.currentCTC && newRecord?.currentCTC?.toFixed(2)}
                                  onValueChange={(e) => {
                                    setNewRecord({ ...newRecord, currentCTC: e })
                                    setCurrentCTCMonthlyValue((e * 100000) / 12)
                                  }}
                                  onKeyDown={onKeyDown_Withpoint}
                                  className="number-box-btn"
                                >
                                  <NumberBoxButton
                                    location="after"
                                    name="current_ctc_yearly"
                                    options={{
                                      text: 'LPA',
                                      stylingMode: 'text',
                                      hoverStateEnabled: false,
                                      focusStateEnabled: false,
                                      activeStateEnabled: false,
                                    }}
                                  />
                                </NumberBox>
                              </div>
                              <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                              <div className="col">
                                <NumberBox
                                  label="Monthly"
                                  labelMode="floating"
                                  value={currentCTCMonthlyValue && currentCTCMonthlyValue.toFixed(2)}
                                  maxLength={15}
                                  min={0}
                                  format="##.##"
                                  onValueChange={(e) => {
                                    setCurrentCTCMonthlyValue(e);
                                    setNewRecord({ ...newRecord, currentCTC: ((e * 12) / 100000) })
                                  }}
                                  onKeyDown={onKeyDown_Withpoint}
                                  className="number-box-btn"
                                >
                                  <NumberBoxButton
                                    location="after"
                                    name="current_ctc_monthly"
                                    options={{
                                      text: 'month',
                                      stylingMode: 'text',
                                      hoverStateEnabled: false,
                                      focusStateEnabled: false,
                                      activeStateEnabled: false,
                                    }}
                                  />
                                </NumberBox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0 pt-3">
                            <div className="row">
                              <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                                Expected CTC:
                              </div>
                              <div className="col">
                                <NumberBox
                                  label="Yearly"
                                  min={0}
                                  labelMode="floating"
                                  maxLength={15}
                                  format="##.##"
                                  value={newRecord?.expectedCTC && newRecord?.expectedCTC?.toFixed(2)}
                                  onValueChange={(e) => {
                                    setNewRecord({ ...newRecord, expectedCTC: e })
                                    setExpectedCTCMonthlyValue((e * 100000) / 12)
                                  }}
                                  onKeyDown={onKeyDown_Withpoint}
                                  className="number-box-btn"
                                >
                                  <NumberBoxButton
                                    location="after"
                                    name="expected_ctc_yearly"
                                    options={{
                                      text: 'LPA',
                                      stylingMode: 'text',
                                      hoverStateEnabled: false,
                                      focusStateEnabled: false,
                                      activeStateEnabled: false,
                                    }}
                                  />
                                </NumberBox>
                              </div>
                              <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                              <div className="col">
                                <NumberBox
                                  label="Monthly"
                                  labelMode="floating"
                                  value={expectedCTCMonthlyValue && expectedCTCMonthlyValue.toFixed(2)}
                                  maxLength={15}
                                  min={0}
                                  format="##.##"
                                  onValueChange={(e) => {
                                    setExpectedCTCMonthlyValue(e);
                                    setNewRecord({ ...newRecord, expectedCTC: ((e * 12) / 100000) })
                                  }}
                                  onKeyDown={onKeyDown_Withpoint}
                                  className="number-box-btn"
                                >
                                  <NumberBoxButton
                                    location="after"
                                    name="expected_ctc_monthly"
                                    options={{
                                      text: 'month',
                                      stylingMode: 'text',
                                      hoverStateEnabled: false,
                                      focusStateEnabled: false,
                                      activeStateEnabled: false,
                                    }}
                                  />
                                </NumberBox>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row pt-3">
                          <div className="col-md-4">
                            <NumberBox label="Total Experience" labelMode="floating"
                              maxLength={6}
                              value={newRecord?.totalExperience}
                              onValueChange={handleTotalExperienceChange}
                              onKeyDown={onKeyDown_Withpoint}
                              format="##.##"
                              inputAttr={{ maxLength: 6 }}
                              step={0}
                              min={0}
                              max={999}
                            />
                          </div>
                          <div className="col-md-4 pt-3 pt-md-0">
                          <NumberBox
                            label="Relevant Experience"
                            labelMode="floating"
                            maxLength={6}
                            min={0}
                            max={999}
                            format="##.##"
                            step={0}
                            inputAttr={{ maxLength: 6 }}
                            onKeyDown={onKeyDown_Withpoint}
                            value={newRecord?.relevantExperience}
                            onValueChange={handleReleventExperienceChange}
                        />
                          </div>
                          <div className="col-md-4 pt-3 pt-md-0">
                            <TextBox
                              label="Reason For Leaving"
                              labelMode="floating"
                              value={newRecord?.reasonForLeaving}
                              maxLength={100}
                              onValueChange={(e) =>
                                setNewRecord({ ...newRecord, reasonForLeaving: e })
                              }
                            />
                          </div>
                        </div>
                        <div className="row pt-3">
                          <div className="col-md-4 pt-3 pt-md-3">
                          <TextBox
                          label="Current Company Name"
                          labelMode="floating"
                          value={newRecord?.currentCompanyName}
                          maxLength={100}
                          onValueChange={(e) =>
                              setNewRecord({ ...newRecord, currentCompanyName: e })
                          }
                       />
                          </div>
                                <div className="col-md-4 pt-3 pt-md-3">
                                      <AcademicQualificationDropDown
                                        validationRequired={false}
                                        validationGroup={ValidationGroupName}
                                        value={newRecord?.academicQualificationID}
                                        onValueChange={(e) =>
                                            setNewRecord({
                                                ...newRecord,
                                                academicQualificationID: e,
                                            })
                                        }
                                        setDropDownClick={setDropDownAcademicQualificationClick}
                                        setAddedacademicQualificationID={
                                            setAddedAcademicQualificationID
                                        }
                                        dropdownHeight={100}
                                        />
                                        </div>
                                        <div className="col-md-4 pt-3 pt-md-3">
                                        {newRecord?.academicQualificationID && (
                                            <SelectBox
                                            label="Year"
                                            labelMode="floating"
                                            items={DsYearDropdownValues}
                                            id="CandidateYear_DropDown"
                                            value={newRecord?.academicQualificationYear}
                                            onValueChange={(e) =>
                                              setNewRecord({
                                                ...newRecord,
                                                academicQualificationYear: e,
                                              })
                                            }
                                            dropDownOptions={{
                                              height: 100,
                                              hideOnParentScroll: true,
                                              container: "#CandidateYear_DropDown"
                                            }}
                                          />
                                        )}
                                </div>
                            </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="row pt-3">
                              <div className="col">
                                <NumberBox
                                  label="Notice Period (Days)"
                                  labelMode="floating"
                                  value={newRecord?.noticePeriod}
                                  maxLength={3}
                                  max={255}
                                  onKeyDown={onKeyDown}
                                  inputAttr={{ maxLength: 3 }}
                                  step={0}
                                  onValueChange={(e) =>
                                    setNewRecord({ ...newRecord, noticePeriod: e })
                                  }
                                />
                              </div>
                              <div className="col py-2">
                                <CheckBox
                                  text="Ready To Relocate"
                                  value={newRecord?.readyToRelocate || false}
                                  onValueChange={(e) =>
                                    setNewRecord({ ...newRecord, readyToRelocate: e })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 pt-3 pt-md-2">
                              <SelectBox
                                  label="Marital Status"
                                  id="Candidate_MaritalStatus_Dropdown"
                                  labelMode="floating"
                                  dataSource={dsMaritalStatus}
                                  valueExpr={"maritalStatusID"}
                                  displayExpr={"maritalStatus"}
                                  value={newRecord?.maritalStatusID}
                                  onValueChange={(e) =>
                                      setNewRecord({ ...newRecord, maritalStatusID: e })
                                  }
                                  dropDownOptions={
                                   {
                                    container: "#Candidate_MaritalStatus_Dropdown",
                                    height:65,
                                   }
                                  }
                              />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            }
          </div>
          <PopupFooter
            ValidationGroupName={ValidationGroupName}
            openPopup={props.openPopup}
            setOpenPopup={props.setOpenPopup}
          />
        </form>
        </ScrollView>
        {
          showCandidateEditorPopup && (
            <CandidateEditorPopup
              isOpen={showCandidateEditorPopup}
              onClose={() => setShowCandidateEditorPopup(false)}
              candidateData={popupOpenType == "EDIT_EMAIL" ? duplicateEmailCandidateDetail : duplicateMobileCandidateDetail}
              editEmailID={popupOpenType == "EDIT_EMAIL"}
              editMobileNo={popupOpenType == "EDIT_MOBILE"}
              setStatus={popupOpenType == "EDIT_EMAIL" ? setEditEmailStatus : setEditMobileStatus}
            />
          )
        }
      </Popup>
      {
        openResumeUploader && (
          <ResumeUploader
            resume={resume}
            setResume={setResume}
            isOpen={openResumeUploader}
            onClose={onHidingResumeUploder}
            handleResumeParsing={handleResumeParsing}
            openFrom={"CandidateDropDownPopup"}
          />
        )
      }
    </div >
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  SelectBox,
  Button as TextBoxButton,
} from "devextreme-react/select-box";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/validator";
import StatePopup from "./StatePopup";
import { ShowAlert, eCRUDStatus } from "../../../utils/common-methods";
import { StateServices } from "../../../api/services/StateServices";

export default function StateDropDown(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const stateServices = new StateServices();

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    openPopup === false &&
      props.setDropDownClick &&
      props.setDropDownClick(false);
  }, [openPopup]);

  useEffect(() => {
    StateLookUpList();
  }, [props?.newAddedStateID]);

  const StateLookUpList = async () => {
    const result = await stateServices.GetLookupListData();
    const response = result.data;
    setStateList(response);
  };

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await stateServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setStateList([result.data[0], ...stateList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setStateList(
          stateList.map((i) =>
            i.cityStateID === primaryKey ? result.data[0] : i
          )
        );
      }
    }
    props.setAddedCityStateID && props.setAddedCityStateID(primaryKey);
    props.setNewAddedStateID && props.setNewAddedStateID(primaryKey);
  };

  const AddButton = {
    icon: "plus",
    stylingMode: "text",
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup);
    },
  };

  const DownArrow = {
    icon: "spindown",
    stylingMode: "text",
    onClick: (e) => {
      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    },
  };

  return (
    <div>
      <SelectBox
        label="State"
        id={props.DropDownId ? props.DropDownId : 'State_DropDown'}
        searchEnabled={true}
        ref={SelectBoxRef}
        labelMode="floating"
        dataSource={stateList}
        displayExpr="stateName"
        valueExpr="cityStateID"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll: true,
          container: props.DropDownId ? "#"+props.DropDownId : "#State_DropDown",
        }}
        inputAttr={{
          autocomplete: "new",
        }}
        mode="tel"
      >
        <TextBoxButton name="State" location="after" options={AddButton} />
        <TextBoxButton name="dropdown" location="after" options={DownArrow} />
        {props.validationRequired && (
          <Validator
            validationGroup={props.validationGroup}
            elementAttr={{ class: "ForValidationAstrikMark" }}
          >
            <RequiredRule
              message={
                props.validationMessage
                  ? props.validationMessage
                  : "State is required"
              }
            />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true && (
        <StatePopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ValidationGroupName={props.validationGroup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />
      )}
    </div>
  );
}

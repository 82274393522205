import React, { useEffect, useRef, useState } from 'react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import { Popup, ScrollView, TagBox, TextBox, Validator } from 'devextreme-react';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { SkillControlName } from '../../../contexts/APIurl';
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { ConflictPopup, ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AsyncRule, CustomRule } from 'devextreme-react/validator';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { JobProfileServices } from '../../../api/services/JobProfileServices';

const jobProfileServices = new JobProfileServices();

export default function JobProfilePopup(props) {

  const ValidationGroupName = "JobProfilePopupValidation"

  const { isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const onShown = () => { FocusedFeild.current?.instance.focus() }
  const FocusedFeild = useRef(null);
  const htmlEditorRef = useRef(null);
  let PrimaryKeyID = props.primaryKey;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [formattedText, setFormattedText] = useState();
  const [MinorSkillsValue, setMinorSkillsValue] = useState([]);
  const [MajorSkillsValue, setMajorSkillsValue] = useState([]);
  const [DsSkills, setDsSkills] = useState([])

  useEffect(() => {
    if (PrimaryKeyID !== null && PrimaryKeyID !== 0) {
      GetModelRecord();
    } else {
      setNewRecord({});
      setShowSkeleton(false);
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    if (!showSkeleton && PrimaryKeyID && PrimaryKeyID !== 0) {
      formattedText && htmlEditorRef.current?.instance.option("value", formattedText);
    }
  }, [showSkeleton])

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus()
  }, [showSkeleton])

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await jobProfileServices.GetModelData(PrimaryKeyID);
      if (result.statusCode === 200) {
        const response = result.data;
        GetTagBoxValues(response)
        setNewRecord(response);
        setOriginalRecord(response)
        setFormattedText(response.descrFormattedText);
        setShowSkeleton(false);
      }
    }, 1000);
  };


  const GetTagBoxValues = (data) => {
    if (data.minorSkills !== "") {
      let MinorSkillsValue = data.minorSkills?.split(",");
      setMinorSkillsValue(MinorSkillsValue)
    }
    if (data.majorSkills !== "") {
      let MajorSkillsValue = data.majorSkills?.split(",");
      setMajorSkillsValue(MajorSkillsValue)
    }
  }


  useEffect(() => {
    GetSkillsLookupList();
  }, [])


  const GetSkillsLookupList = async () => {
    const result = await jobProfileServices.GetSkillLookupListData(SkillControlName);
    if (result.isOk) {
      setDsSkills(result.data)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!PrimaryKeyID) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  };

  const InsertRecord = async () => {
    setShowSkeleton(true);
    var descrText = htmlEditorRef.current?.instance.getQuillInstance();
    var FormattedText = htmlEditorRef.current?.instance.option("value");
    const descrPlainText = descrText.getText();
    let dataToInsert = {
      jobProfileName: newRecord.jobProfileName,
      descrPlainText: descrPlainText && descrPlainText?.trim() !== "" ? descrPlainText?.trim() : null,
      descrFormattedText: FormattedText?.trim(),
      majorSkills: MajorSkillsValue?.join(",") || null,
      minorSkills: MinorSkillsValue?.join(",") || null,
    };

    const result = await jobProfileServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }

      setShowSkeleton(false);

    } else {

      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });

      onClosePopup();

      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    if (originalRecord == newRecord || (originalRecord !== newRecord)) {
      setShowSkeleton(true);
      var descrText = htmlEditorRef.current?.instance.getQuillInstance();
      var FormattedText = htmlEditorRef.current?.instance.option("value");
      const descrPlainText = descrText.getText();
      var dataToUpdate = {
        updatedOn: newRecord.updatedOn,
        jobProfileID: newRecord.jobProfileID,
        jobProfileName: newRecord.jobProfileName,
        descrPlainText: descrPlainText && descrPlainText?.trim() !== "" ? descrPlainText?.trim() : null,
        descrFormattedText: FormattedText?.trim(),
        majorSkills: MajorSkillsValue?.join(",") || null,
        minorSkills: MinorSkillsValue?.join(",") || null,
      };

      const result = await jobProfileServices.UpdateData(dataToUpdate);
      if (!result.isOk) {
        if (result.statusCode === 409) {
          ConflictPopup(result.data).then((res) => {
            if (res) {
              GetModelRecord();
            } else {
              return;
            }
          });
          setShowSkeleton(false);
        } else if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency");
          setShowSkeleton(false);
        }
      } else {
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID: result.data.responseData,
        });
        onClosePopup();
        setShowSkeleton(false);
      }
    }
  };



  const onTagBoxSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      e.component.field().value = "";
    }
  }

  function onMajorSkillsValueChange(e) {
    if (e.value === null) {
      setMajorSkillsValue([]);
    } else {
      const checkMajorSameSkill = MinorSkillsValue?.find(item => {
        return e.value?.some(value => item?.toLowerCase() === value?.toLowerCase());
      });

      if (checkMajorSameSkill) {
        ShowAlert("Cannot accept same skill in major and minor", "Vakency");
      } else {
        setMajorSkillsValue(e.value);
      }
    }
  }

  function onMinorSkillsValueChange(e) {
    if (e.value === null) {
      setMinorSkillsValue([]);
    } else {
      const checkMinorSameSkill = MajorSkillsValue?.find(item => {
        return e.value?.some(value => item?.toLowerCase() === value?.toLowerCase());
      });

      if (checkMinorSameSkill) {
        ShowAlert("Cannot accept same skill in major and minor", "Vakency");
      } else {
        setMinorSkillsValue(e.value);
      }
    }
  }

  const onCustomMajorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = DsSkills;

    const CheckSkillDatSource = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckSkillDatSource) {
      arg.customItem = CheckSkillDatSource;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const onCustomMinorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = DsSkills;

    const CheckMajorDuplicate = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckMajorDuplicate) {
      arg.customItem = CheckMajorDuplicate;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };


  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
  ];


  const onClosePopup = () => {
    props.setOpenPopup(false)
    setNewRecord({})
  }


  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[<span key={"header_title"} className="base-accent-text">{props.primaryKey ? "Edit" : "New"}</span>, " Job Profile"]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    )
  }

  const asyncJobProfileNameValidation = async (e) => {
    // const Name = e.value;
    try {
      const Name = encodeURIComponent(e?.value);
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;

      const result = await jobProfileServices.CheckDuplicateData(CheckDuplicateParams);
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncJobProfileNameValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  };


  return (
    <div className='JobProfile_popup'>
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={(isXSmall || isXXSmall || isExSmall) ? "90%" : 750}
        height={'auto'}
        wrapperAttr={{ class: "CustomPopup" }}
        onShown={onShown}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form onSubmit={handleSubmit} className='Job-Profile-Popup'>
            {showSkeleton ?
              <div className='p-2 pb-0'>
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
              :
              <>
                <div className='py-2 px-3'>
                  <TextBox
                    label='Job Profile Name'
                    maxLength={50}
                    labelMode='floating'
                    value={newRecord.jobProfileName}
                    ref={FocusedFeild}
                    onValueChange={(e) => setNewRecord({ ...newRecord, jobProfileName: e })}
                  >
                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                      <RequiredRule message='Job Profile Name is required' />
                      {/* <CustomRule
                        ignoreEmptyValue={true}
                        message="Can't accept duplicate Job Profile Name"
                        validationCallback={asyncJobProfileNameValidation}
                      /> */}
                      <AsyncRule
                        reevaluate={false}
                        message="Can't accept duplicate Job Profile Name"
                        validationCallback={asyncJobProfileNameValidation}
                      />
                    </Validator>
                  </TextBox>
                </div>
                <div className='row mx-0'>
                  <div className='col-md-6 py-2 ps-3 pe-md-2 pe-3'>
                    <TagBox
                      placeholder=""
                      labelMode={"floating"}
                      label="Major Skills"
                      value={MajorSkillsValue}
                      searchEnabled={true}
                      id="JobProfile_MajorTechnicalSkill_DropDown"
                      dataSource={DsSkills}
                      valueExpr="skillName"
                      displayExpr="skillName"
                      showClearButton={true}
                      onValueChanged={onMajorSkillsValueChange}
                      dropDownOptions={{
                        height: 180,
                        hideOnParentScroll:true,
                        container:"#JobProfile_MajorTechnicalSkill_DropDown",
                      }}
                      showDropDownButton={true}
                      hideSelectedItems={true}
                      multiline={false}
                      onSelectionChanged={onTagBoxSelectionChanged}
                      selectAllMode="allPages"
                      showMultiTagOnly={false}
                      acceptCustomValue={true}
                      onCustomItemCreating={onCustomMajorSkillCreating}
                      maxLength={50}
                    >
                    </TagBox>
                  </div>
                  <div className='col-md-6 py-2 ps-md-2 ps-3 pe-3'>
                    <TagBox
                      placeholder=""
                      labelMode={"floating"}
                      label="Minor Skills"
                      value={MinorSkillsValue}
                      searchEnabled={true}
                      id="JobProfile_MinorTechnicalSkill_DropDown"
                      valueExpr="skillName"
                      displayExpr="skillName"
                      showClearButton={true}
                      dataSource={DsSkills}
                      onValueChanged={onMinorSkillsValueChange}
                      dropDownOptions={{
                        height: 180,
                        hideOnParentScroll:true,
                        container:"#JobProfile_MinorTechnicalSkill_DropDown",
                      }}
                      showDropDownButton={true}
                      hideSelectedItems={true}
                      multiline={false}
                      onSelectionChanged={onTagBoxSelectionChanged}
                      selectAllMode="allPages"
                      showMultiTagOnly={false}
                      acceptCustomValue={true}
                      onCustomItemCreating={onCustomMinorSkillCreating}
                      maxLength={50}
                    >
                    </TagBox>
                  </div>
                </div>
                <div className=' py-2 px-3'>
                  <div className='editor-label'>Description</div>

                  <HtmlEditor
                    ref={htmlEditorRef}
                    height="250px"
                    valueType='html'
                    elementAttr={htmlEditorSecondAttributes}
                    className='CustomizeHtmlEditor'
                    tabIndex={-1}
                  >
                    <HtmlEditorToolbar multiline={false}>
                      <HtmlEditorItem name="link"></HtmlEditorItem>
                      <HtmlEditorItem name="font" acceptedValues={fontValues} />
                      <HtmlEditorItem name="size" acceptedValues={sizeValues} />
                      <HtmlEditorItem name="bold" />
                      <HtmlEditorItem name="italic" />
                      <HtmlEditorItem name="underline" />
                      <HtmlEditorItem name="background" />
                      <HtmlEditorItem name="color" />
                      <HtmlEditorItem name="orderedList" />
                      <HtmlEditorItem name="bulletList" />
                      <HtmlEditorItem name="clear" />
                    </HtmlEditorToolbar>
                  </HtmlEditor>
                </div>
              </>
            }
            {showSkeleton ?
              <div className='d-flex justify-content-end pb-2 pe-2'>
                <div className='skeleton-View Skeleton-popup-footer'></div>
              </div>
              :
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                openPopup={props.openPopup}
                setOpenPopup={props.setOpenPopup}
              />
            }
          </form>
        </ScrollView>
      </Popup>
    </div >
  )
}

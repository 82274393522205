import { Button, LoadIndicator, LoadPanel, TextBox } from 'devextreme-react';
import { EmailRule } from 'devextreme-react/data-grid';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ForgotPassword.scss';
import { forgotPassword } from '../../api/auth';
import { onDragOver } from '../../utils/common-methods';

export default function ForgotPassword(props) {

  const ValidationGroupName = "forgotPasswordValidation";

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: null
  });
  const [showError, setShowError] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.email !== null) {
      props.setShowLoader(true);
      const result = await forgotPassword(formData.email);
      if (result.isOk === false) {
        if (result.statusCode === 400) {
          setShowError(result.data)
          props.setShowLoader(false);
        }
      }
      else {
        setShowError(null)
        localStorage.setItem("forgotPassword_Email", formData.email)
        props.setShowLoader(false);
        navigate("/request-reset-link")
      }
    }
  }

  return (
    <div>
      {props.showLoader ?
        <div className='m-auto text-center loaderCart-height'>
          <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={true} />
          <div className='pt-1'>Please Wait.....</div>
        </div>
        :
        <form onSubmit={handleSubmit} className='px-3 forgot-password-form' onDragOver={onDragOver}>
          <div className='row py-2 '>
            <TextBox
              label='Email ID'
              mode='email'
              labelMode='floating'
              stylingMode='underlined'
              className='py-1 px-0'
              value={formData.email}
              onValueChange={(value) => setFormData({ ...formData, email: value })}
            >
              <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                <RequiredRule message='Email ID is required' />
                <EmailRule message='Email ID is not valid' />
              </Validator>
            </TextBox>
          </div>
          {showError &&
            <div className='text-center text-danger py-1'>
              {showError}
            </div>
          }
          <div className='row py-2 mt-2 justify-content-center'>
            <Button
              width="auto"
              text='Request Reset Link'
              useSubmitBehavior={true}
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              type='default'
              className='reset-button'
              validationGroup={ValidationGroupName}
            >
            </Button>
          </div>
          <div className='row py-2 justify-content-center'>
            <Link to="/login"
              className='request-reset-link w-auto'
            >
              Back to Login
            </Link>
          </div>
        </form>
      }
    </div>
  )
}

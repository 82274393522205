import React from 'react';
import './RequestResetLink.scss';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../../api/auth';
import { ShowAlert } from '../../utils/common-methods';
import { LoadIndicator } from 'devextreme-react';

export default function RequestResetLink() {

  const [isEmailSent, setIsEmailSent] = React.useState(false);

  const sendResetEmail = async () => {
    setIsEmailSent(true);
    try {
      const result = await forgotPassword(localStorage.getItem("forgotPassword_Email"));
      if (!result.isOk) {
        if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency");
        } else if (result.statusCode === 409) {
          ShowAlert(result.data, "Vakency");
        }
      }
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    } finally {
      setIsEmailSent(false);
    }
  }

  return (
    <>
      {
        isEmailSent ? (
          <div className='m-auto text-center loaderCart-height'>
            <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={isEmailSent} />
            <div className='pt-1'>Please Wait.....</div>
          </div>
        ) : (
          <div className='row request-reset-link'>
            <div className=' text-center my-3'><i className='dx-icon-check check-icon p-3 fs-4 rounded-5'></i></div>
            <div className='text-center my-3 message fw-bold '>Reset Link has been sent to your email address.</div>
            <div className=' text-center my-2 instruction'>Please follow given instructions on email to reset your password.</div>
            <div className='row py-2'>
              <div className='text-center font-regular-13'>
                Can't find the email? <button className='text-decoration-underline border-0 bg-transparent email_link' onClick={sendResetEmail}>click to resend the email</button>
              </div>
            </div>
            <div className='text-center my-2'>
              <Link to="/login"
                className='GoToLogin-button fw-semibold'
              >
                Go to Login
              </Link>
            </div>
          </div>
        )
      }
    </>
  )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useScreenSize } from "../../utils/media-query";
import { DataGrid, LoadPanel, Popup, ScrollView, SpeedDialAction, TextBox, Validator } from "devextreme-react";
import HtmlEditor, { Toolbar as HtmlEditorToolbar, Item as HtmlEditorItem, MediaResizing, ImageUpload } from 'devextreme-react/html-editor';
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { useNavigate } from "react-router-dom";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RequiredRule } from "devextreme-react/validator";
import { Column, Paging, Scrolling } from "devextreme-react/data-grid";
import './EmailTemplatePopup.scss';
import { EmailTemplateServices } from "../../api/services/EmailTemplateServices";
import { ShowAlert } from "../../utils/common-methods";
import AddImagePopup from "./AddImagePopup";

const ValidationGroupName = "InterviewerEmailTemplate";

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana',
];
const headerValues = [false, 1, 2, 3, 4, 5];
const fontSizeOptions = {
    inputAttr: {
        'aria-label': 'Font size',
    },
};
const fontFamilyOptions = {
    inputAttr: {
        'aria-label': 'Font family',
    },
};
const headerOptions = {
    inputAttr: {
        'aria-label': 'Font family',
    },
};

const emailTemplateServices = new EmailTemplateServices();

export default function EmailTemplatePopup({
    EmailTemplateType,
    EmailReceiverType,
    title,
    FieldsDataSource,
}) {

    const navigateTo = useNavigate();
    const { isExSmall, isSmall, isXSmall, isXXSmall, isMedium, isLarge, isExLarge } = useScreenSize();
    const subjectTextBoxRef = useRef(null);
    const htmlEditorRef = useRef(null);
    const dataGridRef = useRef(null);

    const [openPopup, setOpenPopup] = useState(true);
    const [showLoader, setShowLoader] = useState(true);
    const [formData, setFormData] = useState({});
    const [isTextBoxFocused, setIsTextBoxFocused] = useState(false);
    const [isHTMLEditorFocused, setIsHTMLEditorFocused] = useState(false);
    const [showAddImagePopup, setShowAddImagePopup] = useState(false);
    const [showGridPanel, setShowGridPanel] = useState(false);

    useEffect(() => {
        GetEmailTemplateModel();
    }, []);

    const GetEmailTemplateModel = async () => {
        const params = `?EmailTemplateType=${EmailTemplateType}&EmailReceiverType=${EmailReceiverType}`;
        try {
            const result = await emailTemplateServices.GetEmailTemplateModel(params);
            if (!result.isOk) {
                if (result.statusCode == 204) {
                    setFormData({
                        emailTemplateTypeID: EmailTemplateType,
                        emailReceiverTypeID: EmailReceiverType,
                    });
                } else {
                    ShowAlert(result.data, "Vakency");
                }
            } else {
                setFormData({ ...result.data });
                htmlEditorRef.current?.instance.option("value", result.data?.emailTemplateFormattextText);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }

    }

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClosePopup}
                    title={title}
                    onSubmit={InsertRecord}
                />
            </>
        );
    };

    const InsertRecord = async () => {
        const dataToUpdate = {
            emailTemplateTypeID: formData?.emailTemplateTypeID || EmailTemplateType,
            emailReceiverTypeID: formData?.emailReceiverTypeID || EmailReceiverType,
            subject: formData.subject,
            emailTemplatePlainText: htmlEditorRef.current?.instance.getQuillInstance().getText(),
            emailTemplateFormattextText: htmlEditorRef.current?.instance.option("value"),
        }
        if (dataToUpdate.emailTemplateFormattextText === null || dataToUpdate.emailTemplateFormattextText === undefined || dataToUpdate.emailTemplateFormattextText === "" || dataToUpdate.emailTemplatePlainText?.trim()?.replace("\n" , "") === "") {
            ShowAlert("The email template body cannot be blank.", "Vakency");
        }
        else{
            try {
                const result = await emailTemplateServices.UpdateEmailTemplate(dataToUpdate);
                if (!result.isOk) {
                    ShowAlert(result.data, "Vakency");
                } else {
                    onClosePopup();
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        InsertRecord();
    }

    const onPopupShown = useCallback(() => {
        subjectTextBoxRef.current?.instance.focus()
    }, []);

    const onClosePopup = useCallback(() => {
        navigateTo("/");
    }, []);

    const onGridRowDblClick = (evt) => {
        if (isTextBoxFocused) {
            const textBoxInstance = subjectTextBoxRef.current.instance;
            const inputElement = textBoxInstance?.element().querySelector('input');
            if (inputElement) {
                const cursorPosition = inputElement.selectionStart; // Get the cursor position
                const textToInsert = evt.data.tag;
                const currentValue = inputElement.value;

                // Insert text at the cursor position
                const updatedValue =
                    currentValue.slice(0, cursorPosition) + textToInsert + currentValue.slice(cursorPosition);

                // Update the TextBox value
                setFormData({ ...formData, subject: updatedValue });

                // Optionally, move the cursor to the end of the inserted text
                const newCursorPosition = cursorPosition + textToInsert.length;
                inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
                textBoxInstance.focus();
            }
        } else if (isHTMLEditorFocused) {
            const htmlEditorInstance = htmlEditorRef.current.instance;
            htmlEditorInstance.insertText(htmlEditorInstance.getSelection(true).index, evt.data.tag);
            htmlEditorInstance.focus();
        }
        setShowGridPanel(false);
    }

    const onFocusInTextBox = useCallback(() => {
        setIsHTMLEditorFocused(false);
        setIsTextBoxFocused(true);
    }, [setIsTextBoxFocused]);

    const onFocusInHTMLEditor = useCallback(() => {
        setIsTextBoxFocused(false);
        setIsHTMLEditorFocused(true);
    }, [setIsHTMLEditorFocused]);

    const fieldNameCellRender = useCallback((e) => {
        return (
            <span className="font-semiBold-14">{e.row.data.name}</span>
        )
    }, []);

    const addImageButtonClick = () => {
        setShowAddImagePopup(true);
    }

    const addImageButtonOptions = useMemo(
        () => ({
            text: 'Add Image',
            stylingMode: 'text',
            onClick: addImageButtonClick,
        }),
        [addImageButtonClick],
    );

    const onCloseAddImagePopup = () => setShowAddImagePopup(false);

    return (
        <>
            <Popup
                visible={openPopup}
                onHiding={onClosePopup}
                height={"auto"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                fullScreen={isExSmall || isXSmall || isXXSmall}
                maxHeight={"90vh"}
                width={(isSmall || isExSmall || isXSmall || isXXSmall) ? '95%' : isMedium ? '80%' : '65%'}
                animation={{
                    show: {
                        type: "slideIn",
                        direction: "top",
                    },
                    hide: {
                        type: "slideOut",
                        direction: "top",
                        duration: 200,
                    },
                }}
                onShown={onPopupShown}
            >
                <LoadPanel
                    visible={showLoader}
                    shading={true}
                    shadingColor="rgba(0,0,0,0.4)"
                />
                <ScrollView height={'100%'} width={'100%'}>
                    <div className="interview-email-template-section" id='popup'>
                        <form onSubmit={handleSubmit}>
                            <div className="row mx-0">
                                <div className={`col-12 ${(!isExSmall && !isXSmall && !isXXSmall) ? 'col-sm-8' : 'col-sm-12'} col-md-7 col-lg-8 col-xxl-9`}>
                                    <div className="row ">
                                        <div className="col-12">
                                            <TextBox
                                                ref={subjectTextBoxRef}
                                                label='Subject'
                                                mode='text'
                                                labelMode='floating'
                                                stylingMode='outlined'
                                                className=''
                                                value={formData.subject}
                                                onValueChange={(value) => setFormData({ ...formData, subject: value })}
                                                onFocusIn={onFocusInTextBox}
                                            >
                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                    <RequiredRule message='Subject is required' />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <HtmlEditor
                                                ref={htmlEditorRef}
                                                height={450}
                                                className="CustomizeHtmlEditor"
                                                id={"JobDescribtionHtmlEditor"}
                                                valueType="html"
                                                onFocusIn={onFocusInHTMLEditor}
                                            // defaultValue={markup}
                                            >
                                                <MediaResizing enabled={true} />
                                                <ImageUpload
                                                    fileUploadMode="base64"
                                                    tabs={['file']}
                                                />
                                                <HtmlEditorToolbar multiline={false}>
                                                    {/* <HtmlEditorItem name="image" /> */}
                                                    <HtmlEditorItem name="link" />
                                                    <HtmlEditorItem name="undo" />
                                                    <HtmlEditorItem name="redo" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem
                                                        name="size"
                                                        acceptedValues={sizeValues}
                                                        options={fontSizeOptions}
                                                    />
                                                    <HtmlEditorItem
                                                        name="font"
                                                        acceptedValues={fontValues}
                                                        options={fontFamilyOptions}
                                                    />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="bold" />
                                                    <HtmlEditorItem name="italic" />
                                                    <HtmlEditorItem name="strike" />
                                                    <HtmlEditorItem name="underline" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="alignLeft" />
                                                    <HtmlEditorItem name="alignCenter" />
                                                    <HtmlEditorItem name="alignRight" />
                                                    <HtmlEditorItem name="alignJustify" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="orderedList" />
                                                    <HtmlEditorItem name="bulletList" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem
                                                        name="header"
                                                        acceptedValues={headerValues}
                                                        options={headerOptions}
                                                    />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="color" />
                                                    <HtmlEditorItem name="background" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="clear" />
                                                    <HtmlEditorItem name="codeBlock" />
                                                    <HtmlEditorItem name="blockquote" />
                                                    <HtmlEditorItem name="separator" />
                                                    <HtmlEditorItem name="insertTable" />
                                                    <HtmlEditorItem name="deleteTable" />
                                                    <HtmlEditorItem name="insertRowAbove" />
                                                    <HtmlEditorItem name="insertRowBelow" />
                                                    <HtmlEditorItem name="deleteRow" />
                                                    <HtmlEditorItem name="insertColumnLeft" />
                                                    <HtmlEditorItem name="insertColumnRight" />
                                                    <HtmlEditorItem name="deleteColumn" />
                                                </HtmlEditorToolbar>
                                            </HtmlEditor>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (!isExSmall && !isXSmall && !isXXSmall) && (
                                        <div className="col-12 col-sm-4 col-md-5 col-lg-4 col-xxl-3">
                                            <div className="custom-card-grid">
                                                <DataGrid
                                                    ref={dataGridRef}
                                                    dataSource={FieldsDataSource}
                                                    className="List_DataGrid"
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    height={498}
                                                    wordWrapEnabled={true}
                                                    onRowDblClick={onGridRowDblClick}
                                                    allowColumnResizing={false}
                                                    focusedRowEnabled={true}
                                                    keyExpr={'id'}
                                                    width={'100%'}
                                                >
                                                    <Scrolling mode={"standard"} />
                                                    <Paging enabled={false} />
                                                    <Column
                                                        alignment="center"
                                                        dataField="name"
                                                        caption="Fields"
                                                        cellRender={fieldNameCellRender}
                                                        width={100}
                                                    />
                                                </DataGrid>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <PopupFooter
                                ValidationGroupName={ValidationGroupName}
                                openPopup={openPopup}
                                setOpenPopup={setOpenPopup}
                            />
                        </form>
                    </div>
                </ScrollView>
                <SpeedDialAction
                    icon="showpanel"
                    hint="Add"
                    onClick={() => setShowGridPanel(!showGridPanel)}
                    visible={isExSmall || isXSmall || isXXSmall}
                    elementAttr={{
                        class: 'speed-dial-btn'
                    }}
                    position={{ my: 'left bottom', at: 'left bottom', of: window, offset: '16 -16' }}
                />
                <Popup
                    visible={showGridPanel}
                    dragEnabled={false}
                    showTitle={false}
                    onHiding={() => setShowGridPanel(false)}
                    hideOnOutsideClick={true}
                    width={280}
                    height={"100%"}
                    position={"right"}
                    wrapperAttr={{ class: "ViewPopup" }}
                    animation={{
                        show: {
                            type: "slideIn",
                            direction: "right",
                        },
                        hide: {
                            type: "slideOut",
                            direction: "right",
                        },
                    }}
                >
                    <div className="custom-card-grid">
                        <DataGrid
                            ref={dataGridRef}
                            dataSource={FieldsDataSource}
                            className="List_DataGrid"
                            showColumnLines={false}
                            showRowLines={true}
                            height={498}
                            wordWrapEnabled={true}
                            onRowDblClick={onGridRowDblClick}
                            allowColumnResizing={false}
                            focusedRowEnabled={true}
                            keyExpr={'id'}
                            width={'100%'}
                        >
                            <Scrolling mode={"standard"} />
                            <Paging enabled={false} />
                            <Column
                                alignment="center"
                                dataField="name"
                                caption="Fields"
                                cellRender={fieldNameCellRender}
                                width={100}
                            />
                        </DataGrid>
                    </div>
                </Popup>
                {showAddImagePopup && <AddImagePopup
                    isOpen={showAddImagePopup}
                    onClose={onCloseAddImagePopup}
                    htmlEditorRef={htmlEditorRef}
                />}
            </Popup>
        </>
    );

}
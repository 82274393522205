import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CheckBox, DateBox, FileUploader, LoadPanel, NumberBox, Popup, ScrollView, SelectBox, TabPanel, TextBox } from 'devextreme-react';
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import './JobApplication.scss';
import { JobOpeningOpenService } from '../../api/services/JobOpeningOpenService';
import { JobApplicationOpenService } from '../../api/services/JobApplicationOpenService';
import { eCRUDStatus, handleNavigateToMap, minDate, onKeyDown, onKeyDown_Withpoint, ShowAlert } from '../../utils/common-methods';
import { NoImage } from '../../utils/base-64-Icons';
import { useScreenSize } from '../../utils/media-query';
import PopupSkeleton from '../../layouts/templates/PopupSkeleton';
import Validator, { EmailRule, RangeRule, RequiredRule, StringLengthRule } from 'devextreme-react/validator';
import PopupSkeletonOneLine from '../../layouts/templates/PopupSkeletonOneLine';
import ResumeUploader from '../../components/resume-uploader/ResumeUploader';
import { custom } from 'devextreme/ui/dialog';
import { dsGenderStatus, dsMaritalStatus } from '../../utils/contants';

const ValidationGroupName = "CandidateValidationGroup"
const jobOpeningOpenServices = new JobOpeningOpenService();
const jobApplicationOpenService = new JobApplicationOpenService();
const maxResumeSizeLength = 5 * 1024 * 1024;

const currentDate = new Date();
const DOBRangeRule = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 18)
);

const CandidateDS = {
    firstName: '',
    lastName: '',
    emailID: '',
    mobileNo: null,
    genderID: null,
    dob: null,
    maritalStatusID: null,
    currentCity: '',
    homeTown: null,
    totalExperience: 0,
    relevantExperience: 0,
    currentCTC: null,
    expectedCTC: null,
    noticePeriod: null,
    readyToRelocate: null,
    reasonForLeaving: null,
    currentCompanyName: null,
    // acceptTermsAndConditions: false
}

export default function JobApplicationPublicView() {
    const { openaccesscode } = useParams();
    const { state } = useLocation();
    const bottomApplyBtnRef = React.useRef(null);
    const [showSkeleton, setShowSkeleton] = React.useState(true);
    const [dsJobApplication, setDsJobApplication] = React.useState({});
    const [companyImg, setCompanyImg] = React.useState({ fileData: null });
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    const [newRecord, setNewRecord] = React.useState(CandidateDS);
    const [resume, setResume] = React.useState({
        uploadedFile: { fileData: null },
        attachedFile: null,
        isUpdated: false,
    });
    const [months, setMonths] = React.useState({
        totalMonth: 0,
        relevantMonth: 0,
    });
    const [status, setStatus] = React.useState({
        eStatus: eCRUDStatus.None,
        message: null
    });
    const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = React.useState();
    const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = React.useState();
    const [isResumeParsing, setIsResumeParsing] = React.useState(false);
    const [openResumeUploader, setOpenResumeUploader] = React.useState(false);
    const [companyData, setCompanyData] = React.useState({});
    const [isSameAsCurrentAddress, setIsSameAsCurrentAddress] = useState(false);

    const NavigateTo = useNavigate();
    const { isExSmall, isSmall, isXXSmall, isMedium, isLarge, isExLarge } = useScreenSize();
    const UploadResumeRef = React.useRef();
    
    const isTopApplyBtnVisible = useOnScreen(bottomApplyBtnRef, "0px", selectedTabIndex);

    React.useEffect(() => {
        if (openaccesscode) {
            GetApplication();
        }
    }, [openaccesscode]);

    React.useEffect(() => {
        if (state?.companyData) {
            setCompanyData(state.companyData || {});
        } else {
            GetRegistrationDetail();
        }
    }, [state]);

    const GetRegistrationDetail = async () => {
        const result = await jobOpeningOpenServices.GetRegistrationDetail(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        } else {
            setCompanyData(result.data);
        }
    }

    const GetApplication = async () => {
        const result = await jobOpeningOpenServices.GetApplicationData(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                let errorMessage = null;
                try {
                    let errorRes = JSON.parse(result.data);
                    errorMessage = errorRes?.status && errorRes.status == 400 ? 'Job application key is invalid.' : 'Something went wrong.';
                } catch (err) {
                    errorMessage = result.data;
                } finally {
                    NavigateTo('/error-page', {
                        state: {
                            message: 'Job link is expired or invalid.',
                        }
                    });
                }
            }
            setShowSkeleton(false);
        } else {
            const companyLogo = await GetCompanyLogo();
            setCompanyImg({
                fileData: companyLogo?.size > 0 ? window.URL.createObjectURL(companyLogo) : "NoData",
            });
            setDsJobApplication(result.data);
            setShowSkeleton(false);
        }
    };

    const GetCompanyLogo = async () => {
        const result = await jobOpeningOpenServices.GetCompanyLogo(openaccesscode);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
        }
        return result.data;
    };

    const onApplyButtonClick = () => {
        setSelectedTabIndex(1);
        if (!resume.uploadedFile.fileData) {
            setOpenResumeUploader(true);
        }
    };

    const onUploadResumeValueChanged = (e) => {
        const file = e[0];
        if (file && file?.size > maxResumeSizeLength) {
            ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
            UploadResumeRef?.current?.instance.reset();
            setResume({
                uploadedFile: { fileData: null },
                attachedFile: null,
                isUpdated: false,
            });
        } else if (
            file &&
            file["type"]?.split("/")[1] !== "pdf"
        ) {
            ShowAlert(`This file is not accepted`, "Vakency");
            UploadResumeRef?.current?.instance.reset();
            setResume({
                uploadedFile: { fileData: null },
                attachedFile: null,
                isUpdated: false,
            });
        } else {
            if (file) {
                const resumeData = {
                    ...resume,
                    uploadedFile: { fileData: file },
                    attachedFile: file,
                    isUpdated: true,
                }
                setResume(resumeData);
                let myDialog = custom({
                    title: "Vakency",
                    messageHtml: "<div class='font-semiBold-16'>Do you want to auto-fill details from the uploaded resume?</div>",
                    buttons: [
                        {
                            text: "Yes",
                            onClick: (e) => {
                                return { buttonText: e.component.option("text") }
                            },
                            elementAttr: {
                                class: 'btnReload'
                            },
                            type: 'default',
                            focusStateEnabled: false,
                        },
                        {
                            text: "No",
                            onClick: (e) => {
                                return { buttonText: e.component.option("text") }
                            },
                            elementAttr: {
                                class: 'btnCancel'
                            },
                            type: 'danger',
                            focusStateEnabled: false,
                        }
                    ]
                });
                myDialog.show().then((dialogResult) => {
                    if (dialogResult.buttonText == 'Yes') {
                        handleResumeParsing(resumeData, true);
                    }
                });
            }
        }
    };

    const goToJobOpenings = () => {
        if (selectedTabIndex == 1) {
            setSelectedTabIndex(0);
        } else {
            NavigateTo(-1);
        }

    }

    const onClickClearResume = async (e) => {
        UploadResumeRef.current?.instance.reset();
        setResume({
            uploadedFile: { fileData: null },
            attachedFile: null,
            isUpdated: false,
        });
    };

    const UpdateResume = async (ID) => {
        if (resume?.attachedFile) {
            var Datatoupload = { attachment: resume.attachedFile };
            await jobApplicationOpenService.UpdateCandidateDocData(
                Datatoupload,
                ID
            );
        }
    };

    const InsertRecord = async () => {
        setShowSkeleton(true);
        var date = newRecord?.dob && new Date(newRecord?.dob);
        var formattedDate = date
          ? date.toDateString()
          : undefined;
        // var formattedDate = newRecord?.dob
        //     ? new Date(
        //         new Date(newRecord?.dob + "Z").toUTCString()
        //     ).toLocaleDateString()
        //     : null;
        let dataToInsert = {
            firstName: newRecord?.firstName,
            lastName: newRecord?.lastName,
            emailID: newRecord?.emailID ? newRecord?.emailID : null,
            mobileNo: newRecord?.mobileNo,
            genderID: newRecord?.genderID,
            dob: formattedDate,
            maritalStatusID: newRecord?.maritalStatusID,
            currentAddress: newRecord?.currentAddress,
            currentCity: newRecord?.currentCity,
            currentState: newRecord?.currentState,
            currentCountry: newRecord?.currentCountry,
            totalExperience: parseFloat(
                (newRecord?.totalExperience ? newRecord?.totalExperience : '0') + "." + (months.totalMonth ? (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth) : '0')
            ).toFixed(2),
            relevantExperience: parseFloat(
                (newRecord?.relevantExperience ? newRecord?.relevantExperience : '0') + "." + (months.relevantMonth ? (months.relevantMonth < 10 ? `0${months.relevantMonth}` : months.relevantMonth) : '0')
            ).toFixed(2),
            currentCTC: newRecord?.currentCTC,
            expectedCTC: newRecord?.expectedCTC,
            noticePeriod: newRecord?.noticePeriod,
            readyToRelocate: newRecord?.readyToRelocate || false,
            reasonForLeaving: newRecord?.reasonForLeaving || '',
            currentCompanyName: newRecord?.currentCompanyName,
        };
        if (isSameAsCurrentAddress) {
            dataToInsert.permanentAddress = newRecord?.currentAddress || null;
            dataToInsert.permanentCity = newRecord?.currentCity || null;
            dataToInsert.permanentState = newRecord?.currentState || null;
            dataToInsert.permanentCountry = newRecord?.currentCountry || null;
        } else {
            dataToInsert.permanentAddress = newRecord?.permanentAddress || null;
            dataToInsert.permanentCity = newRecord?.permanentCity || null;
            dataToInsert.permanentState = newRecord?.permanentState || null;
            dataToInsert.permanentCountry = newRecord?.permanentCountry || null;
        }
        const result = await jobApplicationOpenService.PostCandidateDetail(openaccesscode, dataToInsert);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                if (result.data.startsWith("Duplicate email id")) {
                    ShowAlert(`The email ID you entered already exists in the system. Please use a different email or contact your administrator for assistance.`, "Vakency");
                } else if (result.data.startsWith("Duplicate mobile no")) {
                    ShowAlert(`The mobile no. you entered already exists in the system. Please use a different mobile number or contact your administrator for assistance.`, "Vakency");
                } else {
                    ShowAlert(`${result.data}`, "Vakency");
                }
                setShowSkeleton(false);
            }
        } else {
            UpdateResume(result.data.responseData);
            setNewRecord({});
            setShowSkeleton(false);
            setStatus({
                eStatus: eCRUDStatus.Inserted,
                message: 'Applied Successfully'
            });
            setSelectedTabIndex(0);
            setResume({
                uploadedFile: { fileData: null },
                attachedFile: null,
                isUpdated: false,
            });
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (!resume?.uploadedFile?.fileData) {
            ShowAlert(`Resume is required`, "Vakency");
        } else {
            InsertRecord();
        }
    }

    const clearForm = (e) => {
        e.validationGroup.reset(CandidateDS);
        setNewRecord(CandidateDS);
        setResume({
            uploadedFile: { fileData: null },
            attachedFile: null,
            isUpdated: false,
        });
    }

    const onHidingResumeUploder = () => {
        setOpenResumeUploader(false);
    }

    const handleResumeParsing = async (resume, allowParsing) => {
        if (!resume.uploadedFile.fileData) {
            ShowAlert(`Resume is required`, "Vakency");
            return;
        }
        setOpenResumeUploader(false);
        if (allowParsing) {
            const params = {
                attachment: resume.attachedFile,
                id: openaccesscode,
            }
            setIsResumeParsing(true);
            try {
                const result = await jobApplicationOpenService.ReadCandidateResume(params);
                if (!result.isOk) {
                    if (result.statusCode === 400) {
                        ShowAlert(result.data, "Vakency");
                    } else if (result.statusCode === 409) {
                        ShowAlert(result.data, "Vakency");
                    } else {
                        ShowAlert('Unable to parse uploaded resume, please try again', "Vakency");
                    }
                } else {
                    const response = result.data;
                    setNewRecord({
                        ...newRecord,
                        firstName: response?.FirstName || null,
                        lastName: response?.LastName || null,
                        emailID: response?.EmailID || null,
                        mobileNo: response?.MobileNo1 ? response?.MobileNo1?.replace(/\D/g, "").slice(-10) : null,
                        linkedIn: response?.LinkedIn || null,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsResumeParsing(false);
            }
        }
    }

    const onPhoneButtonClick = () => {
        if (companyData?.phoneNo) {
            window.location.href = `tel:${companyData?.phoneNo}`;
        }
    }

    return (
        <div className='container-fluid'>
            <div className="job-application-public-section row justify-content-center">
                <div className="col-12 col-md-10 col-lg-10 col-xl-8 col-xxl-6 my-0 py-0">
                    <div className={`${(isSmall || isMedium || isLarge || isExLarge) ? 'job-application-public-card' : ''} py-2 job-application-public-content`}>
                        <LoadPanel
                            visible={showSkeleton || isResumeParsing}
                            message={isResumeParsing ? "Parsing resume, please wait..." : ""}
                            shading={true}
                            shadingColor="rgba(0,0,0,0.4)"
                        />

                        <div className="row mx-0">
                            {
                                companyImg.fileData ? (
                                    <>
                                        <div className='col-auto'>
                                            <img
                                                src={companyImg.fileData == 'NoData' ? NoImage : companyImg.fileData}
                                                alt="candidate"
                                                height={'50px'}
                                            />
                                        </div>
                                        <div className='col ps-0'>
                                            <div className='font-semiBold-18'>{dsJobApplication?.companyName}</div>
                                            <div className='row my-1'>
                                                {companyData?.address &&
                                                    <div className="col-auto">
                                                        <i className="dx-icon-locationIcon dx-custom-icon mb-1 cursor-pointer" title="" onClick={() => handleNavigateToMap(companyData?.address)}></i>{" "}
                                                        {companyData?.address}
                                                    </div>
                                                }
                                                {companyData?.phoneNo && companyData?.address && <div className="col-auto px-0 assessment-pipe">|</div>}
                                                {companyData?.phoneNo &&
                                                    <>
                                                        <div className="col-auto cursor-pointer" onClick={onPhoneButtonClick}>
                                                            <i className="dx-icon-MobileIcon dx-custom-icon mb-1" title=""></i>{" "}
                                                            {companyData?.phoneNo}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {
                                            (selectedTabIndex == 1 || (selectedTabIndex == 0 && state?.showBackButton)) && (
                                                <div className={`col-auto text-end ps-0`}>
                                                    <Button
                                                        text={"Back"}
                                                        icon="spinprev"
                                                        type="default"
                                                        hint="Back"
                                                        width={100}
                                                        stylingMode="text"
                                                        className="border-0 Back__button"
                                                        onClick={goToJobOpenings}
                                                    />
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <PopupSkeletonOneLine />
                                )
                            }
                        </div>
                        <hr className='my-2' />
                        <div className='application-detail-section pb-3'>
                            {
                                selectedTabIndex === 0 && (

                                    <ScrollView height={'100%'} width={'100%'}>
                                        <div className="row py-2 px-0 mx-0">
                                            <div className='col ps-2'>
                                                <div className='font-semiBold-24'>{dsJobApplication.jobProfileName}</div>
                                            </div>
                                            <div className='col-auto'>
                                                {
                                                    status.eStatus === 1 ? (
                                                        <>
                                                            <i className="dx-icon-successIcon dx-custom-icon mb-1"></i>{" "}
                                                            <span className='font-semiBold-16 text-success'>{status.message}</span>
                                                        </>
                                                    ) : (
                                                        <div ref={bottomApplyBtnRef}>
                                                            <Button
                                                                text='Apply Now'
                                                                hint='Apply for job'
                                                                className='rounded-2'
                                                                stylingMode='contained '
                                                                type='default'
                                                                onClick={onApplyButtonClick}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row py-3 px-0 mx-0">
                                            <div className='col-auto ps-2'>
                                                <div className='font-semiBold-18'>About the Job</div>
                                                <div className='mt-2' dangerouslySetInnerHTML={{ __html: dsJobApplication.jobDescrFormattedText || '-' }} />
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center pb-2">
                                            <div className='col-auto ps-2 text-center '>
                                                {
                                                    !isTopApplyBtnVisible && status.eStatus === 0 && (
                                                        <Button
                                                            text='Apply Now'
                                                            hint='Apply for job'
                                                            className='rounded-2'
                                                            stylingMode='contained'
                                                            type='default'
                                                            onClick={onApplyButtonClick}
                                                            visible={!isTopApplyBtnVisible && bottomApplyBtnRef.current != null}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </ScrollView>
                                )
                            }
                            {
                                selectedTabIndex === 1 && (
                                    <ScrollView height={'100%'} width={'100%'}>
                                        <form className='' onSubmit={handleSubmit}>
                                            <div className="px-3 py-1">
                                                <div className="row pt-3">
                                                    <div className='col font-semiBold-20'>
                                                        <span className='job-profile-caption'>Job Profile:</span> {dsJobApplication.jobProfileName}
                                                    </div>
                                                    <div className='col-xs-12 col-md pt-md-0 pt-3'>
                                                        <div className="resumeUpload d-flex border rounded align-items-center">
                                                            <label htmlFor="upload-resume" className='ms-1'>
                                                                <span className='text-danger'>*</span>
                                                            </label>
                                                            <FileUploader
                                                                ref={UploadResumeRef}
                                                                accept="application/pdf"
                                                                name="candidate resume"
                                                                multiple={false}
                                                                uploadMode={"useForm"}
                                                                selectButtonText="Browse"
                                                                allowCanceling={false}
                                                                labelText=""
                                                                hoverStateEnabled={false}
                                                                activeStateEnabled={false}
                                                                focusStateEnabled={false}
                                                                uploadedMessage="Resume Uploaded"
                                                                readyToUploadMessage=""
                                                                className=""
                                                                inputAttr={{ 'aria-label': 'Browse' }}
                                                                onValueChange={onUploadResumeValueChanged}
                                                                value={resume?.uploadedFile?.fileData ? [resume?.uploadedFile?.fileData] : []}
                                                            />
                                                            {resume?.uploadedFile?.fileData && (
                                                                <>
                                                                    <Button
                                                                        icon="close"
                                                                        id="Close"
                                                                        onClick={onClickClearResume}
                                                                        stylingMode="text"
                                                                        type="default"
                                                                        hint="Close"
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className='top-card-caption'>
                                                            Maximum file size 5MB, acceptable file type .pdf
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Personal Info */}
                                                <div className="row pt-md-0 pt-3">
                                                    <div className="col-md ">
                                                        <div className="editor-sub-header">Personal Information</div>
                                                        {showSkeleton ? (
                                                            <div className="">
                                                                <PopupSkeleton />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md">
                                                                        <TextBox
                                                                            label="First Name"
                                                                            maxLength={50}
                                                                            labelMode="floating"
                                                                            value={newRecord?.firstName}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, firstName: e })
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="First Name is required" />
                                                                            </Validator>
                                                                        </TextBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <TextBox
                                                                            label="Last Name"
                                                                            maxLength={50}
                                                                            labelMode="floating"
                                                                            value={newRecord?.lastName}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, lastName: e })
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="Last Name is required" />
                                                                            </Validator>
                                                                        </TextBox>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-3">
                                                                    <div className="col-md">
                                                                        <DateBox
                                                                            label="DOB"
                                                                            labelMode="floating"
                                                                            id="DOB_Candidate_Dropdown"
                                                                            value={newRecord.dob ? new Date(newRecord.dob) : null}
                                                                            dateOutOfRangeMessage="Invalid Date"
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, dob: e ? new Date(e) : null })
                                                                            }
                                                                            displayFormat="dd/MM/yyyy"
                                                                            useMaskBehavior={true}
                                                                            min={minDate}
                                                                            dropDownOptions={
                                                                                {
                                                                                    hideOnParentScroll: true,
                                                                                    container: "#DOB_Candidate_Dropdown"
                                                                                }
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="DOB is required" />
                                                                                <RangeRule
                                                                                    message="Candidate must be 18 years old"
                                                                                    max={DOBRangeRule}
                                                                                    ignoreEmptyValue={true}
                                                                                />
                                                                            </Validator>
                                                                        </DateBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <SelectBox
                                                                            label="Gender"
                                                                            id="Gender_Dropdown"
                                                                            labelMode="floating"
                                                                            dataSource={dsGenderStatus}
                                                                            valueExpr={"genderID"}
                                                                            displayExpr={"genderStatus"}
                                                                            value={newRecord?.genderID}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, genderID: e })
                                                                            }
                                                                            dropDownOptions={
                                                                                {
                                                                                    container: "#Gender_Dropdown",
                                                                                    hideOnParentScroll: true,
                                                                                }
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="Gender is required" />
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                    <div className="col-md pt-md-0 pt-3">
                                                                        <SelectBox
                                                                            label="Marital Status"
                                                                            id="MaritalStatus_Dropdown"
                                                                            labelMode="floating"
                                                                            dataSource={dsMaritalStatus}
                                                                            valueExpr={"maritalStatusID"}
                                                                            displayExpr={"maritalStatus"}
                                                                            value={newRecord?.maritalStatusID}
                                                                            onValueChange={(e) =>
                                                                                setNewRecord({ ...newRecord, maritalStatusID: e })
                                                                            }
                                                                            dropDownOptions={
                                                                                {
                                                                                    container: "#MaritalStatus_Dropdown",
                                                                                    hideOnParentScroll: true,
                                                                                }
                                                                            }
                                                                        >
                                                                            <Validator
                                                                                validationGroup={ValidationGroupName}
                                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                            >
                                                                                <RequiredRule message="Marital status is required" />
                                                                            </Validator>
                                                                        </SelectBox>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                {/*-------- End Personal Info ---------*/}

                                                {/* Contact Info */}
                                                <div className="editor-sub-header pt-3">Contact Information</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md">
                                                                <NumberBox
                                                                    label="Mobile No."
                                                                    labelMode="floating"
                                                                    value={
                                                                        newRecord?.mobileNo
                                                                            ? parseInt(newRecord?.mobileNo)
                                                                            : undefined
                                                                    }
                                                                    maxLength={15}
                                                                    format="#"
                                                                    inputAttr={{
                                                                        autocomplete: "new",
                                                                        maxLength: 15,
                                                                    }}
                                                                    onKeyDown={onKeyDown}
                                                                    step={0}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, mobileNo: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                    >
                                                                        <RequiredRule message="Mobile No. is required" />
                                                                        <StringLengthRule
                                                                            message="Mobile No. must have 10 digits"
                                                                            min={10}
                                                                            ignoreEmptyValue={true}
                                                                        />
                                                                        {/* <AsyncRule
                                                                        reevaluate={false}
                                                                        message="Can't accept duplicate Mobile No."
                                                                    validationCallback={asyncMobileNoValidation}
                                                                    /> */}
                                                                    </Validator>
                                                                </NumberBox>
                                                            </div>
                                                            <div className="col-md pt-md-0 pt-3">
                                                                <TextBox
                                                                    label="Email ID"
                                                                    maxLength={100}
                                                                    labelMode="floating"
                                                                    value={newRecord?.emailID}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, emailID: e })
                                                                    }
                                                                    inputAttr={{
                                                                        autocomplete: "new",
                                                                    }}
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: "ForValidationAstrikMark" }}
                                                                    >
                                                                        <RequiredRule message="Email ID is required" />
                                                                        <EmailRule message="Email ID is invalid" />
                                                                        {/* <AsyncRule
                                                                        reevaluate={false}
                                                                        message="Can't accept duplicate email ID"
                                                                    validationCallback={asyncEmailIDValidation}
                                                                    /> */}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/*-------- End Contact Info ---------*/}

                                                {/* Current Address Info */}
                                                <div className="editor-sub-header pt-3">Current Address Information</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-md">
                                                                <TextBox
                                                                    label="Current Address"
                                                                    labelMode="floating"
                                                                    value={newRecord?.currentAddress}
                                                                    maxLength={100}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentAddress: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-3">
                                                            <div className="col-md">
                                                                <TextBox
                                                                    label="City"
                                                                    labelMode="floating"
                                                                    maxLength={50}
                                                                    value={newRecord?.currentCity}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentCity: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: (newRecord.currentState || newRecord.currentCountry) ? "ForValidationAstrikMark" : "" }}
                                                                    >
                                                                        {(newRecord.currentState || newRecord.currentCountry) && <RequiredRule message="City is required" />}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                            <div className="col-md pt-md-0 pt-3">
                                                                <TextBox
                                                                    label="State"
                                                                    labelMode="floating"
                                                                    maxLength={50}
                                                                    value={newRecord?.currentState}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentState: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: (newRecord.currentCity || newRecord.currentCountry) ? "ForValidationAstrikMark" : "" }}
                                                                    >
                                                                        {(newRecord.currentCity || newRecord.currentCountry) && <RequiredRule message="State is required" />}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                            <div className="col-md pt-md-0 pt-3">
                                                                <TextBox
                                                                    label="Country"
                                                                    labelMode="floating"
                                                                    maxLength={50}
                                                                    value={newRecord?.currentCountry}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentCountry: e })
                                                                    }
                                                                >
                                                                    <Validator
                                                                        validationGroup={ValidationGroupName}
                                                                        elementAttr={{ class: (newRecord.currentCity || newRecord.currentState) ? "ForValidationAstrikMark" : "" }}
                                                                    >
                                                                        {(newRecord.currentCity || newRecord.currentState) && <RequiredRule message="Country is required" />}
                                                                    </Validator>
                                                                </TextBox>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/*-------- End Current Address Info ---------*/}

                                                {/* Permanent Address Info */}
                                                <div className="editor-sub-header pt-3">Permanent Address Information</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row py-1">
                                                            <div className="col-md">
                                                                <CheckBox
                                                                    text={'Same as current address'}
                                                                    iconSize={18}
                                                                    value={isSameAsCurrentAddress}
                                                                    className='font-medium-14'
                                                                    onValueChange={(e) =>
                                                                        setIsSameAsCurrentAddress(e)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            !isSameAsCurrentAddress && (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-md">
                                                                            <TextBox
                                                                                label="Permanent Address"
                                                                                labelMode="floating"
                                                                                value={newRecord?.permanentAddress}
                                                                                maxLength={100}
                                                                                onValueChange={(e) =>
                                                                                    setNewRecord({ ...newRecord, permanentAddress: e })
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row pt-3">
                                                                        <div className="col-md">
                                                                            <TextBox
                                                                                label="City"
                                                                                labelMode="floating"
                                                                                maxLength={50}
                                                                                value={newRecord?.permanentCity}
                                                                                onValueChange={(e) =>
                                                                                    setNewRecord({ ...newRecord, permanentCity: e })
                                                                                }
                                                                            >
                                                                                <Validator
                                                                                    validationGroup={ValidationGroupName}
                                                                                    elementAttr={{ class: (newRecord.permanentState || newRecord.permanentCountry) ? "ForValidationAstrikMark" : "" }}
                                                                                >
                                                                                    {(newRecord.permanentState || newRecord.permanentCountry) && <RequiredRule message="City is required" />}
                                                                                </Validator>
                                                                            </TextBox>
                                                                        </div>
                                                                        <div className="col-md pt-md-0 pt-3">
                                                                            <TextBox
                                                                                label="State"
                                                                                labelMode="floating"
                                                                                maxLength={50}
                                                                                value={newRecord?.permanentState}
                                                                                onValueChange={(e) =>
                                                                                    setNewRecord({ ...newRecord, permanentState: e })
                                                                                }
                                                                            >
                                                                                <Validator
                                                                                    validationGroup={ValidationGroupName}
                                                                                    elementAttr={{ class: (newRecord.permanentCity || newRecord.permanentCountry) ? "ForValidationAstrikMark" : "" }}
                                                                                >
                                                                                    {(newRecord.permanentCity || newRecord.permanentCountry) && <RequiredRule message="State is required" />}
                                                                                </Validator>
                                                                            </TextBox>
                                                                        </div>
                                                                        <div className="col-md pt-md-0 pt-3">
                                                                            <TextBox
                                                                                label="Country"
                                                                                labelMode="floating"
                                                                                maxLength={50}
                                                                                value={newRecord?.permanentCountry}
                                                                                onValueChange={(e) =>
                                                                                    setNewRecord({ ...newRecord, permanentCountry: e })
                                                                                }
                                                                            >
                                                                                <Validator
                                                                                    validationGroup={ValidationGroupName}
                                                                                    elementAttr={{ class: (newRecord.permanentCity || newRecord.permanentState) ? "ForValidationAstrikMark" : "" }}
                                                                                >
                                                                                    {(newRecord.permanentCity || newRecord.permanentState) && <RequiredRule message="Country is required" />}
                                                                                </Validator>
                                                                            </TextBox>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )}
                                                {/*-------- End Pemanent Address Info ---------*/}

                                                {/* Work Experience */}
                                                <div className="editor-sub-header pt-3">Work Experience</div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-md">
                                                            <div className="row">
                                                                <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                                                                    Total Experience:
                                                                </div>
                                                                <div className="col">
                                                                    <NumberBox
                                                                        label="Years"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        value={newRecord?.totalExperience}
                                                                        onValueChange={(e) =>
                                                                            setNewRecord({ ...newRecord, totalExperience: e })
                                                                        }
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                                <div className="col ">
                                                                    <NumberBox
                                                                        label="Months"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        max={11}
                                                                        value={months.totalMonth}
                                                                        onValueChange={(e) => {
                                                                            setMonths({ ...months, totalMonth: e });
                                                                        }}
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        inputAttr={{ maxLength: 5 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md pt-md-0 pt-3">
                                                            <div className="row">
                                                                <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                                                                    Relevant Experience:
                                                                </div>
                                                                <div className="col">
                                                                    <NumberBox
                                                                        label="Years"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        value={newRecord?.relevantExperience}
                                                                        onValueChange={(e) =>
                                                                            setNewRecord({ ...newRecord, relevantExperience: e })
                                                                        }
                                                                        onKeyDown={onKeyDown}
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                        format="#"
                                                                    />
                                                                </div>
                                                                <div className="col ">
                                                                    <NumberBox
                                                                        label="Months"
                                                                        labelMode="floating"
                                                                        maxLength={3}
                                                                        onKeyDown={onKeyDown}
                                                                        format="#"
                                                                        max={11}
                                                                        value={months.relevantMonth}
                                                                        onValueChange={(e) =>
                                                                            setMonths({ ...months, relevantMonth: e })
                                                                        }
                                                                        inputAttr={{ maxLength: 3 }}
                                                                        step={0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* ------- End Work Experience -------------*/}

                                                {/* Other Information */}
                                                <div className="editor-sub-header pt-3">
                                                    Other Information
                                                </div>
                                                {showSkeleton ? (
                                                    <div className="">
                                                        <PopupSkeleton />
                                                        <PopupSkeletonOneLine />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row mx-0">
                                                            <div className="col-md">
                                                                <div className='pt-2 position-relative'>
                                                                    <div className='floating-label editor-label'> Current CTC </div>
                                                                    <div className='row border rounded p-2'>
                                                                        <div className='col-sm'>
                                                                            <NumberBox
                                                                                label="Yearly"
                                                                                min={0}
                                                                                labelMode="floating"
                                                                                maxLength={15}
                                                                                format="##.##"
                                                                                value={newRecord?.currentCTC && newRecord?.currentCTC.toFixed(2)}
                                                                                onValueChange={(e) => {
                                                                                    setNewRecord({ ...newRecord, currentCTC: e })
                                                                                    setCurrentCTCMonthlyValue((e * 100000) / 12)
                                                                                }}
                                                                                onKeyDown={onKeyDown_Withpoint}
                                                                                className="number-box-btn"
                                                                            >
                                                                                <NumberBoxButton
                                                                                    location="after"
                                                                                    name="current_ctc_yearly"
                                                                                    options={{
                                                                                        text: 'LPA',
                                                                                        stylingMode: 'text',
                                                                                        hoverStateEnabled: false,
                                                                                        focusStateEnabled: false,
                                                                                        activeStateEnabled: false,
                                                                                    }}
                                                                                />
                                                                            </NumberBox>
                                                                        </div>
                                                                        <div className={`col-12 col-sm-auto pt-1 pt-sm-3 px-0 d-flex justify-content-center justify-content-sm-normal text-gray font-regular-12`}>OR</div>
                                                                        <div className='col-sm'>
                                                                            <NumberBox
                                                                                label="Monthly"
                                                                                labelMode="floating"
                                                                                value={currentCTCMonthlyValue && currentCTCMonthlyValue.toFixed(2)}
                                                                                maxLength={15}
                                                                                min={0}
                                                                                format="##.##"
                                                                                onValueChange={(e) => {
                                                                                    setCurrentCTCMonthlyValue(e);
                                                                                    setNewRecord({ ...newRecord, currentCTC: ((e * 12) / 100000) })
                                                                                }}
                                                                                onKeyDown={onKeyDown_Withpoint}
                                                                                className="number-box-btn"
                                                                            >
                                                                                <NumberBoxButton
                                                                                    location="after"
                                                                                    name="current_ctc_monthly"
                                                                                    options={{
                                                                                        text: 'month',
                                                                                        stylingMode: 'text',
                                                                                        hoverStateEnabled: false,
                                                                                        focusStateEnabled: false,
                                                                                        activeStateEnabled: false,
                                                                                    }}
                                                                                />
                                                                            </NumberBox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md pt-md-0 pt-3 ms-0 ms-md-4">
                                                                <div className='pt-2 position-relative'>
                                                                    <div className='floating-label editor-label'>Expected CTC</div>
                                                                    <div className='row border rounded p-2'>
                                                                        <div className='col-sm'>
                                                                            <NumberBox
                                                                                label="Yearly"
                                                                                labelMode="floating"
                                                                                maxLength={15}
                                                                                min={0}
                                                                                format="##.##"
                                                                                value={newRecord?.expectedCTC && newRecord?.expectedCTC?.toFixed(2)}
                                                                                onValueChange={(e) => {
                                                                                    setNewRecord({ ...newRecord, expectedCTC: e })
                                                                                    setExpectedCTCMonthlyValue((e * 100000) / 12)
                                                                                }}
                                                                                onKeyDown={onKeyDown_Withpoint}
                                                                                className="number-box-btn"
                                                                            >
                                                                                <NumberBoxButton
                                                                                    location="after"
                                                                                    name="expected_ctc_yearly"
                                                                                    options={{
                                                                                        text: 'LPA',
                                                                                        stylingMode: 'text',
                                                                                        hoverStateEnabled: false,
                                                                                        focusStateEnabled: false,
                                                                                        activeStateEnabled: false,
                                                                                    }}
                                                                                />
                                                                            </NumberBox>
                                                                        </div>
                                                                        <div className={`col-12 col-sm-auto pt-1 pt-sm-3 px-0 d-flex justify-content-center justify-content-sm-normal text-gray font-regular-12`}>OR</div>
                                                                        <div className='col-sm'>
                                                                            <NumberBox
                                                                                label="Monthly"
                                                                                labelMode="floating"
                                                                                value={expectedCTCMonthlyValue && expectedCTCMonthlyValue.toFixed(2)}
                                                                                maxLength={15}
                                                                                min={0}
                                                                                format="##.##"
                                                                                onValueChange={(e) => {
                                                                                    setExpectedCTCMonthlyValue(e);
                                                                                    setNewRecord({ ...newRecord, expectedCTC: ((e * 12) / 100000) })
                                                                                }}
                                                                                onKeyDown={onKeyDown_Withpoint}
                                                                                className="number-box-btn"
                                                                            >
                                                                                <NumberBoxButton
                                                                                    location="after"
                                                                                    name="expected_ctc_monthly"
                                                                                    options={{
                                                                                        text: 'month',
                                                                                        stylingMode: 'text',
                                                                                        hoverStateEnabled: false,
                                                                                        focusStateEnabled: false,
                                                                                        activeStateEnabled: false,
                                                                                    }}
                                                                                />
                                                                            </NumberBox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row pt-3">
                                                            <div className="col-md">
                                                                <TextBox
                                                                    label="Current Company Name"
                                                                    labelMode="floating"
                                                                    value={newRecord?.currentCompanyName}
                                                                    maxLength={50}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, currentCompanyName: e })
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-md pt-md-0 pt-3">
                                                                <NumberBox
                                                                    label="Notice Period (Days)"
                                                                    labelMode="floating"
                                                                    value={
                                                                        newRecord?.noticePeriod &&
                                                                        parseInt(newRecord?.noticePeriod)
                                                                    }
                                                                    maxLength={3}
                                                                    max={255}
                                                                    inputAttr={{ maxLength: 3 }}
                                                                    step={0}
                                                                    onKeyDown={onKeyDown}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, noticePeriod: e })
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-md  pt-3">
                                                                <CheckBox
                                                                    text="Ready To Relocate"
                                                                    className='pt-md-0 pt-2'
                                                                    value={newRecord?.readyToRelocate || false}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, readyToRelocate: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row pt-3">
                                                            <div className="col-md">
                                                                <TextBox
                                                                    label="Reason For Leaving"
                                                                    labelMode="floating"
                                                                    value={newRecord?.reasonForLeaving}
                                                                    maxLength={100}
                                                                    onValueChange={(e) =>
                                                                        setNewRecord({ ...newRecord, reasonForLeaving: e })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {/* End Other Information */}

                                                {/* <div className="pt-3">
                                                    <div className="registration_CheckBox">
                                                        <CheckBox
                                                            text={'I agree to the Terms and Conditions'}
                                                            iconSize={15}
                                                            value={newRecord?.acceptTermsAndConditions || false}
                                                            onValueChange={(e) =>
                                                                setNewRecord({ ...newRecord, acceptTermsAndConditions: e })
                                                            }
                                                        >
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                                <RequiredRule message='You must agree with the terms and conditions' />
                                                            </Validator>
                                                        </CheckBox>
                                                        <span className='text-danger'> *</span>
                                                    </div>
                                                </div> */}

                                                <div className='d-flex justify-content-between align-items-center mt-3'>
                                                    <div className="">
                                                        <Button
                                                            text="Submit"
                                                            type="default"
                                                            width={100}
                                                            className='rounded-2'
                                                            useSubmitBehavior={true}
                                                            validationGroup={ValidationGroupName}
                                                            // disabled={!newRecord.acceptTermsAndConditions}
                                                        />
                                                    </div>
                                                    <div className="text-decoration-underline pointer-link base-accent-text" >
                                                        <Button
                                                            text="Clear form"
                                                            type="normal"
                                                            className='rounded-2'
                                                            useSubmitBehavior={false}
                                                            validationGroup={ValidationGroupName}
                                                            onClick={clearForm}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </ScrollView>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                openResumeUploader && (
                    <ResumeUploader
                        resume={resume}
                        setResume={setResume}
                        isOpen={openResumeUploader}
                        onClose={onHidingResumeUploder}
                        handleResumeParsing={handleResumeParsing}
                    />
                )
            }
        </div>
    );
}

function useOnScreen(ref, rootMargin = "0px", selectedTabIndex) {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { rootMargin }
        );
        observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, [ref, rootMargin, selectedTabIndex]);

    return isVisible;
}

import { NumberBox, Popup, TextBox, Validator } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../../layouts/popup-header-footer/PopupFooter";
import { CustomRule, RequiredRule } from "devextreme-react/data-grid";
import { useScreenSize } from "../../../utils/media-query";
import StateDropDown from "../state/StateDropDown";
import {
  ConflictPopup,
  ShowAlert,
  eCRUDStatus,
  onKeyDown,
} from "../../../utils/common-methods";
import { AsyncRule, StringLengthRule } from "devextreme-react/validator";
import PopupSkeleton from "../../../layouts/templates/PopupSkeleton";
import { CityServices } from "../../../api/services/CityServices";

export default function CityPopup(props) {
  const ValidationGroupName = "CityPopupValidation";
  const { isExSmall, isXXSmall, isXSmall } = useScreenSize();
  const FocusedFeild = useRef(null);
  const onShown = () => FocusedFeild.current?.instance.focus();

  const [dropDownClick, setDropDownClick] = useState(false);
  const [addedCityStateID, setAddedCityStateID] = useState(0);
  const [newRecord, setNewRecord] = useState({});
  let PrimaryKeyID = props.primaryKey;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [originalRecord, setOriginalRecord] = useState({});

  const cityServices = new CityServices();

  useEffect(() => {
    if (
      PrimaryKeyID !== null &&
      PrimaryKeyID !== 0 &&
      PrimaryKeyID !== undefined
    ) {
      GetModelRecord();
    } else {
      setNewRecord({});

      setShowSkeleton(false);
    }
  }, [PrimaryKeyID]);

  useEffect(() => {
    if (addedCityStateID !== 0) {
      setNewRecord({ ...newRecord, cityStateID: addedCityStateID });
      setAddedCityStateID(0);
    }
  }, [addedCityStateID]);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus();
  }, [showSkeleton]);

  const GetModelRecord = async () => {
    setTimeout(async () => {
      const result = await cityServices.GetModelData(PrimaryKeyID);
      if (result.statusCode === 200) {
        const response = result.data;
        setNewRecord(response);
        setOriginalRecord(response)
        setShowSkeleton(false);
      }
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownClick === false) {
      if (!PrimaryKeyID) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    }
  };

  const InsertRecord = async () => {

    setShowSkeleton(true);

    let dataToInsert = {
      cityName: newRecord.cityName,
      districtName: newRecord.districtName,
      zip: newRecord.zip,
      cityStateID: newRecord.cityStateID,
    };
    const result = await cityServices.InsertData(dataToInsert);

    if (!result?.isOk) {
      if (result?.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: result.data.responseData,
      });
      onClosePopup();
      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    if (originalRecord == newRecord || (originalRecord !== newRecord)) {


      setShowSkeleton(true);

      var dataToUpdate = {
        updatedOn: newRecord.updatedOn,
        cityID: newRecord.cityID,
        cityName: newRecord.cityName,
        districtName: newRecord.districtName,
        zip: newRecord.zip,
        cityStateID: newRecord.cityStateID,
      };

      const result = await cityServices.UpdateData(dataToUpdate);
      if (!result?.isOk) {
        if (result?.statusCode === 409) {
          ConflictPopup(result.data).then((res) => {
            if (res) {
              GetModelRecord();
            } else {
              return;
            }
          });
          setShowSkeleton(false);
        } else if (result.statusCode === 400) {
          ShowAlert(result.data, "Vakency");
          setShowSkeleton(false);
        }
      } else {
        props.setStatus({
          eStatus: eCRUDStatus.Updated,
          primaryKeyID: result.data.responseData,
        });
        onClosePopup();
        setShowSkeleton(false);
      }
    }
  };

  const onClosePopup = () => {
    props.setOpenPopup(false);
    setNewRecord({});
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header_title"} className="base-accent-text">
              {PrimaryKeyID ? "Edit" : "New"}
            </span>,
            " City",
          ]}
          onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
          hideSaveButton={showSkeleton ? true : false}
        />
      </>
    );
  };

  const asyncCityNameValidation = async (e) => {
    const CityStateID = newRecord.cityStateID;
    if (CityStateID) {
      // const Name = e.value;
      const Name = encodeURIComponent(e?.value);
      const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
      const CheckDuplicateParams = `Name=${Name}&CityStateID=${CityStateID}&ExcludeID=${ExcludeID}`;
      const result = await cityServices.CheckDuplicateCityName(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    }
    else {
      return new Promise((resolve) => {
        resolve( true);
      });
    }
  };

  return (
    <div>
      {props.openPopup && (
        <Popup
          visible={props.openPopup}
          titleRender={PopupTitle}
          width={isExSmall || isXXSmall || isXSmall ? "90%" : "40%"}
          height={"auto"}
          wrapperAttr={{ class: "CustomPopup" }}
          onShown={onShown}
        >
          <form onSubmit={handleSubmit}>
            {showSkeleton ? (
              <div className="p-2 pb-0">
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <div className="px-3">
                <div className="row ">
                  <div className="col py-2">
                    <TextBox
                      label="City"
                      maxLength={50}
                      value={newRecord.cityName}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, cityName: e })
                      }
                      labelMode="floating"
                      ref={FocusedFeild}
                    >
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        {/* <CustomRule
                          ignoreEmptyValue={true}
                          message="Can't accept duplicate City"
                          validationCallback={asyncCityNameValidation}
                        /> */}
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate City"
                          validationCallback={asyncCityNameValidation}
                        />
                        <RequiredRule message="City is required" />
                      </Validator>
                    </TextBox>
                  </div>
                  <div className="col py-2 ">
                    <TextBox
                      label="District"
                      maxLength={50}
                      value={newRecord.districtName}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, districtName: e })
                      }
                      labelMode="floating"
                    ></TextBox>
                  </div>
                </div>
                <div className="py-2">
                  <NumberBox
                    label="Zip Code"
                    maxLength={6}
                    format="#"
                    step={0}
                    onKeyDown={onKeyDown}
                    inputAttr={{ maxLength: 6 }}
                    value={newRecord.zip}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, zip: e })
                    }
                    labelMode="floating"
                  >
                    <Validator validationGroup={ValidationGroupName}>
                      <StringLengthRule
                        message="Zip Code must have 6 digits"
                        min={6}
                        ignoreEmptyValue={true}
                      />
                    </Validator>
                  </NumberBox>
                </div>
                <div className="py-2">
                  <StateDropDown
                    value={newRecord.cityStateID}
                    dropdownHeight={70}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, cityStateID: e })
                    }
                    setAddedCityStateID={setAddedCityStateID}
                    validationGroup={ValidationGroupName}
                    setDropDownClick={setDropDownClick}
                    validationRequired={true}
                    DropDownId={"CityPopup_StateDropDown_Id"}
                  />
                </div>
              </div>
            )}
            {showSkeleton ? (
              <div className="d-flex justify-content-end pb-2 pe-2">
                <div className="skeleton-View Skeleton-popup-footer"></div>
              </div>
            ) : (
              <PopupFooter
                ValidationGroupName={ValidationGroupName}
                openPopup={props.openPopup}
                setOpenPopup={props.setOpenPopup}
              />
            )}
          </form>
        </Popup>
      )}
    </div>
  );
}

import { Button, CheckBox, FileUploader, LoadIndicator, Popup } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { useScreenSize } from "../../utils/media-query";
import './ResumeUploader.scss';
import { ShowAlert } from "../../utils/common-methods";
import { PdfIcon } from "../../utils/base-64-Icons";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";

const maxResumeSizeLength = 10 * 1024 * 1024;

const ResumeUploader = ({
  resume,
  setResume,
  isOpen,
  onClose,
  handleResumeParsing,
  openFrom
}) => {
  const uploadResumeRef = useRef();
  const { isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const [dropState, setDropState] = useState({
    isDropZoneActive: false,
    textVisible: true,
  });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  useEffect(() => {
    if (!resume.uploadedFile?.fileData) {
      setDropState({
        isDropZoneActive: false,
        textVisible: true,
      });
    }
  }, [resume]);


  useEffect(() => {
    if (resume.uploadedFile?.fileData) {
      setDropState({ ...dropState, textVisible: false });
    }
  }, [resume.uploadedFile?.fileData]);

  const onDropZoneEnter = (e) => {
    if (e.dropZoneElement.id === "resume-dropzone-external") {
      setDropState({ ...dropState, isDropZoneActive: true });
    }
  };

  const onDropZoneLeave = (e) => {
    if (e.dropZoneElement.id === "resume-dropzone-external") {
      setDropState({ ...dropState, isDropZoneActive: false });
    }
  };

  const onResumeValueChanged = (e) => {
    const file = e[0];
    setIsCheckboxChecked(false);
    if (file) {
      if (file?.size > maxResumeSizeLength) {
        ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
        uploadResumeRef?.current?.instance.reset();
      } else if (
        file["type"]?.split("/")[1] !== "pdf"
      ) {
        ShowAlert(`This file is not accepted`, "Vakency");
        uploadResumeRef?.current?.instance.reset();
      } else {
        setDropState({
          ...dropState,
          textVisible: false,
          isDropZoneActive: false,
        });
        setResume({
          ...resume,
          uploadedFile: { fileData: file },
          attachedFile: file,
          isUpdated: true,
        });
        setDropState({ ...dropState });
      }
    }
  };

  const onSkipResumeUpload = () => {
    setResume({
      uploadedFile: { fileData: null },
      attachedFile: null,
      isUpdated: false,
    });
    onClose();
  }

  const OnRemoveImage = (e) => {
    uploadResumeRef?.current?.instance.reset();
    setDropState({ ...dropState, textVisible: true, isDropZoneActive: false });
    setResume({
      ...resume,
      uploadedFile: { fileData: null },
      attachedFile: null,
    });
  };

  const onCheckboxValueChanged = (e) => {
    setIsCheckboxChecked(e.value);
  }

  const parseResume = () => {
    handleResumeParsing(resume, isCheckboxChecked);
  }

  const onCrossClick =()=>{
    const value =
    openFrom === "UploadNewResume" ? true :
    openFrom === "CandidateDropDownPopup" ? false :
    null;
    onClose(value);
  }

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          onClosePopup={onCrossClick}
          title={"Candidate"}
          hideSaveButton={true}
          hideCloseButton={false}
        />
      </>
    );
  };

  return (
    <>
      <Popup
        visible={isOpen}
        onHiding={onClose}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"90vh"}
        width={!isExSmall && !isXSmall && !isXXSmall ? 600 : "94%"}
        animation={{
          show: {
            type: "slideIn",
            direction: "top",
          },
          hide: {
            type: "slideOut",
            direction: "top",
            duration: 200,
          },
        }}
      >
        <div className="col-md-auto px-3 pt-md-0 pt-3 resume-upload-section">
          <div className="font-medium-14 top-card-caption">
            Upload PDF file type (5MB max)
          </div>
          {/* Header Section */}
          <div className="editor-label row mb-2">
            {resume.uploadedFile?.fileData && (
              <div className="d-flex justify-content-end">
                <Button
                  hint="Remove File"
                  icon="trash"
                  className={`rounded-5 user-profile-clear-btn z-1 my-2 position-absolute content-on-image`}
                  stylingMode="text"
                  type="danger"
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={OnRemoveImage}
                />
              </div>
            )}
          </div>

          {/* Drag and Drop Zone */}
          <div
            id="resume-dropzone-external"
            className={`flex-box border d-flex align-items-center justify-content-center ${dropState.isDropZoneActive
              ? "dx-theme-accent-as-border-color dropzone-active"
              : "dx-theme-border-color border"
              }`}
          >
            {/* Show Uploaded File */}
            {resume.uploadedFile?.fileData && !dropState.textVisible && (
              <div className="d-flex align-items-center">
                <img
                  src={PdfIcon}
                  alt="Uploaded File"
                  className="file-icon me-2"
                />
                <div>
                  <div className="font-semiBold-16 fw-bold text-break">
                    {resume.uploadedFile.fileData.name}
                  </div>
                  <div className="font-medium-12 text-muted">
                    {(resume.uploadedFile.fileData.size / 1024).toFixed(2)} KB
                  </div>
                </div>
              </div>
            )}
            {/* Show Drag & Drop Instructions */}
            {dropState.textVisible && (
              <div
                className="text-center"
              >
                <Button
                  icon='upload'
                  type='default'
                  hint='Upload'
                  stylingMode='outlined'
                  className='rounded-5 upload-btn'
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                ></Button>
                <span className="d-block font-semiBold-16">Drop file here</span>
                <span className="d-block text-gray font-semiBold-16">or</span>{" "}
                <span className="font-semiBold-16">Select file</span>
              </div>
            )}
          </div>

          {/* File Uploader Component */}
          <FileUploader
            ref={uploadResumeRef}
            id="file-uploader"
            accept="application/pdf"
            maxFileSize={maxResumeSizeLength}
            dialogTrigger="#resume-dropzone-external"
            dropZone="#resume-dropzone-external"
            multiple={false}
            uploadMode="useButtons"
            visible={false}
            onDropZoneEnter={onDropZoneEnter}
            onDropZoneLeave={onDropZoneLeave}
            onValueChange={onResumeValueChanged}
            value={resume?.uploadedFile?.fileData ? [resume?.uploadedFile?.fileData] : []}
          />
        </div>

        <div className='row mx-1 py-1'>
          <div className="col-auto d-flex">
            <CheckBox
              hint="Check to auto-fill details from the uploaded resume."
              iconSize="20"
              className="font-medium-14"
              value={isCheckboxChecked}
              onValueChanged={onCheckboxValueChanged}
              visible={resume.uploadedFile.fileData}
            />
            {resume.uploadedFile.fileData ? <div className="font-semiBold-14 ms-2">Check to auto-fill details from the uploaded resume.</div> : ""}
          </div>
        </div>
        <hr className='mb-0' />
        <div className='row justify-content-end m-2 py-1'>
          <div className='col-auto pe-1'>
            {
              !resume.uploadedFile.fileData && (
                <Button
                  text="Skip"
                  className=''
                  height={38}
                  type='normal'
                  stylingMode="contained"
                  onClick={onSkipResumeUpload}
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  elementAttr={{
                    class: 'btnSkip rounded-3'
                  }}
                />
              )
            }
            {
              resume.uploadedFile.fileData && (
                <Button
                  text="Continue"
                  className='px-1 rounded-3'
                  height={38}
                  type='default'
                  onClick={parseResume}
                />
              )
            }
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ResumeUploader;

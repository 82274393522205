import { CircularGauge } from "devextreme-react";
import { useScreenSize } from "./media-query";
import {
  Geometry,
  Label,
  Range,
  RangeContainer,
  Scale,
  Size,
  Tick,
  ValueIndicator,
} from "devextreme-react/circular-gauge";
import { alert, custom } from "devextreme/ui/dialog";
import { useState } from "react";
import StarRatings from "react-star-ratings";

export const camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
};

export function onKeyDown_withoutComma(e) {
  if (e.event.key === ",") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export function onKeyDown_Withpoint(e) {
  if (e.event.key === "-" || e.event.key === "_") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export function onKeyDown_Withpoint_withoutPlus(e) {
  if (e.event.key === "-" || e.event.key === "_" || e.event.key === "+") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export function onKeyDown_Withpoint_withoutPlus_withoutSpecialCharacters(e) {
  const invalidKeys = [
    "-", "_", "+", "#", "$", "%", "'", ",", "₹",
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
  ];

  if (invalidKeys.includes(e.event.key)) {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}

export function onKeyDown(e) {
  if (e.event.key === "-" || e.event.key === "." || e.event.key === "_") {
    e.event.preventDefault();
    e.event.stopImmediatePropagation();
  }
}


export const HideDatagridLoader = {
  enabled: false,
};

export const StarRating = ({ rating, largeStar }) => {
  const { isXSmall, isSmall } = useScreenSize();

  const stars = Array.from({ length: 5 }, (_, index) => index < rating);
  return (
    <div
      className={
        largeStar && !isXSmall && !isSmall ? "large-star" : "star-rating"
      }
    >
      {stars.map((filled, index) => (
        <span
          key={index}
          role="img"
          aria-label={filled ? "star-filled" : "star-empty"}
          className={filled ? "star-filled" : "star-empty"}
        >
          {filled ? "★" : "☆"}
        </span>
      ))}
    </div>
  );
};

export const NewStarRating = ({ rating, size, changeRating }) => {
  return (
    <StarRatings
      rating={rating || 0}
      starDimension={size ? `${size}px` : "17px"}
      starSpacing="0px"
      starRatedColor="#f6c259"
      starEmptyColor="#E0E0E0"
      numberOfStars={5}
      changeRating={changeRating && changeRating}
      starHoverColor="#f6c259"
    />
  )
}

export default StarRating;

export const StarRatingClickable = ({ initialRating, largeStar, onRatingChange }) => {
  const { isXSmall, isSmall } = useScreenSize();
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (index) => {
    let newRating;
    if (index == 0 && stars[index] == true && stars[index + 1] != true) {
      newRating = null;
    } else {
      newRating = index + 1;
    }
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  const stars = Array.from({ length: 5 }, (_, index) => index < rating);

  return (
    <div
      className={
        largeStar && !isXSmall && !isSmall ? "large-star" : "star-rating"
      }
    >
      {stars.map((filled, index) => (
        <span
          key={index}
          role="img"
          aria-label={filled ? "star-filled" : "star-empty"}
          className={filled ? "star-filled" : "star-empty"}
          onClick={() => handleStarClick(index)}
          style={{ cursor: 'pointer' }}
        >
          {filled ? "★" : "☆"}
        </span>
      ))}
    </div>
  );
};


export const CircularGaugeFunc = (props) => {
  return (
    <>
      <div className="position-relative d-flex justify-content-center">

        <div className={props.smallGauge ? "gauge-percent-value-sm" : "gauge-percent-value"}>{props.percentValue}%</div>
        <CircularGauge id="ChangeOrders">
          <Size width={props.smallGauge ? 50 : 80} height={props.smallGauge ? 50 : 80} />
          <ValueIndicator
            type="twoColorNeedle"
            secondFraction={0.0}
            color="none"
            secondColor={"green"}
          ></ValueIndicator>
          <Scale>
            <Tick visible={false}></Tick>
            <Label visible={false}></Label>
          </Scale>
          <RangeContainer
            width={props.smallGauge ? 4 : 7}
            backgroundColor="#e0e0e0"
            orientation={"inside"}
          >
            <Range
              startValue={0}
              endValue={props.endValue}
              color={props.color}
            ></Range>
          </RangeContainer>
          <Geometry startAngle={90} endAngle={450}></Geometry>
        </CircularGauge>
      </div>
    </>
  );
};


export function ShowAlert(message, title) {
  return (
    alert(`<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${message}</span></div>`, title)
  )
}

export function getFormattedTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  return `${month}/${day}/${year}`;
}

export const eCRUDStatus = {
  None: 0,
  Inserted: 1,
  Updated: 2,
  Deleted: 3
};

export const eEmailStatus = {
  None: 0,
  InProgress: 1,
  Success: 2,
  Error: 3,
  showLoader: 4

}

const pad = (num) => {
  return num < 10 ? '0' + num : num;
};

export function GetFormattedDate(inputString) {
  if (inputString) {
    const dateObject = new Date(inputString);

    // Get the individual components of the date
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString(); // Get year

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }
  return null;
}

export function GetFormattedDateOldFormat(inputString) {
  if (inputString) {
    const dateObject = new Date(inputString);

    // Get the individual components of the date
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString(); // Get year

    // Create the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate
  }
  return null;
}


export function GetFormattedDateTime(inputString) {
  if (inputString) {
    const dateObject = new Date(inputString);

    // Get the individual components of the date
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear().toString(); // Get year

    // Get local time components
    let hours = dateObject.getHours();
    const minutes = pad(dateObject.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate
  }
  return null;
}

export function GetFormattedTime(inputString) {
  const dateObject = new Date(inputString);

  // Get the individual components of the time
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  return formattedTime
}

export const GetFormattedDateTimeUTCString = (inputString, showSecound = true) => {
  if (inputString) {
    // Convert UTC datetime string to a Date object
    const utcDate = new Date(new Date(inputString + "Z").toUTCString());

    // Get local date components
    const day = pad(utcDate.getDate());
    const month = pad(utcDate.getMonth() + 1); // Month is zero-based
    const year = utcDate.getFullYear();

    // Get local time components
    let hours = utcDate.getHours();
    const minutes = pad(utcDate.getMinutes());
    const seconds = pad(utcDate.getSeconds());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date and time
    const formattedDateTime = showSecound ? `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}` : `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDateTime
  }
  return null;
}

export const parseCustomDateFormat = (dateStr) => {
  const [datePart, timePart] = dateStr.split(', ');

  const [day, month, year] = datePart.split('/');

  const [time, period] = timePart.split(' ');

  let [hours, minutes] = time.split(':');


  hours = parseInt(hours, 10);
  if (period === 'PM' && hours !== 12) {
    hours += 12;
  }
  if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  const formattedDate = `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${minutes}:00Z`;

  return new Date(formattedDate);
}


export function ConflictPopup(conflicMessage) {

  if (conflicMessage === "Record already modified by another user.") {
    let myDialog = custom({
      title: "Vakency",
      messageHtml: "This record has been modified by another user. <br/> Reload to see the latest version of the record. Data entered on this screen will be lost.",
      buttons: [{
        text: "Reload",
        type: "default",
        focusStateEnabled: false,
        stylingMode: "contained",
        elementAttr: { class: "btnReload" },
        onClick: (e) => {
          return true;
        }
      },
      {
        text: "Cancel",
        type: "danger",
        focusStateEnabled: false,
        stylingMode: "contained",
        elementAttr: { class: "btnCancel" },
        onClick: (e) => {
          return false;
        }
      },
      ]
    });

    return myDialog.show().then((dialogResult) => {
      if (dialogResult) {
        return true;
      }
      else {
        return false;
      }

    });
  } else {
    return;
  }
}

export const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\[\]{};:'",.<>/?\\|`~\u20B9])[A-Za-z\d!@#$%^&*()_\-+=\[\]{};:'",.<>/?\\|`~\u20B9]{8,16}$/;


export const onDragOver = (e) => {
  e.preventDefault();
}

export const minDate = new Date(1901, 0, 1);

const extractFileNamesWithExtension = (array) => {
  return array.map(path => {
    return path.name;
  });
}

export const addUniqueName = (array, newName) => {
  let counter = 1;
  let originalName = newName.substring(0, newName.lastIndexOf('.'));
  let extension = newName.substring(newName.lastIndexOf('.'));
  let uniqueName = originalName;
  let newArray = extractFileNamesWithExtension(array);

  // Check if the name already exists in the array
  while (newArray.includes(uniqueName + extension)) {
    uniqueName = originalName + counter;
    counter++;
  }

  return uniqueName;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function GetTimeAgoFormatted(timestamp) {

  function parseDateTime(dateTimeStr) {
    const [datePart, timePart] = dateTimeStr?.split(',');
    const [day, month, year] = datePart?.split('/');
    const [time, period] = timePart?.trim()?.split(' ');

    const [hours, minutes, seconds] = time?.split(':');


    let hours24 = parseInt(hours, 10);
    if (period?.toUpperCase() === 'PM' && hours24 < 12) {
      hours24 += 12;
    } else if (period?.toUpperCase() === 'AM' && hours24 === 12) {
      hours24 = 0;
    }

    const parsedDate = new Date(year, month - 1, day, hours24, minutes, seconds);
    return parsedDate;
  }


  const currentDate = new Date();
  const timestampDate = parseDateTime(timestamp);
  const seconds = Math.floor((currentDate - new Date(timestampDate)) / 1000);
  let interval = Math.floor(seconds / 31536000); // Years

  if (interval >= 1) return interval + (interval === 1 ? " year ago" : " years ago");
  interval = Math.floor(seconds / 2592000); // Months
  if (interval >= 1) return interval + (interval === 1 ? " month ago" : " months ago");
  interval = Math.floor(seconds / 86400); // Days
  if (interval >= 1) return interval + (interval === 1 ? " day ago" : " days ago");
  interval = Math.floor(seconds / 3600); // Hours
  if (interval >= 1) return interval + (interval === 1 ? " hour ago" : " hours ago");
  interval = Math.floor(seconds / 60); // Minutes
  if (interval >= 1) return interval + (interval === 1 ? " minute ago" : " minutes ago");
  const Seconds = Math.abs(seconds);
  return Seconds + (Seconds === 1 ? " second ago" : " seconds ago");
}

export const handleNavigateToMap = (address) => {
  if (address) {
    const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
    window.open(mapsUrl, "_blank");
  }
}

export const popupAnimation = {
  show: {
    type: 'pop',
    duration: 50,
    from: {
      scale: 0.9
    }
  }
}

export const websiteUrlRegex = /^(https?:\/\/(?!.*\/\/)[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,}|www\.(?!.*\/\/)[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,})$/;

export const closeDateBoxOnScroll = (ref , e) => {
  if (ref.current?.instance) {
    ref.current.instance.close(); // Close the DateBox calendar
  }
};
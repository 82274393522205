import { Popup, ScrollView } from "devextreme-react";
import React from "react";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { TickIcon } from "../../utils/base-64-Icons";



const AssessmentSuccessPopup = (props) => {
  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall,isExLarge } = useScreenSize();

  const onClosePopup = () => {
    props.HideSuccessPopup();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={"Assessment"}
          hideSaveButton={true}
        />
      </>
    );
  };


  return (
    <>
      <Popup
        visible={props.showSuccessPopup}
        onHiding={props.HideSuccessPopup}
        width={
          isXSmall || isXXSmall || isExSmall
          ? "75vw"
          : isMedium || isSmall
          ? "30vw"
          : "20vw"
        }
        height={"auto"}
        maxHeight={"95vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
      >
        <ScrollView width={"100%"} height={"100%"}  className="text-center">
        <div  className="text-center d-flex flex-column">
          <div className="text-center">
            <img
              src={TickIcon}
              alt=""
              width={50}
            />
          </div>
       
        
          <span className="fs-6 my-2 fw-semibold"> Assessment Submitted Successfully</span>
       </div>
        </ScrollView>
      </Popup>
    </>
  );
};

export default AssessmentSuccessPopup;

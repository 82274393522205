import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components';
import DataGrid, { Column, Button as DataGridButton, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import './User.scss'
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import UserPopup from './UserPopup';
import { confirm } from 'devextreme/ui/dialog';
import { UsersServices } from '../../../api/services/UsersServices';

const usersServices = new UsersServices();
export default function UserList() {


  const { isExSmall, isXXSmall } = useScreenSize();

  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsUser, setDsUser] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [userStatus, setUserStatus] = useState({
    popupStatus: eCRUDStatus.None,
    primaryKey: primaryKey,
    updatedOn: null
  })
  
  const GetUserListData = async () => {
    // setShowSkeleton(true)

    const result = await usersServices.GetListData();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency")
      }
      else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
      }
      setShowSkeleton(false);
    }
    else {
      const UserData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsUser(UserData)
      setShowSkeleton(false)
    }
  }

  useEffect(() => {
    if (userStatus.popupStatus !== eCRUDStatus.None) {
      GetUserModel(userStatus.primaryKey);
    }
  }, [userStatus])

  const GetUserModel = async (id) => {
    const result = await usersServices.GetListByIdData(id);
    if (!result.isOk) {
      if (result.statusCode === 400 || result.statusCode === 409) {
        ShowAlert(result.data, "Vakency")
      }
    }
    else {
      const UserData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      let userData = UserData[0]
      if (userStatus.popupStatus === eCRUDStatus.Inserted) {
        setDsUser([userData, ...dsUser])
      }
      else if (userStatus.popupStatus === eCRUDStatus.Updated) {
        setDsUser(dsUser.map((i) => (i.userID === id ? userData : i)));
      }
    }
    setfocusedRowKey(id);
    setPrimaryKey(0);
  }

  useEffect(() => {
    GetUserListData();
  }, [])

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(null)
    setShowPopup(true)
  }

  const handleEdit = (e) => {

    setPrimaryKey(e.row?.data?.primaryKeyID)
    setShowPopup(true)
  }

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data?.primaryKeyID)
    setShowPopup(true)
  }

  const handleDeleteWithComfirm = (e) => {
    let result = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
  );
    result.then(dialogResult =>
      dialogResult ? handleDeleteUser(e) : ""
    )
  }

  const handleDeleteUser = async (e) => {
    let primaryKeyID = e.row?.data?.primaryKeyID
    const result = await usersServices.DeleteData(primaryKeyID);
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200)  {
      setDsUser(dsUser.filter((x) => x.primaryKeyID !== primaryKeyID));
      // also delete profile picture
      const deleteResult = await usersServices.DeleteUserProfilePicture(primaryKeyID);
      if (deleteResult.statusCode === 400 || deleteResult.statusCode === 409) {
        ShowAlert("Cannot delete this user profile", "Vakency")
      }else{
        return;
      }
  }
  }

  const DataGridRef = useRef(null);

  const FullName = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link text-break'  title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.fullName}</span></div>
    </>
  }

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <div className='User'>
      <Header
        title={"Users"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetUserListData}
      />
      <div className='list-section' onDragOver={onDragOver}>
        {
          showSkeleton ?
            <ListSkeleton />
            :
            <DataGrid
              ref={DataGridRef}
              dataSource={dsUser}
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              focusedRowEnabled={true}
              focusedRowKey={focusedRowKey}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              autoNavigateToFocusedRow={true}
              keyExpr="userID"
              height={'100%'}
              width={"100%"}
              filterSyncEnabled={true}
              onOptionChanged={onOptionChange}
              className='List_DataGrid'
              loadPanel={HideDatagridLoader}
              onRowDblClick={onRowDbClick}
              noDataText='No Record Found'
              onFocusedRowChanged={onFocusedRowChanged}
            >
              <Sorting mode={"multiple"} />
              <FilterPanel visible={filterVisible} />
              <FilterBuilderPopup visible={false} />
              <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true} />
              <ColumnChooser enabled={true}>
                <ColumnChooserSearch
                  enabled={true}
                  />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
              <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
              <StateStoring enabled={true} type="localStorage" storageKey="user_layout" />
              <Pager
                visible={true}
                showInfo={true}
                infoText={`{2} Rows`}
              ></Pager>
              <Toolbar>
                <Item location={'after'} name="columnChooserButton" />
                <Item location={'after'} name="searchPanel" />
              </Toolbar>
              
              <Column 
                caption='Name' 
                cellRender={FullName} 
                dataField='fullName' 
                minWidth={120} 
                width={150} 
                alignment='left' 
                allowHiding={false} 
                // sortOrder='asc'
              />
              <Column 
                dataField='address' 
                minWidth={150} 
                alignment='left' 
                width={200} 
                // sortOrder='asc'
              />
              <Column caption='State' dataField='state' minWidth={120} width={150} alignment='left' />
              <Column caption='City' dataField='city' minWidth={100} width={120} alignment='left' />
              <Column caption='Zip Code' dataField='zipCode' minWidth={90} width={120} alignment='left' />
              <Column caption='Mobile No.' dataField='mobileNo' minWidth={100} width={130} alignment='left' />
              <Column caption='Email' dataField='emailID' minWidth={140} width={160} alignment='left' />
              <Column caption='User Role' dataField='userPermissionName' minWidth={150} alignment='left' />
              <Column
                dataField="createdByUserName"
                caption="Created By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />

              <Column
                dataField="updatedByUserName"
                caption="Updated By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="createdOn"
                caption="Created On"
                visible={false}
                width={135}
                minWidth={80}
                alignment="left"
                // dataType='datetime'
                // format="dd/MM/yyyy hh:mm a"
              />
              <Column
                dataField="updatedOn"
                caption="Updated On"
                visible={false}
                width={137}
                minWidth={80}
                alignment="left"
                // dataType='datetime'
                // format="dd/MM/yyyy hh:mm a"
              />
              <Column type='buttons'  name="delete_button_column" fixed={false} allowResizing={false} width={43} allowReordering={false}>
                <DataGridButton
                  name='delete'
                  icon='trash'
                  hint='Delete'
                  visible={true}
                  onClick={handleDeleteWithComfirm}
                  cssClass='text-danger'
                />
              </Column>
            </DataGrid>
        }
      </div>
     
      {showPopup === true &&
        <UserPopup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          primaryKey={primaryKey}
          setUserStatus={setUserStatus}
        />
      }
    </div>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import { SelectBox, Button as TextBoxButton } from 'devextreme-react/select-box';
import { Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import AcademicQualificationPopup from './AcademicQualificationPopup';
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AcademicQualificationServices } from '../../../api/services/AcademicQualificationServices';

const academicQualificationServices = new AcademicQualificationServices();
export default function AcademicQualificationDropDown(props) {


  const [openPopup, setOpenPopup] = useState(false);
  const [academicQualificationList, setAcademicQualificationList] = useState([]);
  let [primaryKey, setPrimaryKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const SelectBoxRef = useRef(null);

  useEffect(() => {
    openPopup === false && props.setDropDownClick && props.setDropDownClick(false);
  }, [openPopup])

  useEffect(() => {
    AcademicQualificationLookUpList();
  }, [])


  const AcademicQualificationLookUpList = async () => {
    const result = await academicQualificationServices.GetLookupListData();
    const response = result.data;
    setAcademicQualificationList(response);
  }

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await academicQualificationServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      if (status.eStatus === eCRUDStatus.Inserted) {
        setAcademicQualificationList([result.data[0], ...academicQualificationList]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setAcademicQualificationList(academicQualificationList.map((i) => i.academicQualificationID === primaryKey ? result.data[0] : i));
      }
    }
    props.setAddedacademicQualificationID && props.setAddedacademicQualificationID(primaryKey);
  };

  const AddButton = {
    icon: 'plus',
    stylingMode: 'text',
    onClick: () => {
      props.setDropDownClick && props.setDropDownClick(true);
      setOpenPopup(!openPopup)
    }
  }

  const DownArrow = {
    icon: 'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  return (
    <div>
      <SelectBox
        label="Academic Qualification"
        searchEnabled={true}
        id="AcademicQualification_DropDown"
        labelMode='floating'
        ref={SelectBoxRef}
        valueExpr="academicQualificationID"
        dataSource={academicQualificationList}
        displayExpr="academicQualificationName"
        value={props.value}
        onValueChange={props.onValueChange}
        dropDownOptions={{
          height: props.dropdownHeight ? props.dropdownHeight : 120,
          hideOnParentScroll:true,
          container:"#AcademicQualification_DropDown"
       }}
       hint='Academic Qualification'
      >
        <TextBoxButton
          name="add"
          location="after"
          options={AddButton}
        />
        <TextBoxButton
          name="dropdown"
          location="after"
          options={DownArrow}
        />
        {props.validationRequired && (
          <Validator validationGroup={props.validationGroup} elementAttr={{ class: 'ForValidationAstrikMark' }}>
            <RequiredRule message={props.validationMessage ? props.validationMessage : "Academic Qualification is required"} />
          </Validator>
        )}
      </SelectBox>
      {openPopup === true &&
        <AcademicQualificationPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          ValidationGroupName={props.validationGroup}
          setStatus={setStatus}
          primaryKey={primaryKey}
        />}
    </div>
  )
}

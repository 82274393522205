import { JobOpeningOpenControlName } from "../../contexts/APIurl";
import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";

const url = JobOpeningOpenControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobOpeningOpenService extends VakencyApiServices {
    constructor() {
        super(url);
    }

    GetLiveOpenings = async (key) => {
        const GetListUrl = url + `/GetLiveOpenings/${key}`;
        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetCompanyLogo = async (key) => {
        const GetListUrl = url + `/GetCompanyLogo/${key}`;
        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
            if (data.status && data.status === 200) {
                const fileUrl = await data.text();
                return this.fetchFileAsBlob(fileUrl);
            } else {
                return {
                    isOk: false,
                    data: await data.text(),
                    statusCode: data.status,
                };
            }
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetApplicationData = async (key) => {
        const GetListUrl = url + `/Get/${key}`;
        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetRegistrationDetail = async (params) => {
        const GetUrl = url + `/GetRegistrationDetail/${params}`;

        let data = null;
        try {
            data = await fetchWrapper.getWithoutHeader(baseURL + GetUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }
}
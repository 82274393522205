import { Button, DateBox, NumberBox, Popup, ScrollView, SelectBox, Validator } from "devextreme-react";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import React, { useEffect, useRef, useState } from "react";
import { closeDateBoxOnScroll, GetFormattedDateOldFormat, onKeyDown_Withpoint, popupAnimation, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RangeRule, RequiredRule } from "devextreme-react/validator";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";

const htmlEditorSecondAttributes = {
  class: "noteSecondHtml",
};
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const AcceptOfferPopup = (props) => {
  const ValidationGroupName = "assessmentPopupValidation"
  const [newRecord, setNewRecord] = useState({});
  const [acceptedCTCMonthlyValue, setAcceptedCTCMonthlyValue] = useState();

  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall, isExLarge } = useScreenSize();
  const htmlEditorRef = useRef();
  const DateBoxRef = useRef(null);
  const AcceptedDateBoxRef = useRef(null);

  const GetOfferDetails = async () => {

    const result = await jobOpeningControlPanelServices.GetOfferDetails(
      props?.jobApplicationID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      const response = result.data;
      setNewRecord({
        expectedJoiningDate: response?.expectedJoiningDate ? new Date(response?.expectedJoiningDate) : null,
        offerAcceptedDate: response?.offerAcceptedDate ? new Date(response?.offerAcceptedDate) : null,
        acceptedCTCPA: response?.offeredCTCPA || null,
      })
      if (response?.offeredCTCPA != null) {
        setAcceptedCTCMonthlyValue((response?.offeredCTCPA * 100000) / 12);
      }
    }
  };

  useEffect(() => {
    if (props?.jobApplicationID) {
      GetOfferDetails()
    }
  }, [])

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={"Accept Offer"}
          onSubmit={onSubmit}
          ValidationGroupName={ValidationGroupName}
        />
      </>
    );
  };

  const onSubmit = async () => {
    const dataToUpdate = {
      updatedOn: props?.jobApplicationUpdatedOn || null,
      jobApplicationID: props?.jobApplicationID,
      offerAcceptedDate: GetFormattedDateOldFormat(new Date(newRecord?.offerAcceptedDate)),
      acceptedCTCPA: newRecord?.acceptedCTCPA,
      expectedJoiningDate: GetFormattedDateOldFormat(new Date(newRecord?.expectedJoiningDate)),
      descrPlainText: htmlEditorRef.current?.instance.getQuillInstance().getText(),
      descrFormattedText: htmlEditorRef.current?.instance.option("value"),
    };

    try {
      const result = await jobOpeningControlPanelServices.AcceptOffer(dataToUpdate);
      if (result.isOk) {
        props?.GetApplication();
        props?.GetHeader();
        props.HideAssessmentPopup();
      } else {
        ShowAlert(result.data, "Vakency");
      }
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  }

  const OnPoupScroll =(e)=>{
      closeDateBoxOnScroll(DateBoxRef,e)
      closeDateBoxOnScroll(AcceptedDateBoxRef,e)
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95vw"
            : "50vw"
        }
        height={"auto"}
        maxHeight={"92vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        animation={popupAnimation}
      >
        <ScrollView width={"100%"} height={"100%"} onScroll={OnPoupScroll}>
          <form onSubmit={handleSubmit}>
            <div className="row px-3">
              <div className="col-6 pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <DateBox
                  id="JobOpening_offerAcceptedDate"
                  labelMode="floating"
                  ref={AcceptedDateBoxRef}
                  label="Offer Accepted Date"
                  value={newRecord?.offerAcceptedDate ? new Date(newRecord?.offerAcceptedDate) : null}
                  onValueChange={(e) => setNewRecord({ ...newRecord, offerAcceptedDate: e })}
                  type="date"
                  useMaskBehavior={true}
                  displayFormat="dd/MM/yyyy"
                  // min={minDate}
                  // dropDownOptions={{
                  //   hideOnParentScroll: true,
                  //   container: "#JobOpening_offerAcceptedDate"
                  // }}
                  dateOutOfRangeMessage="Invalid Date"
                  validationMessagePosition="right"
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Offer Accepted Date is required" />
                  </Validator>
                </DateBox>
              </div>
              <div className="col-6  pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <DateBox
                  id="JobOpening_expectedJoiningDate"
                  labelMode="floating"
                  ref={DateBoxRef}
                  label="Expected Joining Date"
                  value={newRecord?.expectedJoiningDate ? new Date(newRecord?.expectedJoiningDate) : null}
                  onValueChange={(e) => setNewRecord({ ...newRecord, expectedJoiningDate: e })}
                  type="date"
                  useMaskBehavior={true}
                  displayFormat="dd/MM/yyyy"
                  // min={minDate}
                  // dropDownOptions={{
                  //   hideOnParentScroll: true,
                  //   container: "#JobOpening_expectedJoiningDate"
                  // }}
                  dateOutOfRangeMessage="Invalid Date"
                  validationMessagePosition="left"
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Expected Joining Date is required" />
                    {
                      newRecord?.offerAcceptedDate &&
                      <RangeRule
                        message="Expected Joining Date should not be earlier than the Offer Date"
                        min={newRecord?.offerAcceptedDate}
                        ignoreEmptyValue={true}
                      />
                    }
                  </Validator>
                </DateBox>
              </div>
            </div>
            <div className="row pt-3 px-3">
              <div className="col-xxl col-xl col-lg">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                    Accepted CTC:
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Yearly"
                      labelMode="floating"
                      maxLength={15}
                      min={0}
                      format="##.##"
                      value={newRecord?.acceptedCTCPA && newRecord?.acceptedCTCPA.toFixed(2)}
                      onValueChange={(e) => {
                        setNewRecord({ ...newRecord, acceptedCTCPA: e })
                        setAcceptedCTCMonthlyValue((e * 100000) / 12)
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="accepted_ctc_yearly"
                        options={{
                          text: 'LPA',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Accepted CTC is required" />
                      </Validator>
                    </NumberBox>
                  </div>
                  <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                  <div className="col">
                    <NumberBox
                      label="Monthly"
                      labelMode="floating"
                      value={acceptedCTCMonthlyValue && acceptedCTCMonthlyValue.toFixed(2)}
                      maxLength={15}
                      min={0}
                      format="##.##"
                      onValueChange={(e) => {
                        setAcceptedCTCMonthlyValue(e);
                        setNewRecord({ ...newRecord, acceptedCTCPA: ((e * 12) / 100000) || null })
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="accepted_ctc_monthly"
                        options={{
                          text: 'month',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                    </NumberBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="assessment-html-editor-section px-4">
                <HtmlEditor
                  ref={htmlEditorRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  // height={isExLarge ? "670px" :  "790px"
                  //     // : "1100px"
                  // }
                  width={"auto"}
                  height={"200px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Overall Comments"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="d-flex justify-content-end py-2 pe-3">
              <Button
                validationGroup={ValidationGroupName}
                text="Submit"
                type="default"
                height={33}
                width={120}
                className="rounded-3"
                useSubmitBehavior={true}
              />
            </div>
          </form>
        </ScrollView>
      </Popup>
    </>
  );
};

export default AcceptOfferPopup;

import { Button, DataGrid, Popup, ScrollView } from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import { eEmailStatus, ShowAlert } from "../../utils/common-methods";
import {
  Column,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import { TickIcon, rejectedIcon } from "../../utils/base-64-Icons";
import CircleLoader from "../../images/CircleLoader.gif";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";

const candidateInteractionScheduleServices =
  new CandidateInteractionScheduleServices();
const EmailStatusPopup = (props) => {
  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall } = useScreenSize();
  const dataGridRef = useRef(null);
  const [dsGroupCandidate, setDsGroupCandidate] = useState([]);
  const [iterviewerEmailSent, setInterviewerEmailSent] = useState(false);
  const [candidateEmailSent, setCandidateEmailSent] = useState(false);

  const onClosePopup = () => {
    props.HideEmailStatusPopup();
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={"Email Status"}
          hideSaveButton={true}
        />
      </>
    );
  };



  useEffect(() => {
    if (props.candidateData) {
      let listData = [props.candidateData];
      listData.map((i) => {
        i.interviewerEmailStatus = eEmailStatus.InProgress;
        i.interviewerEmailResult = "Loading...";
        i.candidateEmailStatus = eEmailStatus.InProgress;
        i.candidateEmailResult = "Loading...";
      });
      setDsGroupCandidate(listData);
    } else {
      setDsGroupCandidate([]);
    }
  }, [props.candidateData]);


  const SendToInterviewerEmail = async (id) => {
    try {
      setInterviewerEmailSent(true)
      const result = await candidateInteractionScheduleServices.SendEmailToInterviewer(id);
      if (!result.isOk) {
        setDsGroupCandidate(prevState => [
          {
            ...prevState[0],
            interviewerEmailStatus: eEmailStatus.Error,
            interviewerEmailResult: result.data.includes("Message dropped because all recipients were suppressed")
              ? "Message dropped because all recipients were suppressed"
              : result.data.includes("Value cannot be empty or contain only white-space characters. (Parameter 'address')")
                ? "Invalid Email"
                : result.data
          },
        ]);
      } else if (result && result.isOk) {
        setDsGroupCandidate(prevState => [
          {
            ...prevState[0],
            interviewerEmailStatus: eEmailStatus.Success,
            interviewerEmailResult: "Email sent successfully"
          },
        ]);
      }
    } catch (error) {
      ShowAlert(error, "Vakency");
    }
  };
  const SendToCandidateEmail = async (id) => {
    try {
      setCandidateEmailSent(true)
      const result = await candidateInteractionScheduleServices.SendEmailToCandidate(id);
      if (!result.isOk) {
        setDsGroupCandidate(prevState => [
          {
            ...prevState[0],
            candidateEmailStatus: eEmailStatus.Error,
            candidateEmailResult: result.data.includes("Message dropped because all recipients were suppressed")
              ? "Message dropped because all recipients were suppressed"
              : result.data.includes("Value cannot be empty or contain only white-space characters. (Parameter 'address')")
                ? "Invalid Email"
                : result.data
          },
        ]);
      } else if (result && result.isOk) {
        setDsGroupCandidate(prevState => [
          {
            ...prevState[0],
            candidateEmailStatus: eEmailStatus.Success,
            candidateEmailResult: "Email sent successfully"
          },
        ]);
      }
    } catch (error) {
      ShowAlert(error, "Vakency");
    }

  };

  useEffect(() => {
    if (dsGroupCandidate.length > 0) {
      if (dsGroupCandidate[0].interviewerEmailStatus == eEmailStatus.InProgress && !iterviewerEmailSent) {
        SendToInterviewerEmail(props.candidateScheduleID);
      }
      if (dsGroupCandidate[0].candidateEmailStatus == eEmailStatus.InProgress && !candidateEmailSent) {
        SendToCandidateEmail(props.candidateScheduleID);

      }
    }
  }, [dsGroupCandidate]);


  const EmailInterviewerStatusCellRender = (cellInfo) => {
    if (cellInfo.data.interviewerEmailStatus == eEmailStatus.InProgress) {
      return (
        <div className="text-center">
          <>
            <img
              src={CircleLoader}
              alt=""
              width={24}
              title={cellInfo.data.interviewerEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    } else if (cellInfo.data.interviewerEmailStatus == eEmailStatus.Success) {
      return (
        <div className="text-center">
          <>
            <img
              src={TickIcon}
              alt=""
              width={24}
              title={cellInfo.data.interviewerEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    } else if (cellInfo.data.interviewerEmailStatus == eEmailStatus.Error) {
      return (
        <div className="text-center">
          <>
            <img
              src={rejectedIcon}
              alt=""
              width={20}
              title={cellInfo.data.interviewerEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    }
  };
  const EmailCandidateStatusCellRender = (cellInfo) => {
    if (cellInfo.data.candidateEmailStatus == eEmailStatus.InProgress) {
      return (
        <div className="text-center">
          <>
            <img
              src={CircleLoader}
              alt=""
              width={24}
              title={cellInfo.data.candidateEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    } else if (cellInfo.data.candidateEmailStatus == eEmailStatus.Success) {
      return (
        <div className="text-center">
          <>
            <img
              src={TickIcon}
              alt=""
              width={24}
              title={cellInfo.data.candidateEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    } else if (cellInfo.data.candidateEmailStatus == eEmailStatus.Error) {
      return (
        <div className="text-center">
          <>
            <img
              src={rejectedIcon}
              alt=""
              width={20}
              title={cellInfo.data.candidateEmailResult}
              className="rejected-icon-email-status"
            />
          </>
        </div>
      );

    }
  };

  return (
    <>
      <Popup
        visible={props.showEmailStatusPopup}
        onHiding={props.HideEmailStatusPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95%"
            : isMedium || isSmall
              ? "65%"
              : "45%"
        }
        height={"auto"}
        maxHeight={"92vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form>
            <DataGrid
              width={"100%"}
              ref={dataGridRef}
              className="List_DataGrid Email_Window_Grid"
              dataSource={dsGroupCandidate}
              keyExpr="candidateID"
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              autoNavigateToFocusedRow={true}
              filterSyncEnabled={true}
              noDataText="No Record Found"
            >
              <Column
                dataField="interviewerEmailStatus"
                caption="Interviewer Email Status"
                cellRender={EmailInterviewerStatusCellRender}
                width={200}
                minWidth={80}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="candidateEmailStatus"
                caption="Candidate Email Status"
                cellRender={EmailCandidateStatusCellRender}
                width={200}
                minWidth={80}
                allowHeaderFiltering={false}
              />

              <Column
                dataField="candidateName"
                caption="Candidate Name"
                minWidth={100}
                width={200}
              />
              <Column
                dataField="emailID"
                caption="Candidate Email ID"
                minWidth={100}
                width={180}
              />
            </DataGrid>
            <div className="py-3 text-center">
              <Button
                text="Close"
                type="default"
                height={33}
                width={120}
                className="rounded-3 "
                onClick={onClosePopup}
              />
            </div>
          </form>

        </ScrollView>

      </Popup>
    </>
  );
};

export default EmailStatusPopup;

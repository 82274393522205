import React, { useEffect, useState } from "react";
import "./AcceptOffer.scss";
import { Button, LoadPanel } from "devextreme-react";
import { GetFormattedDate, GetFormattedDateTimeUTCString, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import { useNavigate, useParams } from 'react-router-dom';
import { JobManagementOpenServices } from "../../api/services/JobManagementOpenServices";
import ListSkeleton from "../../layouts/templates/ListSkeleton";
import RejectOfferPopup from "./RejectOfferPopup";
import AcceptOfferSuccessPopup from "./AcceptOfferSuccessPopup";
import { PdfIcon } from "../../utils/base-64-Icons";

const jobManagementOpenServices = new JobManagementOpenServices()

const JobApplicationStatus = {
  "Pending": 0,
  "Shortlisted": 1,
  "Under Review": 2,
  "Selected": 3,
  "Offered": 4,
  "Offer accepted": 5,
  "Hired": 6,
  "On-boarded/joined": 7,
  "Rejected by us": 101,
  "Shortlisted but not selected": 102,
  "Offer rejected by candidate": 103,
  "Cancelled by candidate": 104,
  "Candidate not responding": 105
};

const AcceptOffer = () => {

  const { openaccesscode } = useParams();
  const NavigateTo = useNavigate();
  const [offerDetails, setOfferDetails] = useState(null);
  const [offerLetterFile, setOfferLetterFile] = useState(null);
  const [isOfferRejected, setIsOfferRejected] = useState(false);
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  const [showAcceptBtn, setShowAcceptBtn] = useState(false);
  const [validUptoDate, setValidUptoDate] = useState(null);
  const [showRejectOfferPopup, setShowRejectOfferPopup] = useState(false);
  const [offeredCTCMonthlyValue, setOfferedCTCMonthlyValue] = useState(0);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showLoadPanel, setShowLoadPanel] = useState(false);

  const {
    isXSmall,
    isExSmall,
    isXXSmall,
    isLarge,
    isExLarge,
  } = useScreenSize();

  useEffect(() => {
    GetOfferDetail();
  }, [isOfferAccepted, isOfferRejected]);

  useEffect(() => {
    GetOfferLetterFile().then((res) => {
      if (res?.statusCode == 204) {
        setOfferLetterFile({
          uploadedFile: {
            fileData: 'NoData'
          },
          attachedFile: 'NoData'
        })
      }
      else {
        setOfferLetterFile({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      }
    });
  }, [])

  useEffect(() => {
    if (offerDetails) {
      if (offerDetails?.jobApplicationStatus == JobApplicationStatus['Offered']) {
        let currentDateTime = new Date();
        const validUptoDateObject = new Date(offerDetails?.offerValidUpto);
        if (currentDateTime > validUptoDateObject) {
          redirectToErrorPage();
        }
        else {
          const timeDifference = validUptoDateObject - currentDateTime;
          const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Hours
          const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Minutes
          if (hoursDifference >= 24) {
            setValidUptoDate(validUptoDateObject.toLocaleString());
          } else {
            setValidUptoDate(`${hoursDifference} hrs ${minutesDifference} min`);
          }
          setShowAcceptBtn(true);
          setIsOfferAccepted(false);
          setIsOfferRejected(false);
        }
      }
      else if (
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Rejected by us'] ||
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Offer rejected by candidate']
      ) {
        setIsOfferRejected(true);
        setShowAcceptBtn(false);
        setIsOfferAccepted(false);
      }
      else if (
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Offer accepted'] ||
        offerDetails?.jobApplicationStatus == JobApplicationStatus['Hired'] ||
        offerDetails?.jobApplicationStatus == JobApplicationStatus['On-boarded/joined']
      ) {
        setIsOfferAccepted(true);
        setIsOfferRejected(false);
        setShowAcceptBtn(false);
      }
      else {
        redirectToErrorPage();
      }
    }
  }, [offerDetails]);

  const GetOfferLetterFile = async () => {
    try {
      const result = await jobManagementOpenServices.GetOfferLetterFile(openaccesscode);
      if (result.isOk) {
        let response = result.data;
        return response;
      }
      else {
        if (result.statusCode == 204) {
          return result
        }
      }
    } catch (error) {
      console.error(error);
    }

  }

  const GetOfferDetail = async () => {
    setShowSkeleton(true);
    try {
      const result = await jobManagementOpenServices.GetOfferDetails(openaccesscode);
      if (!result.isOk) {
        redirectToErrorPage();
      } else {
        setOfferDetails(result?.data);
        if (result.data?.offeredCTCPA) {
          setOfferedCTCMonthlyValue((result.data.offeredCTCPA * 100000) / 12)
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowSkeleton(false);
    }
  };

  const onOfferAccepted = async () => {
    setShowLoadPanel(true);
    const dataToUpdate = {
      offerAcceptedDate: new Date(),
      acceptedCTCPA: offerDetails?.offeredCTCPA,
    };
    try {
      const result = await jobManagementOpenServices.AcceptOffer(openaccesscode, dataToUpdate);
      if (!result.isOk) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSuccessPopup(true);
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    } finally {
      setShowLoadPanel(false);
    }

  };

  const onRejectOffer = () => {
    setShowRejectOfferPopup(true);
  }

  const HideRejectOfferPopup = () => {
    setShowRejectOfferPopup(false);
  }

  const onHideSuccessPopup = () => {
    setShowSuccessPopup(false);
    setIsOfferAccepted(true);
  }

  const onHidingLoadPanel = () => {
    setShowLoadPanel(false)
  }

  const onOfferRejected = () => {
    setIsOfferRejected(true);
    setShowAcceptBtn(false);
    setIsOfferAccepted(false);
  }

  const redirectToErrorPage = () => {
    NavigateTo("/error-page", {
      state: {
        message: 'Offer link is expired or invalid. Please contact to your hiring manager'
      }
    });
  }

  const downloadOfferLetter = () => {
    const elem = window.document.createElement("a");
    elem.href = offerLetterFile.uploadedFile.fileData;
    elem.download = 'OfferLetter.pdf';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }

  return (
    <>
      <LoadPanel
        visible={showLoadPanel}
        onHiding={onHidingLoadPanel}
        message="Processing your acceptance... Please wait."
      />
      {
        showSkeleton || !offerLetterFile?.uploadedFile?.fileData ? (
          <ListSkeleton />
        ) : (
          <div className="accept-offer-section w-100">
            <div className="accept-offer-card">
              <div className="row align-items-center px-3 m-0 mt-2">
                <div className={!isExLarge && !isLarge ? "col-12" : "col"}>
                  <div
                    className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                      } align-bottom  d-flex`}
                  >
                    <div className="me-3">
                      <span title="Edit">
                        {offerDetails?.jobProfileName}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${!isExLarge ? "font-semiBold-20" : "font-semiBold-23"
                      } align-bottom  d-flex text-gray`}
                  >
                    <div className="me-3">
                      <span title={offerDetails?.candidateName}>
                        {offerDetails?.candidateName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={"col-auto"}>
                  <i className="dx-icon-offereddateIcon dx-custom-icon me-1"></i>
                  Offered Date:{" "}
                  <b>{GetFormattedDate(offerDetails?.offerDate)}</b>
                </div>
                <div className="col-auto vertical-line d-none d-md-block px-0">|</div>
                <div className={"col-auto"}>
                  <i className="dx-icon-joiningdateIcon dx-custom-icon me-1"></i>
                  <span>Joining Date:</span>{" "}
                  <b>{GetFormattedDate(offerDetails?.expectedJoiningDate)}</b>
                </div>
                <div className="col-auto vertical-line d-none d-md-block px-0">|</div>
                <div className={"col-auto"}>
                  <i className="dx-icon-moneyIcon dx-custom-icon me-1"></i>
                  <span>Offered CTC:</span>{" "}
                  <b>{offerDetails?.offeredCTCPA} LPA</b>
                  <span className="px-2 assessment-pipe">|</span>
                  <b>{offeredCTCMonthlyValue?.toFixed(2)} Monthly</b>
                </div>
                {
                  validUptoDate && (
                    <>
                      <div className="col-auto vertical-line d-none d-md-block px-0">|</div>
                      <div className={"col-auto"}>
                        <i className="dx-icon-ValidUptodateIcon dx-custom-icon me-1"></i>
                        <span>Valid up to</span>{" "}
                        <b>{validUptoDate}</b>
                      </div>
                    </>
                  )
                }

              </div>
            </div>
            <div className="mt-0">
              {
                offerLetterFile?.uploadedFile?.fileData == "NoData" ? (
                  <p className="d-flex justify-content-center">No data for preview.</p>
                ) : (
                  <div className="offer-iframe">
                    {
                      (isExSmall || isXXSmall) ? (
                        <div className="d-flex flex-column justify-content-center align-items-center text-center offerLetter-iframe-sm">
                          <div className="d-flex align-items-center">
                            <img
                              src={PdfIcon}
                              alt="Uploaded File"
                              className="me-2"
                              width={50}
                              height={50}
                            />
                            <span className="font-medium-16">Offer Letter</span>
                          </div>
                          <Button
                            icon="download"
                            text="Download"
                            className="btnSkip mt-2 px-3 rounded-3"
                            hint="Download"
                            activeStateEnabled={false}
                            onClick={downloadOfferLetter}
                          />
                        </div>
                      ) : (
                        <iframe
                          src={`${offerLetterFile?.uploadedFile?.fileData}#navpanes=0`}
                          width="100%"
                          className={
                            isExSmall || isXXSmall || isXSmall
                              ? "offerLetter-iframe-sm"
                              : "offerLetter-iframe"
                          }
                          title="Offer Letter"
                        />
                      )
                    }
                  </div>
                )
              }
            </div>
            <div className="row mx-0 mt-2">
              <div className="col">
                {
                  showAcceptBtn && (
                    <>
                      <div className="row">
                        <div className="col-auto">
                          <Button
                            text="Accept Offer"
                            className="add-new-candidate-btn"
                            hint="Accept Offer"
                            onClick={onOfferAccepted}
                          />
                        </div>
                        <div className="col-auto ">
                          <Button
                            text="Reject Offer"
                            className="reject-offer-btn"
                            hint="Reject Offer"
                            onClick={onRejectOffer}
                          />
                        </div>
                      </div>
                    </>
                  )
                }
                {
                  isOfferAccepted && (
                    <div className="font-semiBold-18 d-flex align-items-center">
                      <i className="dx-icon-tickIcon dx-custom-icon me-1"></i>
                      <span className="me-1">The offer was accepted on</span>{" "}
                      <b>{GetFormattedDateTimeUTCString(offerDetails?.offerAcceptedDate , false)}</b>
                    </div>
                  )
                }
                {
                  isOfferRejected && (
                    <div className="font-semiBold-18 d-flex align-items-center">
                      <i className="dx-icon-crossIcon dx-custom-icon me-1" />
                      <span>{`The offer was rejected by the ${offerDetails?.jobApplicationStatus == JobApplicationStatus['Rejected by us'] ? 'hiring manager' : 'candidate'}`}</span>{" "}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      {showRejectOfferPopup && <RejectOfferPopup
        showPopup={showRejectOfferPopup}
        HideRejectOfferPopup={HideRejectOfferPopup}
        openaccesscode={openaccesscode}
        onOfferRejected={onOfferRejected}
      />}
      {showSuccessPopup && <AcceptOfferSuccessPopup
        isOpen={showSuccessPopup}
        onClose={onHideSuccessPopup} />
      }
    </>
  );
};

export default AcceptOffer;

import { Button, LoadIndicator, LoadPanel } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import React, { useEffect, useRef, useState } from 'react';
import "./RegistrationForm.scss";
import Validator from 'devextreme-react/validator';
import { EmailRule, RequiredRule, } from 'devextreme-react/data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidateEmail, send_Email_Verification } from '../../api/auth';
import { onDragOver } from '../../utils/common-methods';

const ValidationGroupName = "registrationValidation";

export default function RegistrationForm(props) {

    const FocusedField = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [registrationData, setRegisrationData] = useState({
        email: null,
    });
    const [showError, setShowError] = useState(null);

    useEffect(() => {
        FocusedField.current?.instance.focus();
        if (location.state?.registration_Email) {
            setRegisrationData({ email: location.state?.registration_Email });
        }
    }, [location]);

    const sendEmailVerification = async () => {
        props.setShowLoader(true);
        const response = await ValidateEmail(registrationData.email);
        if (response.isOk) {
            const result = await send_Email_Verification(registrationData.email);
            if (result.isOk) {
                setShowError(null)
                localStorage.setItem("registration_Email", registrationData.email)
                props.setShowLoader(false);
                navigate('/check-your-email')
            }
            else {
                props.setShowLoader(false);
                setShowError(result.data)
            }
        }
        else {
            props.setShowLoader(false);
            setShowError(response.data);
            // setTimeout(() => {
            //     navigate('/login');
            // }, 5000)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (registrationData.email) {
            sendEmailVerification();
        }
    }

    return (
        <>
            {props.showLoader ?
                <div className='m-auto text-center loaderCart-height'>
                    <LoadIndicator className='indicator' id="small-indicator" height={50} width={50} visible={true} />
                    <div className='pt-1'>Please Wait.....</div>
                </div>
                :
                <form onSubmit={handleSubmit} className='px-3' onDragOver={onDragOver}>
                    <div className='row py-2 mt-3'>
                        <div className='font-semiBold-16 text-center registartion-title'>Registration into Vakency</div>
                    </div>
                    <div className='row py-2 form_label'>
                        <TextBox
                            ref={FocusedField}
                            label='Work Email'
                            mode='email'
                            labelMode='floating'
                            stylingMode='underlined'
                            className='py-1 px-0'
                            inputAttr={{
                                autocomplete: "new"
                            }}
                            value={registrationData.email}
                            onValueChange={(value) => setRegisrationData({ ...registrationData, email: value })}
                        >
                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                <RequiredRule message='Email ID is required' />
                                <EmailRule message='Email ID is not valid' />
                            </Validator>
                        </TextBox>
                    </div>
                    {showError &&
                        <div className='text-center text-danger py-1'>
                            {showError}
                        </div>
                    }
                    <div className='row py-2 mt-2 justify-content-center'>
                        <Button
                            width="auto"
                            text='Get Started'
                            useSubmitBehavior={true}
                            type='default'
                            validationGroup={ValidationGroupName}
                        >
                        </Button>
                    </div>
                    <div className='row py-2 mt-2 '>
                        <div className='text-center font-regular-13'>Sign up link will be sent to you on email</div>
                    </div>
                </form>
            }

        </>
    )
}

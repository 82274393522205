export const dsMaritalStatus = [
    {
        maritalStatusID: 0,
        maritalStatus: "Single",
    },
    {
        maritalStatusID: 1,
        maritalStatus: "Married",
    },
    {
        maritalStatusID: 2,
        maritalStatus: "Divorced",
    },
    {
        maritalStatusID: 3,
        maritalStatus: "Other",
    },
];

export const dsGenderStatus = [
    {
        genderID: 0,
        genderStatus: "Male",
    },
    {
        genderID: 1,
        genderStatus: "Female",
    },
    {
        genderID: 2,
        genderStatus: "Transgender",
    },
    {
        genderID: 3,
        genderStatus: "Other",
    },
];

export const EmailTemplateType = {
    Assessment: 1,
}

export const EmailReceiverType = {
    Interviewer: 0,
    Candidate: 1,
}
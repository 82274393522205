import { ScrollView } from 'devextreme-react'
import React from 'react'

const HomeListSkeleton = (props) => {
    return (
        <div>
            <>
                <ScrollView width={'100%'} height={props.height ? props.height : "100%"}>
                    <div className="skeleton-section">
                        <div className="row gx-0">
                            <div className="col ps-1"> <span className='font-semiBold-18'>{props.title}</span></div>
                            <div className="col skeleton-toolbar-height rounded list-toolbar-skeleton w-75"></div>
                        </div>
                        <span className="custom-skeleton skeleton-header-height"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                        <span className="custom-skeleton"></span>
                    </div>
                </ScrollView>
            </>
        </div>
    )
}

export default HomeListSkeleton

import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../components/header/Header';
import { DataGrid } from 'devextreme-react';
import { Column, Button as DataGridButton, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, Pager, Scrolling, SearchPanel, Sorting, StateStoring, Toolbar, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import CandidateSourcePopup from './CandidateSourcePopup';
import { confirm } from 'devextreme/ui/dialog';
import { CandidateSourceServices } from '../../../api/services/CandidateSourceServices';

const candidateSourceServices = new CandidateSourceServices();
export default function CandidateSource() {


  const { isExSmall, isXXSmall } = useScreenSize();

  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true)
  const [dsCandidateSource, setDsCandidateSource] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const GetList = async () => {
    
    // setShowSkeleton(true)
    
    const result = await candidateSourceServices.GetListData();
    if (result.statusCode === 400 || result.statusCode === 409) {    
      ShowAlert(result.data, "Vakency")
      setShowSkeleton(false);
    }
    else {
      const CSData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsCandidateSource(CSData);
      setShowSkeleton(false);
    }
  }

  // Get all countries records
  useEffect(() => {
    GetList();
  }, [])

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await candidateSourceServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const CSData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
        const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsCandidateSource([CSData[0], ...dsCandidateSource]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsCandidateSource(dsCandidateSource.map((i) => i.candidateSourceID === primaryKey ? CSData[0] : i));
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(null)
    setShowPopup(true)
  }

  const onEditClick = (e) => {
    setPrimaryKey(e.data.candidateSourceID)
    setShowPopup(true)
  }

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.candidateSourceID)
    setShowPopup(true)
  }

  const DataGridRef = useRef(null);

  const CandidateSourceName = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => onEditClick(cellData)}>{cellData.data.candidateSourceName}</span></div>
    </>
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.candidateSourceID;
    const result = await candidateSourceServices.DeleteData(Id);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200) {
      setDsCandidateSource(dsCandidateSource.filter((x) => x.candidateSourceID !== e.row.data.candidateSourceID));
    }
  }


  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }


  return (
    <>
      <Header
        title={"Candidate Sources"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetList}
      />
      <div className='list-section' onDragOver={onDragOver}>
        {
          showSkeleton ?
            <ListSkeleton />
            :
            <DataGrid
              ref={DataGridRef}
              dataSource={dsCandidateSource}
              showBorders={true}
              showColumnLines={false}
              showRowLines={true}
              focusedRowEnabled={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              autoNavigateToFocusedRow={true}
              keyExpr="candidateSourceID"
              height={'100%'}
              width={"100%"}
              filterSyncEnabled={true}
              onOptionChanged={onOptionChange}
              className='List_DataGrid'
              loadPanel={HideDatagridLoader}
              onRowDblClick={onRowDbClick}
              focusedRowKey={focusedRowKey}
              onFocusedRowChanged={onFocusedRowChanged}
              noDataText='No Record Found'
            >
              <Sorting mode={"multiple"} />
              <FilterPanel visible={filterVisible} />
              <FilterBuilderPopup visible={false} />
              <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true} />
              <ColumnChooser enabled={true}>
                <ColumnChooserSearch
                  enabled={true}
                />
              </ColumnChooser>
              <HeaderFilter visible={true} >
                <Search
                  enabled={true}
                />
              </HeaderFilter>
              <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
              <StateStoring enabled={true} type="localStorage" storageKey="CandidateSources_Layout" />
              <Pager
                visible={true}
                showInfo={true}
                infoText={`{2} Rows`}
              ></Pager>
              <Toolbar>
                <Item location={'after'} name="columnChooserButton" />
                <Item location={'after'} name="searchPanel" />
              </Toolbar>
              <Column
                caption='Candidate Source'
                dataField='candidateSourceName'
                minWidth={100}
                cellRender={CandidateSourceName}
                alignment='left'
                allowHiding={false}
              />
              <Column
                dataField="createdByUserName"
                caption="Created By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />

              <Column
                dataField="updatedByUserName"
                caption="Updated By"
                visible={false}
                width={125}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="createdOn"
                caption="Created On"
                visible={false}
                width={135}
                minWidth={80}
                alignment="left"
              />
              <Column
                dataField="updatedOn"
                caption="Updated On"
                visible={false}
                width={137}
                minWidth={80}
                alignment="left"
              />
              <Column type='buttons' fixed={false} allowResizing={false} width={43} allowReordering={false}>
                <DataGridButton
                  name='delete'
                  icon='trash'
                  hint='Delete'
                  visible={true}
                  cssClass='text-danger'
                  onClick={onDeleteButtonClick}
                />
              </Column>
            </DataGrid>
        }
      </div>

      {showPopup === true &&
        <CandidateSourcePopup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
          status={status}
        />
      }
    </>
  )
}

import {
  CheckBox,
  DateBox,
  LoadPanel,
  NumberBox,
  Popup,
  ScrollView,
  TagBox,
  TextBox,
  Validator,
} from "devextreme-react";
import SelectBox, {
  Button as SelectBoxButton
} from "devextreme-react/select-box";
import React, { useEffect, useRef, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { useScreenSize } from "../../utils/media-query";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { BlankProfile, candidateUnavailableIcon, completedIcon, failedIcon, interviewCancelledIcon, notAttendIcon, pendingIcon, rejectedIcon, selectedIcon, shortlistedIcon } from "../../utils/base-64-Icons";
import { NewStarRating, ShowAlert, closeDateBoxOnScroll, eCRUDStatus, handleNavigateToMap, websiteUrlRegex } from "../../utils/common-methods";
import {
  CustomRule,
  EmailRule,
  PatternRule,
  RequiredRule,
} from "devextreme-react/validator";
import {
  CandidateControlName,
  CandidateInteractionSchedule,
  InteractionRoundControlName,
} from "../../contexts/APIurl";
import { CandidateInteractionServices } from "../../api/services/CandidateInteractionServices";
import { CandidateInteractionScheduleServices } from "../../api/services/CandidateInteractionScheduleServices";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import EmailStatusPopup from "./EmailStatusPopup";
import { JobOpeningServices } from "../../api/services/JobOpeningServices";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import FilePreviewer from "../candidate/FilePreviewer";
import JobInteractionRoundPopup from "../application-settings/interaction-round/JobInteractionRoundPopup";

const candidateInteractionServices = new CandidateInteractionServices();
const candidateInteractionScheduleServices = new CandidateInteractionScheduleServices();
const candidateControlServices = new CandidateControlServices();
const jobOpeningServices = new JobOpeningServices();
const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const InteractionPopup = (props) => {
  const { isExSmall, isXXSmall, isXSmall, isSmall, isMedium } = useScreenSize();

  const [dsInteractionRound, setDsInteractionRound] = useState();
  const [groupedInteractionRoundsResult, setGroupedInteractionRoundsResult] = useState({});
  const [newRecord, setNewRecord] = useState({
    interactionNo: null,
    interactionRound: null,
    interviewers: "",
    coInterviewers: [],
    scheduleLater: false,
    scheduleDate: null,
    scheduleTime: null,
    meetingLink: null,
  });
  const [candidateData, setCandidateData] = useState();
  const [candidateScheduleID, setCandidateScheduleID] = useState();
  const [showEmailStatusPopup, setShowEmailStatusPopup] = useState(false);
  const [showFilePreview, setShowFilePreview] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);
  const [showInteractionRoundPopup, setShowInteractionRoundPoup] = useState(false);
  const [NewRoundNames, SetNewRoundNames] = useState('');
  const [dropDownClick, setDropDownClick] = useState(false);

  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });

  const minDate = new Date();

  const ValidationGroupName = "ScheduleValidationGroupName";

  const SelectBoxRef = useRef(null);
  const scheduleTimeRef = useRef(null);
  const DateBoxRef = useRef(null);

  useEffect(() => {
    GenerateInteractionNo();
    GetInteractionRoundLookupList();
    GetModelRecord(props?.candidateId);
  }, []);

  useEffect(() => {
    if (props.dsInteractionRoundsResults.length > 0) {
      const result = Object.groupBy(props.dsInteractionRoundsResults, ({ interactionRoundName }) => interactionRoundName);
      setGroupedInteractionRoundsResult(result);
    }
  }, [props.dsInteractionRoundsResults]);

  useEffect(() => {
    GetInteractionRoundLookupList(NewRoundNames);
  }, [NewRoundNames])

  useEffect(() => {
    showInteractionRoundPopup === false &&
      setDropDownClick &&
      setDropDownClick(false);
  }, [showInteractionRoundPopup])

  const GenerateInteractionNo = async () => {
    const result = await candidateInteractionServices.GenerateCandidateInteractionNo();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setNewRecord({ ...newRecord, interactionNo: result.data });
    }
  };

  const GetInteractionRoundLookupList = async (NewRoundName) => {
    const result = await jobOpeningServices.GetJobOpeningInteractionRoundLookupList(props?.jobOpeningID);
    if (result.isOk) {
      if (NewRoundName) {
        result.data.map((item) => {
          if (item?.interactionRoundName === NewRoundName) {
            setNewRecord({ ...newRecord, interactionRound: item?.interactionRoundID })
          }
        })
      }
      SetNewRoundNames("")
      setDsInteractionRound(result.data);
    }
  };

  const onClosePopup = () => {
    props.setOpenPopup(false);
  };

  const GetModelRecord = async (Id) => {
    const result = await candidateInteractionServices.GetModelDataByControlName(CandidateControlName, Id);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      const response = result.data;
      setCandidateData(response);
      // setNewRecord(response)
    }
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={[
            <span key={"header-title"} className="base-accent-text">
              {" "}
              New
            </span>,
            " Interaction",
          ]}
          onSubmit={InsertRecord}
        />
      </>
    );
  };

  function mapCoInterviewEmails(emailArray) {
    return emailArray.map((email) => {
      return {
        interviewerEmailID: email,
        interviewerTypeID: 1,
      };
    });
  }

  function convertToISOString(dateTimestamp, timeTimestamp) {
    // Extract timezone offset from time timestamp
    let offset = timeTimestamp.getTimezoneOffset() * 60000; // Offset in milliseconds
    // Adjust timeDt by timezone offset
    let timeDtUTC = new Date(timeTimestamp.getTime() - offset);
    // Combine date and time
    let combinedDt = new Date(
      Date.UTC(
        dateTimestamp.getFullYear(),
        dateTimestamp.getMonth(),
        dateTimestamp.getDate(),
        timeDtUTC.getUTCHours(),
        timeDtUTC.getUTCMinutes(),
        timeDtUTC.getUTCSeconds()
      )
    );
    // Format to ISO 8601
    let isoFormat = combinedDt.toISOString();
    return isoFormat;
  }

  const HideEmailStatusPopup = () => {
    onClosePopup();
    setShowEmailStatusPopup(!showEmailStatusPopup)
  }

  const InsertRecord = async () => {
    const coInterviewers = mapCoInterviewEmails(newRecord?.coInterviewers);
    let dataToInsert = {
      jobApplicationID: props.jobApplicationID,
      interactionRoundID: newRecord.interactionRound,
      interviewers: [
        {
          interviewerEmailID: newRecord.interviewers,
          interviewerTypeID: 0,
        },
        ...coInterviewers,
      ],
    };
    const response = await candidateInteractionServices.InsertData(
      dataToInsert
    );
    if (!response.isOk) {
      if (response.statusCode === 400) {
        ShowAlert(response.data, "Vakency");
      }
    } else {
      if (newRecord.scheduleLater !== true) {
        const dataForSchedule = {
          candidateInteractionID: response.data.responseData,
          scheduleDateTime: `${convertToISOString(
            newRecord.scheduleDate,
            newRecord.scheduleTime
          )}`,
          meetingLink: newRecord.meetingLink,
          descrPlainText: null,
          descrFormattedText: null,
        };
        const scheduleResult = await candidateInteractionScheduleServices.InsertData(
          dataForSchedule
        );
        if (!scheduleResult.isOk) {
          if (scheduleResult.statusCode === 400) {
            ShowAlert(scheduleResult.data, "Vakency");
          }
        }
        else {
          const ScheduleID = scheduleResult?.data?.responseData?.data
          setCandidateScheduleID(ScheduleID)
          setShowEmailStatusPopup(true);
        }
      }
      if (newRecord?.scheduleLater) {
        onClosePopup();
      }
      props.setStatus({
        eStatus: eCRUDStatus.Inserted,
        primaryKeyID: response.data.responseData,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownClick === false) {
      InsertRecord();
    }
  };

  const ValidateInterviewer = () => {
    if (newRecord.interviewers) {
      for (let j = 0; j < newRecord.coInterviewers.length; j++) {
        if (newRecord.interviewers == newRecord.coInterviewers[j]) {
          return false;
        }
      }
    }
    return true;
  };

  const ValidateCandidate = (e) => {
    if (candidateData?.emailID) {
      if (candidateData?.emailID === e?.value) {
        return false
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  const ValidateCandidateCoInterviewer = (e) => {
    if (candidateData?.emailID) {
      for (let j = 0; j < e?.value?.length; j++) {
        if (candidateData?.emailID === e?.value[j]) {
          return false;
        }
      }
    }
    return true;
  }


  useEffect(() => {
    if (props?.candidateId) {
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
    }
  }, []);

  const GetProfileImage = async () => {
    let params = `?CandidateID=${props?.candidateId}`;
    const result = await candidateControlServices.GetImage(params);
    let response = result.data;
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };
  const onPhoneButtonClick = () => {
    window.location.href = `tel:${candidateData?.mobileNo1}`;
  }

  const itemRender = (data) => {
    const currentRounds = groupedInteractionRoundsResult[data?.interactionRoundName];
    let existingRound;
    let roundResult;
    if (currentRounds && currentRounds?.length > 0) {
      existingRound = currentRounds[currentRounds.length - 1];
      roundResult = existingRound?.interactionResultName;
    } else {
      roundResult = null;
    }
    return (
      <div className="row">
        <div className={`text-break text-wrap col-10`}>{data.interactionRoundName}</div>
        <div className="col-2 d-flex justify-content-end">
          {
            roundResult && (
              <img
                src={roundResult === "Pending" ? pendingIcon : roundResult === "Passed" ? selectedIcon : roundResult === "Failed" ? failedIcon : roundResult === "Candidate did not attend" ? notAttendIcon : roundResult === "Cancelled by candidate" ? candidateUnavailableIcon : roundResult === "Cancelled by us" ? interviewCancelledIcon : rejectedIcon}
                title={roundResult}
                alt="interaction status"
                height={20}
                width={20}
              />
            )
          }
        </div>
      </div>
    )
  }

  const onPictureClick = (fileSrc) => {
    if (fileSrc) {
      setShowFilePreview(true);
      setSelectedFile({
        fileSrc: fileSrc,
      });
    }
  }

  const onFilePreviewClose = () => {
    setShowFilePreview(false)
  }

  const AddButton = {
    icon: 'plus',
    stylingMode: 'text',
    onClick: () => {
      setDropDownClick(true);
      setShowInteractionRoundPoup(!showInteractionRoundPopup)
    }
  }

  const DownArrow = {
    icon: 'spindown',
    stylingMode: 'text',
    onClick: (e) => {

      var selectBoxInstance = SelectBoxRef.current?.instance;
      var isOpened = selectBoxInstance.option("opened");
      if (isOpened) {
        selectBoxInstance.close();
      } else {
        selectBoxInstance.open();
        selectBoxInstance.focus();
      }
    }
  }

  const OnPoupScroll =(e)=>{
        closeDateBoxOnScroll(DateBoxRef,e)
        closeDateBoxOnScroll(scheduleTimeRef,e)
  }

  return (
    <>
      <LoadPanel
        visible={showFilePreviewLoad}
        message='File is loading. We’ll display the preview shortly.'
      />
      <Popup
        visible={props.openPopup}
        titleRender={PopupTitle}
        width={
          isExSmall || isXXSmall || isXSmall
            ? "90%"
            : isSmall || isMedium
              ? "60%"
              : "40%"
        }
        height={"auto"}
        maxHeight={"95vh"}
        wrapperAttr={{ class: "CustomPopup CommonPopupClass" }}
      >
        <ScrollView onScroll={OnPoupScroll} height={"100%"}>
        <form onSubmit={handleSubmit}>
            <div className="row pt-2" id="InteractionPopup_DropDown">
              <div className="col-auto" >
                <div onClick={() => onPictureClick(profileImg.uploadedFile?.fileData)}>
                  {profileImg.uploadedFile?.fileData ? (
                    <img
                      src={profileImg.uploadedFile?.fileData}
                      alt="candidate"
                      width={100}
                      height={80}
                      className="ms-3"
                    />
                  ) :
                    <img
                      src={BlankProfile}
                      alt="candidate"
                      width={100}
                      height={80}
                      className="ms-3"
                    />}
                </div>
              </div>
              <div className="col ">
                <div>
                  <div className="row">
                    <div className="col-auto font-semiBold-20" title={(candidateData?.candidateName) ? (candidateData?.candidateName) : ""}>
                      {(candidateData?.candidateName) ? ((candidateData?.candidateName).length > 35 ? (candidateData?.candidateName).slice(0, 35) + "..." : (candidateData?.candidateName)) : ""}
                    </div>
                    <div className="col font-medium-18">
                      <NewStarRating rating={props.candidateRating} size={16} />
                    </div>
                    <div className="col d-flex justify-content-end">
                      <div className="pe-3">
                        <img
                          src={shortlistedIcon}
                          alt="status"
                          width={22}
                          title="Shortlisted"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-1 font-medium-14">
                    {candidateData?.currentCompanyName && (
                      <span title={(candidateData?.currentCompanyName) ? ("Current Company Name :" + candidateData?.currentCompanyName) : ""}>
                        {(candidateData?.currentCompanyName) ? ((candidateData?.currentCompanyName).length > 35 ? (candidateData?.currentCompanyName.slice(0, 35) + "...") : (candidateData?.currentCompanyName)) : ""}
                      </span>
                    )}
                    {candidateData?.currentCompanyName && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    {candidateData?.currentCompanyName && (
                      <span title="Working Period">2022 - Present</span>
                    )}
                    {candidateData?.academicQualificationYear && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    {candidateData?.academicQualificationYear && (
                      <span title="Last Education - year">
                        B.Tech - {candidateData?.academicQualificationYear}
                      </span>
                    )}
                  </div>
                  <div className="pt-1 font-medium-14">
                    {candidateData?.currentAddress && (
                      <i className="dx-icon-locationIcon dx-custom-icon cursor-pointer" onClick={() => handleNavigateToMap(candidateData?.currentAddress)}></i>
                    )}
                    {candidateData?.currentAddress}
                    {candidateData?.currentAddress && (
                      <span className="px-2 verticle-line-small">|</span>
                    )}
                    <span onClick={onPhoneButtonClick} className="cursor-pointer">
                      <i className="dx-icon-MobileIcon dx-custom-icon"></i>
                      {candidateData?.mobileNo1}
                    </span>{" "}
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.linkedIn ? <a
                      href={`${candidateData?.linkedIn}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="dx-icon-LinkedInIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon mb-1"></i>
                    }
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.skype ? <a
                      href={`skype:${candidateData?.skype}?chat`}
                      rel="noreferrer"
                    >
                      <i className="dx-icon-skypeIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon mb-1"></i>
                    }
                    <span className="px-2 verticle-line-small">|</span>
                    {candidateData?.mobileNo2 ? <a
                      href={`https://wa.me/91${candidateData?.mobileNo2}`}
                      rel="noreferrer"
                      target='_blank'
                    >
                      <i className="dx-icon-WhatsappIcon dx-custom-icon mb-1"></i>
                    </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon mb-1"></i>
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row px-3">
              <div className="col-md">
                <NumberBox
                  label="Interaction No."
                  readOnly={true}
                  labelMode="floating"
                  value={newRecord.interactionNo}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionNo: e })
                  }
                />
              </div>
              <div className="col-md pt-3 pt-md-0">
                <SelectBox
                  id="InteractionRoundPopup_DropDown"
                  label="Interaction Round"
                  labelMode="floating"
                  valueExpr={"interactionRoundID"}
                  displayExpr={"interactionRoundName"}
                  dataSource={dsInteractionRound}
                  value={newRecord.interactionRound}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, interactionRound: e })
                  }
                  dropDownOptions={{
                    height: newRecord.scheduleLater ? 100 : 180,
                    hideOnParentScroll: true,
                    container: "#InteractionRoundPopup_DropDown"
                  }}
                  itemRender={itemRender}
                  ref={SelectBoxRef}
                >
                  <SelectBoxButton
                    name="add"
                    location="after"
                    options={AddButton}
                  />
                  <SelectBoxButton
                    name="dropdown"
                    location="after"
                    options={DownArrow}
                  />
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Interaction Round is required" />
                  </Validator>
                </SelectBox>
              </div>
            </div>
            <div className="pt-3 px-3">
              <TextBox
                id="Interviewers"
                name="Interviewers"
                label="Interviewers Email ID"
                labelMode="floating"
                value={newRecord?.interviewers}
                onValueChange={(e) =>
                  setNewRecord({ ...newRecord, interviewers: e })
                }
                showDropDownButton={false}
                openOnFieldClick={false}
                acceptCustomValue={true}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                  elementAttr={{ class: "ForValidationAstrikMark" }}
                >
                  <RequiredRule message="Interviewers are required" />
                  <EmailRule message="Enter a valid Email ID" />
                  <CustomRule
                    message="Interviewer and Candidate should not be same."
                    validationCallback={ValidateCandidate}
                  />
                </Validator>
              </TextBox>
            </div>
            <div className="pt-3 px-3">
              <TagBox
                id="Co-Interviewers"
                name="Co-Interviewers"
                label="Co-Interviewers Email ID"
                labelMode="floating"
                // value={newRecord.coInterviewers}
                onValueChange={(e) =>
                  setNewRecord((prevState) => ({
                    ...prevState,
                    coInterviewers: e,
                  }))
                }
                showDropDownButton={false}
                openOnFieldClick={false}
                acceptCustomValue={true}
                placeholder=""
                maxLength={50}
                multiline={false}
              >
                <Validator
                  validationGroup={ValidationGroupName}
                >
                  <CustomRule
                    message="Interviewer and Co-Interviewer should not be same."
                    validationCallback={ValidateInterviewer}
                  />
                  <CustomRule
                    message="Co-Interviewer and Candidate should not be same."
                    validationCallback={ValidateCandidateCoInterviewer}
                  />
                  <EmailRule message="Enter a valid Email ID" />
                </Validator>
              </TagBox>
            </div>
            <div className="ms-3 pt-3">
              <CheckBox
                text="Schedule Later"
                //   value={newRecord.scheduleLater ? newRecord.scheduleLater : false}
                onValueChange={(e) => {
                  setNewRecord({ ...newRecord, scheduleLater: e });
                }}
              />
            </div>

            <hr className="mx-3 mt-2" />
            <div>
              {newRecord.scheduleLater !== true && (
                <>
                  <div className="row px-3">
                    <div className="col-auto pt-3 editor-label">Schedule:</div>
                    <div className="col">
                      <DateBox
                         ref={DateBoxRef}
                        id="InteractionDate_DropDown"
                        label="Date"
                        labelMode="floating"
                        value={newRecord.scheduleDate}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, scheduleDate: e })
                        }
                        dateOutOfRangeMessage="Invalid Date"
                        displayFormat="dd/MM/yyyy"
                        type="date"
                        useMaskBehavior={true}
                        min={minDate}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Date is required" />
                        </Validator>
                      </DateBox>
                    </div>
                    <div className="col">
                      <DateBox
                        label="Time"
                        ref={scheduleTimeRef}
                        id="InteractionTime_DropDown"
                        labelMode="floating"
                        type="time"
                        dateOutOfRangeMessage="Invalid Date"
                        dropDownOptions={{
                          height: 300,
                        
                        }}
                        value={newRecord.scheduleTime}
                        onValueChange={(e) => {
                          if (isNaN(Date.parse(e))) {
                            scheduleTimeRef.current.instance.reset(); // Reset invalid value
                            return;
                          }
                          setNewRecord({ ...newRecord, scheduleTime: e });
                        }}
                        useMaskBehavior={true}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Time is required" />
                        </Validator>
                      </DateBox>
                    </div>
                  </div>
                  <div className="px-3 pt-3">
                    <TextBox
                      label="Meeting Link"
                      labelMode="floating"
                      value={newRecord.meetingLink}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, meetingLink: e })
                      }
                    >
                      <Validator
                        validationGroup={ValidationGroupName}
                      >
                        <PatternRule pattern={websiteUrlRegex} message="Meeting Link is invalid" />
                      </Validator>
                    </TextBox>
                  </div>
                </>
              )}
            </div>

            <PopupFooter ValidationGroupName={ValidationGroupName} />
        </form>
        </ScrollView>
      </Popup>
      {showEmailStatusPopup && <EmailStatusPopup showEmailStatusPopup={showEmailStatusPopup} HideEmailStatusPopup={HideEmailStatusPopup} candidateData={candidateData} candidateScheduleID={candidateScheduleID} />}
      {
        showFilePreview && (
          <FilePreviewer
            showFilePreview={showFilePreview}
            selectedFile={selectedFile}
            setShowFilePreviewLoad={setShowFilePreviewLoad}
            onFilePreviewClose={onFilePreviewClose}
            isImageOnly={true}
          />
        )
      }
      {showInteractionRoundPopup &&
        <JobInteractionRoundPopup
          openPopup={showInteractionRoundPopup}
          setOpenPopup={setShowInteractionRoundPoup}
          jobName={props.jobProfileName}
          primaryKey={props?.jobOpeningID}
          SetNewRoundNames={SetNewRoundNames}
          isJobOpeningPanel={true}
        />
      }
    </>
  );
};

export default InteractionPopup;

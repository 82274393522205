import {
  Button,
  FileUploader,
  LoadPanel,
  NumberBox,
  Popup,
  TabPanel,
  TextBox,
  Validator,
  Tooltip,
} from "devextreme-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import "./UploadNewResume.scss";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import JobOpeningDropDown from "../job-opening/JobOpeningDropdown";
import { AsyncRule, EmailRule, RequiredRule } from "devextreme-react/validator";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import { useScreenSize } from "../../utils/media-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ShowAlert, eCRUDStatus, getFormattedTodayDate, onKeyDown } from "../../utils/common-methods";
import { StringLengthRule } from "devextreme-react/data-grid";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import ResumeUploader from "../../components/resume-uploader/ResumeUploader";
import { custom } from "devextreme/ui/dialog";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import { JobApplicationServices } from "../../api/services/JobApplicationServices";
import { CurrentUserControlServices } from "../../api/services/CurrentUserControlServices";
import CandidateEditorPopup from '../candidate/CandidateEditorPopup';

const candidateControlServices = new CandidateControlServices();
const jobApplicationServices = new JobApplicationServices();
const currentUserControlServices = new CurrentUserControlServices();

const maxResumeSizeLength = 5 * 1024 * 1024;
const ValidationGroupName = "UploadResumeValidationGroup";

const UploadNewResume = (props) => {

  const UploadResumeRef = useRef();
  const { isExSmall, isXXSmall, isXSmall, isSmall } = useScreenSize();
  const [newRecord, setNewRecord] = useState({});
  const [showUploadNewResume, setShowUploadNewResume] = useState(false);
  const [dropDownJobProfileClick, setDropDownJobProfileClick] = useState(false);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [dropDownJobOpeningClick, setDropDownJobOpeningClick] = useState(false);
  const [addedJobOpeningID, setAddedJobOpeningID] = useState(0);
  const [resume, setResume] = useState({
    uploadedFile: { fileData: null },
    attachedFile: null,
    isUpdated: false,
  });
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [isResumeParsing, setIsResumeParsing] = useState(false);
  const [openResumeUploader, setOpenResumeUploader] = useState(false);
  const [jobOpeningDuplicateDs, setJobOpeningDuplicateDs] = useState(null);
  const [hrManagerId, setHrManagerId] = useState(null);
  const [showCandidateEditorPopup, setShowCandidateEditorPopup] = useState(false);
  const [isDuplicateEmailFound, setIsDuplicateEmailFound] = useState(false);
  const [showDuplicateEmailTootip, setShowDuplicateEmailTootip] = useState(false);
  const [duplicateEmailCandidateDetail, setDuplicateEmailCandidateDetail] = useState(null);
  const [editEmailStatus, setEditEmailStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [editMobileStatus, setEditMobileStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [isDuplicateMobileFound, setIsDuplicateMobileFound] = useState(false);
  const [showDuplicateMobileTootip, setShowDuplicateMobileTootip] = useState(false);
  const [duplicateMobileCandidateDetail, setDuplicateMobileCandidateDetail] = useState(null);
  const [popupOpenType, showPopupOpenType] = useState(null);

  const currentLocation = useLocation();
  const FocusedFeild = useRef(null);

  const NavigateTo = useNavigate();

  useEffect(() => {
    setOpenResumeUploader(true);
  }, []);

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedJobOpeningID !== 0) {
      setNewRecord({ ...newRecord, jobOpeningID: addedJobOpeningID });
      setAddedJobOpeningID(0);
    }
  }, [addedJobOpeningID]);

  useEffect(() => {
    GetCurrentUser();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dropDownJobProfileClick === false && dropDownJobOpeningClick === false) {
      InsertRecord();
    }
  };

  const GetCurrentUser = async () => {
    const result = await currentUserControlServices.GetModelAPI_WithoutId();
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else {
      let userId = result.data.userID;
      setHrManagerId(userId);
    }
  };

  const createJobApplication = async (result) => {
    let dataForApplication = {
      jobApplicationDate: getFormattedTodayDate(),
      jobOpeningID: parseInt(newRecord?.jobOpeningID),
      candidateID: parseFloat(result.data.responseData),
      hrManagerID: parseInt(hrManagerId),
    };
    try {
      const response = await jobApplicationServices.InsertData(
        dataForApplication
      );

      if (!response.isOk) {
        if (response.statusCode === 400) {
          ShowAlert(response.data, "Vakency");
        }
      } else {
        onClosePopup();
        setShowSkeleton(false);
        setNewRecord({});
      }
    } catch (error) {
      console.error(error);
    }

  };

  const InsertRecord = async () => {
    setShowSkeleton(true);
    if (newRecord?.jobOpeningID && !newRecord.jobProfileID) {
      const selectedOpeningData = jobOpeningDuplicateDs.find(item => item.jobOpeningID == newRecord?.jobOpeningID);
      if (selectedOpeningData) newRecord.jobProfileID = selectedOpeningData.jobProfileID;
    }
    let dataToInsert = {
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1 || null,
      mobileNo2: null,
      emailID: newRecord?.emailID || null,
      linkedIn: null,
      skype: null,
      currentAddress: null,
      currentStateID: null,
      currentCityID: null,
      currentPIN: null,
      permanentAddress: null,
      permanentCityID: null,
      permanentStateID: null,
      permanentPIN: null,
      dob: null,
      maritalStatusID: null,
      dependentFamilyMember: 0,
      academicQualificationID: null,
      academicQualificationGrade: null,
      academicQualificationYear: null,
      primaryEducationLanguage: null,
      majorTechnicalSkills: null,
      minorTechnicalSkills: null,
      otherTechnicalSkills: null,
      attitude: null,
      candidateSourceID: null,
      currentCompanyName: null,
      currentCTC: null,
      expectedCTC: null,
      noticePeriod: null,
      readyToRelocate: newRecord?.readyToRelocate || false,
      totalExperience: null,
      relevantExperience: null,
      descrPlainText: null,
      descrFormattedText: null,
    };
    const result = await candidateControlServices.InsertData(dataToInsert);

    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      UpdateResume(result.data.responseData);
      if (newRecord?.jobOpeningID) {
        createJobApplication(result);
      } else {
        onClosePopup();
        setShowSkeleton(false);
        setNewRecord({});
      }
    }
  };

  const UpdateResume = async (candidateID) => {
    if (resume.attachedFile) {
      var Datatoupload = { attachment: resume.attachedFile, candidateID: candidateID };
      await candidateControlServices.UpdateCandidateDocData(
        Datatoupload,
        candidateID
      );
    }
  };

  const onClickClearResume = (e) => {
    UploadResumeRef.current?.instance.reset();
    setResume({
      uploadedFile: { fileData: null },
      attachedFile: null,
      isUpdated: false,
    });
  };

  const onClosePopup = () => {
    props.setOpenPopup && props.setOpenPopup(false)
    setShowUploadNewResume(false);
    setResume({
      uploadedFile: { fileData: null },
      attachedFile: null,
      isUpdated: false,
    });
    setNewRecord({});
  };

  const onUploadResumeValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxResumeSizeLength) {
      ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
        isUpdated: false,
      });
    } else if (
      file &&
      file["type"]?.split("/")[1] !== "pdf"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
        isUpdated: false,
      });
    } else {
      if (file) {
        const resumeData = {
          ...resume,
          uploadedFile: { fileData: file },
          attachedFile: file,
          isUpdated: true,
        }
        setResume(resumeData);
        let myDialog = custom({
          title: "Vakency",
          messageHtml: "<div class='font-semiBold-16'>Do you want to auto-fill details from the uploaded resume?</div>",
          buttons: [
            {
              text: "Yes",
              onClick: (e) => {
                return { buttonText: e.component.option("text") }
              },
              elementAttr: {
                class: 'btnReload'
              },
              type: 'default',
              focusStateEnabled: false,
            },
            {
              text: "No",
              onClick: (e) => {
                return { buttonText: e.component.option("text") }
              },
              elementAttr: {
                class: 'btnCancel'
              },
              type: 'danger',
              focusStateEnabled: false,
            }
          ]
        });
        myDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText == 'Yes') {
            handleResumeParsing(resumeData, true);
          }
        });
      }
    }
  };

  const PopupTitle = () => {
    return (
      <>
        <PopupHeader
          ValidationGroupName={ValidationGroupName}
          onClosePopup={onClosePopup}
          title={"Upload New Resume"}
          onSubmit={InsertRecord}
          hideSaveButton={isResumeParsing}
          hideCloseButton={isResumeParsing}
        />
      </>
    );
  };

  const onHiding = () => {
    if (currentLocation.pathname === "/UploadNewResume") {
      NavigateTo("/");
    }
  };

  const asyncEmailIDValidation = useCallback(async (e) => {
    try {
      const EmailID = e.value;
      const ExcludeID = 0;
      const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateEmailData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncEmailIDValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }, [editEmailStatus]);

  const asyncMobileNoValidation = useCallback(async (e) => {
    try {
      const mobileNo = e.value;
      const ExcludeID = 0;
      const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateMobileNoData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncMobileNoValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }, [editMobileStatus]);

  const onValidateEmailField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      getProfileByEmailID(e.value);
    } else {
      clearEmailDuplicationCheckData();
    }
  }, [setDuplicateEmailCandidateDetail, setIsDuplicateEmailFound, setShowDuplicateEmailTootip]);

  const onValidateMobileField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      GetProfileByMobileNo(e.value);
    } else {
      clearMobileDuplicationCheckData();
    }
  }, [setDuplicateMobileCandidateDetail, setIsDuplicateMobileFound, setShowDuplicateMobileTootip]);

  const onHidingResumeUploder = (iscrossclick = false) => {
    setOpenResumeUploader(false);
    if(iscrossclick){
      setShowUploadNewResume(false);
      props.setOpenPopup && props.setOpenPopup(false)
      if (currentLocation.pathname === "/UploadNewResume") {
        NavigateTo("/");
      }
    } else{
      setShowUploadNewResume(true);
    }
  }

  const handleResumeParsing = async (resume, allowParsing) => {
    if (!resume.uploadedFile.fileData) {
      ShowAlert(`Resume is required`, "Vakency");
      return;
    }
    setShowUploadNewResume(true);
    setOpenResumeUploader(false);
    if (allowParsing) {
      const params = {
        attachment: resume.attachedFile
      };
      setIsResumeParsing(true);
      try {
        const result = await candidateControlServices.ReadCandidateResume(params);
        if (!result.isOk) {
          if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency");
          } else if (result.statusCode === 409) {
            ShowAlert(result.data, "Vakency");
          } else {
            ShowAlert('Unable to parse uploaded resume, please try again', "Vakency");
          }
        } else {
          const response = result.data;
          setNewRecord({
            ...newRecord,
            firstName: response?.FirstName || null,
            lastName: response?.LastName || null,
            emailID: response?.EmailID || null,
            mobileNo1: response?.MobileNo1 ? response?.MobileNo1?.replace(/\D/g, "").slice(-10) : null,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsResumeParsing(false);
      }
    }
  }

  const getProfileByEmailID = async (emailID) => {
    const result = await candidateControlServices.GetProfileByEmailID(emailID);
    if (!result.isOk) {

    } else {
      setDuplicateEmailCandidateDetail(result.data);
      setIsDuplicateEmailFound(true);
      setShowDuplicateEmailTootip(true);
    }
  }

  const GetProfileByMobileNo = async (mobileNo) => {
    const result = await candidateControlServices.GetProfileByMobileNo(mobileNo);
    if (!result.isOk) {

    } else {
      setDuplicateMobileCandidateDetail(result.data);
      setIsDuplicateMobileFound(true);
      setShowDuplicateMobileTootip(true);
    }
  }

  const clearEmailDuplicationCheckData = () => {
    setDuplicateEmailCandidateDetail(null);
    setIsDuplicateEmailFound(false);
    setShowDuplicateEmailTootip(false);
  }

  const clearMobileDuplicationCheckData = () => {
    setDuplicateMobileCandidateDetail(null);
    setIsDuplicateMobileFound(false);
    setShowDuplicateMobileTootip(false);
  }

  const onHidingEmailTooltip = () => {
    setShowDuplicateEmailTootip(false);
  }

  const onHidingMobileTooltip = () => {
    setShowDuplicateMobileTootip(false);
  }

  const editCandidateEmailID = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateEmailTootip(false);
    showPopupOpenType('EDIT_EMAIL');
  }, [setShowCandidateEditorPopup, setShowDuplicateEmailTootip, showPopupOpenType]);

  const editCandidateMobileNo = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateMobileTootip(false);
    showPopupOpenType('EDIT_MOBILE');
  }, [setShowCandidateEditorPopup, setShowDuplicateMobileTootip, showPopupOpenType]);

  const emailDuplicateButton = {
    elementAttr: {
      id: "duplicate_emailID"
    },
    visible: isDuplicateEmailFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateEmailTootip(!showDuplicateEmailTootip);
    }
  };

  const mobileDuplicateButton = {
    elementAttr: {
      id: "duplicate_mobileNo"
    },
    visible: isDuplicateMobileFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateMobileTootip(!showDuplicateMobileTootip);
    }
  };

  return (
    <>
      <Popup
        visible={showUploadNewResume}
        onHiding={onHiding}
        height={"auto"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        deferRendering={false}
        maxHeight={"90vh"}
        width={!isExSmall && !isXSmall && !isXXSmall ? 800 : "94%"}
        onShown={() => FocusedFeild.current?.instance.focus()}
        animation={{
          show: {
            type: "slideIn",
            direction: "top",
          },
          hide: {
            type: "slideOut",
            direction: "top",
            duration: 200,
          },
        }}
      >
        <LoadPanel
          visible={isResumeParsing}
          message="Parsing resume, please wait..."
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          wrapperAttr={{
            class: 'custom_loadpanel'
          }}
        />
        <form onSubmit={handleSubmit}>
          {(showUploadNewResume) && (
            <div className="px-3 py-1">
              {(showSkeleton) ? (
                <div>
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                  <PopupSkeleton />
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md">
                      <TextBox
                        label="First Name"
                        labelMode={"floating"}
                        ref={FocusedFeild}
                        value={newRecord?.firstName}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, firstName: e })
                        }
                        maxLength={50}
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="First Name is Required" />
                        </Validator>
                      </TextBox>
                    </div>
                    <div className=" col-md  pt-md-0 pt-3">
                      <TextBox
                        label="Last Name"
                        labelMode={"floating"}
                        maxLength={50}
                        value={newRecord?.lastName}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, lastName: e })
                        }
                      >
                        <Validator
                          validationGroup={ValidationGroupName}
                          elementAttr={{ class: "ForValidationAstrikMark" }}
                        >
                          <RequiredRule message="Last Name is Required" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>
                  <div className="pt-3">
                    <TextBox
                      label="Email ID"
                      labelMode={"floating"}
                      maxLength={50}
                      inputAttr={{
                        autocomplete: "new",
                      }}
                      value={newRecord?.emailID}
                      onValueChange={(e) => {
                        setNewRecord({ ...newRecord, emailID: e })
                      }}
                    >
                      <TextBoxButton
                        location="after"
                        name="duplicate_emailID"
                        id="duplicate_emailID"
                        options={emailDuplicateButton}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                        onValidated={onValidateEmailField}
                      >
                        <RequiredRule message="Email ID is required" />
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate email ID"
                          validationCallback={asyncEmailIDValidation}
                        />
                        <EmailRule message="Email ID is not valid" />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      position={"top"}
                      target={`#duplicate_emailID`}
                      className="custom-tooltip"
                      hideOnOutsideClick={true}
                      visible={showDuplicateEmailTootip}
                      onHiding={onHidingEmailTooltip}
                      hideOnParentScroll={true}
                    >
                      <div className="p-2 text-wrap">
                        <div className="font-semiBold-16">
                          {duplicateEmailCandidateDetail?.candidateName}
                          <span>
                            <Button
                              icon="edit"
                              stylingMode="text"
                              hint="Edit Candidate"
                              type="default"
                              className="ps-1 resume-edit-btn"
                              onClick={editCandidateEmailID}
                              focusStateEnabled={false}
                              hoverStateEnabled={false}
                              activeStateEnabled={false}
                            />
                          </span>
                        </div>
                        <div className="font-semiBold-12 text-gray">
                          {duplicateEmailCandidateDetail?.jobProfileName}
                        </div>
                        <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                          <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                          This email ID is already assigned to the above candidate.
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="py-2">
                    <NumberBox
                      label="Mobile No."
                      labelMode={"floating"}
                      value={newRecord?.mobileNo1 && newRecord?.mobileNo1}
                      maxLength={15}
                      format="#"
                      onKeyDown={onKeyDown}
                      inputAttr={{
                        maxLength: 15
                      }}
                      step={0}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, mobileNo1: e })
                      }
                    >
                      <NumberBoxButton
                        location="after"
                        name="duplicate_mobileNo"
                        id="duplicate_mobileNo"
                        options={mobileDuplicateButton}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                        onValidated={onValidateMobileField}
                      >
                        <RequiredRule message="Mobile No. is required" />
                        <StringLengthRule
                          message="Mobile No. must have 10 digits"
                          min={10}
                          ignoreEmptyValue={true}
                        />
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate Mobile No."
                          validationCallback={asyncMobileNoValidation}
                        />
                      </Validator>
                    </NumberBox>
                    <Tooltip
                      position={"top"}
                      target={`#duplicate_mobileNo`}
                      className="custom-tooltip"
                      hideOnOutsideClick={true}
                      visible={showDuplicateMobileTootip}
                      onHiding={onHidingMobileTooltip}
                      hideOnParentScroll={true}
                    >
                      <div className="p-2 text-wrap">
                        <div className="font-semiBold-16">
                          {duplicateMobileCandidateDetail?.candidateName}
                          <span>
                            <Button
                              icon="edit"
                              stylingMode="text"
                              hint="Edit Candidate"
                              type="default"
                              className="ps-1 resume-edit-btn"
                              onClick={editCandidateMobileNo}
                              focusStateEnabled={false}
                              hoverStateEnabled={false}
                              activeStateEnabled={false}
                            />
                          </span>
                        </div>
                        <div className="font-semiBold-12 text-gray">
                          {duplicateMobileCandidateDetail?.jobProfileName}
                        </div>
                        <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                          <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                          This mobile no is already assigned to the above candidate.
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div className="row d-flex my-3">
                    <div className="col-md-auto editor-label pt-3 mt-2 pe-3">
                      Resume
                    </div>
                    <div className="col-md-9 col-lg-8 col resumeUpload mx-2  mx-md-0 d-flex border rounded align-items-center">
                      <FileUploader
                        ref={UploadResumeRef}
                        name="candidate resume"
                        multiple={false}
                        accept="application/pdf"
                        uploadMode={"useForm"}
                        selectButtonText="Browse"
                        allowCanceling={false}
                        labelText=""
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        uploadedMessage="Resume Uploaded"
                        readyToUploadMessage=""
                        className=""
                        inputAttr={{ 'aria-label': 'Browse' }}
                        onValueChange={onUploadResumeValueChanged}
                        value={resume?.uploadedFile?.fileData ? [resume?.uploadedFile?.fileData] : []}
                      />
                      {resume?.uploadedFile?.fileData && (
                        <>
                          <Button
                            icon="close"
                            id="Close"
                            onClick={onClickClearResume}
                            stylingMode="text"
                            type="default"
                            hint="Close"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="add-details-section">
                    <hr className="py-0 mt-2 mb-3 " />
                    <div className="font-semiBold-16 d-flex ">
                      {" "}
                      <span className="pe-3">Add Details</span>
                    </div>
                    <div className="pt-2">
                      <JobProfileDropDown
                        validationRequired={true}
                        EditorData={newRecord?.jobOpeningID}
                        customValidate={true}
                        dropdownHeight={135}
                        setAddedJobProfileID={setAddedJobProfileID}
                        setDropDownClick={setDropDownJobProfileClick}
                        validationGroup={ValidationGroupName}
                        value={newRecord?.jobProfileID}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, jobProfileID: e })
                        }
                        ValidationMessagePosition={"top"}
                        validationMessage={"Job Profile or Job Opening is required"}
                      />
                    </div>
                    <div className="text-center py-1 mt-1 or-text">OR</div>
                    <div className="">
                      <JobOpeningDropDown
                        EditorData={newRecord?.jobProfileID}
                        customValidate={true}
                        validationRequired={true}
                        validationGroup={ValidationGroupName}
                        dropdownHeight={70}
                        value={newRecord?.jobOpeningID}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, jobOpeningID: e })
                        }
                        setAddedJobOpeningID={setAddedJobOpeningID}
                        setDropDownClick={setDropDownJobOpeningClick}
                        setJobOpeningDuplicateDs={setJobOpeningDuplicateDs}
                        validationMessage={"Job Profile or Job Opening is required"}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          <PopupFooter
            ValidationGroupName={ValidationGroupName}
            openPopup={props.openPopup}
            setOpenPopup={props.setOpenPopup}
          />
        </form>
      </Popup >
      {
        showCandidateEditorPopup && (
          <CandidateEditorPopup
            isOpen={showCandidateEditorPopup}
            onClose={() => setShowCandidateEditorPopup(false)}
            candidateData={popupOpenType == "EDIT_EMAIL" ? duplicateEmailCandidateDetail : duplicateMobileCandidateDetail}
            editEmailID={popupOpenType == "EDIT_EMAIL"}
            editMobileNo={popupOpenType == "EDIT_MOBILE"}
            setStatus={popupOpenType == "EDIT_EMAIL" ? setEditEmailStatus : setEditMobileStatus}
          />
        )
      }
      {
        openResumeUploader && (
          <ResumeUploader
            resume={resume}
            setResume={setResume}
            isOpen={openResumeUploader}
            onClose={onHidingResumeUploder}
            handleResumeParsing={handleResumeParsing}
            openFrom={"UploadNewResume"}
          />
        )
      }
    </>
  );
};

export default UploadNewResume;

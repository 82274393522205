import React from "react";
import "./CardSkeleton.scss";

const CardSkeleton = () => {
    return (
        <>
            <div className="card-section p-3">
                <span className="card-skeleton"></span>
                <span className="card-skeleton"></span>
                <span className="card-skeleton"></span>
                <span className="card-skeleton"></span>
                <span className="card-skeleton"></span>
            </div>
        </>
    );
};

export default CardSkeleton;

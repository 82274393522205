import {
    DateBox,
    LoadPanel,
    NumberBox,
    Popup,
    ScrollView,
    TextBox,
    Validator,
} from "devextreme-react";
import React, { useEffect, useRef, useState } from "react";
import {
    RequiredRule,
    StringLengthRule,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import PopupFooter from "../../layouts/popup-header-footer/PopupFooter";
import "./Candidate.scss";
import CandidatePictureUploader from "./CandidatePictureUploader";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import JobOpeningDropDown from "../job-opening/JobOpeningDropdown";
import { AsyncRule, EmailRule, RangeRule } from "devextreme-react/validator";

import {
    ConflictPopup,
    ShowAlert,
    closeDateBoxOnScroll,
    eCRUDStatus,
    minDate,
} from "../../utils/common-methods";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { BlankProfile } from "../../utils/base-64-Icons";

const candidateControlServices = new CandidateControlServices();

const ValidationGroupName = "CandidateEditorPopupValidation";

export default function CandidateEditorPopup({
    isOpen,
    onClose,
    candidateData,
    editEmailID,
    editMobileNo,
    setStatus,
}) {
    const { isExSmall, isXSmall, isXXSmall } = useScreenSize();

    const FocusedEmailField = useRef(null);
    const FocusedMobileField = useRef(null);
    const DateBoxRef = useRef(null);
    const onShown = () => {
        if (editMobileNo) FocusedMobileField.current?.instance.focus()
        if (editEmailID) FocusedEmailField.current?.instance.focus()
    };

    const [newRecord, setNewRecord] = useState({});
    const [profileImg, setProfileImg] = useState({
        uploadedFileUrl: null,
        attachedFile: null,
    });
    const [showSkeleton, setShowSkeleton] = useState(false);

    useEffect(() => {
        if (candidateData.candidateID) {
            setNewRecord(candidateData);
            GetProfileImage().then((res) => {
                setProfileImg({
                    uploadedFile: {
                        fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
                    },
                    attachedFile: res?.size > 0 ? res : null,
                });
            });
        }
    }, [candidateData]);

    const UpdateRecord = async () => {
        setShowSkeleton(true);
        var dataToUpdate = {
            candidateID: newRecord?.candidateID,
            updatedOn: newRecord?.updatedOn,
            mobileNo1: newRecord?.mobileNo1,
            emailID: newRecord?.emailID ? newRecord?.emailID : null,
        };

        try {
            const result = await candidateControlServices.UpdateCandidateMiniProfile(dataToUpdate);
            if (!result.isOk) {
                ShowAlert(result.data, "Vakency");
                setShowSkeleton(false);
            } else {
                setStatus &&
                    setStatus({
                        eStatus: eCRUDStatus.Updated,
                        primaryKeyID: result.data.responseData,
                    });
                onClose();
                setShowSkeleton(false);
                setNewRecord({});
            } 
        } catch (error) {
            console.error(error);
        } finally {
            setShowSkeleton(false);
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateRecord();
    };

    const onClosePopup = () => {
        setNewRecord({});
        onClose();
    };

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClosePopup}
                    title={[
                        <span key={"header-title"} className="base-accent-text">
                            Edit
                        </span>,
                        ` Candidate #${candidateData.candidateID}`,
                    ]}
                    onSubmit={UpdateRecord}
                />
            </>
        );
    };

    const asyncEmailIDValidation = async (e) => {
        try {
            const EmailID = e.value;
            const ExcludeID = candidateData.candidateID ? candidateData.candidateID : 0;
            const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
            const result = await candidateControlServices.CheckDuplicateEmailData(
                CheckDuplicateParams
            );
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncEmailIDValidation:", error);
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    };

    const asyncMobileNoValidation = async (e) => {
        try {
            const mobileNo = e.value;
            const ExcludeID = candidateData.candidateID ? candidateData.candidateID : 0;
            const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
            const result = await candidateControlServices.CheckDuplicateMobileNoData(
                CheckDuplicateParams
            );
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncMobileNoValidation:", error);
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    };

    const GetProfileImage = async () => {
        let params = `?CandidateID=${candidateData.candidateID}`;
        const result = await candidateControlServices.GetImage(params);
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    };

    return (
        <div className="CandidateSource_popup">
            <Popup
                visible={isOpen}
                showTitle={true}
                titleRender={PopupTitle}
                width={isExSmall || isXXSmall || isXSmall ? "90%" : 750}
                height={"auto"}
                wrapperAttr={{ class: "CustomPopup" }}
                deferRendering={false}
                maxHeight={"90vh"}
                onShown={onShown}
            >
                <ScrollView onScroll={(e) => closeDateBoxOnScroll(DateBoxRef, e)} height={"100%"}>
                    <form onSubmit={handleSubmit} id="CandidatePopUp_Dropdown">
                        <div className="px-3 pt-2">
                            {
                                showSkeleton ? (
                                    <div>
                                        <PopupSkeleton />
                                        <PopupSkeleton />
                                        <PopupSkeleton />
                                    </div>
                                ) : (
                                    <>
                                        {(isExSmall || isXXSmall || isXSmall) && (
                                            <>
                                                <div className="candidate-picture-sm">
                                                    <div className="col-md-auto pt-2">
                                                        <img
                                                            src={BlankProfile}
                                                            width={150}
                                                            height={150}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row">
                                            <div className="col-xxl col-xl col-lg col-md col-sm">
                                                <div className="row">
                                                    <div className="col-xxl col-xl col-lg col-md col-sm">
                                                        <TextBox
                                                            label="Candidate Name"
                                                            labelMode="floating"
                                                            readOnly={true}
                                                            value={newRecord.candidateName}
                                                        ></TextBox>
                                                    </div>
                                                    <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                                                        <TextBox
                                                            label="Job Profile"
                                                            labelMode="floating"
                                                            readOnly={true}
                                                            value={newRecord.jobProfileName}
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-xxl col-xl col-lg col-md col-sm">
                                                        <TextBox
                                                            ref={FocusedEmailField}
                                                            label="Email ID"
                                                            maxLength={50}
                                                            labelMode="floating"
                                                            readOnly={editEmailID ? false : true}
                                                            value={newRecord?.emailID}
                                                            onValueChange={(e) =>
                                                                setNewRecord({ ...newRecord, emailID: e })
                                                            }
                                                        >
                                                            <Validator validationGroup={ValidationGroupName} elementAttr={{ class: "ForValidationAstrikMark" }} >
                                                                <RequiredRule message="Email ID is required" />
                                                                <EmailRule message="Email ID is invalid" />
                                                                <AsyncRule
                                                                    reevaluate={false}
                                                                    message="Can't accept duplicate email ID"
                                                                    validationCallback={asyncEmailIDValidation}
                                                                />
                                                            </Validator>
                                                        </TextBox>
                                                    </div>
                                                    <div className="col-xxl col-xl col-lg col-md col-sm pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                                                        <NumberBox
                                                            ref={FocusedMobileField}
                                                            label="Mobile No."
                                                            labelMode="floating"
                                                            value={newRecord?.mobileNo1}
                                                            readOnly={editMobileNo ? false : true}
                                                            maxLength={15}
                                                            format="#"
                                                            inputAttr={{
                                                                maxLength: 15,
                                                            }}
                                                            step={0}
                                                            onValueChange={(e) =>
                                                                setNewRecord({ ...newRecord, mobileNo1: e })
                                                            }
                                                        >
                                                            <Validator
                                                                validationGroup={ValidationGroupName}
                                                                elementAttr={{ class: "ForValidationAstrikMark" }}
                                                            >
                                                                <RequiredRule message="Mobile No. is required" />
                                                                <StringLengthRule
                                                                    message="Mobile No. must have 10 digits"
                                                                    min={10}
                                                                />
                                                                <AsyncRule
                                                                    reevaluate={false}
                                                                    message="Can't accept duplicate Mobile No."
                                                                    validationCallback={asyncMobileNoValidation}
                                                                />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <TextBox
                                                        label="Current Address"
                                                        labelMode="floating"
                                                        readOnly={true}
                                                        value={newRecord.currentAddress}
                                                    ></TextBox>
                                                </div>
                                            </div>
                                            {!isExSmall && !isXXSmall && !isXSmall && (
                                                <div className="w-auto">
                                                    <div className="col-md-auto pt-2">
                                                        <img
                                                            src={profileImg?.uploadedFile?.fileData ? profileImg?.uploadedFile?.fileData : BlankProfile}
                                                            width={150}
                                                            height={150}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <PopupFooter
                            ValidationGroupName={ValidationGroupName}
                        />
                    </form>
                </ScrollView>
            </Popup>
        </div >
    );
}

import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import React from 'react';
import './PopupHeaderFooter.scss';
import { useScreenSize } from '../../utils/media-query';

export default function PopupHeader({ onClosePopup, title, ValidationGroupName, onSubmit, hideSaveButton, isWithoutValidationPopup, hideCloseButton }) {

  const { isXSmall, isXXSmall, isExSmall, } = useScreenSize();

  const SaveForm = (e) => {
    if (!isWithoutValidationPopup) {
      var formValid = e.validationGroup.validate();
      if (formValid.status === 'pending') {
        formValid.complete.then((r) => {
          if (r.isValid) {
            onSubmit && onSubmit();
          }
        });
      } else {
        if (formValid.isValid) {
          onSubmit && onSubmit();
        }
      }
    }
    else {
      onSubmit && onSubmit();
    }
  }

  return (
    <div className='popup_header shadow-sm mb-2 '>
      <div className='row mb-2 mx-1'>

        <Toolbar className='my-1'>
          {title &&
            <Item
              location="before"
            >
              <div className={`${(isXSmall || isXXSmall || isExSmall) ? 'font-semiBold-14' : 'font-semiBold-18'} fw-semibold`}>{title}</div>
            </Item>
          }
          {
            !hideSaveButton &&
            <Item location='before'>
              <span className="vertical-line">|</span>
            </Item>
          }
          {
            !hideSaveButton &&

            <Item location='before'>
              <Button
                icon='save'
                className='rounded-5 save-icon'
                type='default'
                hint='Save'
                stylingMode='outlined'
                onClick={SaveForm}
                validationGroup={ValidationGroupName}
              />
            </Item>
          }
          <Item
            location="after"
          >
            {
              !hideCloseButton && (
                <Button
                  icon='close'
                  type='default'
                  hint='Close'
                  stylingMode='outlined'
                  className='rounded-5 remove-icon'
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  onClick={onClosePopup}
                />
              )
            }
          </Item>
        </Toolbar>
      </div>
    </div>
  )
}

import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { DashboardControlName } from "../../contexts/APIurl";

const url = DashboardControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class HomeServices extends VakencyApiServices {
  constructor() {
    super(url);
  }

  GetHomeList = async () => {
    const GetListUrl = url + "/GetDashboardData"
    let data = null;
    try {
      data = await fetchWrapper.get(baseURL + GetListUrl)
      return this.handleResponse(data);
    }
    catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data.status
      };
    }
  }

  GetSchedules = async (id) => {
    const GetListUrl = url + "/GetUpcomingSchedules/" + id
    let data = null;
    try {
      data = await fetchWrapper.get(baseURL + GetListUrl)
      return this.handleResponse(data);
    }
    catch {
      return {
        isOk: false,
        message: "Failed To Load Data",
        statusCode: data.status
      };
    }
  }
}
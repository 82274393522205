import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Header } from "../../components";
import Editor from "../../layouts/templates/Editor";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  CheckBox,
  DateBox,
  FileUploader,
  LoadPanel,
  NumberBox,
  SelectBox,
  TagBox,
  TextBox,
  Tooltip,
  Validator,
} from "devextreme-react";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import { Button as TextBoxButton } from 'devextreme-react/text-box'
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import {
  RequiredRule,
  StringLengthRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/validator";
import "./Candidate.scss";
import CandidatePictureUploader from "./CandidatePictureUploader";
import JobProfileDropDown from "../application-settings/job-profile/JobProfileDropDown";
import CityDropDown from "../application-settings/city/CityDropDown";
import StateDropDown from "../application-settings/state/StateDropDown";
import { useScreenSize } from "../../utils/media-query";
import {
  ShowAlert,
  eCRUDStatus,
  ConflictPopup,
  onKeyDown,
  onKeyDown_Withpoint,
  onDragOver,
  minDate,
} from "../../utils/common-methods";
import CandidateSourceDropDown from "../application-settings/candidate-source/CandidateSourceDropDown";
import { RangeRule } from "devextreme-react/data-grid";
import AcademicQualificationDropDown from "../application-settings/academic-qualification/AcademicQualificationDropDown";
import PopupSkeleton from "../../layouts/templates/PopupSkeleton";
import { AcademicQualificationServices } from "../../api/services/AcademicQualificationServices";
import { AtitudeFactorServices } from "../../api/services/AtitudeFactorServices";
import { SkillServices } from "../../api/services/SkillServices";
import { CandidateControlServices } from '../../api/services/CandidateControlServices'
import { CityServices } from "../../api/services/CityServices";
import { JobProfileServices } from "../../api/services/JobProfileServices";
import ResumeUploader from "../../components/resume-uploader/ResumeUploader";
import { custom } from "devextreme/ui/dialog";
import { dsGenderStatus, dsMaritalStatus } from "../../utils/contants";
import CandidateEditorPopup from "./CandidateEditorPopup";

const academicQualificationServices = new AcademicQualificationServices();
const atitudeFactorServices = new AtitudeFactorServices();
const skillServices = new SkillServices();
const candidateControlServices = new CandidateControlServices();
const cityServices = new CityServices();
const jobProfileServices = new JobProfileServices();

const htmlEditorSecondAttributes = {
  class: "noteSecondHtml",
};

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const CandidateEditor = () => {
  const ValidationGroupName = "CandidateValidationGroup";

  const NavigateTo = useNavigate();
  const UploadResumeRef = useRef();
  const AttitudeDropDownBoxRef = useRef(null);
  const htmlEditorNoteRef = useRef(null);

  const { isXSmall, isXXSmall, isExSmall } = useScreenSize();

  const { state } = useLocation();

  const { CandidateID, editorOpenFrom } = state || {};

  const FocusedFeild = useRef(null);
  const maxResumeSizeLength = 5 * 1024 * 1024;

  const currentDate = new Date();
  const DOBRangeRule = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 14)
  );

  const [newRecord, setNewRecord] = useState({});
  const [DsAcademicQualification, setDsAcademicQualification] = useState([]);
  const [DsAttitudeFactor, setDsAttitudeFactor] = useState([]);
  const [attitudeFactorValue, setattitudeFactorValue] = useState([]);
  const [MinorSkillsValue, setMinorSkillsValue] = useState([]);
  const [MajorSkillsValue, setMajorSkillsValue] = useState([]);
  const [OtherSkillsValue, setOtherSkillsValue] = useState([]);
  const [CandidateNo, setCandidateNo] = useState();
  const [DsYearDropdownValues, setDsYearDropdownValues] = useState([]);
  const [resume, setResume] = useState({
    uploadedFile: { fileData: null },
    attachedFile: null,
    isUpdated: false,
  });
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [filename, setFilename] = useState(null);
  const [months, setMonths] = useState({
    totalMonth: undefined,
    relevantMonth: undefined,
  });
  const [dropDownCityClick, setDropDownCityClick] = useState(false);
  const [addedCityID, setAddedCityID] = useState(0);
  const [dropDownStateClick, setDropDownStateClick] = useState(false);
  const [addedStateID, setAddedStateID] = useState(0);
  const [dropDownJobProfileClick, setDropDownJobProfileClick] = useState(false);
  const [addedJobProfileID, setAddedJobProfileID] = useState(0);
  const [dropDownCandidateClick, setDropDownCandidateClick] = useState(false);
  const [addedCandidateSourceID, setAddedCandidateSourceID] = useState(0);
  const [dropDownPermanentCityClick, setDropDownPermanentCityClick] =
    useState(false);
  const [addedPermanentCityID, setAddedPermanentCityID] = useState(0);
  const [dropDownPermanentStateClick, setDropDownPermanentStateClick] =
    useState(false);
  const [addedPermanentStateID, setAddedPermanentStateID] = useState(0);
  const [
    dropDownAcademicQualificationClick,
    setDropDownAcademicQualificationClick,
  ] = useState(false);
  const [addedAcademicQualificationID, setAddedAcademicQualificationID] =
    useState(0);
  const [currentZipCode, setCurrentZipCode] = useState(null);
  const [permanentZipCode, setPermanentZipCode] = useState(null);
  const [dsSkill, setDsSkill] = useState([]);
  const [isFetchingSkills, setIsFetchingSkills] = useState(false);
  const [dropDownUserProfileClick, setDropDownUserProfileClick] =
    useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [currentCTCMonthlyValue, setCurrentCTCMonthlyValue] = useState();
  const [expectedCTCMonthlyValue, setExpectedCTCMonthlyValue] = useState();
  const [newAddedStateID, setNewAddedStateID] = useState(0);
  const [isResumeParsing, setIsResumeParsing] = useState(false);
  const [openResumeUploader, setOpenResumeUploader] = useState(false);
  const [showCandidateEditorPopup, setShowCandidateEditorPopup] = useState(false);
  const [isDuplicateEmailFound, setIsDuplicateEmailFound] = useState(false);
  const [showDuplicateEmailTootip, setShowDuplicateEmailTootip] = useState(false);
  const [duplicateEmailCandidateDetail, setDuplicateEmailCandidateDetail] = useState(null);
  const [editEmailStatus, setEditEmailStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [editMobileStatus, setEditMobileStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: null,
  });
  const [isDuplicateMobileFound, setIsDuplicateMobileFound] = useState(false);
  const [showDuplicateMobileTootip, setShowDuplicateMobileTootip] = useState(false);
  const [duplicateMobileCandidateDetail, setDuplicateMobileCandidateDetail] = useState(null);
  const [popupOpenType, showPopupOpenType] = useState(null);

  useEffect(() => {
    if (addedPermanentCityID !== 0) {
      setNewRecord({ ...newRecord, permanentCityID: addedPermanentCityID });
      setAddedPermanentCityID(0);
    }
  }, [addedPermanentCityID]);

  useEffect(() => {
    if (addedPermanentStateID !== 0) {
      setNewRecord({ ...newRecord, permanentStateID: addedPermanentStateID });
      setAddedPermanentStateID(0);
    }
  }, [addedPermanentStateID]);

  useEffect(() => {
    if (addedAcademicQualificationID !== 0) {
      setNewRecord({
        ...newRecord,
        academicQualificationID: addedAcademicQualificationID,
      });
      setAddedAcademicQualificationID(0);
    }
  }, [addedAcademicQualificationID]);

  useEffect(() => {
    if (addedCityID !== 0) {
      setNewRecord({ ...newRecord, currentCityID: addedCityID });
      setAddedCityID(0);
    }
  }, [addedCityID]);

  useEffect(() => {
    if (addedStateID !== 0) {
      setNewRecord({ ...newRecord, currentStateID: addedStateID });
      setAddedCityID(0);
    }
  }, [addedStateID]);

  useEffect(() => {
    if (addedJobProfileID !== 0) {
      setNewRecord({ ...newRecord, jobProfileID: addedJobProfileID });
      GetSkills(addedJobProfileID);
      setAddedJobProfileID(0);
    }
  }, [addedJobProfileID]);

  useEffect(() => {
    if (addedCandidateSourceID !== 0) {
      setNewRecord({ ...newRecord, candidateSourceID: addedCandidateSourceID });
      setAddedCandidateSourceID(0);
    }
  }, [addedCandidateSourceID]);

  useEffect(() => {
    GetAcademicQualificationLookupList();
    GetSkillsLookupList();
    GetAttitudeFactorLookupList();
    getYearOptions();
  }, []);

  useEffect(() => {
    !showSkeleton && FocusedFeild.current?.instance.focus();
  }, [showSkeleton]);

  useEffect(() => {
    FocusedFeild.current?.instance.focus();
    if (
      CandidateID !== null &&
      CandidateID !== 0 &&
      CandidateID !== undefined
    ) {
      GetModelRecord();
    } else {
      setNewRecord({});
      GenerateCandidateNo();
      setShowSkeleton(false);
      setOpenResumeUploader(true);
    }
  }, [CandidateID]);

  useEffect(() => {
    if (newRecord.currentCityID && newRecord.currentCityID !== currentZipCode) {
      GetCurrentZip(newRecord.currentCityID);
      setCurrentZipCode(undefined);
    } else if (newRecord.currentCityID === currentZipCode) {
      setNewRecord({ ...newRecord });
    } else if (!newRecord.currentCityID) {
      setNewRecord({ ...newRecord, currentPIN: undefined });
    }
  }, [newRecord.currentCityID]);

  useEffect(() => {
    if (
      newRecord.permanentCityID &&
      newRecord.permanentCityID !== permanentZipCode
    ) {
      GetPermanentZip(newRecord.permanentCityID);
      setPermanentZipCode(undefined);
    } else if (newRecord.permanentCityID === permanentZipCode) {
      setNewRecord({ ...newRecord });
    } else if (!newRecord.permanentCityID) {
      setNewRecord({ ...newRecord, permanentPIN: undefined });
    }
  }, [newRecord.permanentCityID]);

  const GetSkills = async (id) => {
    setIsFetchingSkills(true);
    // const result = await GetSkills_JobProfilePage(JobProfileControlName, id);
    const result = await jobProfileServices.GetSkills_JobProfilePage(id);
    if (result.isOk) {
      const MajorfilteredSkills = result.data.majorSkillIds;
      const matchedMajorSkills = dsSkill.filter((skill) =>
        MajorfilteredSkills.includes(skill.skillID)
      );
      const matchedMajorSkillNames = matchedMajorSkills.map(
        (skill) => skill.skillName
      );
      setMajorSkillsValue(matchedMajorSkillNames);
      const MinorfilteredSkills = result.data.minorSkillIds;
      const matchedMinorSkills = dsSkill.filter((skill) =>
        MinorfilteredSkills.includes(skill.skillID)
      );
      const matchedMinorSkillNames = matchedMinorSkills.map(
        (skill) => skill.skillName
      );
      setMinorSkillsValue(matchedMinorSkillNames);
    }
    setIsFetchingSkills(false);
  };

  const GetCurrentZip = async (CurrentCityID) => {
    const result = await cityServices.GetZipCode(CurrentCityID);
    let response = result.data;
    setNewRecord({
      ...newRecord,
      currentPIN: response ? parseInt(response) : undefined,
    });
  };

  const GetPermanentZip = async (permanentCityID) => {
    const result = await cityServices.GetZipCode(permanentCityID);
    let response = result.data;
    setNewRecord({
      ...newRecord,
      permanentPIN: response ? parseInt(response) : undefined,
    });
  };

  const GetModelRecord = async () => {
    const result = await candidateControlServices.GetModelData(CandidateID);
    const response = result.data;
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency");
      setShowSkeleton(false);
    } else {
      GetProfileImage().then((res) => {
        setProfileImg({
          ...result.data,
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
      GetResume();
      GetTagBoxValues(response);
      setNewRecord({
        ...response,
        dob: response.dob ? new Date(response.dob) : undefined,
      });
      // setNewRecord({
      //   ...response,
      //   dob: response.dob ? new Date(new Date(response.dob + "Z").toUTCString()).toLocaleDateString() : undefined,
      // });
      if (
        response.totalExperience !== null ||
        !response.relevantExperience !== null
      ) {
        let splitExperienceString = response.totalExperience?.toFixed(2)?.toString() || "0";
        let splitExperience = splitExperienceString.split(".");
        let totalMonths = parseInt(splitExperience[1] && splitExperience[1]);
        let totalYears = parseInt(splitExperience[0] && splitExperience[0]);
        let splitReleventExperienceString =
          response.relevantExperience?.toFixed(2)?.toString() || "0";
        let splitReleventExperience = splitReleventExperienceString.split(".");
        let relevantMonths = parseInt(
          splitReleventExperience[1] ? splitReleventExperience[1] : 0
        );
        let relevantYears = parseInt(
          splitReleventExperience[0] ? splitReleventExperience[0] : 0
        );
        setNewRecord({
          ...response,
          totalExperience: totalYears,
          relevantExperience: relevantYears,
        });
        setMonths({ totalMonth: totalMonths, relevantMonth: relevantMonths });
      }
      if (response.currentCTC != null) {
        setCurrentCTCMonthlyValue((response.currentCTC * 100000) / 12);
      }
      if (response.expectedCTC != null) {
        setExpectedCTCMonthlyValue((response.expectedCTC * 100000) / 12);
      }
      setCurrentZipCode(result.data.currentCityID);
      htmlEditorNoteRef.current?.instance.option(
        "value",
        response.descrFormattedText
      );
      setShowSkeleton(false);
    }
  };

  const GetResume = async () => {
    let params = `?CandidateID=${CandidateID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    if (result.isOk) {
      setFilename("Resume.pdf");
      setResume({
        ...resume,
        uploadedFile: { fileData: new File([result.data], 'Resume.pdf', { type: 'application/pdf' }) },
        attachedFile: new File([result.data], 'Resume.pdf', { type: 'application/pdf' }),
      });
    }
  };

  const GetProfileImage = async () => {
    let params = `?CandidateID=${CandidateID}`;
    const result = await candidateControlServices.GetImage(params);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GenerateCandidateNo = async () => {
    const result = await candidateControlServices.GenerateCandidateNoData();
    if (result.isOk) {
      setCandidateNo(result.data);
    }
  };

  const getYearOptions = useCallback(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1975; year--) {
      years.push(year);
    }
    setDsYearDropdownValues(years);
  }, []);

  const OnCancelClick = () => {
    NavigateTo(editorOpenFrom, {
      state: {
        ...state,
        popupStatus: {
          eStatus: eCRUDStatus.None,
          primaryKeyID: CandidateID,
        },
      },
    });
  };

  const handlePermanentStateValueChange = (e) => {
    setNewRecord({ ...newRecord, permanentStateID: e, permanentCityID: null });
  };

  const handleCurrentStateValueChange = (e) => {
    setNewRecord({ ...newRecord, currentStateID: e, currentCityID: null });
  };

  const GetTagBoxValues = (data) => {
    if (data.attitude !== "") {
      let attitudeValue = data.attitude?.split(",");
      setattitudeFactorValue(attitudeValue);
    }
    if (data.minorTechnicalSkills !== "") {
      let MinorSkillsValue = data.minorTechnicalSkills?.split(",");
      setMinorSkillsValue(MinorSkillsValue);
    }
    if (data.majorTechnicalSkills !== "") {
      let MajorSkillsValue = data.majorTechnicalSkills?.split(",");

      setMajorSkillsValue(MajorSkillsValue);
    }
    if (data.otherTechnicalSkills !== "") {
      let otherSkillsValue = data.otherTechnicalSkills?.split(",");
      setOtherSkillsValue(otherSkillsValue);
    }
  };

  const GetAcademicQualificationLookupList = async () => {
    const result = await academicQualificationServices.GetLookupListData();
    if (result.isOk) {
      setDsAcademicQualification(result.data);
    }
  };

  const GetAttitudeFactorLookupList = async () => {
    const result = await atitudeFactorServices.GetLookupListData();
    if (result.isOk) {
      setDsAttitudeFactor(result.data);
    }
  };

  const GetSkillsLookupList = async () => {
    const result = await skillServices.GetLookupListData();
    if (result.isOk) {
      setDsSkill(result.data);
    }
  };

  const onUploadResumeValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxResumeSizeLength) {
      ShowAlert(`Resume of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
      });
    } else if (
      file &&
      file["type"]?.split("/")[1] !== "pdf"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadResumeRef?.current?.instance.reset();
      setResume({
        uploadedFile: { fileData: null },
        attachedFile: null,
      });
    } else {
      if (file) {
        setFilename("");
        const resumeData = {
          ...resume,
          uploadedFile: { fileData: file },
          attachedFile: file,
          isUpdated: true,
        }
        setResume(resumeData);
        if (!CandidateID) {
          let myDialog = custom({
            title: "Vakency",
            messageHtml: "<div class='font-semiBold-16'>Do you want to auto-fill details from the uploaded resume?</div>",
            buttons: [
              {
                text: "Yes",
                onClick: (e) => {
                  return { buttonText: e.component.option("text") }
                },
                elementAttr: {
                  class: 'btnReload'
                },
                type: 'default',
                focusStateEnabled: false,
              },
              {
                text: "No",
                onClick: (e) => {
                  return { buttonText: e.component.option("text") }
                },
                elementAttr: {
                  class: 'btnCancel'
                },
                type: 'danger',
                focusStateEnabled: false,
              }
            ]
          });
          myDialog.show().then((dialogResult) => {
            if (dialogResult.buttonText == 'Yes') {
              handleResumeParsing(resumeData, true);
            }
          });
        }
      }
    }
  };

  const onJobProfileValueChange = (e) => {
    setNewRecord({ ...newRecord, jobProfileID: e });
    GetSkills(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      dropDownCityClick === false &&
      dropDownStateClick === false &&
      dropDownJobProfileClick === false &&
      dropDownCandidateClick === false &&
      dropDownPermanentCityClick === false &&
      dropDownPermanentStateClick === false &&
      dropDownAcademicQualificationClick === false &&
      dropDownUserProfileClick === false
    ) {
      if (
        CandidateID !== null &&
        CandidateID !== 0 &&
        CandidateID !== undefined
      ) {
        UpdateRecord();
      } else {
        InsertRecord();
      }
    }
  };


  const InsertRecord = async () => {
    setShowSkeleton(true);
    let tempDivElement = htmlEditorNoteRef.current.instance.getQuillInstance();
    const descrPlainText = tempDivElement.getText();
    var date = newRecord?.dob && new Date(newRecord?.dob)
    var formattedDate = date ? date.toDateString() : undefined;
    let dataToInsert = {
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: newRecord?.mobileNo2,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: newRecord?.skype,
      currentAddress: newRecord?.currentAddress,
      currentStateID: newRecord?.currentStateID,
      currentCityID: newRecord?.currentCityID,
      currentPIN: newRecord?.currentPIN,
      permanentAddress: newRecord?.permanentAddress,
      permanentCityID: newRecord?.permanentCityID,
      permanentStateID: newRecord?.permanentStateID,
      permanentPIN: newRecord?.permanentPIN,
      dob: formattedDate,
      maritalStatusID: newRecord?.maritalStatusID,
      genderID: newRecord?.genderID,
      dependentFamilyMember: newRecord?.dependentFamilyMember || 0,
      academicQualificationID: newRecord?.academicQualificationID,
      academicQualificationGrade: newRecord?.academicQualificationGrade,
      academicQualificationYear: newRecord?.academicQualificationYear,
      primaryEducationLanguage: newRecord?.primaryEducationLanguage,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || null,
      minorTechnicalSkills: MinorSkillsValue?.join(",") || null,
      otherTechnicalSkills: OtherSkillsValue?.join(",") || null,
      attitude: attitudeFactorValue?.join(",") || null,
      candidateSourceID: newRecord?.candidateSourceID,
      currentCompanyName: newRecord?.currentCompanyName,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience ? newRecord?.totalExperience : '0') + "." + (months.totalMonth ? (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth) : '0')
      ).toFixed(2),
      relevantExperience: parseFloat(
        (newRecord?.relevantExperience ? newRecord?.relevantExperience : '0') + "." + (months.relevantMonth ? (months.relevantMonth < 10 ? `0${months.relevantMonth}` : months.relevantMonth) : '0')
      ).toFixed(2),
      descrPlainText:
        descrPlainText && descrPlainText?.trim() !== ""
          ? descrPlainText?.trim()
          : null,
      descrFormattedText: newRecord?.descrFormattedText,
    };

    const result = await candidateControlServices.InsertData(dataToInsert);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      UpdateResume(result.data.responseData);
      if (profileImg.attachedFile) {
        UpdateImage(result.data.responseData);
      }
      NavigateTo("/Candidate/List", {
        state: {
          popupStatus: {
            eStatus: eCRUDStatus.Inserted,
            primaryKeyID: result.data.responseData,
          },
        },
      });
      setNewRecord({});
      setShowSkeleton(false);
    }
  };

  const UpdateRecord = async () => {
    setShowSkeleton(true);
    let tempDivElement = htmlEditorNoteRef.current.instance.getQuillInstance();
    const descrPlainText = tempDivElement.getText();
    var date = newRecord?.dob && new Date(newRecord?.dob)
    var formattedDate = date ? date.toDateString() : undefined;
    var dataToUpdate = {
      updatedOn: newRecord.updatedOn,
      candidateID: newRecord?.candidateID,
      jobProfileID: newRecord?.jobProfileID,
      firstName: newRecord?.firstName,
      lastName: newRecord?.lastName,
      mobileNo1: newRecord?.mobileNo1,
      mobileNo2: newRecord?.mobileNo2,
      emailID: newRecord?.emailID ? newRecord?.emailID : null,
      linkedIn: newRecord?.linkedIn,
      skype: newRecord?.skype,
      currentAddress: newRecord?.currentAddress,
      currentCityID: newRecord?.currentCityID,
      currentStateID: newRecord?.currentStateID,
      currentPIN: newRecord?.currentPIN,
      permanentAddress: newRecord?.permanentAddress,
      permanentCityID: newRecord?.permanentCityID,
      permanentStateID: newRecord?.permanentStateID,
      permanentPIN: newRecord?.permanentPIN,
      dob: formattedDate,
      maritalStatusID: newRecord?.maritalStatusID,
      genderID: newRecord?.genderID,
      dependentFamilyMember: newRecord?.dependentFamilyMember,
      academicQualificationID: newRecord?.academicQualificationID,
      academicQualificationGrade: newRecord?.academicQualificationGrade,
      academicQualificationYear: newRecord?.academicQualificationYear,
      primaryEducationLanguage: newRecord?.primaryEducationLanguage,
      majorTechnicalSkills: MajorSkillsValue?.join(",") || null,
      minorTechnicalSkills: MinorSkillsValue?.join(",") || null,
      otherTechnicalSkills: OtherSkillsValue?.join(",") || null,
      attitude: attitudeFactorValue?.join(",") || null,
      candidateSourceID: newRecord?.candidateSourceID,
      currentCompanyName: newRecord?.currentCompanyName,
      currentCTC: newRecord?.currentCTC,
      expectedCTC: newRecord?.expectedCTC,
      noticePeriod: newRecord?.noticePeriod,
      readyToRelocate: newRecord?.readyToRelocate || false,
      reasonForLeaving: newRecord?.reasonForLeaving || '',
      totalExperience: parseFloat(
        (newRecord?.totalExperience ? newRecord?.totalExperience : '0') + "." + (months.totalMonth ? (months.totalMonth < 10 ? `0${months.totalMonth}` : months.totalMonth) : '0')
      ).toFixed(2),
      relevantExperience: parseFloat(
        (newRecord?.relevantExperience ? newRecord?.relevantExperience : '0') + "." + (months.relevantMonth ? (months.relevantMonth < 10 ? `0${months.relevantMonth}` : months.relevantMonth) : '0')
      ).toFixed(2),
      descrPlainText:
        descrPlainText && descrPlainText?.trim() !== ""
          ? descrPlainText?.trim()
          : null,
      descrFormattedText: newRecord?.descrFormattedText,
    };
    const result = await candidateControlServices.UpdateData(dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 409) {
        ConflictPopup(result.data).then((res) => {
          if (res) {
            GetModelRecord();
          } else {
            return;
          }
        });
        setShowSkeleton(false);
      } else if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
        setShowSkeleton(false);
      }
    } else {
      UpdateResume(newRecord?.candidateID);
      UpdateImage(newRecord?.candidateID);
      NavigateTo(editorOpenFrom, {
        state: {
          ...state,
          popupStatus: {
            eStatus: eCRUDStatus.Updated,
            primaryKeyID: newRecord?.candidateID,
          },
        },
      });
      setNewRecord({});
      setShowSkeleton(false);
    }
  };

  const UpdateImage = async (candidateID) => {
    var Datatoupload = {
      candidateID: candidateID,
      attachments: profileImg.attachedFile,
    };
    const result = await candidateControlServices.InsertImage(
      Datatoupload,
      candidateID
    );
    if (result.isOk) {
      return;
    } else {
      ShowAlert(result.data);
    }
  };

  const UpdateResume = async (candidateID) => {
    if (resume.isUpdated && resume.attachedFile) {
      var Datatoupload = { attachment: resume.attachedFile, candidateID: candidateID };
      await candidateControlServices.UpdateCandidateDocData(
        Datatoupload,
        candidateID
      );
    }
  };

  const onClickClearResume = async (e) => {
    UploadResumeRef.current?.instance.reset();
    setResume({
      uploadedFile: { fileData: null },
      attachedFile: null,
    });
    setFilename(null);
  };

  const candidateName = () => {
    let fullName = "";

    if (newRecord?.firstName) {
      fullName += newRecord?.firstName;
    }

    if (newRecord?.lastName) {
      fullName += fullName ? ` ${newRecord?.lastName}` : newRecord?.lastName;
    }

    return fullName;
  };

  const onTagBoxSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      e.component.field().value = "";
    }
  };

  function onMajorSkillsValueChange(e) {
    if (!isFetchingSkills) {
      if (e.value === null) {
        setMajorSkillsValue([]);
      } else {
        const checkMajorSameSkill = MinorSkillsValue?.find((item) => {
          return e.value?.some(
            (value) => item?.toLowerCase() === value?.toLowerCase()
          );
        });
        const CheckOtherSkill = OtherSkillsValue?.find((item) => {
          return e.value?.some(
            (value) => item?.toLowerCase() === value?.toLowerCase()
          );
        });
        if (checkMajorSameSkill || CheckOtherSkill) {
          ShowAlert(
            "Cannot accept same skill in major, minor and other",
            "Vakency"
          );
        } else {
          setMajorSkillsValue(e.value);
        }
      }
    }
  }

  function onMinorSkillsValueChange(e) {
    if (!isFetchingSkills) {
      if (e.value === null) {
        setMinorSkillsValue([]);
      } else {
        const checkMinorSameSkill = MajorSkillsValue?.find((item) => {
          return e.value?.some(
            (value) => item?.toLowerCase() === value?.toLowerCase()
          );
        });
        const CheckOtherSkill = OtherSkillsValue?.find((item) => {
          return e.value?.some(
            (value) => item?.toLowerCase() === value?.toLowerCase()
          );
        });
        if (checkMinorSameSkill || CheckOtherSkill) {
          ShowAlert(
            "Cannot accept same skill in major, minor and other",
            "Vakency"
          );
        } else {
          setMinorSkillsValue(e.value);
        }
      }
    }
  }

  function onOtherSkillsValueChange(e) {
    if (e.value === null) {
      setOtherSkillsValue([]);
    } else {
      const checkMinorSameSkill = MajorSkillsValue?.find((item) => {
        return e.value?.some(
          (value) => item?.toLowerCase() === value?.toLowerCase()
        );
      });
      const checkMajorSameSkill = MinorSkillsValue?.find((item) => {
        return e.value?.some(
          (value) => item?.toLowerCase() === value?.toLowerCase()
        );
      });
      if (checkMinorSameSkill || checkMajorSameSkill) {
        ShowAlert(
          "Cannot accept same skill in major, minor and other",
          "Vakency"
        );
      } else {
        setOtherSkillsValue(e.value);
      }
    }
  }

  function onAttitudeValueChange(e) {
    if (e.value === null) {
      setattitudeFactorValue([]);
    } else {
      setattitudeFactorValue(e.value);
    }
  }

  const asyncEmailIDValidation = useCallback(async (e) => {
    try {
      const EmailID = e.value;
      const ExcludeID = CandidateID ? CandidateID : 0;
      const CheckDuplicateParams = `EmailID=${EmailID}&ExcludeID=${ExcludeID}`;

      const result = await candidateControlServices.CheckDuplicateEmailData(
        CheckDuplicateParams
      );

      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncEmailIDValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }, [editEmailStatus]);

  const asyncMobileNoValidation = useCallback(async (e) => {
    try {
      const mobileNo = e.value;
      const ExcludeID = CandidateID ? CandidateID : 0;
      const CheckDuplicateParams = `mobileNo=${mobileNo}&ExcludeID=${ExcludeID}`;
      const result = await candidateControlServices.CheckDuplicateMobileNoData(
        CheckDuplicateParams
      );
      return new Promise((resolve) => {
        resolve(result.isOk !== false);
      });
    } catch (error) {
      console.error("Error in asyncMobileNoValidation:", error);
      return new Promise((resolve) => {
        resolve(false);
      });
    }

  }, [editMobileStatus]);

  const onValidateEmailField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      getProfileByEmailID(e.value);
    } else {
      clearEmailDuplicationCheckData();
    }
  }, [setDuplicateEmailCandidateDetail, setIsDuplicateEmailFound, setShowDuplicateEmailTootip]);

  const onValidateMobileField = useCallback(async (e) => {
    if (e.brokenRule && e.brokenRule.type == 'async' && !e.isValid && e.value) {
      GetProfileByMobileNo(e.value);
    } else {
      clearMobileDuplicationCheckData();
    }
  }, [setDuplicateMobileCandidateDetail, setIsDuplicateMobileFound, setShowDuplicateMobileTootip]);

  const onHtmlEditorValueChange = (e) => {
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      descrFormattedText: e,
    }));
  };

  const onCustomAttitudeCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = DsAcademicQualification;

    const CheckAttiudeDuplicate = currentItems.find((item) => {
      return item.attitudeName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckAttiudeDuplicate) {
      arg.customItem = CheckAttiudeDuplicate;
      return;
    } else {
      let DataToInsert = {
        attitudeName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const onCustomMajorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsSkill;

    const CheckSkillDatSource = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckSkillDatSource) {
      arg.customItem = CheckSkillDatSource;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const onCustomMinorSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsSkill;

    const CheckMajorDuplicate = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckMajorDuplicate) {
      arg.customItem = CheckMajorDuplicate;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const onCustomOtherSkillCreating = async (arg) => {
    if (!arg.text) {
      arg.customItem = null;
      return;
    }

    const { text } = arg;
    const currentItems = dsSkill;

    const CheckMajorDuplicate = currentItems?.find((item) => {
      return item.skillName?.toLowerCase() === text?.toLowerCase();
    });

    if (CheckMajorDuplicate) {
      arg.customItem = CheckMajorDuplicate;
    } else {
      let DataToInsert = {
        skillName: text && text?.trim() !== "" ? text?.trim() : null,
      };
      currentItems.push(DataToInsert);
      arg.customItem = DataToInsert;
    }
  };

  const DownloadResume = async (ID) => {
    let params = `?CandidateID=${ID}`;
    const result = await candidateControlServices.GetCandidateDocData(params);
    const fileData = await result.data;
    if (result.isOk) {
      const fileURL = URL.createObjectURL(fileData);
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(fileData);
      downloadLink.download = `resume.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(fileURL);
    } else if (result.statusCode === 204) {
      ShowAlert("No file uploaded", "Vakency");
    }
  };

  const onHidingResumeUploder = () => {
    setOpenResumeUploader(false);
  }

  const handleResumeParsing = async (resume, allowParsing) => {
    if (!resume.uploadedFile.fileData) {
      ShowAlert(`Resume is required`, "Vakency");
      return;
    }
    setOpenResumeUploader(false);
    if (allowParsing) {
      const params = {
        attachment: resume.attachedFile
      }
      setIsResumeParsing(true);
      try {
        const result = await candidateControlServices.ReadCandidateResume(params);
        if (!result.isOk) {
          if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency");
          } else if (result.statusCode === 409) {
            ShowAlert(result.data, "Vakency");
          } else {
            ShowAlert('Unable to parse uploaded resume, please try again', "Vakency");
          }
        } else {
          const response = result.data;
          setNewRecord({
            ...newRecord,
            firstName: response?.FirstName || null,
            lastName: response?.LastName || null,
            emailID: response?.EmailID || null,
            mobileNo1: response?.MobileNo1 ? response?.MobileNo1?.replace(/\D/g, "").slice(-10) : null,
            linkedIn: response?.LinkedIn || null,
            // skype: response?.Skype || null,
            // mobileNo2: parseInt(response?.MobileNo2) || null,
            // dob: response?.DOB ? new Date(response?.DOB) : null,
            // currentAddress: response?.CurrentCityName || null,
            // totalExperience: response?.TotalExperience ? parseInt(response?.TotalExperience) : newRecord?.totalExperience,
            // currentAddress: response?.CurrentAddress || null,
            // currentStateID: response?.CurrentStateName || null,
            // currentCityID: response?.CurrentCityName || null,
            // permanentAddress: response?.PermanentAddress || null,
            // permanentCityID: response?.permanentCityID || null,
            // permanentStateID: response?.permanentStateID || null,
            // permanentPIN: response?.permanentPIN || null,
            // academicQualificationID: response?.AcademicQualificationName?.length > 1 ? response?.AcademicQualificationName[0] : null,
            // academicQualificationGrade: response?.AcademicQualificationGrade || null,
            // academicQualificationYear: response?.AcademicQualificationYear || null,
            // primaryEducationLanguage: response?.PrimaryEducationLanguage || null,
            // currentCompanyName: response?.CurrentCompanyName || null,
          });
          // if (response?.MajorTechnicalSkills && response.MajorTechnicalSkills?.length > 0) {
          //   setMajorSkillsValue(response.MajorTechnicalSkills);
          // }
          // if (response?.MinorTechnicalSkills && response.MinorTechnicalSkills?.length > 0) {
          //   setMinorSkillsValue(response.MinorTechnicalSkills);
          // }
          // if (response?.OtherTechnicalSkills && response.OtherTechnicalSkills?.length > 0) {
          //   setOtherSkillsValue(response.OtherTechnicalSkills);
          // }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsResumeParsing(false);
      }
    }
  }

  const getProfileByEmailID = async (emailID) => {
    const result = await candidateControlServices.GetProfileByEmailID(emailID);
    if (!result.isOk) {

    } else {
      setDuplicateEmailCandidateDetail(result.data);
      setIsDuplicateEmailFound(true);
      setShowDuplicateEmailTootip(true);
    }
  }

  const GetProfileByMobileNo = async (mobileNo) => {
    const result = await candidateControlServices.GetProfileByMobileNo(mobileNo);
    if (!result.isOk) {

    } else {
      setDuplicateMobileCandidateDetail(result.data);
      setIsDuplicateMobileFound(true);
      setShowDuplicateMobileTootip(true);
    }
  }

  const clearEmailDuplicationCheckData = () => {
    setDuplicateEmailCandidateDetail(null);
    setIsDuplicateEmailFound(false);
    setShowDuplicateEmailTootip(false);
  }

  const clearMobileDuplicationCheckData = () => {
    setDuplicateMobileCandidateDetail(null);
    setIsDuplicateMobileFound(false);
    setShowDuplicateMobileTootip(false);
  }

  const onHidingEmailTooltip = () => {
    setShowDuplicateEmailTootip(false);
  }

  const onHidingMobileTooltip = () => {
    setShowDuplicateMobileTootip(false);
  }

  const editCandidateEmailID = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateEmailTootip(false);
    showPopupOpenType('EDIT_EMAIL');
  }, [setShowCandidateEditorPopup, setShowDuplicateEmailTootip, showPopupOpenType]);

  const editCandidateMobileNo = useCallback(() => {
    setShowCandidateEditorPopup(true);
    setShowDuplicateMobileTootip(false);
    showPopupOpenType('EDIT_MOBILE');
  }, [setShowCandidateEditorPopup, setShowDuplicateMobileTootip, showPopupOpenType]);

  const emailDuplicateButton = {
    elementAttr: {
      id: "duplicate_emailID"
    },
    visible: isDuplicateEmailFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateEmailTootip(!showDuplicateEmailTootip);
    }
  };

  const mobileDuplicateButton = {
    elementAttr: {
      id: "duplicate_mobileNo"
    },
    visible: isDuplicateMobileFound,
    text: '',
    icon: 'WarningIcon',
    stylingMode: 'text',
    hoverStateEnabled: false,
    focusStateEnabled: false,
    activeStateEnabled: false,
    onClick: () => {
      setShowDuplicateMobileTootip(!showDuplicateMobileTootip);
    }
  };

  return (
    <>
      <form className="" onSubmit={handleSubmit} onDragOver={onDragOver}>
        <Header
          title={[
            <span key={"header_title"} className="base-accent-text">
              {CandidateID ? "Edit" : "New"}
            </span>,
            " Candidate",
          ]}
          hideDefaultButtons={true}
          validationGroupName={ValidationGroupName}
          onBackButtonClick={OnCancelClick}
          submitEditor={showSkeleton ? false : true}
          setDropDownClick={setDropDownUserProfileClick}
        />
        <LoadPanel
          visible={isResumeParsing}
          message="Parsing resume, please wait..."
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
        />
        <Editor>
          <div className="px-3 py-2">
            {/* Personal Info */}

            <div className="row">
              <div className="col-md ">
                <div className="editor-sub-header">Personal Information</div>
                {(isXSmall || isXXSmall || isExSmall) && (
                  <div className="mb-3">
                    {showSkeleton ? (
                      <div className="skeleton-View image-Skeleton-Candidateprofile"></div>
                    ) : (
                      <CandidatePictureUploader
                        newRecord={profileImg}
                        setNewRecord={setProfileImg}
                      />
                    )}
                  </div>
                )}
                {showSkeleton ? (
                  <div className="">
                    <PopupSkeleton />
                    <PopupSkeleton />
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-md ">
                        <NumberBox
                          label="Candidate No."
                          maxLength={50}
                          readOnly={true}
                          labelMode="floating"
                          value={
                            CandidateID ? newRecord?.candidateNo : CandidateNo
                          }
                        />
                      </div>
                      <div className="col-xxl col-xl col-lg col-md  pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0  pt-3">
                        <JobProfileDropDown
                          FocusedFeild={FocusedFeild}
                          setAddedJobProfileID={setAddedJobProfileID}
                          setDropDownClick={setDropDownJobProfileClick}
                          validationRequired={true}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.jobProfileID}
                          onValueChange={onJobProfileValueChange}
                          dropdownHeight={180}
                        />
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className=" col-md ">
                        <TextBox
                          label="First Name"
                          maxLength={50}
                          labelMode="floating"
                          value={newRecord?.firstName}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, firstName: e })
                          }
                        >
                          <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                          >
                            <RequiredRule message="First Name is required" />
                          </Validator>
                        </TextBox>
                      </div>
                      <div className="col-md pt-md-0 pt-3">
                        <TextBox
                          label="Last Name"
                          maxLength={50}
                          labelMode="floating"
                          value={newRecord?.lastName}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, lastName: e })
                          }
                        >
                          <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                          >
                            <RequiredRule message="Last Name is required" />
                          </Validator>
                        </TextBox>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-12">
                        <TextBox
                          label="Candidate Name"
                          maxLength={100}
                          readOnly={true}
                          labelMode="floating"
                          value={candidateName()}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className=" col-md-auto">
                {!isXSmall && !isXXSmall && !isExSmall && (
                  <div>
                    {showSkeleton ? (
                      <div className="mt-1">
                        <div className="skeleton-View image-Skeleton-Candidateprofile mt-4"></div>
                      </div>
                    ) : (
                      <div className="CandidatePicture">
                        <CandidatePictureUploader
                          newRecord={profileImg}
                          setNewRecord={setProfileImg}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="row px-2 px-md-0 pt-3">
              <div className="col-md-auto editor-label pt-3 mt-1">Resume</div>
              <div className="col-md-8 col-lg-6 resumeUpload d-flex border rounded align-items-center">
                <FileUploader
                  ref={UploadResumeRef}
                  accept="application/pdf"
                  name="candidate resume"
                  multiple={false}
                  uploadMode={"useForm"}
                  selectButtonText="Browse"
                  allowCanceling={false}
                  labelText=""
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  uploadedMessage="Resume Uploaded"
                  readyToUploadMessage=""
                  className=""
                  inputAttr={{ 'aria-label': 'Browse' }}
                  onValueChange={onUploadResumeValueChanged}
                  value={resume?.uploadedFile?.fileData ? [resume?.uploadedFile?.fileData] : []}
                />
                {resume?.uploadedFile?.fileData && (
                  <>
                    <Button
                      icon="close"
                      id="Close"
                      onClick={onClickClearResume}
                      stylingMode="text"
                      type="default"
                      hint="Close"
                    />
                    {
                      filename && (
                        <Button
                          icon="download"
                          id="Download"
                          onClick={() => DownloadResume(CandidateID)}
                          stylingMode="text"
                          type="default"
                          hint="Download"
                        />
                      )
                    }
                  </>
                )}
              </div>
            </div>

            {/*-------- End Personal Info ---------*/}

            {/* Contact Info */}
            <div className="editor-sub-header pt-5">Contact Information</div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <div className="row ">
                  <div className="col-xxl col-xl col-lg col-md">
                    <div className="row">
                      <div className="col-xxl col-xl col-lg col-md">
                        <NumberBox
                          label="Mobile No."
                          labelMode="floating"
                          value={
                            newRecord?.mobileNo1
                              ? parseInt(newRecord?.mobileNo1)
                              : undefined
                          }
                          maxLength={15}
                          format="#"
                          inputAttr={{
                            autocomplete: "new",
                            maxLength: 15,
                          }}
                          onKeyDown={onKeyDown}
                          step={0}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, mobileNo1: e })
                          }
                        >
                          <NumberBoxButton
                            location="after"
                            name="duplicate_mobileNo"
                            id="duplicate_mobileNo"
                            options={mobileDuplicateButton}
                          />
                          <Validator
                            validationGroup={ValidationGroupName}
                            elementAttr={{ class: "ForValidationAstrikMark" }}
                            onValidated={onValidateMobileField}
                          >
                            <RequiredRule message="Mobile No. is required" />
                            <StringLengthRule
                              message="Mobile No. must have 10 digits"
                              min={10}
                              ignoreEmptyValue={true}
                            />
                            <AsyncRule
                              reevaluate={false}
                              message="Can't accept duplicate Mobile No."
                              validationCallback={asyncMobileNoValidation}
                            />
                          </Validator>
                        </NumberBox>
                        <Tooltip
                          position={"top"}
                          target={`#duplicate_mobileNo`}
                          className="custom-tooltip"
                          hideOnOutsideClick={true}
                          visible={showDuplicateMobileTootip}
                          onHiding={onHidingMobileTooltip}
                          hideOnParentScroll={true}
                        >
                          <div className="p-2 text-wrap">
                            <div className="font-semiBold-16">
                              {duplicateMobileCandidateDetail?.candidateName}
                              <span>
                                <Button
                                  icon="edit"
                                  stylingMode="text"
                                  hint="Edit Candidate"
                                  type="default"
                                  className="ps-1 resume-edit-btn"
                                  onClick={editCandidateMobileNo}
                                  focusStateEnabled={false}
                                  hoverStateEnabled={false}
                                  activeStateEnabled={false}
                                />
                              </span>
                            </div>
                            <div className="font-semiBold-12 text-gray">
                              {duplicateMobileCandidateDetail?.jobProfileName}
                            </div>
                            <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                              <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                              This mobile no is already assigned to the above candidate.
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="col-md pt-md-0  pt-3">
                        <NumberBox
                          label="Whatsapp No."
                          labelMode="floating"
                          value={
                            newRecord?.mobileNo2
                              ? parseInt(newRecord?.mobileNo2)
                              : undefined
                          }
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, mobileNo2: e })
                          }
                          maxLength={10}
                          format="#"
                          inputAttr={{ maxLength: 10 }}
                          step={0}
                          onKeyDown={onKeyDown}
                        >
                          <Validator validationGroup={ValidationGroupName}>
                            <StringLengthRule
                              message="Whatsapp No. must have 10 digits"
                              min={10}
                              ignoreEmptyValue={true}
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0  pt-3">
                    <TextBox
                      label="Email ID"
                      maxLength={100}
                      labelMode="floating"
                      value={newRecord?.emailID}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, emailID: e })
                      }
                      inputAttr={{
                        autocomplete: "new",
                      }}
                    >
                      <TextBoxButton
                        location="after"
                        name="duplicate_emailID"
                        id="duplicate_emailID"
                        options={emailDuplicateButton}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                        onValidated={onValidateEmailField}
                      >
                        <RequiredRule message="Email ID is required" />
                        <EmailRule message="Email ID is invalid" />
                        <AsyncRule
                          reevaluate={false}
                          message="Can't accept duplicate email ID"
                          validationCallback={asyncEmailIDValidation}
                        />
                      </Validator>
                    </TextBox>
                    <Tooltip
                      position={"top"}
                      target={`#duplicate_emailID`}
                      className="custom-tooltip"
                      hideOnOutsideClick={true}
                      visible={showDuplicateEmailTootip}
                      onHiding={onHidingEmailTooltip}
                      hideOnParentScroll={true}
                    >
                      <div className="p-2 text-wrap">
                        <div className="font-semiBold-16">
                          {duplicateEmailCandidateDetail?.candidateName}
                          <span>
                            <Button
                              icon="edit"
                              stylingMode="text"
                              hint="Edit Candidate"
                              type="default"
                              className="ps-1 resume-edit-btn"
                              onClick={editCandidateEmailID}
                              focusStateEnabled={false}
                              hoverStateEnabled={false}
                              activeStateEnabled={false}
                            />
                          </span>
                        </div>
                        <div className="font-semiBold-12 text-gray">
                          {duplicateEmailCandidateDetail?.jobProfileName}
                        </div>
                        <div className="font-regular-12 mt-2 text-danger d-flex align-items-center">
                          <i className="dx-icon-WarningIcon dx-custom-icon me-1" />
                          This email ID is already assigned to the above candidate.
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-xxl col-xl col-lg col-md">
                    <TextBox
                      label="Linkedin"
                      maxLength={200}
                      labelMode="floating"
                      value={newRecord?.linkedIn}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, linkedIn: e ? e.includes("https://") ? e : "https://" + e : null })
                      }
                    />
                  </div>
                  <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0  pt-3">
                    <TextBox
                      label="Skype"
                      maxLength={200}
                      labelMode="floating"
                      value={newRecord?.skype}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, skype: e })
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {/*-------- End Contact Info ---------*/}

            {/* Address Info */}
            <div className="editor-sub-header pt-5">
              Current Address Information
            </div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <TextBox
                  label="Current Address"
                  labelMode="floating"
                  maxLength={255}
                  value={newRecord?.currentAddress}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, currentAddress: e })
                  }
                />
                <div className="row pt-3">
                  <div className="col-lg-10 ">
                    <div className="row">
                      <div className="col-md">
                        <StateDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.currentStateID}
                          onValueChange={handleCurrentStateValueChange}
                          setDropDownClick={setDropDownStateClick}
                          setAddedCityStateID={setAddedStateID}
                          newAddedStateID={newAddedStateID}
                          setNewAddedStateID={setNewAddedStateID}
                          dropdownHeight={180}
                        />
                      </div>
                      <div className="col-md  pt-md-0 pt-3">
                        <CityDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.currentCityID}
                          cityStateID={newRecord?.currentStateID}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, currentCityID: e })
                          }
                          setDropDownClick={setDropDownCityClick}
                          setAddedCityID={setAddedCityID}
                          dropdownHeight={180}
                        />
                      </div>
                      <div className="col-md-auto pt-md-0  pt-3">
                        <NumberBox
                          label="Pin"
                          labelMode="floating"
                          width={200}
                          value={
                            newRecord?.currentPIN &&
                            parseInt(newRecord?.currentPIN)
                          }
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, currentPIN: e })
                          }
                          maxLength={6}
                          format="#"
                          onKeyDown={onKeyDown}
                          inputAttr={{ maxLength: 6 }}
                          step={0}
                        >
                          <Validator validationGroup={ValidationGroupName}>
                            <StringLengthRule
                              message="Pin must have 6 digits"
                              min={6}
                              ignoreEmptyValue={true}
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="editor-sub-header pt-5">
              Permanent Address Information
            </div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <TextBox
                  label="Permanent Address"
                  labelMode="floating"
                  maxLength={255}
                  value={newRecord?.permanentAddress}
                  onValueChange={(e) =>
                    setNewRecord({ ...newRecord, permanentAddress: e })
                  }
                />

                <div className="row pt-3">
                  <div className="col-lg-10 ">
                    <div className="row">
                      <div className="col-md">
                        <StateDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.permanentStateID}
                          onValueChange={handlePermanentStateValueChange}
                          setDropDownClick={setDropDownPermanentStateClick}
                          setAddedCityStateID={setAddedPermanentStateID}
                          newAddedStateID={newAddedStateID}
                          setNewAddedStateID={setNewAddedStateID}
                          dropdownHeight={180}
                        />
                      </div>
                      <div className="col-md  pt-md-0 pt-3">
                        <CityDropDown
                          validationRequired={false}
                          validationGroup={ValidationGroupName}
                          value={newRecord?.permanentCityID}
                          cityStateID={newRecord?.permanentStateID}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, permanentCityID: e })
                          }
                          setDropDownClick={setDropDownPermanentCityClick}
                          setAddedCityID={setAddedPermanentCityID}
                          dropdownHeight={180}
                        />
                      </div>
                      <div className="col-md-auto pt-md-0  pt-3">
                        <NumberBox
                          label="Pin"
                          labelMode="floating"
                          width={200}
                          value={
                            newRecord?.permanentPIN &&
                            parseInt(newRecord?.permanentPIN)
                          }
                          maxLength={6}
                          onValueChange={(e) =>
                            setNewRecord({ ...newRecord, permanentPIN: e })
                          }
                          format="#"
                          inputAttr={{ maxLength: 6 }}
                          step={0}
                          onKeyDown={onKeyDown}
                        >
                          <Validator validationGroup={ValidationGroupName}>
                            <StringLengthRule
                              message="Pin must have 6 digits"
                              min={6}
                              ignoreEmptyValue={true}
                            />
                          </Validator>
                        </NumberBox>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/*------End Address Info------------ */}

            {/* Additional Personal Info */}

            <div className="editor-sub-header pt-5">
              Additional Personal Information
            </div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
              </div>
            ) : (
              <div className="row ">
                <div className="col-xxl col-xl col-lg col-md col-sm">
                  <DateBox
                    label="DOB"
                    labelMode="floating"
                    id="DOB_Candidate_Dropdown"
                    // value={newRecord?.dob}
                    value={newRecord.dob ? new Date(newRecord.dob) : null}
                    dateOutOfRangeMessage="Invalid Date"
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, dob: e ? new Date(e) : null })
                    }
                    useMaskBehavior={true}
                    type="date"
                    displayFormat="dd/MM/yyyy"
                    min={minDate}
                    dropDownOptions={
                      {
                        hideOnParentScroll: true,
                        container: "#DOB_Candidate_Dropdown"
                      }
                    }
                  >
                    <Validator validationGroup={ValidationGroupName}>
                      <RangeRule
                        message="Candidate must be 14 years old"
                        max={DOBRangeRule}
                        ignoreEmptyValue={true}
                      />
                    </Validator>
                  </DateBox>
                </div>
                <div className="col-xxl col-xl col-lg col-md col-sm pt-sm-0 pt-3">
                  <SelectBox
                    label="Marital Status"
                    id="Candidate_MaritalStatus_Dropdown"
                    labelMode="floating"
                    dataSource={dsMaritalStatus}
                    valueExpr={"maritalStatusID"}
                    displayExpr={"maritalStatus"}
                    value={newRecord?.maritalStatusID}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, maritalStatusID: e })
                    }
                    dropDownOptions={
                      {
                        container: "#Candidate_MaritalStatus_Dropdown"
                      }
                    }
                  />
                </div>
                <div className="col-xxl col-xl col-lg col-md col-sm pt-sm-0 pt-3">
                  <SelectBox
                    label="Gender"
                    id="Candidate_Gender_Dropdown"
                    labelMode="floating"
                    dataSource={dsGenderStatus}
                    valueExpr={"genderID"}
                    displayExpr={"genderStatus"}
                    value={newRecord?.genderID}
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, genderID: e })
                    }
                    dropDownOptions={
                      {
                        container: "#Candidate_Gender_Dropdown"
                      }
                    }
                  />
                </div>
                <div className="col-xxl col-xl col-lg col-md col-sm pt-sm-0 pt-3">
                  <NumberBox
                    label="Dependent Family Members"
                    labelMode="floating"
                    maxLength={2}
                    format="#"
                    step={0}
                    onKeyDown={onKeyDown}
                    inputAttr={{ maxLength: 2 }}
                    value={
                      newRecord?.dependentFamilyMember &&
                      parseInt(newRecord?.dependentFamilyMember)
                    }
                    onValueChange={(e) =>
                      setNewRecord({ ...newRecord, dependentFamilyMember: e })
                    }
                  />
                </div>
              </div>
            )}
            {/* ------- End Additional Personal Info -------------*/}

            {/* Educational and Professional Info */}

            <div className="editor-sub-header pt-5">
              Educational and Professional Information
            </div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <div className="row ">
                  <div className="col-md-3">
                    <AcademicQualificationDropDown
                      validationRequired={false}
                      validationGroup={ValidationGroupName}
                      value={newRecord?.academicQualificationID}
                      onValueChange={(e) =>
                        setNewRecord({
                          ...newRecord,
                          academicQualificationID: e,
                        })
                      }
                      setDropDownClick={setDropDownAcademicQualificationClick}
                      setAddedacademicQualificationID={
                        setAddedAcademicQualificationID
                      }
                      dropdownHeight={180}
                    />
                  </div>
                  {newRecord?.academicQualificationID && (
                    <>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <TextBox
                          label="Primary Education Language"
                          labelMode="floating"
                          maxLength={50}
                          value={newRecord?.primaryEducationLanguage}
                          onValueChange={(e) =>
                            setNewRecord({
                              ...newRecord,
                              primaryEducationLanguage: e,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <TextBox
                          label="Grade"
                          labelMode="floating"
                          maxLength={10}
                          value={newRecord?.academicQualificationGrade}
                          onValueChange={(e) =>
                            setNewRecord({
                              ...newRecord,
                              academicQualificationGrade: e,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <SelectBox
                          label="Year"
                          labelMode="floating"
                          items={DsYearDropdownValues}
                          id="CandidateYear_DropDown"
                          value={newRecord?.academicQualificationYear}
                          onValueChange={(e) =>
                            setNewRecord({
                              ...newRecord,
                              academicQualificationYear: e,
                            })
                          }
                          dropDownOptions={{
                            height: 180,
                            hideOnParentScroll: true,
                            container: "#CandidateYear_DropDown"
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="row pt-3">
                  <div className="col-md-6">
                    <TagBox
                      placeholder=""
                      labelMode={"floating"}
                      label="Major Technical Skills"
                      value={MajorSkillsValue}
                      searchEnabled={true}
                      id="Candidate_MajorTechnicalSkill_DropDown"
                      valueExpr="skillName"
                      displayExpr="skillName"
                      showClearButton={true}
                      dataSource={dsSkill}
                      onValueChanged={onMajorSkillsValueChange}
                      dropDownOptions={{
                        height: 180,
                        hideOnParentScroll: true,
                        container: "#Candidate_MajorTechnicalSkill_DropDown"
                      }}
                      showDropDownButton={true}
                      hideSelectedItems={true}
                      multiline={false}
                      onSelectionChanged={onTagBoxSelectionChanged}
                      selectAllMode="allPages"
                      showMultiTagOnly={false}
                      acceptCustomValue={true}
                      onCustomItemCreating={onCustomMajorSkillCreating}
                      maxLength={50}
                    ></TagBox>
                  </div>
                  <div className="col-md-6 pt-md-0 pt-3">
                    <TagBox
                      placeholder=""
                      labelMode={"floating"}
                      label="Minor Technical Skills"
                      id="Candidate_MinorTechnicalSkill_DropDown"
                      value={MinorSkillsValue}
                      searchEnabled={true}
                      valueExpr="skillName"
                      displayExpr="skillName"
                      showClearButton={true}
                      dataSource={dsSkill}
                      onValueChanged={onMinorSkillsValueChange}
                      dropDownOptions={{
                        height: 180,
                        hideOnParentScroll: true,
                        container: "#Candidate_MinorTechnicalSkill_DropDown"
                      }}
                      showDropDownButton={true}
                      multiline={false}
                      onSelectionChanged={onTagBoxSelectionChanged}
                      selectAllMode="allPages"
                      showMultiTagOnly={false}
                      hideSelectedItems={true}
                      acceptCustomValue={true}
                      onCustomItemCreating={onCustomMinorSkillCreating}
                      maxLength={50}
                    ></TagBox>
                  </div>
                </div>
                <div className="pt-3">
                  <TagBox
                    placeholder=""
                    labelMode={"floating"}
                    label="Other Skills"
                    id="Candidate_OtherSkill_DropDown"
                    value={OtherSkillsValue}
                    searchEnabled={true}
                    valueExpr="skillName"
                    displayExpr="skillName"
                    showClearButton={true}
                    dataSource={dsSkill}
                    hideSelectedItems={true}
                    onValueChanged={onOtherSkillsValueChange}
                    dropDownOptions={{
                      height: 180,
                      hideOnParentScroll: true,
                      container: "#Candidate_OtherSkill_DropDown"
                    }}
                    showDropDownButton={true}
                    multiline={false}
                    onSelectionChanged={onTagBoxSelectionChanged}
                    selectAllMode="allPages"
                    showMultiTagOnly={false}
                    acceptCustomValue={true}
                    onCustomItemCreating={onCustomOtherSkillCreating}
                    maxLength={50}
                  ></TagBox>
                </div>
              </>
            )}
            {/* ------- End Educational and Professional Info -------------*/}

            {/* Personal Attributes and Preferences */}

            <div className="editor-sub-header pt-5">
              Personal Attributes and Preferences
            </div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <div className="row ">
                  <div className="col-md-6 ">
                    <TagBox
                      placeholder=""
                      value={attitudeFactorValue}
                      ref={AttitudeDropDownBoxRef}
                      searchEnabled={true}
                      id="Candidate_AttitudeTagBoxBox"
                      valueExpr="attitudeName"
                      displayExpr="attitudeName"
                      showClearButton={true}
                      dataSource={DsAttitudeFactor}
                      onValueChanged={onAttitudeValueChange}
                      dropDownOptions={{
                        height: 180,
                        hideOnParentScroll: true,
                        container: "#Candidate_AttitudeTagBoxBox"
                      }}
                      labelMode={"floating"}
                      label="Attitude"
                      showDropDownButton={true}
                      multiline={false}
                      onSelectionChanged={onTagBoxSelectionChanged}
                      selectAllMode="allPages"
                      showMultiTagOnly={false}
                      acceptCustomValue={true}
                      onCustomItemCreating={onCustomAttitudeCreating}
                      maxLength={50}
                      hideSelectedItems={true}
                    ></TagBox>
                  </div>
                  <div className=" col-md-6 pt-md-0 pt-3">
                    <CandidateSourceDropDown
                      label="Source"
                      setAddedCandidateSourceID={setAddedCandidateSourceID}
                      setDropDownClick={setDropDownCandidateClick}
                      value={newRecord?.candidateSourceID}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, candidateSourceID: e })
                      }
                      height={40}
                      dropdownHeight={180}
                    />
                  </div>
                </div>

                {showSkeleton ? (
                  <div className="">
                    <PopupSkeleton />
                  </div>
                ) : (
                  <div className="row pt-3">
                    <div className="col-xxl col-xl col-lg ">
                      <div className="row">
                        <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                          Current CTC:
                        </div>
                        <div className="col">
                          <NumberBox
                            label="Yearly"
                            min={0}
                            labelMode="floating"
                            maxLength={15}
                            format="##.##"
                            value={newRecord?.currentCTC && newRecord?.currentCTC?.toFixed(2)}
                            onValueChange={(e) => {
                              setNewRecord({ ...newRecord, currentCTC: e })
                              setCurrentCTCMonthlyValue((e * 100000) / 12)
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="current_ctc_yearly"
                              options={{
                                text: 'LPA',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                        <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                        <div className="col">
                          <NumberBox
                            label="Monthly"
                            labelMode="floating"
                            value={currentCTCMonthlyValue && currentCTCMonthlyValue.toFixed(2)}
                            maxLength={15}
                            min={0}
                            format="##.##"
                            onValueChange={(e) => {
                              setCurrentCTCMonthlyValue(e);
                              setNewRecord({ ...newRecord, currentCTC: ((e * 12) / 100000) })
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="current_ctc_monthly"
                              options={{
                                text: 'month',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0 pt-3">
                      <div className="row">
                        <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                          Expected CTC:
                        </div>
                        <div className="col">
                          <NumberBox
                            label="Yearly"
                            min={0}
                            labelMode="floating"
                            maxLength={15}
                            format="##.##"
                            value={newRecord?.expectedCTC && newRecord?.expectedCTC?.toFixed(2)}
                            onValueChange={(e) => {
                              setNewRecord({ ...newRecord, expectedCTC: e })
                              setExpectedCTCMonthlyValue((e * 100000) / 12)
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="expected_ctc_yearly"
                              options={{
                                text: 'LPA',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                        <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                        <div className="col">
                          <NumberBox
                            label="Monthly"
                            labelMode="floating"
                            value={expectedCTCMonthlyValue && expectedCTCMonthlyValue.toFixed(2)}
                            maxLength={15}
                            min={0}
                            format="##.##"
                            onValueChange={(e) => {
                              setExpectedCTCMonthlyValue(e);
                              setNewRecord({ ...newRecord, expectedCTC: ((e * 12) / 100000) })
                            }}
                            onKeyDown={onKeyDown_Withpoint}
                            className="number-box-btn"
                          >
                            <NumberBoxButton
                              location="after"
                              name="expected_ctc_monthly"
                              options={{
                                text: 'month',
                                stylingMode: 'text',
                                hoverStateEnabled: false,
                                focusStateEnabled: false,
                                activeStateEnabled: false,
                              }}
                            />
                          </NumberBox>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row pt-3">
                  <div className="col">
                    <TextBox
                      label="Current Company Name"
                      labelMode="floating"
                      value={newRecord?.currentCompanyName}
                      maxLength={100}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, currentCompanyName: e })
                      }
                    />
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Notice Period (Days)"
                      labelMode="floating"
                      value={
                        newRecord?.noticePeriod &&
                        parseInt(newRecord?.noticePeriod)
                      }
                      maxLength={3}
                      min={0}
                      max={255}
                      inputAttr={{ maxLength: 3 }}
                      step={0}
                      onKeyDown={onKeyDown}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, noticePeriod: e })
                      }
                    />
                  </div>
                  <div className="col py-2">
                    <CheckBox
                      text="Ready To Relocate"
                      value={newRecord?.readyToRelocate || false}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, readyToRelocate: e })
                      }
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col">
                    <TextBox
                      label="Reason For Leaving"
                      labelMode="floating"
                      value={newRecord?.reasonForLeaving}
                      maxLength={100}
                      onValueChange={(e) =>
                        setNewRecord({ ...newRecord, reasonForLeaving: e })
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {/* ------- End Personal Attributes and Preferences -------------*/}

            {/* Work Experience */}

            <div className="editor-sub-header pt-5">Work Experience</div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
              </div>
            ) : (
              <div className="row pt-2">
                <div className="col-xxl col-xl col-lg ">
                  <div className="row">
                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  pt-2 mt-1 editor-label">
                      Total Experience:
                    </div>
                    <div className="col">
                      <NumberBox
                        label="Years"
                        labelMode="floating"
                        maxLength={3}
                        value={newRecord?.totalExperience}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, totalExperience: e })
                        }
                        onKeyDown={onKeyDown}
                        format="#"
                        inputAttr={{ maxLength: 3 }}
                        step={0}
                      />
                    </div>
                    <div className="col ">
                      <NumberBox
                        label="Months"
                        labelMode="floating"
                        maxLength={3}
                        max={11}
                        value={months.totalMonth}
                        onValueChange={(e) => {
                          setMonths({ ...months, totalMonth: e });
                        }}
                        onKeyDown={onKeyDown}
                        format="#"
                        inputAttr={{ maxLength: 5 }}
                        step={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl col-xl col-lg pt-xxl-0 pt-xl-0 pt-lg-0 pt-3">
                  <div className="row">
                    <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                      Relevant Experience:
                    </div>
                    <div className="col">
                      <NumberBox
                        label="Years"
                        labelMode="floating"
                        maxLength={3}
                        value={newRecord?.relevantExperience}
                        onValueChange={(e) =>
                          setNewRecord({ ...newRecord, relevantExperience: e })
                        }
                        onKeyDown={onKeyDown}
                        inputAttr={{ maxLength: 3 }}
                        step={0}
                        format="#"
                      />
                    </div>
                    <div className="col ">
                      <NumberBox
                        label="Months"
                        labelMode="floating"
                        maxLength={3}
                        onKeyDown={onKeyDown}
                        format="#"
                        max={11}
                        value={months.relevantMonth}
                        onValueChange={(e) =>
                          setMonths({ ...months, relevantMonth: e })
                        }
                        inputAttr={{ maxLength: 3 }}
                        step={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ------- End Work Experience -------------*/}

            {/*Additional Information */}

            <div className="editor-sub-header pt-5">Other Information</div>
            {showSkeleton ? (
              <div className="">
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
                <PopupSkeleton />
              </div>
            ) : (
              <>
                <div className="row pt-2">
                  <div className="col-xxl col-xl col-lg col-md">
                    <span className="editor-label">Description/Notes</span>
                    <HtmlEditor
                      ref={htmlEditorNoteRef}
                      id={"JobDescribtionHtmlEditor"}
                      elementAttr={htmlEditorSecondAttributes}
                      valueType="html"
                      height={200}
                      className="CustomizeHtmlEditor"
                      defaultValue={newRecord?.descrFormattedText}
                      onValueChange={onHtmlEditorValueChange}
                      tabIndex={-1}
                    >
                      <HtmlEditorToolbar multiline={false}>
                        <HtmlEditorItem name="link"></HtmlEditorItem>
                        <HtmlEditorItem
                          name="font"
                          acceptedValues={fontValues}
                        />
                        <HtmlEditorItem
                          name="size"
                          acceptedValues={sizeValues}
                        />
                        <HtmlEditorItem name="bold" />
                        <HtmlEditorItem name="italic" />
                        <HtmlEditorItem name="underline" />
                        <HtmlEditorItem name="background" />
                        <HtmlEditorItem name="color" />
                        <HtmlEditorItem name="orderedList" />
                        <HtmlEditorItem name="bulletList" />
                        <HtmlEditorItem name="clear" />
                      </HtmlEditorToolbar>
                    </HtmlEditor>
                  </div>
                </div>
              </>
            )}
            {/* ------- End Additional Information -------------*/}
          </div>
        </Editor>
        {
          openResumeUploader && (
            <ResumeUploader
              resume={resume}
              setResume={setResume}
              isOpen={openResumeUploader}
              onClose={onHidingResumeUploder}
              handleResumeParsing={handleResumeParsing}
            />
          )
        }
      </form>
      {
        showCandidateEditorPopup && (
          <CandidateEditorPopup
            isOpen={showCandidateEditorPopup}
            onClose={() => setShowCandidateEditorPopup(false)}
            candidateData={popupOpenType == "EDIT_EMAIL" ? duplicateEmailCandidateDetail : duplicateMobileCandidateDetail}
            editEmailID={popupOpenType == "EDIT_EMAIL"}
            editMobileNo={popupOpenType == "EDIT_MOBILE"}
            setStatus={popupOpenType == "EDIT_EMAIL" ? setEditEmailStatus : setEditMobileStatus}
          />
        )
      }
    </>
  );
};

export default CandidateEditor;

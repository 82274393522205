import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";
import { EmailTemplateControlName } from "../../contexts/APIurl";

const url = EmailTemplateControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class EmailTemplateServices extends VakencyApiServices {
    constructor() {
        super(url);
    }

    GetEmailTemplateList = async () => {
        const GetListUrl = url + "/GetList"
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetListUrl)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    GetEmailTemplateModel = async (params) => {
        const GetModelUrl = url + "/GetModel";
        let data = null;
        try {
            data = await fetchWrapper.get(baseURL + GetModelUrl + params)
            return this.handleResponse(data);
        }
        catch {
            return {
                isOk: false,
                message: "Failed To Load Data",
                statusCode: data.status
            };
        }
    }

    UpdateEmailTemplate = async (dataObj) => {
        const UpdateUrl = url + "/Update"
        let data = null;
        try {
            data = await fetchWrapper.put(baseURL + UpdateUrl, dataObj)
            return this.handleResponse(data);
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To update data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }
}
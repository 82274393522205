import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlankProfile, CancelledByCandidateIcon, CandidateAcceptedOfferIcon, CandidateRejectedOfferIcon, candidateUnavailableIcon, HiredStatusIcon, JoinedStatusIcon, MoveToUnderReviewIcon, OfferedStatusIcon, pendingIcon, RejectIcon, selectedIcon, shortlistedIcon } from '../../utils/base-64-Icons';
import { GetFormattedDate, handleNavigateToMap, NewStarRating } from '../../utils/common-methods';
import { useScreenSize } from '../../utils/media-query';
import { Button, LoadPanel, Popover } from 'devextreme-react';
import FilePreviewer from '../candidate/FilePreviewer';
import EmailStatusPopup from './EmailStatusPopup';

const CandidateDetailPanel = ({
    showRightSection,
    candidateData,
    resume,
    profileImg,
    onActionButttonClick,
    lastInteractionRoundID,
    dsInteractionRounds,
    lastInteractionStatus,
    lastRoundScheduleDate,
    lastCandidateInteractionID,
    candidateApplicationStatus,
    lastInteractionScheduleID,
    dropdownItems,
    goToCandidateProfile,
    applicationfocusedRowKey,
    jobOpeningID
}) => {

    const NavigateTo = useNavigate();
    const {
        isSmall,
        isXSmall,
        isExSmall,
        isXXSmall,
        isMedium,
        isLarge,
        isExLarge,
    } = useScreenSize();

    const { pathname } = useLocation();
    const [showFilePreview, setShowFilePreview] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);
    const [resendScheduleEmail, setResendScheduleEmail] = React.useState(false);

    function openResume() {
        const file = new Blob([resume], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.target = '_blank';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(fileURL);
    }

    const onAssessmentViewerButtonClick = (id) => {
        NavigateTo('/AssessmentViewer', {
            state: {
                InteractionID: id,
                applicationfocusedRowKey: applicationfocusedRowKey,
                openFromPage: pathname,
                jobOpeningID: jobOpeningID
            },
        });
    }

    const onEmailButtonClick = () => {
        window.location.href = `mailto:${candidateData?.emailID}`;
    }
    const onPhoneButtonClick = () => {
        window.location.href = `tel:${candidateData?.mobileNo1}`;
    }

    const onPictureClick = (fileSrc) => {
        if (fileSrc) {
            setShowFilePreview(true);
            setSelectedFile({
                fileSrc: fileSrc,
            });
        }
    }

    const onFilePreviewClose = () => {
        setShowFilePreview(false)
    }

    const resendInteractionScheduleEmail = () => {
        setResendScheduleEmail(true);
    }

    const lastRoundScheduleDateObject = new Date(lastRoundScheduleDate);
    const currentDate = new Date();
    return (
        <>
            <LoadPanel
                visible={showFilePreviewLoad}
                message='File is loading. We’ll display the preview shortly.'
            />
            <div className="side-panel-height d-flex justify-content-end">
                <div
                    className={`  ${showRightSection
                        ? "right-candidate-section-expand"
                        : "right-section-collapse"
                        }`}
                >
                    <div className={!showRightSection ? "d-none" : undefined}>
                        <div
                            className={`pt-2 ${!isSmall && !isXSmall && !isXXSmall && !isExSmall && !isMedium
                                ? ""
                                : ""
                                }`}>
                            <div className="ms-2 me-2">
                                <div className="row mt-3 mx-0">
                                    <div className="col my-auto">
                                        <div className="row align-items-center">
                                            <div className="px-2 font-medium-22 text-break d-flex align-items-center">
                                                <div className="text-decoration-underline pointer-link pe-3" onClick={() => goToCandidateProfile(candidateData?.candidateID)}>{candidateData?.candidateName}
                                                </div>
                                                <img
                                                    src={
                                                        candidateApplicationStatus === "Job application rejected by us"
                                                            ? RejectIcon
                                                            : candidateApplicationStatus === "Pending"
                                                                ? pendingIcon :
                                                                candidateApplicationStatus === "Shortlisted"
                                                                    ? shortlistedIcon :
                                                                    candidateApplicationStatus === "Under Review"
                                                                        ? MoveToUnderReviewIcon :
                                                                        candidateApplicationStatus === "Selected"
                                                                            ? selectedIcon :
                                                                            candidateApplicationStatus === "Offered"
                                                                                ? OfferedStatusIcon :
                                                                                candidateApplicationStatus === "Offer accepted"
                                                                                    ? CandidateAcceptedOfferIcon :
                                                                                    candidateApplicationStatus === "Hired"
                                                                                        ? HiredStatusIcon :
                                                                                        candidateApplicationStatus === "On-boarded/Joined"
                                                                                            ? JoinedStatusIcon :
                                                                                            candidateApplicationStatus === "Offer rejected by candidate"
                                                                                                ? CandidateRejectedOfferIcon :
                                                                                                candidateApplicationStatus === "Shortlisted but not selected"
                                                                                                    ? CancelledByCandidateIcon :
                                                                                                    candidateApplicationStatus === "Cancelled by Candidate"
                                                                                                        ? CancelledByCandidateIcon :
                                                                                                        candidateApplicationStatus === "Candidate is not responding"
                                                                                                        && candidateUnavailableIcon
                                                    }
                                                    title={candidateApplicationStatus}
                                                    alt="status"
                                                    width={22}
                                                    height={22}
                                                    className='mt-1'
                                                />
                                            </div>
                                           
                                            <div className="font-medium-20 px-2">
                                                <NewStarRating rating={candidateData?.rating} size={16} />
                                            </div>
                                        </div>

                                        <div className="row py-1">
                                            {
                                                dsInteractionRounds.length > 0 && (
                                                    <div
                                                        className={`col-sm-auto px-2 d-flex justify-content-lg-end col-12 ${isLarge && isExLarge
                                                            ? "me-1"
                                                            : ""
                                                            }`}
                                                    >
                                                        <div className='d-flex flex-column gap-0'>
                                                            <div className='text-start font-semiBold-14'>Hiring Progress Tracker</div>
                                                            <div className='d-flex flex-wrap'>
                                                                {dsInteractionRounds.map((interaction, index) => (
                                                                    <div className='d-flex align-items-center mb-1' key={interaction.interactionRoundID}>
                                                                        <div
                                                                            id={`link-${index}`}
                                                                            className={`d-flex justify-content-center align-items-center ${interaction.interactionResultName === 'Pending' ? 'candidate_progress_circle_in_progress' : interaction.interactionResultName != null ? 'candidate_progress_circle_completed' : 'candidate_progress_circle_pending'
                                                                                }`}
                                                                            onClick={() => interaction.interactionResultName != null && onAssessmentViewerButtonClick(interaction.candidateInteractionID)}
                                                                        />
                                                                        <Popover
                                                                            target={`#link-${index}`}
                                                                            showEvent="mouseenter"
                                                                            hideEvent="mouseleave"
                                                                            position="bottom"
                                                                            width={'auto'}
                                                                        >
                                                                            <div className='font-medium-14'>
                                                                                <span className='italic-text'>Round Name:</span>{" "}
                                                                                {interaction.interactionRoundName ? interaction.interactionRoundName : '-'}
                                                                            </div>
                                                                            <div className='font-medium-14'>
                                                                                <span className='italic-text'>Status:</span>{" "}
                                                                                {interaction.interactionResultName ? interaction.interactionResultName : '-'}
                                                                            </div>
                                                                            <div className='font-medium-14'>
                                                                                <span className='italic-text'>Date of Interaction:</span>{" "}
                                                                                {interaction.scheduleDateTime ? GetFormattedDate(interaction.scheduleDateTime) : '-'}
                                                                            </div>
                                                                        </Popover>
                                                                        {index < dsInteractionRounds.length - 1 && (
                                                                            <div
                                                                                className={interaction.interactionResultName === 'Pending' ? 'candidate_progress_line_pending' : interaction.interactionResultName != null ? 'candidate_progress_line_completed' : 'candidate_progress_line_pending'}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div>
                                            {(profileImg.uploadedFile?.fileData) ? <img
                                                src={profileImg.uploadedFile?.fileData}
                                                width={100}
                                                style={{ maxHeight: "100px", height: "auto" }}
                                                alt="candidate"
                                                className="candidate-image"
                                                onClick={() => onPictureClick(profileImg.uploadedFile?.fileData)}
                                            /> :
                                                <img
                                                    src={BlankProfile}
                                                    width={100}
                                                    height={100}
                                                    alt="candidate"
                                                    className="candidate-image"
                                                />}
                                        </div>
                                        {
                                            resume && (
                                                <div className='font-medium-14 text-decoration-underline text-center pointer-link pt-1' onClick={openResume}>
                                                    View Resume
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row px-2 font-regular-14 d-flex pt-1">
                                    <div className='col-auto'>
                                        <Button
                                            icon="email"
                                            hint="Email"
                                            stylingMode="text"
                                            className="resume-edit-btn pe-1"
                                            onClick={onEmailButtonClick}
                                        />{" "}
                                        {candidateData?.emailID ? candidateData?.emailID : ""}
                                    </div>
                                    {candidateData?.emailID && <div className="col-auto px-1 assessment-pipe">|</div>}
                                    <div className='col-auto'>
                                        <div onClick={onPhoneButtonClick} className="cursor-pointer">
                                            <i className="dx-icon-MobileIcon dx-custom-icon"></i>{" "}
                                            {candidateData?.mobileNo1}
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 pt-1 font-regular-14">
                                   
                                    {candidateData?.currentAddress &&
                                        <span className="text-break">
                                                <i className="dx-icon-locationIcon dx-custom-icon mb-1 cursor-pointer" title="Current Address" onClick={() => handleNavigateToMap(candidateData?.currentAddress)}></i>{" "}
                                                {candidateData?.currentAddress}
                                            <span className=" px-1 assessment-pipe">|</span>
                                        </span>
                                    }
                                    {candidateData?.permanentAddress &&
                                        <span className="text-break">
                                                <i className="dx-icon-homeTownIcon dx-custom-icon mb-1 me-1 cursor-pointer" title="Permanent Address" onClick={() => handleNavigateToMap(candidateData?.permanentAddress)}></i>{" "}
                                                {candidateData?.permanentAddress}
                                            <span className="px-1 assessment-pipe">|</span>
                                        </span>
                                    }
                                    <span className=" ">
                                        {candidateData?.linkedIn ? <a
                                            href={`${candidateData?.linkedIn}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="dx-icon-LinkedInIcon dx-custom-icon"></i>
                                        </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon"></i>
                                        }
                                    </span>
                                    <span className=" px-1 assessment-pipe">|</span>
                                    <span className=" ">
                                        {candidateData?.skype ? <a
                                            href={`skype:${candidateData?.skype}?chat`}
                                            rel="noreferrer"
                                        >
                                            <i className="dx-icon-skypeIcon dx-custom-icon"></i>
                                        </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon"></i>
                                        }
                                    </span>
                                    <span className=" px-1 assessment-pipe">|</span>
                                    <span className="">
                                        {candidateData?.mobileNo2 ? <a
                                            href={`https://wa.me/91${candidateData?.mobileNo2}`}
                                            rel="noreferrer"
                                            target='_blank'
                                        >
                                            <i className="dx-icon-WhatsappIcon dx-custom-icon"></i>
                                        </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon"></i>
                                        }
                                    </span>
                                </div>
                                <div className="row mx-0 pt-1">


                                    {lastInteractionRoundID && (
                                        <div className="px-2 col-auto">
                                            <span className="font-semiBold-14">{lastRoundScheduleDate && lastInteractionStatus ? "Last Interaction Date & Result" : lastRoundScheduleDate ? "Last Interaction Date" : lastInteractionStatus ? "Last Interaction Result" : "" }</span>:{" "}
                                            <span className="font-regular-14 ms-1">
                                                {lastRoundScheduleDate ? GetFormattedDate(lastRoundScheduleDate) : ""}{lastInteractionStatus ? ",  " : "  "}
                                            </span>
                                            <span className="font-regular-14">
                                                {lastInteractionStatus}
                                            </span>
                                            {lastInteractionStatus == 'Pending' && lastRoundScheduleDateObject > currentDate && (
                                                <i className="dx-icon-ResendEmailIcon dx-custom-icon ms-1" title="Resend Email" onClick={resendInteractionScheduleEmail} />
                                            )}
                                            <i title={"View Assessment"} onClick={() => onAssessmentViewerButtonClick(lastCandidateInteractionID)} class="ms-1 dx-icon-assessmentIcon dx-custom-icon col-auto pe-0"></i>
                                        </div>
                                    )
                                    }
                                </div>
                                <hr className="mb-2" />
                                <div className="mt-4">
                                    <div
                                        className={`button-container`}
                                    >
                                        {dropdownItems.map((item, index) => (
                                            <Button
                                                icon={item?.icon}
                                                key={index}
                                                text={item?.name}
                                                className={`action-btn ${dropdownItems.length < 10 ? "single-button" : "double-button"
                                                    } mb-1`}
                                                width={"100%"}
                                                onClick={() => onActionButttonClick(item)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showFilePreview && (
                    <FilePreviewer
                        showFilePreview={showFilePreview}
                        selectedFile={selectedFile}
                        setShowFilePreviewLoad={setShowFilePreviewLoad}
                        onFilePreviewClose={onFilePreviewClose}
                        isImageOnly={true}
                    />
                )
            }
            {resendScheduleEmail && <EmailStatusPopup
                showEmailStatusPopup={resendScheduleEmail}
                HideEmailStatusPopup={() => setResendScheduleEmail(false)}
                candidateData={candidateData}
                candidateScheduleID={lastInteractionScheduleID} />
            }
        </>
    );
}

export default CandidateDetailPanel;
import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../components';
import { useScreenSize } from '../../utils/media-query';
import { ActiveCandidates, googleMeetingIcon, HiredStatusIcon, OpenPositionsIcon, skypeIcon, teamsIcon, UpcomingIcon, zoomIcon } from '../../utils/base-64-Icons';
import './Home.scss';
import DataGrid, { SearchPanel, Toolbar, Item, Scrolling, HeaderFilter, Column, Selection, Paging, LoadPanel } from 'devextreme-react/data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react';
import { HomeServices } from '../../api/services/HomeServices';
import { GetFormattedDate, GetFormattedDateTime, GetFormattedTime, parseCustomDateFormat, ShowAlert } from '../../utils/common-methods';
import HomeCardSkeleton from '../../layouts/templates/HomeCardSkeleton';
import HomeListSkeleton from '../../layouts/templates/HomeListSkeleton';

export default function Home() {

  const { isExLarge, isExSmall, isXXSmall, isXSmall, isSmall } = useScreenSize();

  const NavigateTo = useNavigate();

  const TodaysScheduleRef = useRef();
  const CurrentOpeningsGrid = useRef();
  const UpcomingScheduleRef = useRef();

  const { state , pathname} = useLocation();

  const {
    SelectedDashboardRowKey
  } = state || {};

  const [isTodaysScheduleExpanded, setIsTodaysScheduleExpanded] = useState(true);
  const [isUpcomingScheduleExpanded, setIsUpcomingScheduleExpanded] = useState(true);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [dsDashboardData, setDsDashboardData] = useState({});
  const [dsTodaysSchedules, setDsTodaysSchedules] = useState([]);
  const [dsUpcomingSchedules, setDsUpcomingSchedules] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [showGridSkeleton, setShowGridSkeleton] = useState(false);

  const homeServices = new HomeServices();

  useEffect(() => {
    window.history.replaceState(pathname, null);
    GetDashboardData()
  }, [])

  useEffect(() => {
    if (selectedRowKey?.length > 0) {
      if(!selectedRowKey[0]) return;
      GetSchedulesData(selectedRowKey[0]);
    }
  }, [selectedRowKey])


  const GetDashboardData = async () => {
    setShowSkeleton(true);
    const result = await homeServices.GetHomeList();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSkeleton(false);
    } else {
      const LeftListData = result?.data?.jobOpenings?.map((item) => {
        return {
          ...item,
          openingsCount :  item.openings ,
          openings :  item.openings ? item.openings > 1  ? item.openings + " Openings" : item.openings + " Opening" : "",
          requiredExperienceCount : item.requiredExperience, 
          requiredExperience : item.requiredExperience ? item.requiredExperience > 1  ? item.requiredExperience + " yrs of experience" : item.requiredExperience + " yr of experience" : "",
          hiredCandidatesCount : item.hiredCandidates ? item.hiredCandidates : 0 ,
          hiredCandidates : item.hiredCandidates ? item.hiredCandidates > 1  ? item.hiredCandidates + " Hired Candidates"  : item.hiredCandidates + " Hired Candidate" : 0 + " Hired Candidate",
          activeCandidatesCount : item.activeCandidates ? item.activeCandidates : 0 ,
          activeCandidates : item.activeCandidates ? item.activeCandidates > 1  ? item.activeCandidates + " Active Candidates"  : item.activeCandidates + " Active Candidate" : 0 + " Active Candidate",
          jobOpeningDate : item.jobOpeningDate ? GetFormattedDate(item.jobOpeningDate) : null,
          targetDate : item.targetDate ? GetFormattedDate(item.targetDate) : null,
        };
      });
      setSelectedRowKey(SelectedDashboardRowKey ? SelectedDashboardRowKey : [result?.data?.jobOpenings[0]?.jobOpeningID]);
      setDsDashboardData({...result.data , jobOpenings : LeftListData});
      setShowSkeleton(false);
    }
  }

  const GetSchedulesData = async (jobOpeningID) => {
    setShowGridSkeleton(true)
    const result = await homeServices.GetSchedules(jobOpeningID);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      setShowGridSkeleton(false)
    } else {
      const ListData = result?.data?.map((item) => {
        const ScheduleDateTime = item.scheduleDateTime ? GetFormattedDateTime(item.scheduleDateTime) : null;
        const ScheduleTime = item.scheduleDateTime ? GetFormattedTime(item.scheduleDateTime) : null;
        const ScheduleDateTimeOriginal = item.scheduleDateTime ? item.scheduleDateTime : null
        return {
          ...item,
          scheduleDateTime: ScheduleDateTime ? ScheduleDateTime : null,
          sortOrder : item.sortOrder ? "#" + item.sortOrder : null,
          ScheduleTime :ScheduleTime ? ScheduleTime : null,
          ScheduleDateTimeOriginal : ScheduleDateTimeOriginal
        };
      });
      let TodayScheduleList = [];
      let UpcomingScheduleList = [];
      const currentDate = new Date();
      const currentDateString = currentDate?.toISOString()?.split('T')[0];
    
      
      ListData.forEach((item) => {
        const itemDate = parseCustomDateFormat(item.scheduleDateTime);
        const itemDateString = itemDate?.toISOString()?.split('T')[0];
  
        if (itemDateString === currentDateString) {
          TodayScheduleList.push(item);
        } else if (itemDate > currentDate) {
          UpcomingScheduleList.push(item); 
        }
      });

      setDsTodaysSchedules(TodayScheduleList)
      setDsUpcomingSchedules(UpcomingScheduleList)
      setShowGridSkeleton(false)
    }
  }

  const NavigateToControlPanel = (e) => {
    NavigateTo("/JobOpeningControlPanel", {
      state: {
        jobOpeningID: e?.data?.jobOpeningID,
        openFrom: "/Dashboard",
        SelectedDashboardRowKey:selectedRowKey
      },
    }
    )
  }

  const CollapseTodayScheduleGrid = () => {
    TodaysScheduleRef?.current?.instance?.repaint();
    setIsTodaysScheduleExpanded(false)
    document.getElementById("TodaysScheduleGridSection").style.display = "none";
    document.getElementById("TodaysScheduleSection").style.display = "flex";
  }

  const ExpandTodaysSchedule = () => {
    TodaysScheduleRef?.current?.instance?.repaint();
    setIsTodaysScheduleExpanded(true)
    document.getElementById("TodaysScheduleSection").style.display = "none";
    document.getElementById("TodaysScheduleGridSection").style.display = "flex";
  }

  const CollapseUpcomingScheduleGrid = () => {
    UpcomingScheduleRef?.current?.instance?.repaint();
    setIsUpcomingScheduleExpanded(false)
    document.getElementById("UpcomingScheduleGridSection").style.display = "none";
    document.getElementById("UpcomingScheduleSection").style.display = "flex";
  }

  const ExpandUpcomingSchedule = () => {
    UpcomingScheduleRef?.current?.instance?.repaint();
    setIsUpcomingScheduleExpanded(true)
    document.getElementById("UpcomingScheduleSection").style.display = "none";
    document.getElementById("UpcomingScheduleGridSection").style.display = "flex";
  }


  let i = 1;

  const CurrentOpeningRowData = (e) => {
    i = (e?.rowIndex % 5) + 1;
    return (
      <React.Fragment>
         {
          showSkeleton ?
          <td title="" className='pt-0 CurrentOpening-Card-container'>
              <HomeCardSkeleton height={150}/>
          </td>
          :
          <td title="" className='pt-0 CurrentOpening-Card-container'>
          <div className={`ms-2 shadow-sm p-3 bg-white  rounded border Left-column-grid Current-Opening-Card-${i} ${selectedRowKey?.length > 0 && selectedRowKey[0] === e?.data?.jobOpeningID ? "Selected-Card shadow" : ""}`}>
            <div className='row py-1'>
              <div className='col-8  font-semiBold-18 text-decoration-underline text-break'><span className='cursor-pointer' title='Visit Control Panel' onClick={()=>NavigateToControlPanel(e)}>
                {e?.data?.jobProfileName}</span></div>
              <div className='col-4 d-flex justify-content-end text-end text-break'><i><span className='fw-semibold'>{e?.data?.openingsCount}</span>{e?.data?.openingsCount > 1  ?  " Openings" :  " Opening" }</i></div>
            </div>
            <div className='row py-1'>
              <div className='col-6'>
                <img src={ActiveCandidates} alt="Active" width={20} />&nbsp;<i><span className='fw-semibold text-break'>{e?.data?.activeCandidatesCount ?  e?.data?.activeCandidatesCount : 0}</span>{e?.data?.hiredCandidatesCount > 1  ?  " Active Candidates"  : " Active Candidate"} </i>
              </div>
              <div className='col-6 d-flex justify-content-end text-end text-break'><i><span title={`Opening Date`} className='fw-semibold'>{e?.data?.jobOpeningDate}</span>{e?.data?.targetDate ? " -"  : ""} <span title={`Target Date`} className='fw-semibold'>{e?.data?.targetDate}</span></i></div>
            </div>
            <div className='row py-1'>
              <div className='col-6'>
                <img src={HiredStatusIcon} alt="Hired" width={20} />&nbsp;<i><span className='fw-semibold text-break'>{e?.data?.hiredCandidatesCount ? e?.data?.hiredCandidatesCount : 0 }</span>{e?.data?.activeCandidatesCount > 1  ?  " Hired Candidates"  : " Hired Candidate"}</i>
              </div>
              <div className='col-6 d-flex justify-content-end text-end text-break'><i><span className='fw-semibold'>{e?.data?.requiredExperienceCount}</span>{e?.data?.requiredExperienceCount ?  e?.data?.requiredExperienceCount > 1 ?  " yrs of experience" :  " yr of experience"   : ""} </i></div>
            </div>
          </div>
      </td>
        }
      </React.Fragment>
    )
  }

  const onTodaysScheduleResetLayoutClick =()=>{
    TodaysScheduleRef.current?.instance.state(null);
    TodaysScheduleRef.current?.instance.clearFilter();
    TodaysScheduleRef.current?.instance.repaint();
  }

  const onUpcomingScheduleResetLayoutClick=()=>{
    UpcomingScheduleRef.current?.instance.state(null);
    UpcomingScheduleRef.current?.instance.clearFilter();
    UpcomingScheduleRef.current?.instance.repaint();
  }

  const MeetingLinkCellRender = (e) => {
    const meetingLink = e?.data?.meetingLink;
    return (
      <>
        {
          meetingLink && <a
            href={meetingLink}
            target="_blank"
            rel="noreferrer"
            className="join-meeting-section"
          >
            {meetingLink?.includes("meet") ? (
              <img src={googleMeetingIcon} alt="google meet" width={20} />
            ) : meetingLink?.includes("teams") ? (
              <img src={teamsIcon} alt="teams" width={20} />
            ) : meetingLink?.includes("skype") ? (
              <img src={skypeIcon} alt="skype" width={20} />
            ) : meetingLink?.includes("zoom") ? (
              <img src={zoomIcon} alt="zoom" width={24} />
            ) : (
              ""
            )}

            <span className="join-meeting">join meeting</span>
          </a>
        }
      </>
    )
  }

  const InteractionRoundRender = (e) => {
    return (
      <>
        <div>{e?.data?.interactionRoundName}</div>
        <div>{e?.data?.sortOrder}</div>
      </>
    )
  }

  const onSelectionChanged = (e) => {
    if(selectedRowKey?.length > 0 && e?.selectedRowKeys?.length > 0 ){
      if(selectedRowKey[0] !== e?.selectedRowKeys[0] )
        setSelectedRowKey(e?.selectedRowKeys);
    }
  }

  const onCellPreparedChangeColor = (e) => {
    if (e.rowType === "data" && e.column.dataField) {            
      const currentDateTime = new Date();
      const PreviousTime = e?.data?.ScheduleDateTimeOriginal ? new Date(e?.data?.ScheduleDateTimeOriginal) : null;
      if (PreviousTime && PreviousTime < currentDateTime) {
        e.cellElement.style.cssText = "color:red !important;"
      }

    }

  }

  return (
    <React.Fragment>

      <Header
        title={"Dashboard"}
        hideDefaultButtons={true}
        showUploadNewResume={true}
      />
      <div className='p-2 pe-3'>
        <div className='row'>
          <div className='col-md-4'>
            {
              showSkeleton ?
                <div>
                    <HomeCardSkeleton  height={100}/>
                </div>
                :
                <div className='shadow-sm p-3 card-Container rounded'>
                <div className='row'>
                  <div className='col-auto'>
                    <img src={OpenPositionsIcon} alt="open" width={54} />
                  </div>
                  <div className='col'>
                    <div className='row font-medium-22 d-flex justify-content-center'>{dsDashboardData?.openPositions}</div>
                    <div className={`row d-flex justify-content-center ${isExSmall || isXXSmall || isXSmall || isSmall ? "font-regular-14" : "font-regular-16"}`}>Open Positions</div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className='col-md-4 pt-2 pt-md-0'>
          {
              showSkeleton ?
                <div>
                    <HomeCardSkeleton  height={100}/>
                </div>
                :
                <div className='shadow-sm p-3 card-Container rounded '>
              <div className='row'>
                <div className='col-auto'>
                  <img src={UpcomingIcon} alt="open" width={50} />
                </div>
                <div className='col'>
                  <div className='row font-medium-22 d-flex justify-content-center'>{dsDashboardData?.upcomingInterviews}</div>
                  <div className={`row d-flex justify-content-center ${isExSmall || isXXSmall || isXSmall || isSmall ? "font-regular-14" : "font-regular-16"}`}>Upcoming Interviews</div>
                </div>
              </div>
                </div>
          }
          </div>
          <div className='col-md-4 pt-2 pt-md-0'>
          {
              showSkeleton ?
                <div>
                    <HomeCardSkeleton  height={100}/>
                </div>
                :
            <div className='shadow-sm p-3 card-Container rounded '>
              <div className='row'>
                <div className='col-auto'>
                  <img src={ActiveCandidates} alt="upcoming" width={54} />
                </div>
                <div className='col'>
                  <div className='row font-medium-22 d-flex justify-content-center'>{dsDashboardData?.activeCandidates}</div>
                  <div className={`row d-flex justify-content-center  ${isExSmall || isXXSmall || isXSmall || isSmall ? "font-regular-14" : "font-regular-16"}`}>Active Candidates</div>
                </div>
              </div>
            </div>
         } 
          </div>
        </div>
        <div className='row pt-3 pb-0'>
          <div className='col-md-5 col-lg-4 mb-3 mb-md-0 Current-Openings-Grid'>
            <DataGrid
              dataSource={dsDashboardData?.jobOpenings}
              showBorders={true}
              showColumnLines={false}
              showRowLines={false}
              focusedRowEnabled={true}
              wordWrapEnabled={true}
              hoverStateEnabled={true}
              height={"100%"}
              keyExpr={"jobOpeningID"}
              className='CurrentOpening-Grid'
              filterSyncEnabled={true}
              dataRowRender={CurrentOpeningRowData}
              selectedRowKeys={selectedRowKey}
              onSelectionChanged={onSelectionChanged}
              autoNavigateToFocusedRow={true}
              showColumnHeaders={false}
              noDataText='No Record Found'
              ref={CurrentOpeningsGrid}
            >
              <SearchPanel visible={true} width={isExLarge ? 230 : 150} />
              <Selection mode={"single"} />
              <Paging enabled={false}/>
              <LoadPanel enabled={false}/>
              <Toolbar>
                <Item location="before">
                  <div className='font-semiBold-18 text-truncate'>Current Openings</div>
                </Item>
                <Item location="after" name='searchPanel' locateInMenu='auto' visible={!showSkeleton}></Item>
              </Toolbar>
            </DataGrid>
          </div>
          <div className='col-md-7 col-lg-8'>
            <div className="custom-card-grid pt-2  border shadow-sm px-2" style={{ display: "none" }} id="TodaysScheduleSection">
              <div className='row w-100 gx-0 pb-1'>
                <div className="col font-semiBold-18">Today's Schedules</div>
                <div className='col-auto justify-content-end'>
                  <Button
                    icon="chevrondown"
                    hint="Expand Today's Schedules Section"
                    onClick={ExpandTodaysSchedule}
                    hoverStateEnabled={false}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    stylingMode='text'
                  />
                </div>
              </div>
            </div>
            <div className={`custom-card-grid py-2 shadow-sm border ${isUpcomingScheduleExpanded ? "Upcoming-Schedule-Grid-full" : "Upcoming-Schedule-Grid"}`} id="TodaysScheduleGridSection">
              {
              showGridSkeleton ? 
               <div className='w-100'>
                <HomeListSkeleton title={"Today's Schedules"} height={isUpcomingScheduleExpanded ?  "350px" : "650px"}/>
               </div>
               :
                <DataGrid
                keyExpr={"candidateInteractionScheduleID"}
                className="Schedules_DataGrid mx-2 mb-2"
                dataSource={dsTodaysSchedules}
                showColumnLines={false}
                showRowLines={true}
                height={"100%"}
                wordWrapEnabled={true}
                allowResizing={true}
                allowReordering={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                noDataText="No Record Found"
                autoNavigateToFocusedRow={true}
                ref={TodaysScheduleRef}
                onCellPrepared={onCellPreparedChangeColor}
              >
                <Toolbar>
                  <Item location="before">
                    <span className="font-semiBold-18">Today's Schedules</span>
                  </Item>
                  <Item locateInMenu="auto" location="after">
                    <Button
                      icon="pulldown"
                      type="default"
                      stylingMode="outlined"
                      hint="Reset Layout"
                      className='Schedule-Grid-Reset-Layout'
                      onClick={onTodaysScheduleResetLayoutClick}
                    />
                  </Item>
                  <Item name="searchPanel" locateInMenu="auto" />
                  <Item location="after" locateInMenu='auto'>
                    <Button
                      icon="chevronup"
                      hint="Collapse Today's Schedules Section"
                      onClick={CollapseTodayScheduleGrid}
                      hoverStateEnabled={false}
                      activeStateEnabled={false}
                      focusStateEnabled={false}
                      stylingMode='text'
                    />
                  </Item>
                </Toolbar>
                <Scrolling mode="virtual" />
                <SearchPanel visible={true} width={260} />
                <HeaderFilter visible={true} />
                <LoadPanel enabled={false}/>
                <Column
                  dataField="ScheduleTime"
                  caption='Schedule Time'
                  alignment="left"
                  width={140}
                  minWidth={80}
                />
                <Column
                  dataField="candidateName"
                  caption="Candidate Name"
                  width={150}
                  minWidth={120}
                />
                <Column
                  dataField="interactionRoundName"
                  cellRender={InteractionRoundRender}
                  caption='Interaction Round'
                  width={isSmall ? 120 : 170}
                  minWidth={isSmall ? 100 : 120}
                />
                <Column
                  dataField="meetingLink"
                  cellRender={MeetingLinkCellRender}
                  caption='Meeting Link'
                  minWidth={80}
                />
                <Column
                  dataField='sortOrder'
                  visible={false}
                  showInColumnChooser={false}
                />
              </DataGrid>
              }
            </div>
            <div className="custom-card-grid pt-2  border shadow-sm px-2 mt-3" style={{ display: "none" }} id="UpcomingScheduleSection">
              <div className='row w-100 gx-0 pb-1'>
                <div className="col font-semiBold-18">Upcoming Schedules</div>
                <div className='col-auto justify-content-end'>
                  <Button
                    icon="chevrondown"
                    hint="Expand Upcoming Schedules Section"
                    onClick={ExpandUpcomingSchedule}
                    hoverStateEnabled={false}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    stylingMode='text'
                  />
                </div>
              </div>
            </div>
            <div className={`custom-card-grid border mt-3 py-2 shadow-sm ${isTodaysScheduleExpanded ? "Upcoming-Schedule-Grid-full" : "Upcoming-Schedule-Grid"}`} id="UpcomingScheduleGridSection">
            {
              showGridSkeleton ? 
              <div className='w-100'>
                <HomeListSkeleton title={"Upcoming Schedules"} height={isTodaysScheduleExpanded ?  "350px" : "650px"}/>
              </div>
               :
              <DataGrid
                keyExpr={"candidateInteractionScheduleID"}
                className="Schedules_DataGrid mx-2 mb-2"
                dataSource={dsUpcomingSchedules}
                showColumnLines={false}
                showRowLines={true}
                height={"100%"}
                wordWrapEnabled={true}
                allowResizing={true}
                allowReordering={true}
                hoverStateEnabled={true}
                focusedRowEnabled={true}
                noDataText="No Record Found"
                autoNavigateToFocusedRow={true}
                ref={UpcomingScheduleRef}
              >
                <Toolbar>
                  <Item location="before">
                    <span className="font-semiBold-18">Upcoming Schedules</span>
                  </Item>
                  <Item locateInMenu="auto" location="after">
                    <Button
                      icon="pulldown"
                      type="default"
                      stylingMode="outlined"
                      hint="Reset Layout"
                      className='Schedule-Grid-Reset-Layout'
                      onClick={onUpcomingScheduleResetLayoutClick}
                    />
                  </Item>
                  <Item name="searchPanel" location="after" locateInMenu="auto" />
                  <Item location="after" locateInMenu="auto">
                    <Button
                      icon="chevronup"
                      hint="Collapse Upcoming Schedules Section"
                      onClick={CollapseUpcomingScheduleGrid}
                      hoverStateEnabled={false}
                      activeStateEnabled={false}
                      focusStateEnabled={false}
                      stylingMode='text'
                    />
                  </Item>
                </Toolbar>
                <Scrolling mode="virtual" />
                <SearchPanel visible={true} width={260} />
                <HeaderFilter visible={true} />
                <Scrolling mode="virtual" />
                <LoadPanel enabled={false}/>
                <Column
                  dataField="scheduleDateTime"
                  alignment="left"
                  width={200}
                  minWidth={150}
                  caption='Schedule Date & Time'
                />
                <Column
                  dataField="candidateName"
                  width={220}
                  minWidth={80}
                  caption='Candidate Name'
                />
                <Column
                  dataField="interactionRoundName"
                  cellRender={InteractionRoundRender}
                  caption='Interaction Round'
                  width={220}
                  minWidth={100}
                />
                <Column
                  dataField='sortOrder'
                  visible={false}
                  showInColumnChooser={false}
                />
              </DataGrid>
              }
            </div>
          </div>
        </div>
      </div>


    </React.Fragment>
  )
}

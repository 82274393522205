import React from 'react';
import './ListSkeleton.scss';
import { Height } from 'devextreme-react/chart';

const HomeCardSkeleton = (props) => {
  return (
    <div className='px-2 '>
        <div className="custom-skeleton-card" style={{height: props.height ? props.height : "100px"}}></div>
    </div>
  )
}

export default HomeCardSkeleton

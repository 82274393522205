import { Button, DateBox, NumberBox, Popup, ScrollView, SelectBox, Validator } from "devextreme-react";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import React, { useEffect, useRef, useState } from "react";
import StarRating, { onKeyDown_Withpoint, popupAnimation, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RequiredRule } from "devextreme-react/validator";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import CandidateCard from "./CandidateCard";
import JobApplicationEmailStatusPopup from "./JobApplicationEmailStatusPopup";

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();

const RejectAndRevokeOfferPopup = (props) => {
  const ValidationGroupName = "assessmentPopupValidation"
  const [offerDetails, setOfferDetails] = useState();
  const [offerLetterFile, setOfferLetterFile] = useState(null);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [offeredCTCMonthlyValue, setOfferedCTCMonthlyValue] = useState();

  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall, isExLarge } = useScreenSize();
  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const htmlEditorNoteRef = useRef(null);

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const onCloseEmailPopup = () => {
    setShowEmailPopup(false);
    props.HideAssessmentPopup();
  }

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={props.actionPopupName == "RevokeOffer" ? "Revoke Offer" : "Reject Offer"}
          onSubmit={onSubmit}
          ValidationGroupName={ValidationGroupName}
        />
      </>
    );
  };

  const GetOfferLetterFile = async () => {
    const result = await jobOpeningControlPanelServices.GetOfferLetterFile(props?.jobApplicationID);
    if (result.isOk) {
      setOfferLetterFile(result.data);
    }
  };

  const GetOfferDetails = async () => {
    const result = await jobOpeningControlPanelServices.GetOfferDetails(
      props?.jobApplicationID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      // setShowSkeleton(false);
    } else {
      setOfferDetails(result.data);
      if (result.data?.offeredCTCPA != null) {
        setOfferedCTCMonthlyValue((result.data?.offeredCTCPA * 100000) / 12);
      }
      GetOfferLetterFile();
    }
  };

  useEffect(() => {
    if (props?.jobApplicationID) {
      GetOfferDetails()
    }
  }, [])


  const onSubmit = async () => {
    const dataToUpdate = {
      updatedOn: props?.jobApplicationUpdatedOn || null,
      jobApplicationID: props?.jobApplicationID,
      descrPlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
      descrFormattedText: htmlEditorNoteRef.current?.instance.option("value")
    };

    // Determine the action to perform based on props.actionName
    let actionToPerform;

    if (props.actionPopupName === 'OfferRejectedByCandidate') {
      actionToPerform = jobOpeningControlPanelServices.OfferRejectedByCandidate;
    }
    else if (props.actionPopupName === 'RevokeOffer') {
      actionToPerform = jobOpeningControlPanelServices.RevokeOffer;
    }

    if (actionToPerform) {
      try {
        const result = await actionToPerform(dataToUpdate);
        if (result.isOk) {
          // if (props.actionPopupName === 'RevokeOffer') {
          //   setShowEmailPopup(true);
          // } else {
          //   onClosePopup();
          // }
          setShowEmailPopup(true);
          props?.GetApplication();
          props?.GetHeader();
        } else {
          ShowAlert(result.data, "Vakency");
        }
      } catch (error) {
        ShowAlert("An error occurred while processing the action.", "Vakency");
      }
    } else {
      ShowAlert("Invalid action. Please try again.", "Vakency");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  }

  function openOfferLetter() {
    const file = new Blob([offerLetterFile], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
    // const link = document.createElement("a");
    // link.href = fileURL;
    // link.target = '_blank';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // window.URL.revokeObjectURL(fileURL);
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95vw"
            : isMedium || isSmall
              ? "70vw"
              : "50vw"
        }
        height={"auto"}
        maxHeight={"92vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        animation={popupAnimation}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form onSubmit={handleSubmit}>
            <div className="row pt-2 px-1 mb-1">
              <div className="">
                <CandidateCard
                  candidateRating={props?.candidateRating}
                  candidateApplicationStatus={props?.candidateApplicationStatus}
                  candidateId={props?.candidateId}
                  PopupType={props.actionPopupName}
                  jobProfileName={props?.jobProfileName}
                  lastRoundScheduleDate={props?.lastRoundScheduleDate}
                  dsInteractionRounds={props.dsInteractionRounds}
                  lastInteractionRoundName={props.lastInteractionRoundName}
                />
              </div>
            </div>

            <div className="row px-3 my-3">
              <div className="col-xxl col-xl col-lg">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                    Offered CTC:
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Yearly"
                      labelMode="floating"
                      maxLength={15}
                      min={0}
                      format="##.##"
                      value={offerDetails?.offeredCTCPA && offerDetails?.offeredCTCPA.toFixed(2)}
                      readOnly={true}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="offered_ctc_yearly"
                        options={{
                          text: 'LPA',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                    </NumberBox>
                  </div>
                  <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                  <div className="col">
                    <NumberBox
                      label="Monthly"
                      labelMode="floating"
                      value={offeredCTCMonthlyValue && offeredCTCMonthlyValue.toFixed(2)}
                      maxLength={15}
                      format="##.##"
                      min={0}
                      readOnly={true}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="accepted_ctc_monthly"
                        options={{
                          text: 'month',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                    </NumberBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-3 my-3">
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <DateBox
                  id="JobOpening_ExpectedJoiningDate"
                  labelMode="floating"
                  label="Expected Joining Date"
                  value={offerDetails?.expectedJoiningDate}
                  type="date"
                  displayFormat="dd/MM/yyyy"
                  readOnly={true}
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Expected Joining Date is required" />
                  </Validator>
                </DateBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <div className="mt-2 px-2 border border-1 rounded-2 d-flex align-items-center" style={{ height: '35px' }}>
                  <div className="text-black-50">{"Offer Letter -"}</div>
                  <span className="font-medium-14 text-decoration-underline text-center cursor-pointer" onClick={openOfferLetter}>
                    View Offer Letter
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="assessment-html-editor-section px-4">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  // height={isExLarge ? "670px" :  "790px"
                  //     // : "1100px"
                  // }
                  width={"auto"}
                  height={"300px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Overall Comments"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="d-flex justify-content-end py-2 pe-3">
              <Button
                validationGroup={ValidationGroupName}
                text="Submit"
                type="default"
                height={33}
                width={120}
                className="rounded-3"
                useSubmitBehavior={true}
              />
            </div>
          </form>
        </ScrollView>
        {
          showEmailPopup && (
            <JobApplicationEmailStatusPopup
              actionName={props.actionPopupName}
              jobApplicationID={props?.jobApplicationID}
              showEmailPopup={showEmailPopup}
              onClosePopup={onCloseEmailPopup}
              candidateData={props.candidateData}
            />
          )
        }
      </Popup>
    </>
  );
};

export default RejectAndRevokeOfferPopup;

import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components';
import DataGrid, { Column, ColumnChooser, Button as DataGridButton, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager, Scrolling, SearchPanel, Sorting, Toolbar, StateStoring, Selection, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../../utils/media-query';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import AcademicQualificationPopup from './AcademicQualificationPopup';
import { confirm } from 'devextreme/ui/dialog';
import { AcademicQualificationServices } from '../../../api/services/AcademicQualificationServices';

const academicQualificationServices = new AcademicQualificationServices();
export default function AcademicQualificationList() {


    const { isExSmall, isXXSmall } = useScreenSize();

    const [filterVisible, setFilterVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [showPopup, setShowPopup] = useState(false)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [listData, setListdata] = useState([])
    const [focusedRowKey, setfocusedRowKey] = useState(0);
    const [status, setStatus] = useState({
        eStatus: eCRUDStatus.None,
        primaryKeyID: primaryKey,
    });

    const GetList = async () => {
        // setShowSkeleton(true)
        const result = await academicQualificationServices.GetListData();
        if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Vakency")
            setShowSkeleton(false);
        }
        else {
            const AQData = result.data?.map(item => {
                const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
                const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
                return {
                  ...item,
                  createdOn: createdOn ? createdOn: null,
                  updatedOn: updatedOn ? updatedOn : null
                };
              });
            setListdata(AQData);
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        GetList();
    }, [])


    useEffect(() => {
        if (status.eStatus !== eCRUDStatus.None) {
            const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
            UpdateListByID(PrimaryKeyID);
        }
    }, [status])

    const UpdateListByID = async (primaryKey) => {
        const result = await academicQualificationServices.GetListByIdData(primaryKey);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency")
        } else {
            const AQData = result.data?.map(item => {
                const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
                const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
                return {
                  ...item,
                  createdOn: createdOn ? createdOn: null,
                  updatedOn: updatedOn ? updatedOn : null
                };
              });
            if (status.eStatus === eCRUDStatus.Inserted) {
                setListdata([AQData[0], ...listData]);
            } else if (status.eStatus === eCRUDStatus.Updated) {

                setListdata(listData.map((i) => i.academicQualificationID === primaryKey ? AQData[0] : i));
            }
        }
        setfocusedRowKey(primaryKey);
        setPrimaryKey(0);
    };

    const onOptionChange = (e) => {
        if (e.fullName === "filterValue") {
            setFilterVisible(!!e.value);
        }
    };

    const handleAdd = () => {
        setPrimaryKey(null)
        setShowPopup(true)
    }

    const handleEdit = (e) => {
        setPrimaryKey(e.data.academicQualificationID)
        setShowPopup(true)
    }

    const onDeleteButtonClick = (e) => {
        let res = confirm(
            `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
            "Vakency"
        );
        res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
    };

    const handleDelete = async (e) => {
        const Id = e.row.data.academicQualificationID;
        const result = await academicQualificationServices.DeleteData(Id);
        if (result.statusCode === 400) {
            ShowAlert(result.data, "Vakency")
        }
        else if (result.statusCode === 409) {
            // Reload Popup
        }
        else if (result.statusCode === 200) {
            setListdata(listData.filter((x) => x.academicQualificationID !== e.row.data.academicQualificationID));
        }
    }

    const onRowDbClick = (e) => {
        setPrimaryKey(e.data.academicQualificationID)
        setShowPopup(true)
    }

    const DataGridRef = useRef(null);


    const QualificationName = (cellData) => {
        return <>
            <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.academicQualificationName}</span></div>
        </>
    }

    function onFocusedRowChanged(e) {
        setfocusedRowKey(e.component.option("focusedRowKey"));
    }



    return (
        <div>
            <div className='Academic_Qualification' onDragOver={onDragOver}>
                <Header
                    title={"Academic Qualifications"}
                    handleAdd={handleAdd}
                    dataGridRef={DataGridRef}
                    GetRecord={GetList}
                />
                <div className='list-section'>
                    {
                        showSkeleton ?
                            <ListSkeleton />
                            :
                            <DataGrid
                                ref={DataGridRef}
                                dataSource={listData}
                                showBorders={true}
                                showColumnLines={false}
                                showRowLines={true}
                                focusedRowEnabled={true}
                                wordWrapEnabled={true}
                                hoverStateEnabled={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                autoNavigateToFocusedRow={true}
                                keyExpr="academicQualificationID"
                                height={'100%'}
                                width={"100%"}
                                filterSyncEnabled={true}
                                onOptionChanged={onOptionChange}
                                className='List_DataGrid'
                                loadPanel={HideDatagridLoader}
                                onRowDblClick={onRowDbClick}
                                focusedRowKey={focusedRowKey}
                                noDataText='No Record Found'
                                onFocusedRowChanged={onFocusedRowChanged}
                            >
                                <Sorting mode={"multiple"} />
                                <FilterPanel visible={filterVisible} />
                                <FilterBuilderPopup visible={false} />
                                <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true} />
                                <ColumnChooser enabled={true}>
                                    <ColumnChooserSearch
                                        enabled={true}
                                    />
                                </ColumnChooser>
                                <HeaderFilter visible={true} >
                                    <Search
                                        enabled={true}
                                    />
                                </HeaderFilter>
                                <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                                <StateStoring enabled={true} type="localStorage" storageKey="AcademicQualification_Layout" />
                                <Pager
                                    visible={true}
                                    showInfo={true}
                                    infoText={`{2} Rows`}
                                ></Pager>
                                <Toolbar>
                                    <Item location={'after'} name="columnChooserButton" />
                                    <Item location={'after'} name="searchPanel" />
                                </Toolbar>
                                <Column caption='Qualification' cellRender={QualificationName} dataField='academicQualificationName' minWidth={140} width={230} allowHiding={false} />
                                <Column caption='Certificate Type' dataField='certificateTypeName' minWidth={200} />
                                <Column
                                    dataField="createdByUserName"
                                    caption="Created By"
                                    visible={false}
                                    width={125}
                                    minWidth={80}
                                    alignment="left"
                                />

                                <Column
                                    dataField="updatedByUserName"
                                    caption="Updated By"
                                    visible={false}
                                    width={125}
                                    minWidth={80}
                                    alignment="left"
                                />
                                <Column
                                    dataField="createdOn"
                                    caption="Created On"
                                    visible={false}
                                    width={135}
                                    minWidth={80}
                                    alignment="left"
                                />
                                <Column
                                    dataField="updatedOn"
                                    caption="Updated On"
                                    visible={false}
                                    width={137}
                                    minWidth={80}
                                    alignment="left"
                                />
                                <Column type='buttons' fixed={false} allowResizing={false} width={43} allowReordering={false}>
                                    <DataGridButton
                                        name='delete'
                                        icon='trash'
                                        hint='Delete'
                                        visible={true}
                                        cssClass='text-danger'
                                        onClick={onDeleteButtonClick}
                                    />
                                </Column>
                            </DataGrid>
                    }
                </div>
                {showPopup === true &&
                    <AcademicQualificationPopup
                        openPopup={showPopup}
                        setOpenPopup={setShowPopup}
                        primaryKey={primaryKey}
                        setStatus={setStatus}
                    />
                }
            </div>
        </div>
    )
}

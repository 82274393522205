import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/header/Header";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Button as DataGridButton,
  ColumnChooser,
  FilterBuilderPopup,
  FilterPanel,
  HeaderFilter,
  Item,
  Pager,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
  Toolbar,
  ColumnChooserSearch,
  Search,
} from "devextreme-react/data-grid";
import { useScreenSize } from "../../../utils/media-query";
import ListSkeleton from "../../../layouts/templates/ListSkeleton";
import {
  GetFormattedDateTimeUTCString,
  HideDatagridLoader,
  ShowAlert,
  eCRUDStatus,
  onDragOver,
} from "../../../utils/common-methods";
import AttitudeFactorPopup from "./AttitudeFactorPopup";
import { confirm } from "devextreme/ui/dialog";
import { AtitudeFactorServices } from "../../../api/services/AtitudeFactorServices";

const atitudeFactorServices = new AtitudeFactorServices();
export default function AttitudeFactorList() {
  const { isExSmall, isXXSmall } = useScreenSize();
  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsAttitudeFactor, setDsAttitudeFactor] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [primaryKey, setPrimaryKey] = useState(null);
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });

  const GetRecord = async () => {
   
    const result = await atitudeFactorServices.GetListData();

    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
      setShowSkeleton(false);
    } else {
      const AttitudeData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsAttitudeFactor(AttitudeData);
      setShowSkeleton(false);
    }
  };

  useEffect(() => {
    GetRecord();
  }, []);

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID =
        status.eStatus === eCRUDStatus.Inserted
          ? status.primaryKeyID
          : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status]);

  const UpdateListByID = async (primaryKey) => {
    const result = await atitudeFactorServices.GetListByIdData(primaryKey);

    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const AttitudeData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
        const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsAttitudeFactor([AttitudeData[0], ...dsAttitudeFactor]);
      } else if (status.eStatus === eCRUDStatus.Updated) {

        setDsAttitudeFactor(
          dsAttitudeFactor.map((i) =>
            i.attitudeID === primaryKey ? AttitudeData[0] : i
          )
        );
      }
    }

    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(null);
    setShowPopup(true);
  };

  const onEditClick = (e) => {
    setPrimaryKey(e.data.attitudeID);
    setShowPopup(true);
  };

  const DataGridRef = useRef(null);

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.attitudeID);
    setShowPopup(true);
  };

  const AttitudeName = (cellData) => {
    return (
      <>
        <div>
          <span
            className="text-decoration-underline pointer-link text-break"
            title='Edit'
            onClick={(e) => onEditClick(cellData)}
          >
            {cellData.data.attitudeName}
          </span>
        </div>
      </>
    );
  };

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
  );
    res.then((dialogResult) => (dialogResult ? DeleteRecord(e) : ""));
  };

  const DeleteRecord = async (e) => {
    const recordID = e.row.data.attitudeID;
    const result = await atitudeFactorServices.DeleteData(recordID);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency");
    } else if (result.statusCode === 200) {
      setDsAttitudeFactor(
        dsAttitudeFactor.filter((x) => x.attitudeID !== e.row.data.attitudeID)
      );
    }
  };

  function onFocusedRowChanged(e) {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <>
      <Header
        title={"Attitude Factors"}
        handleAdd={handleAdd}
        dataGridRef={DataGridRef}
        GetRecord={GetRecord}
      />
      <div className="list-section" onDragOver={onDragOver}>
        {showSkeleton ? (
          <ListSkeleton />
        ) : (
          <DataGrid
            ref={DataGridRef}
            dataSource={dsAttitudeFactor}
            showBorders={true}
            showColumnLines={false}
            showRowLines={true}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            autoNavigateToFocusedRow={true}
            key={"attitudeID"}
            keyExpr="attitudeID"
            height={"100%"}
            width={"100%"}
            filterSyncEnabled={true}
            onOptionChanged={onOptionChange}
            className="List_DataGrid"
            loadPanel={HideDatagridLoader}
            onRowDblClick={onRowDbClick}
            focusedRowKey={focusedRowKey}
            onFocusedRowChanged={onFocusedRowChanged}
            noDataText='No Record Found'
          >
            <Sorting mode={"multiple"} />
            <FilterPanel visible={filterVisible} />
            <FilterBuilderPopup visible={false} />
            <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true} />
            <ColumnChooser enabled={true}>
              <ColumnChooserSearch
                enabled={true}
              />
            </ColumnChooser>
            <HeaderFilter visible={true} >
              <Search
                enabled={true}
              />
            </HeaderFilter>
            <SearchPanel
              visible={true}
              width={isExSmall || isXXSmall ? 200 : 400}
            />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey="AttitudeFactor_Layout"
            />
            <Pager visible={true} showInfo={true} infoText={`{2} Rows`}></Pager>
            <Toolbar>
              <Item location={"after"} name="columnChooserButton" />
              <Item location={"after"} name="searchPanel" />
            </Toolbar>
            <Column
              caption="Attitude"
              dataField="attitudeName"
              cellRender={AttitudeName}
              minWidth={100}
              alignment="left"
              allowHiding={false}
            />
            <Column
              dataField="createdByUserName"
              caption="Created By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />

            <Column
              dataField="updatedByUserName"
              caption="Updated By"
              visible={false}
              width={125}
              minWidth={80}
              alignment="left"
            />
            <Column
              dataField="createdOn"
              caption="Created On"
              visible={false}
              width={150}
              minWidth={80}
              alignment="left"
         
            />
            <Column
              dataField="updatedOn"
              caption="Updated On"
              visible={false}
              width={150}
              minWidth={80}
              alignment="left"
            
            />
            <Column
              type="buttons"
              fixed={false}
              allowResizing={false}
              width={43}
              allowReordering={false}
            >
              <DataGridButton
                name="delete"
                icon="trash"
                hint="Delete"
                visible={true}
                cssClass="text-danger"
                onClick={onDeleteButtonClick}
              />
            </Column>
          </DataGrid>
        )}
      </div>

      {showPopup === true && (
        <AttitudeFactorPopup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          primaryKey={primaryKey}
          setStatus={setStatus}
        />
      )}
    </>
  );
}

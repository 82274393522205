import { ForgotPasswordControlName, RegistrationControlName } from '../contexts/APIurl';
import defaultUser from '../utils/default-user';

const baseURL = process.env.REACT_APP_BASEURL;

export async function signIn(LoginData) {

  try {
    const loginOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(LoginData)
    };

    let data = await fetch(`${baseURL}UserManagement/AuthenticateUser`, loginOptions);

    if (data.status === 401) {
      return {
        isOk: false,
        message: "Incorrect Username or Password",
      };
    }

    let loginResponseData = await data.json();


    localStorage.setItem("authToken", loginResponseData.authToken);
    localStorage.setItem("userEmail", LoginData.emailid);
    let userData = await getUser();

    return {
      isOk: true,
      data: userData.data,
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}


export async function getUser() {
  try {
    // Send request
    const userEmail = localStorage.getItem("userEmail")
    if (userEmail === null) {
      return {
        // isOk: true,
        isOk: false,
      };
    }
    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(RegistrationData) {

  try {
    const RegistrationOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(RegistrationData)
    };

    const data = await fetch(baseURL + RegistrationControlName + "/Post", RegistrationOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.json(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function send_Email_Verification(email) {
  try {
    const EmailVerificationOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = await fetch(baseURL + RegistrationControlName + `/SendVerificationEmail?EmailID=${email}`, EmailVerificationOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Send Verification Email"
    }
  }
}

export async function ValidateEmail(email) {
  try {
    const EmailVerificationOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const data = await fetch(baseURL + RegistrationControlName + `/ValidateEmailID?EmailID=${email}`, EmailVerificationOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Validate Email"
    }
  }
}

export async function validateOTP(email, otp) {
  try {
    const ValidateOtpOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = await fetch(baseURL + RegistrationControlName + `/ValidateOTP?EmailID=${email}&OTP=${otp}`, ValidateOtpOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed Validate OTP"
    }
  }
}

export async function forgotPassword(email) {
  try {
    const ForgotPasswordOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = await fetch(baseURL + ForgotPasswordControlName + `/SendResetPasswordCode?EmailID=${email}`, ForgotPasswordOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Send Request Reset Link"
    }
  }
}


export async function validateResetPasswordCode(code) {
  try {
    const ResetPasswordOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    const data = await fetch(baseURL + ForgotPasswordControlName + `/ValidateResetPasswordCode/${code}`, ResetPasswordOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to Validate Reset Password Code"
    }
  }
}


export async function resetPassword(DataObj) {
  try {
    const ResetPasswordOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(DataObj)
    };

    const data = await fetch(baseURL + ForgotPasswordControlName + `/ResetPassword`, ResetPasswordOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data.text(),
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

export async function checkDuplicateMobileNoData(params) {
  try {
    var checkUrl = `${baseURL}${RegistrationControlName}/CheckDuplicateMobileNo?${params}`
    const CheckDuplicationOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const data = await fetch(checkUrl, CheckDuplicationOptions);
    if (data.status === 200) {
      return {
        isOk: true,
        data: await data,
        statusCode: data.status
      };
    } else {
      return {
        isOk: false,
        data: await data.text(),
        statusCode: data.status,
      };
    }

  } catch {
    return {
      isOk: false,
      message: "Failed To check duplication",
    };
  }
}
import { Button, FileUploader, Popup } from 'devextreme-react';
import React, { useRef, useState } from 'react';
import PopupHeader from '../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../layouts/popup-header-footer/PopupFooter';

const ValidationGroupName = "AddImageValidation";
const maxImageSizeLength = 3 * 1024 * 1024;

const AddImagePopup = ({
    isOpen,
    onClose,
    htmlEditorRef
}) => {

    const imageUploaderRef = useRef(null);

    const [imgFile, setImgFile] = useState(null);

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClose}
                    title={"Add Image"}
                    hideSaveButton={true}
                />
            </>
        );
    };

    const onValueChanged = (e) => {
        const file = e[0];
        if (file) {
            if (
                file["type"]?.split("/")[0] !== "image" ||
                file?.type === "image/avif"
            ) {
                alert(
                    `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning pe-0 fs-2" > </i><b class="alertText col">This file is not accepted.</b></div>`,
                    "Vakency"
                );
                imageUploaderRef?.current?.instance.reset();
                return;
            }
            if (file?.size > maxImageSizeLength) {
                alert(
                    `<div class="row align-items-center"><i class="dx-icon-warning alerticon col-auto text-warning fs-2 pe-0"> </i><b class="alertText col">Image of more than 10 MB size cannot be uploaded.</b></div>`,
                    "Vakency"
                );
                imageUploaderRef?.current?.instance.reset();
                return;
            }

            if (file["type"]?.split("/")[0] === "image") {
                // setImgFile(file);
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setImgFile(fileReader.result);
                }
                fileReader.readAsDataURL(file);
            }
        }
    };

    const onClickClearFile = () => {
        imageUploaderRef.current?.instance.reset();
        setImgFile(null);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        htmlEditorRef.current.instance.insertEmbed(0,'extendedImage', {
            src: imgFile,
            title: 'My Image',
        });
        onClickClearFile();
        onClose();
    }

    return (
        <>
            <Popup
                visible={isOpen}
                onHiding={onClose}
                height={'auto'}
                titleRender={PopupTitle}
                deferRendering={false}
                maxHeight={'90vh'}
                wrapperAttr={{ class: "CustomPopup" }}
                animation={{
                    show: {
                        type: "slideIn",
                        direction: "top",
                    },
                    hide: {
                        type: "slideOut",
                        direction: "top",
                        duration: 200,
                    },
                }}
                width={500}
            >
                <form onSubmit={handleSubmit}>
                    <div className="px-3">
                        <div className="resumeUpload my-2 d-flex border rounded align-items-center">
                            <FileUploader
                                ref={imageUploaderRef}
                                accept="image/*"
                                name="image"
                                multiple={false}
                                uploadMode={"useForm"}
                                selectButtonText="Browse"
                                allowCanceling={false}
                                labelText=""
                                hoverStateEnabled={false}
                                activeStateEnabled={false}
                                focusStateEnabled={false}
                                uploadedMessage="Image Uploaded"
                                readyToUploadMessage=""
                                className=""
                                inputAttr={{ 'aria-label': 'Browse' }}
                                onValueChange={onValueChanged}
                                // value={imgFile ? [imgFile] : []}
                            />
                            {imgFile && (
                                <>
                                    <Button
                                        icon="close"
                                        id="Close"
                                        onClick={onClickClearFile}
                                        stylingMode="text"
                                        type="default"
                                        hint="Close"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <PopupFooter ValidationGroupName={ValidationGroupName} />
                </form>
            </Popup>
        </>
    );
}

export default AddImagePopup;
import { Button, DataGrid, ScrollView } from "devextreme-react";
import React, { useEffect, useState } from "react";
import "./ShortListed.scss";
import { JobApplicationHistoryServices } from "../../api/services/JobApplicationHistory";
import { GetFormattedDateTimeUTCString, GetTimeAgoFormatted, ShowAlert } from "../../utils/common-methods";
import { Paging } from "devextreme-react/data-grid";
import { useScreenSize } from "../../utils/media-query";
import { BlankProfile } from "../../utils/base-64-Icons";

const jobApplicationHistoryServices = new JobApplicationHistoryServices();

const RightPanel = ({
  showRightSection,
  selectedRowKeyDetail
}) => {

  const [dsTimeLine, setDsTimeline] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const {
    isSmall,
    isXSmall,
    isExSmall,
    isXXSmall,
    isMedium,
    isLarge,
    isExLarge,
  } = useScreenSize();

  useEffect(() => {
    setDsTimeline([]);
    if (selectedRowKeyDetail?.jobApplicationID) {
      GetList();
    }
  }, [selectedRowKeyDetail]);

  const GetList = async () => {
    const result = await jobApplicationHistoryServices.GetJobApplicationHistory(selectedRowKeyDetail?.jobApplicationID);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      }
      setShowSkeleton(false);
    } else {
      setDsTimeline(result.data);
    }
  }

  const GetInitials = (name) => {
    const Name = name?.split(' ');
    const firstLetter = [Name[0]]?.map(word => word[0]?.toUpperCase());
    const lastLetter = [Name[Name.length - 1]]?.map(word => word[0]?.toUpperCase());
    return firstLetter + lastLetter
  }

  const TimeLineRowRender = (e) => {
    return (
      <>
        <tr>
          <td className="text-center p-0 ">
            <span className="timeline-arrowup-icon">&#8593;</span>
          </td>
          {e.data.createdByUserName ? 
          <td className="d-flex pt-1 mt-1 p-0">
            <div className="timeline-lable ">{e.data.createdByUserName ? GetInitials(e.data.createdByUserName) : ''}</div>
          </td>
           :
           <td className="d-flex pt-1 mt-1 p-0">
               <img
                   src={BlankProfile}
                   width={30}
                   height={30}
                   alt="candidate"
                   className="candidate-image"
                   style={{ borderRadius: "50% "}}
               />
          </td>
          } 
          <td colSpan={7}>
            <div>
              <div className="pb-1">
                <span className="uploaded-by">
                  <i>Application Status</i>{" "}
                </span>
                <span className="font-semiBold-14" title={e.data.jobApplicationStatusName}>{e.data.jobApplicationStatusName}</span>{" "}
              </div>
              {e.data.createdByUserName &&  <div className="pb-1">
                <span className="uploaded-by">
                  <i>Created By</i>{" "}
                </span>
                <span className="font-semiBold-14" title={e.data.createdByUserName}>{e.data.createdByUserName}</span>{" "}
              </div> }
              <div className="pb-1">
                <span className="uploaded-by " title={GetFormattedDateTimeUTCString(e.data?.createdOn)}>
                  <i>{GetTimeAgoFormatted(GetFormattedDateTimeUTCString(e.data?.createdOn))}</i>
                </span>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <div className="side-panel-height d-flex justify-content-end">
        <div
          className={`  ${showRightSection
            ? "right-section-expand"
            : "right-section-collapse"
            }`}
        >
          <div className={!showRightSection ? "d-none" : undefined}>
            <div className="timeline-header">Timeline</div>
            <div
              className={`pt-2 ${!isSmall && !isXSmall && !isXXSmall && !isExSmall && !isMedium
                ? "right-grid-section"
                : "right-grid-section-small-screen"
                }`}>
              <ScrollView>
                <DataGrid
                  dataSource={dsTimeLine}
                  dataRowRender={TimeLineRowRender}
                  showColumnHeaders={false}
                  showColumnLines={false}
                  height={"100%"}
                  wordWrapEnabled={true}
                >
                  <Paging enabled={false} />
                </DataGrid>
              </ScrollView>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default RightPanel;

import { CountryList, HomePage, UserList, JobOpeningList, JobProfileList, AcademicQualificationList, CandidateSourceList, AttitudeFactorList, InteractionRoundList, CandidateList, CandidateEditor, JobApplicationEditor, InteractionList, InteractionEditor, ScheduleList, ScheduleEditor, SkillList, JobApplicationList, UploadNewResume, ShortListed, CityList, CompanyProfile } from './pages';

import { withNavigationWatcher } from './contexts/navigation';
import JobOpeningControlPanel from './pages/job-opening/JobOpeningControlPanel';
import StateList from './pages/application-settings/state/StateList';
import CandidateProfile from './pages/candidate/CandidateProfile';
import AssessmentViewer from './pages/assessment-viewer/AssessmentViewer';
import InterviewerEmailTemplate from './components/email-templates/InterviewerEmailTemplate';
import CandidateEmailTemplate from './components/email-templates/CandidateEmailTemplate';

const routes = [
    {
        path: '/Dashboard',
        element: HomePage,
        showSideNavBar: true,
    },
    {
        path: '/JobOpening/List',
        element: JobOpeningList,
        showSideNavBar: true,
    },
    {
        path: '/Candidate/List',
        element: CandidateList,
        showSideNavBar: true,
    },
    {
        path: '/Candidate/:NeworEdit',
        element: CandidateEditor,
        showSideNavBar: true,
    },
    {
        path: '/CandidateProfile',
        element: CandidateProfile,
        showSideNavBar: true,
    },
    {
        path: '/JobApplication/List',
        element: JobApplicationList,
        showSideNavBar: true,
    },
    {
        path: '/JobApplication/:NeworEdit',
        element: JobApplicationEditor,
        showSideNavBar: true,
    },
    {
        path: '/Interaction/List',
        element: InteractionList,
        showSideNavBar: true,
    },
    {
        path: '/Interaction/:NeworEdit',
        element: InteractionEditor,
        showSideNavBar: true,
    },
    {
        path: '/Schedule/List',
        element: ScheduleList,
        showSideNavBar: true,
    },
    {
        path: '/Schedule/:NeworEdit',
        element: ScheduleEditor,
        showSideNavBar: true,
    },
    {
        path: '/Country/List',
        element: CountryList,
        showSideNavBar: true,
    },
    {
        path: '/Cities/List',
        element: CityList,
        showSideNavBar: true,
    },
    {
        path: '/States/List',
        element: StateList,
        showSideNavBar: true,
    },
    {
        path: '/User/List',
        element: UserList,
        showSideNavBar: true,
    },

    {
        path: '/JobProfile/List',
        element: JobProfileList,
        showSideNavBar: true,
    },
    {
        path: '/Skill/List',
        element: SkillList,
        showSideNavBar: true,
    },
    {
        path: '/AcademicQualification/List',
        element: AcademicQualificationList,
        showSideNavBar: true,
    },

    {
        path: '/InteractionRound/List',
        element: InteractionRoundList,
        showSideNavBar: true,
    },
    {
        path: '/CandidateSource/List',
        element: CandidateSourceList,
        showSideNavBar: true,
    },
    {
        path: '/AttitudeFactor/List',
        element: AttitudeFactorList,
        showSideNavBar: true,
    },
    {
        path: '/UploadNewResume',
        element: UploadNewResume,
        showSideNavBar: true,
    },
    {
        path: '/Shortlisted',
        element: ShortListed,
        showSideNavBar: true,
    },
    {
        path: '/JobOpeningControlPanel',
        element: JobOpeningControlPanel,
        showSideNavBar: true,
    },
    {
        path: '/CompanyProfile',
        element: CompanyProfile,
        showSideNavBar: true,
    },
    {
        path: '/AssessmentViewer',
        element: AssessmentViewer,
        showSideNavBar: false,
    },
    {
        path: '/EmailTemplate/Interviewer',
        element: InterviewerEmailTemplate,
        showSideNavBar: true,
    },
    {
        path: '/EmailTemplate/Candidate',
        element: CandidateEmailTemplate,
        showSideNavBar: true,
    }
];


export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});

import React, { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components';
import DataGrid, { Button as DataGridButton, Column, ColumnChooser, FilterBuilderPopup, FilterPanel, HeaderFilter, Pager, Scrolling, SearchPanel, Sorting, Toolbar, StateStoring, ColumnChooserSearch, Search } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../../utils/media-query';
import ListSkeleton from '../../../layouts/templates/ListSkeleton';
import { GetFormattedDateTimeUTCString, HideDatagridLoader, ShowAlert, eCRUDStatus, onDragOver } from '../../../utils/common-methods';
import JobProfilePopup from './JobProfilePopup';
import { confirm } from 'devextreme/ui/dialog';
import { JobProfileServices } from '../../../api/services/JobProfileServices';
import JobInteractionRoundPopup from '../interaction-round/JobInteractionRoundPopup';

const jobProfileServices = new JobProfileServices();

export default function JobProfileList() {

  const { isExSmall, isXXSmall } = useScreenSize();

  const [filterVisible, setFilterVisible] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [dsJobProfile, setDsJobProfile] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [showInteractionRoundPopup, setShowInteractionRoundPopup] = useState(false)
  const [primaryKey, setPrimaryKey] = useState(null)
  const [focusedRowKey, setfocusedRowKey] = useState(0);
  const [status, setStatus] = useState({
    eStatus: eCRUDStatus.None,
    primaryKeyID: primaryKey,
  });
  const [jobProfileName, setJobProfileName] = useState(null);

  const GetList = async () => {
    // setShowSkeleton(true)
    const result = await jobProfileServices.GetListData();
    if (result.statusCode === 400 || result.statusCode === 409) {
      ShowAlert(result.data, "Vakency")
      setShowSkeleton(false);
    }
    else {
      const JobProfileData = result.data?.map(item => {
        const createdOn = GetFormattedDateTimeUTCString(item.createdOn);
        const updatedOn = GetFormattedDateTimeUTCString(item.updatedOn);
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      setDsJobProfile(JobProfileData);
      setShowSkeleton(false);
    }
  }

  useEffect(() => {
    GetList();
  }, [])

  useEffect(() => {
    if (status.eStatus !== eCRUDStatus.None) {
      const PrimaryKeyID = status.eStatus === eCRUDStatus.Inserted ? status.primaryKeyID : primaryKey;
      UpdateListByID(PrimaryKeyID);
    }
  }, [status])

  const UpdateListByID = async (primaryKey) => {
    const result = await jobProfileServices.GetListByIdData(primaryKey);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    } else {
      const JobProfileData = result.data?.map(item => {
        const createdOn = item.createdOn ? new Date(new Date(item.createdOn + "Z").toUTCString()).toLocaleString(): null;
        const updatedOn = item.updatedOn ?new Date(new Date(item.updatedOn + "Z").toUTCString()).toLocaleString() : null;
        return {
          ...item,
          createdOn: createdOn ? createdOn: null,
          updatedOn: updatedOn ? updatedOn : null
        };
      });
      if (status.eStatus === eCRUDStatus.Inserted) {
        setDsJobProfile([JobProfileData[0], ...dsJobProfile]);
      } else if (status.eStatus === eCRUDStatus.Updated) {
        setDsJobProfile(dsJobProfile.map((i) => i.jobProfileID === primaryKey ? JobProfileData[0] : i));
      }
    }
    setfocusedRowKey(primaryKey);
    setPrimaryKey(0);
  };

  const onOptionChange = (e) => {
    if (e.fullName === "filterValue") {
      setFilterVisible(!!e.value);
    }
  };

  const handleAdd = () => {
    setPrimaryKey(null)
    setShowPopup(true)
  }

  const handleEdit = (e) => {
    setPrimaryKey(e.data.jobProfileID)
    setShowPopup(true)
  }

  const DataGridRef = useRef(null);

  const onRowDbClick = (e) => {
    setPrimaryKey(e.data.jobProfileID)
    setShowPopup(true)
  }

  const JobProfileName = (cellData) => {
    return <>
      <div><span className='text-decoration-underline pointer-link text-break' title='Edit' onClick={(e) => handleEdit(cellData)}>{cellData.data.jobProfileName}</span></div>
    </>
  }

  const DescriptionRender = (cellData)=>{
      const Desc = cellData.data.descrPlainText;
      if (Desc?.length > 120) {
        return (
          <div className='text-break' title={Desc}>
  
            {cellData.data.descrPlainText?.substring(0, 120) + "..."}
  
          </div>
  
        )
      } else {
        return (
          <div className='text-break'>
            {cellData.data.descrPlainText}
          </div>
        )
      }
  }

  const onDeleteButtonClick = (e) => {
    let res = confirm(
      `<div class="row align-items-center"><i class="dx-icon-warning alert-icon col-auto pe-0"> </i><span class="alertText col">${"Are you sure, you want to delete this record"}</span></div>`,
      "Vakency"
    );
    res.then((dialogResult) => (dialogResult ? handleDelete(e) : ""));
  };

  const handleDelete = async (e) => {
    const Id = e.row.data.jobProfileID;
    const result = await jobProfileServices.DeleteData(Id);
    if (result.statusCode === 400) {
      ShowAlert(result.data, "Vakency")
    }
    else if (result.statusCode === 200) {
      setDsJobProfile(dsJobProfile.filter((x) => x.jobProfileID !== e.row.data.jobProfileID));
    }
  }

  const onInteractionRoundButtonClick = (e) => {
    setJobProfileName(e.row?.data?.jobProfileName);
    setShowInteractionRoundPopup(true);
    setPrimaryKey(e.row?.data?.jobProfileID);
  }

  const onFocusedRowChanged = (e) => {
    setfocusedRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <div>
      <div className='Job_Profile'>
        <Header
          title={"Job Profiles"}
          handleAdd={handleAdd}
          dataGridRef={DataGridRef}
          GetRecord={GetList}
        />
        <div className='list-section' onDragOver={onDragOver}>
          {
            showSkeleton ?
              <ListSkeleton />
              :
              <DataGrid
                ref={DataGridRef}
                dataSource={dsJobProfile}
                showBorders={true}
                showColumnLines={false}
                showRowLines={true}
                className='List_DataGrid'
                focusedRowKey={focusedRowKey}
                onFocusedRowChanged={onFocusedRowChanged}
                focusedRowEnabled={true}
                wordWrapEnabled={true}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                autoNavigateToFocusedRow={true}
                keyExpr="jobProfileID"
                height={"100%"}
                width={"100%"}
                filterSyncEnabled={true}
                onOptionChanged={onOptionChange}
                loadPanel={HideDatagridLoader}
                onRowDblClick={onRowDbClick}
                noDataText='No Record Found'
              >

                <Sorting mode={"multiple"} />
                <FilterPanel visible={filterVisible} />
                <FilterBuilderPopup visible={false} />
                <Scrolling mode={"infinite"} preloadEnabled={true} useNative={true} />
                <ColumnChooser enabled={true}>
                  <ColumnChooserSearch
                    enabled={true}
                  />
                </ColumnChooser>
                <HeaderFilter visible={true} >
                  <Search
                    enabled={true}
                  />
                </HeaderFilter>
                <SearchPanel visible={true} width={(isExSmall || isXXSmall) ? 200 : 400} />
                <StateStoring enabled={true} type="localStorage" storageKey="JobProfile_Layout" />
                <Pager
                  visible={true}
                  showInfo={true}
                  infoText={`{2} Rows`}
                ></Pager>
                <Toolbar>
                  <Item location={'after'} name="columnChooserButton" />
                  <Item location={'after'} name="searchPanel" />
                </Toolbar>
                <Column caption='Job Profile' cellRender={JobProfileName} dataField='jobProfileName' minWidth={140} width={280} allowHiding={false} />
                <Column caption='Major Skills'  dataField='majorSkills' minWidth={140} width={200} />
                <Column caption='Minor Skills'  dataField='minorSkills' minWidth={140} width={200} />
                <Column caption='Description' dataField='descrPlainText' cellRender={DescriptionRender} minWidth={300} />
                <Column
                  dataField="createdByUserName"
                  caption="Created By"
                  visible={false}
                  width={125}
                  minWidth={80}
                  alignment="left"
                />

                <Column
                  dataField="updatedByUserName"
                  caption="Updated By"
                  visible={false}
                  width={125}
                  minWidth={80}
                  alignment="left"
                />
                <Column
                  dataField="createdOn"
                  caption="Created On"
                  visible={false}
                  width={135}
                  minWidth={80}
                  alignment="left"
                // dataType='datetime'
                // format="dd/MM/yyyy hh:mm a"
                />
                <Column
                  dataField="updatedOn"
                  caption="Updated On"
                  visible={false}
                  width={137}
                  minWidth={80}
                  alignment="left"
                // dataType='datetime'
                // format="dd/MM/yyyy hh:mm a"
                />
                <Column type='buttons' fixed={false} allowResizing={false} width={60} allowReordering={false}>
                  <DataGridButton
                    name='interaction'
                    hint='Edit Interaction Round'
                    visible={true}
                    cssClass='dx-icon-interactionRoundsIcon dx-link dx-link-icon'
                    onClick={onInteractionRoundButtonClick}
                  >
                  </DataGridButton>
                  <DataGridButton
                    name='delete'
                    icon='trash'
                    hint='Delete'
                    visible={true}
                    cssClass='text-danger'
                    onClick={onDeleteButtonClick}
                  />
                </Column>
              </DataGrid>
          }
        </div>
        {showPopup === true &&
          <JobProfilePopup
            openPopup={showPopup}
            setOpenPopup={setShowPopup}
            primaryKey={primaryKey}
            setStatus={setStatus}
          />
        }
        {
          showInteractionRoundPopup && (
            <JobInteractionRoundPopup
              openPopup={showInteractionRoundPopup}
              setOpenPopup={setShowInteractionRoundPopup}
              jobName={jobProfileName}
              primaryKey={primaryKey}
              isJobProfilePanel={true}
            />
          )
        }
      </div>
    </div>
  )
}

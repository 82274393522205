import { Button, DateBox, FileUploader, NumberBox, Popup, ScrollView, SelectBox, Validator } from "devextreme-react";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { Button as NumberBoxButton } from 'devextreme-react/number-box';
import React, { useEffect, useRef, useState } from "react";
import StarRating, { closeDateBoxOnScroll, minDate, onKeyDown_Withpoint, popupAnimation, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RangeRule, RequiredRule } from "devextreme-react/validator";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import CandidateCard from "./CandidateCard";
import { CandidateControlServices } from "../../api/services/CandidateControlServices";
import JobApplicationEmailStatusPopup from "./JobApplicationEmailStatusPopup";

const htmlEditorSecondAttributes = {
  class: "noteSecondHtml",
};
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const candidateControlServices = new CandidateControlServices();

const InitiateOfferPopup = (props) => {
  const now = new Date();
  const ValidationGroupName = "statusPopupValidation"
  const UploadOfferLetterRef = useRef();
  const DateBoxRef = useRef();
  const DateTimeBoxRef = useRef();
  const maxFileSizeLength = 5 * 1024 * 1024;
  const [newRecord, setNewRecord] = useState({});
  const [offerLetter, setOfferLetter] = useState(null);
  const [offerNumber, setOfferNumber] = useState();
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [offerCTCMonthlyValue, setOfferCTCMonthlyValue] = useState();
  const [filename, setFilename] = useState();

  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall,  isLarge,  isExLarge } = useScreenSize();
  const htmlEditorNoteRef = useRef(null);

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const GenerateOfferNo = async () => {
    const result = await jobOpeningControlPanelServices.GenerateOfferNo();
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setOfferNumber(result.data);
    }
  };

  const GetOfferDetails = async () => {
    const result = await jobOpeningControlPanelServices.GetOfferDetails(
      props?.jobApplicationID
    );
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      const response = result.data;
      if (response?.offeredCTCPA != null) {
        setOfferCTCMonthlyValue((response?.offeredCTCPA * 100000) / 12);
        setNewRecord({
          ...newRecord,
          offerDate: new Date().setHours(0, 0, 0, 0),
          offervalidUpto: response?.offerValidUpto,
          expectedJoiningDate: response?.expectedJoiningDate,
          offeredCTCPA: response?.offeredCTCPA,
        });
      }
      GetOfferLetterFile();
    }
  };

  const GetOfferLetterFile = async () => {
    const result = await jobOpeningControlPanelServices.GetOfferLetterFile(props?.jobApplicationID);
    if (result.isOk) {
      setFilename("Offer_Letter.pdf");
      setOfferLetter(new File([result.data], 'Offer_Letter.pdf', { type: 'application/pdf' }));
    }
  };

  useEffect(() => {
    GenerateOfferNo();
    setNewRecord({ ...newRecord, offerDate: new Date().setHours(0, 0, 0, 0) })
    if (props.actionName === "ReviseOffer") {
      GetOfferDetails();
    }
  }, []);

  const getTitle = () => {
    if (props.actionName === "InitiateOffer") {
      return "Initiate Offer";
    } else if (props.actionName === "ReviseOffer") {
      return "Revise Offer";
    }
    else {
      return ""; // Or some default title if needed
    }
  };

  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={getTitle()}
          onSubmit={onSubmit}
          ValidationGroupName={ValidationGroupName}
        />
      </>
    );
  };

  const formatDate = (date, includeTime = false) => {
    if (!date) return null;

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    if (includeTime) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return `${year}-${month}-${day}`;
  };

  const onSubmit = async () => {
    if (!offerLetter) {
      ShowAlert(`Offer Letter is required`, "Vakency");
    } else{
      const dataToUpdate = {
        jobApplicationID: props?.jobApplicationID,
        offerDate: formatDate(new Date(newRecord?.offerDate)),
        offervalidUpto: formatDate(new Date(newRecord?.offervalidUpto), true),
        expectedJoiningDate: formatDate(new Date(newRecord?.expectedJoiningDate)),
        offeredCTCPA: newRecord?.offeredCTCPA,
        descrPlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
        descrFormattedText: htmlEditorNoteRef.current?.instance.option("value"),
        attachment: offerLetter,
      };

       // Conditionally add `updatedOn` if it's not null or undefined
    if (props?.jobApplicationUpdatedOn !== null && props?.jobApplicationUpdatedOn !== undefined) {
      dataToUpdate.updatedOn = props?.jobApplicationUpdatedOn;
    }

    try {
      const result = await jobOpeningControlPanelServices.InitiateOffer(dataToUpdate);
      if (result.isOk) {
        setShowEmailPopup(true);
        props?.GetApplication();
        props?.GetHeader();
      } else {
        ShowAlert(result.data, "Vakency");
      }
    } catch (error) {
      ShowAlert("An error occurred while processing the action.", "Vakency");
    }
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  }

  const onUploadOfferLetterValueChanged = (e) => {
    const file = e[0];
    if (file && file?.size > maxFileSizeLength) {
      ShowAlert(`Offer letter of more than 5 MB size cannot be uploaded`, "Vakency");
      UploadOfferLetterRef?.current?.instance.reset();
      setOfferLetter(null);
    } else if (
      file &&
      // file["type"]?.split("/")[1] !== "doc" &&
      // file["type"]?.split("/")[1] !== "docx" &&
      // file["type"]?.split("/")[1] !== "jpg" &&
      // file["type"]?.split("/")[1] !== "png" &&
      file["type"]?.split("/")[1] !== "pdf"
      // &&
      // file["type"]?.split("/")[1] !== "webp" &&
      // file["type"]?.split("/")[1] !== "jpeg" &&
      // file["type"]?.split("/")[1] !== "xlsx" &&
      // file["type"]?.split("/")[1] !== "xls" &&
      // file["type"]?.split("/")[1] !== "msword" &&
      // file["type"]?.split("/")[1] !== "vnd.ms-excel" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      // file["type"]?.split("/")[1] !==
      //   "vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      ShowAlert(`This file is not accepted`, "Vakency");
      UploadOfferLetterRef?.current?.instance.reset();
      setOfferLetter(null);
    } else {
      if (file) {
        setFilename("");
        setOfferLetter(file);
      }
    }
  };

  const onClickClearFile = async (e) => {
    UploadOfferLetterRef.current?.instance.reset();
    setOfferLetter(null);
  };

  const onCloseEmailPopup = () => {
    setShowEmailPopup(false);
    props.HideAssessmentPopup();
  }

  const DownloadResume = async (ID) => {
    const fileURL = URL.createObjectURL(offerLetter);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = `Offer_Letter.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileURL);
  };

  const OnPoupScroll =(e)=>{
    closeDateBoxOnScroll(DateBoxRef,e)
    closeDateBoxOnScroll(DateTimeBoxRef,e)
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "98vw"
            : isMedium || isSmall
              ? "70vw"
              : "50vw"
        }
        height={"auto"}
        maxHeight={"95vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup CommonPopupClass" }}
        animation={popupAnimation}
      >
        <ScrollView width={"100%"} height={"100%"} onScroll={OnPoupScroll} useNative={true}>
          <form onSubmit={handleSubmit}>
            <div className="row m-0">
              <CandidateCard
                candidateRating={props?.candidateRating}
                candidateApplicationStatus={props?.candidateApplicationStatus}
                candidateId={props?.candidateId}
                jobProfileName={props?.jobProfileName}
                PopupType={props.actionName}
                lastRoundScheduleDate={props.lastRoundScheduleDate}
                dsInteractionRounds={props.dsInteractionRounds}
                lastInteractionRoundName={props.lastInteractionRoundName}
              />
            </div>
            <div className="row m-0">
              <div className="col-xxl col-xl col-lg col-md mt-3 mt-md-0">
                <NumberBox
                  label="Offer Number"
                  maxLength={15}
                  labelMode="floating"
                  value={offerNumber ? offerNumber : null}
                  readOnly={true}
                />
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <DateBox
                  ref={DateTimeBoxRef}
                  id="JobOpening_OffervalidUpto"
                  labelMode="floating"
                  label="Offer Valid-Upto"
                  defaultValue={now}
                  value={newRecord?.offervalidUpto ? new Date(newRecord?.offervalidUpto) : null}
                  onValueChange={(e) => setNewRecord({ ...newRecord, offervalidUpto: e })}
                  type="datetime"
                  displayFormat="dd/MM/yyyy, hh:mm a"
                  dropDownOptions={{
                    restorePosition:false,
                    position:"center",
                  }}
                //  displayFormat=" dd-MM-yyyy HH:mm"
                // useMaskBehavior={true}
                // min={minDate}
                // dropDownOptions={{
                //   hideOnParentScroll: true,
                //   container: "#JobOpening_OffervalidUpto"
                // }}
                // dateOutOfRangeMessage="Invalid Date"
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Offer Valid Upto Date and Time is required" />
                    {
                      newRecord?.offerDate &&
                      <RangeRule
                        message="Offer Valid Upto Date should not be earlier than the Offer Date"
                        min={newRecord?.offerDate}
                        ignoreEmptyValue={true}
                      />
                    }
                  </Validator>
                </DateBox>
              </div>
              <div className="col-xxl col-xl col-lg col-md pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0">
                <DateBox
                  ref={DateBoxRef}
                  id="JobOpening_ExpectedJoiningDate"
                  labelMode="floating"
                  label="Expected Joining Date"
                  value={newRecord?.expectedJoiningDate ? new Date(newRecord?.expectedJoiningDate) : null}
                  onValueChange={(e) => setNewRecord({ ...newRecord, expectedJoiningDate: e })}
                  type="date"
                  useMaskBehavior={true}
                  displayFormat="dd/MM/yyyy"
                  min={minDate}
                  // dropDownOptions={{
                  //   hideOnParentScroll: true,
                  //   container: "#JobOpening_ExpectedJoiningDate"
                  // }}
                  dateOutOfRangeMessage="Invalid Date"
                >
                  <Validator
                    validationGroup={ValidationGroupName}
                    elementAttr={{ class: "ForValidationAstrikMark" }}
                  >
                    <RequiredRule message="Expected Joining Date is required" />
                    {
                      newRecord?.offerDate &&
                      <RangeRule
                        message="Joining Date should not be earlier than the Offer Date"
                        min={newRecord?.offerDate}
                        ignoreEmptyValue={true}
                      />
                    }
                  </Validator>
                </DateBox>
              </div>
            </div>
            <div className="row m-0 pt-2">
              <div className="col-xxl col-xl col-lg">
                <div className="row">
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto pt-2 mt-1 editor-label">
                    Offer CTC:
                  </div>
                  <div className="col">
                    <NumberBox
                      label="Yearly"
                      labelMode="floating"
                      maxLength={15}
                      min={0}
                      format="##.##"
                      value={newRecord?.offeredCTCPA && newRecord?.offeredCTCPA.toFixed(2)}
                      onValueChange={(e) => {
                        setNewRecord({ ...newRecord, offeredCTCPA: e })
                        setOfferCTCMonthlyValue((e * 100000) / 12)
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="offer_ctc_yearly"
                        options={{
                          text: 'LPA',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                      <Validator
                        validationGroup={ValidationGroupName}
                        elementAttr={{ class: "ForValidationAstrikMark" }}
                      >
                        <RequiredRule message="Offer CTC is required" />
                      </Validator>
                    </NumberBox>
                  </div>
                  <div className="col-auto px-0 pt-3 text-gray font-regular-12">OR</div>
                  <div className="col">
                    <NumberBox
                      label="Monthly"
                      labelMode="floating"
                      value={offerCTCMonthlyValue && offerCTCMonthlyValue.toFixed(2)}
                      maxLength={15}
                      min={0}
                      format="##.##"
                      onValueChange={(e) => {
                        setOfferCTCMonthlyValue(e);
                        setNewRecord({ ...newRecord, offeredCTCPA: ((e * 12) / 100000) || null })
                      }}
                      onKeyDown={onKeyDown_Withpoint}
                      className="number-box-btn"
                    >
                      <NumberBoxButton
                        location="after"
                        name="offer_ctc_monthly"
                        options={{
                          text: 'month',
                          stylingMode: 'text',
                          hoverStateEnabled: false,
                          focusStateEnabled: false,
                          activeStateEnabled: false,
                        }}
                      />
                       <Validator
                         validationGroup={ValidationGroupName}
                         elementAttr={{ class: "ForValidationAstrikMark" }}
                       >
                         <RequiredRule message="Offer CTC is required" />
                      </Validator>
                    </NumberBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 pt-2">
              <div className="col-md-auto editor-label pt-3 mt-3">Offer Letter</div>
              <div className="col-md-8 col-lg-6  mx-3 mt-2 resumeUpload d-flex border rounded align-items-center">
                <label htmlFor="upload-offer-letter" className='ms-1'>
                  <span className='text-danger'>*</span>
                </label>
                <FileUploader
                  ref={UploadOfferLetterRef}
                  id='upload-offer-letter'
                  accept="application/pdf"
                  name="candidate offer letter"
                  multiple={false}
                  uploadMode={"useForm"}
                  selectButtonText="Browse"
                  allowCanceling={false}
                  labelText=""
                  hoverStateEnabled={false}
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  uploadedMessage="Offer Letter Uploaded"
                  readyToUploadMessage=""
                  className=""
                  inputAttr={{ 'aria-label': 'Browse' }}
                  onValueChange={onUploadOfferLetterValueChanged}
                  value={offerLetter ? [offerLetter] : []}
                  hint={offerLetter ? offerLetter?.name : ""}
                />
                {offerLetter && (
                  <>
                    <Button
                      icon="close"
                      id="Close"
                      onClick={onClickClearFile}
                      stylingMode="text"
                      type="default"
                      hint="Close"
                    />
                    {
                      filename && (
                        <Button
                          icon="download"
                          id="Download"
                          onClick={DownloadResume}
                          stylingMode="text"
                          type="default"
                          hint="Download"
                        />
                      )
                    }
                  </>
                )}
              </div>

            </div>
            <div className="row pt-2 mx-1 mb-3">
            </div>
            <div className="row m-0">
              <div className="assessment-html-editor-section px-2">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  // height={isExLarge ? "670px" :  "790px"
                  //     // : "1100px"
                  // }
                  width={"auto"}
                  height={"250px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Description/Notes"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="text-end py-2 pe-3">
              <Button
                validationGroup={ValidationGroupName}
                text="Submit"
                type="default"
                height={33}
                width={120}
                className="rounded-3"
                useSubmitBehavior={true}
              />
            </div>
          </form>
        </ScrollView>
        {
          showEmailPopup && (
            <JobApplicationEmailStatusPopup
              actionName={props.actionName}
              jobApplicationID={props?.jobApplicationID}
              showEmailPopup={showEmailPopup}
              onClosePopup={onCloseEmailPopup}
              candidateData={props.candidateData}
            />
          )
        }
      </Popup>
    </>
  );
};

export default InitiateOfferPopup;

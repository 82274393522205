import React from "react";
import EmailTemplatePopup from "./EmailTemplatePopup";
import { EmailReceiverType, EmailTemplateType } from "../../utils/contants";

const FieldsDataSource = [
    { id: 1, name: 'Candidate Email', tag: "{{Candidate Email}}" },
    { id: 2, name: 'Candidate Mobile', tag: "{{Candidate Mobile}}" },
    { id: 3, name: 'Candidate Name', tag: "{{Candidate Name}}" },
    { id: 4, name: 'Company Contact Number', tag: "{{Company Contact Number}}" },
    { id: 5, name: 'Company Email ID', tag: "{{Company Email ID}}" },
    { id: 6, name: 'Company Name', tag: "{{Company Name}}" },
    { id: 7, name: 'Company Website', tag: "{{Company Website}}" },
    { id: 8, name: 'HR Manager', tag: "{{HR Manager}}" },
    { id: 9, name: 'Interview Round Name', tag: "{{Interview Round Name}}" },
    { id: 10, name: 'Job Profile', tag: "{{Job Profile}}" },
    { id: 11, name: 'Meeting Link', tag: "{{Meeting Link}}" },
    { id: 12, name: 'Schedule Date and Time', tag: "{{Schedule Date & Time}}" },
];

export default function CandidateEmailTemplate() {

    return (
        <>
            <EmailTemplatePopup
                EmailTemplateType={EmailTemplateType.Assessment}
                EmailReceiverType={EmailReceiverType.Candidate}
                title={"Candidate Email Template"}
                FieldsDataSource={FieldsDataSource}
            />
        </>
    );

}
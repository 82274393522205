import { JobApplicationOpenControlName } from "../../contexts/APIurl";
import { fetchWrapper } from "./authHeader";
import { VakencyApiServices } from "./VakencyApiServices";

const url = JobApplicationOpenControlName;
const baseURL = process.env.REACT_APP_BASEURL;

export class JobApplicationOpenService extends VakencyApiServices {
    constructor() {
        super(url);
    }

    PostCandidateDetail = async (key, data) => {
        const result = await fetchWrapper.postWithoutHeader((baseURL + url + `/Post/${key}`), data);
        try {
            if (result.status && result.status === 200) {
                return {
                    isOk: true,
                    data: await result.json(),
                    statusCode: result.status
                };
            } else {
                return {
                    isOk: false,
                    data: await result.text(),
                    statusCode: result.status
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                data: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: result.status
            };
        }
    }

    UpdateCandidateDocData = async (params, id) => {
        const formData = new FormData();
        formData.append('attachment', params.attachment);
        const UpdateUrl = url + `/UploadCandidateResume/${id}`
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData)

            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data,
                    statusCode: data.status
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status
                };
            }
        }

        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status
            };
        }
    }

    ReadCandidateResume = async (params) => {
        const formData = new FormData();
        formData.append("attachments", params.attachment);
        const UpdateUrl = url + `/ReadCandidateResume/${params.id}`;
        let data = null;
        try {
            data = await fetchWrapper.postwithFile(baseURL + UpdateUrl, formData);
            if (data.status && data.status === 200) {
                return {
                    isOk: data.ok,
                    data: await data.json(),
                    statusCode: data.status,
                };
            } else {
                return {
                    isOk: data.ok,
                    data: await data.text(),
                    stautsCode: data.status && data.status,
                };
            }
        }
        catch (err) {
            return {
                isOk: false,
                message: "Failed To Load  Data : " + JSON.stringify(err),
                statusCode: data.status,
            };
        }
    }

}
import { Popup, TextBox, Validator } from 'devextreme-react';
import { SelectBox, Button as TextBoxButton } from 'devextreme-react/select-box';
import React, { useEffect, useRef, useState } from 'react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { useScreenSize } from '../../../utils/media-query';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { ConflictPopup, ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AsyncRule, CustomRule } from 'devextreme-react/validator';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { AcademicQualificationServices } from '../../../api/services/AcademicQualificationServices';

const academicQualificationServices = new AcademicQualificationServices();
export default function AcademicQualificationPopup(props) {


    const ValidationGroupName = "academicqualificationPopupValidation"


    const { isExSmall, isXXSmall } = useScreenSize();
    const FocusedFeild = useRef(null);
    const onShown = () => FocusedFeild.current?.instance.focus();

    const SelectBoxRef = useRef(null);

    const [newRecord, setNewRecord] = useState({});
    const [originalRecord, setOriginalRecord] = useState({});
    let [isQualificationNameValid, setIsQualificationValid] = useState(false)
    let PrimaryKeyID = props.primaryKey;
    const [showSkeleton, setShowSkeleton] = useState(true);

    useEffect(() => {
        if (PrimaryKeyID !== null && PrimaryKeyID !== 0) {
            GetModelRecord();
        } else {
            setNewRecord({})
            setShowSkeleton(false);
        }
    }, [PrimaryKeyID]);

    useEffect(() => {
        !showSkeleton && FocusedFeild.current?.instance.focus()
    }, [showSkeleton])

    const GetModelRecord = async () => {
        setTimeout(async () => {
            const result = await academicQualificationServices.GetModelData(PrimaryKeyID);
            if (result.statusCode === 200) {
                const response = result.data;
                setNewRecord(response)
                setOriginalRecord(response)
                setShowSkeleton(false);
            }
        }, 1000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!PrimaryKeyID) {
            InsertRecord();
        } else {
            UpdateRecord();
        }
    }

    const UpdateRecord = async () => {
        if(originalRecord == newRecord || (originalRecord !== newRecord)){
            setShowSkeleton(true);
            var dataToUpdate = {
                updatedOn: newRecord.updatedOn,
                academicQualificationID: newRecord.academicQualificationID,
                academicQualificationName: newRecord.academicQualificationName,
                certificateType: newRecord.certificateType,
            }

            const result = await academicQualificationServices.UpdateData(dataToUpdate);
            if (!result.isOk) {
                if (result.statusCode === 409) {
                    ConflictPopup(result.data).then((res) => {
                        if (res) {
                            GetModelRecord();
                        } else {
                            return;
                        }
                    });
                    setShowSkeleton(false);
                } else if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency");
                    setShowSkeleton(false);
                }
            } else {
                props.setStatus({
                    eStatus: eCRUDStatus.Updated,
                    primaryKeyID: result.data.responseData
                });
                onClosePopup();
                setShowSkeleton(false);
            }
        }
    }

    const InsertRecord = async () => {
        setShowSkeleton(true);
        let dataToInsert = {
            academicQualificationName: newRecord.academicQualificationName,
            certificateType: newRecord.certificateType,
        }
        const result = await academicQualificationServices.InsertData(dataToInsert);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency")
                setShowSkeleton(false);
            }
        } else {
            props.setStatus({
                eStatus: eCRUDStatus.Inserted,
                primaryKeyID: result.data.responseData
            })
            onClosePopup();
            setShowSkeleton(false);
        }
    }

    const onClosePopup = () => {
        setIsQualificationValid(false)
        props.setOpenPopup(false);
        setNewRecord({});
    }

    const Certificate_Type_List = [
        {
            Id: 1,
            Type: "Under Graduate"
        },
        {
            Id: 2,
            Type: "Graduate"
        },
        {
            Id: 3,
            Type: "Post Graduate"
        },
        {
            Id: 4,
            Type: "Certificate or Diploma"
        },
    ]

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onClosePopup={onClosePopup}
                    onSubmit={PrimaryKeyID ? UpdateRecord : InsertRecord}
                    title={[<span key={"header_title"} className="base-accent-text">{PrimaryKeyID ? "Edit" : "New"}</span>, " Academic Qualification"]}
                    hideSaveButton={showSkeleton ? true : false}
                />
            </>
        )
    }

    const asyncAcademicQualificationNameValidation = async (e) => {
        try {
            const Name = e?.value;
            const ExcludeID = PrimaryKeyID ? PrimaryKeyID : 0;
            const CheckDuplicateParams = `Name=${Name}&ExcludeID=${ExcludeID}`;
            const result = await academicQualificationServices.CheckDuplicateData(CheckDuplicateParams);
        //   if(result.isOk !== false){
        //         setIsQualificationValid(true)
        //     }
        //   else{
        //         setIsQualificationValid(false)
        //     }
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncAcademicQualificationNameValidation:", error);
            return new Promise((resolve) => {
                // setIsQualificationValid(false)
                resolve(false);
            });
        }
    };



    const DownArrow = {
        icon: 'spindown',
        stylingMode: 'text',
        onClick: (e) => {

            var selectBoxInstance = SelectBoxRef.current?.instance;
            var isOpened = selectBoxInstance.option("opened");
            if (isOpened) {
                selectBoxInstance.close();
            } else {
                selectBoxInstance.open();
                selectBoxInstance.focus();
            }
        }
    }


    return (
        <div className='Academic_Qualification_popup'>
            {props.openPopup &&
                <Popup
                    visible={props.openPopup}
                    titleRender={PopupTitle}
                    width={(isXXSmall || isExSmall) ? "95%" : 450}
                    height={'auto'}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onShown={onShown}
                >
                    <form onSubmit={handleSubmit}>
                        {showSkeleton ?
                            <div className='p-2 pb-0'>
                                <PopupSkeleton />
                                <PopupSkeleton />
                            </div>
                            :
                            <div className='px-3'>
                                <div className='py-2 '>
                                    <TextBox
                                        label='Qualification Name'
                                        maxLength={50}
                                        value={newRecord.academicQualificationName}
                                        onValueChange={(e) => setNewRecord({ ...newRecord, academicQualificationName: e })}
                                        labelMode='floating'
                                        ref={FocusedFeild}
                                    >
                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                            <RequiredRule message='Qualification Name is required' />
                                            {/* <CustomRule
                                                ignoreEmptyValue={true}
                                                message="Can't accept duplicate Qualification Name"
                                                validationCallback={asyncAcademicQualificationNameValidation}
                                            /> */}
                                            <AsyncRule
                                                reevaluate={false}
                                                message="Can't accept duplicate Qualification Name"
                                                validationCallback={asyncAcademicQualificationNameValidation}
                                            />
                                        </Validator>
                                    </TextBox>
                                </div>
                                <div className='py-2 '>
                                    <SelectBox
                                        id="CertificateType_DropDown"
                                        dataSource={Certificate_Type_List}
                                        label='Certificate Type'
                                        valueExpr="Id"
                                        labelMode='floating'
                                        displayExpr="Type"
                                        ref={SelectBoxRef}
                                        value={newRecord.certificateType}
                                        onValueChange={(e) => setNewRecord({ ...newRecord, certificateType: e })}
                                        dropDownOptions={{
                                            height: 70,
                                            hideOnParentScroll: true,
                                            container: "#CertificateType_DropDown"
                                        }}
                                    >
                                        <TextBoxButton
                                            name="dropdown"
                                            location="after"
                                            options={DownArrow}
                                        />
                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                            <RequiredRule message='Certificate Type is required' />
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>
                        }
                        {showSkeleton ?
                            <div className='d-flex justify-content-end pb-3 pe-2'>
                                <div className='skeleton-View Skeleton-popup-footer'></div>
                            </div>
                            :
                            <PopupFooter
                                ValidationGroupName={ValidationGroupName}
                                openPopup={props.openPopup}
                                setOpenPopup={props.setOpenPopup}
                            />
                        }
                    </form>
                </Popup>
            }
        </div>
    )
}

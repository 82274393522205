import React from 'react';
import './PopupSkeleton.scss';

export default function PopupSkeleton(props) {
  return (
    <>
      <div className="">
        {props.showOnFullPage ?
        <>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          <span className="custom-skeleton-two "></span>
          </>
          :
          <>
             <span className="custom-skeleton-two "></span>
             <span className="custom-skeleton-two "></span>
          </>
        }
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import ViewMode from '../../layouts/templates/ViewMode'
import { CancelledByCandidateIcon, CandidateAcceptedOfferIcon, CandidateRejectedOfferIcon, candidateUnavailableIcon, HiredStatusIcon, JoinedStatusIcon, MoveToUnderReviewIcon, OfferedStatusIcon, pendingIcon, RejectIcon, selectedIcon, shortlistedIcon } from '../../utils/base-64-Icons'
import { JobApplicationHistoryServices } from '../../api/services/JobApplicationHistory';
import { GetFormattedDate, ShowAlert } from '../../utils/common-methods';
import PopupSkeleton from '../../layouts/templates/PopupSkeleton';


const jobApplicationHistoryServices = new JobApplicationHistoryServices();

const ViewComments = (props) => {

    const [ViewData, setViewData] = useState([])
    const [showSkeleton, setShowSkeleton] = useState(false)

    useEffect(() => {
        GetList();
    }, []);

    const GetList = async () => {
        setShowSkeleton(true);
        const result = await jobApplicationHistoryServices.GetJobApplicationHistory(props?.jobApplicationID);
        if (!result.isOk) {
            if (result.statusCode === 400) {
                ShowAlert(result.data, "Vakency");
            }
            setShowSkeleton(false);
        } else {
            setViewData(result.data);
            setShowSkeleton(false);
        }
    }
    let i = 1;
    return (
        <>
            <ViewMode
                showView={props.showView}
                setShowView={props.setShowView}
                title={"Comments"}
                hideSubTitle={false}
            >
                {showSkeleton ?
                <>
                <PopupSkeleton showOnFullPage={true}/>
                </>
                : 
                <>
                {ViewData?.map((item, index) => {
                    i = (index% 5) + 1;
                    return (
                        <div key={index} className='ViewComment-Container'>
                            <div className='pb-2'>
                                <div className={`font-bold-14 pb-1 Date-String-Div-${i}`}>
                                    {new Date(item.createdOn)?.toString()?.split(" ")[1]} &nbsp;{new Date(item.createdOn)?.toString()?.split(" ")[3]}
                                </div>
                                <div className='d-flex align-items-center pb-1'>
                                    <div className={`Date-pipline-Container-${i} font-semiBold-16`}>
                                        {item.createdOn ? GetFormattedDate(item.createdOn)?.split("/")[0] : ""}
                                    </div>
                                    <div className='font-semiBold-16 px-1'>{item?.jobApplicationStatusName}</div>
                                    <img
                                        src={
                                            item?.jobApplicationStatusName === "Job application rejected by us"
                                                ? RejectIcon
                                                : item?.jobApplicationStatusName === "Pending"
                                                    ? pendingIcon :
                                                    item?.jobApplicationStatusName === "Shortlisted"
                                                        ? shortlistedIcon :
                                                        item?.jobApplicationStatusName === "Under Review"
                                                            ? MoveToUnderReviewIcon :
                                                            item?.jobApplicationStatusName === "Selected"
                                                                ? selectedIcon :
                                                                item?.jobApplicationStatusName === "Offered"
                                                                    ? OfferedStatusIcon :
                                                                    item?.jobApplicationStatusName === "Offer accepted"
                                                                        ? CandidateAcceptedOfferIcon :
                                                                        item?.jobApplicationStatusName === "Hired"
                                                                            ? HiredStatusIcon :
                                                                            item?.jobApplicationStatusName === "On-boarded/Joined"
                                                                                ? JoinedStatusIcon :
                                                                                item?.jobApplicationStatusName === "Offer rejected by candidate"
                                                                                    ? CandidateRejectedOfferIcon :
                                                                                    item?.jobApplicationStatusName === "Shortlisted but not selected"
                                                                                        ? CancelledByCandidateIcon :
                                                                                        item?.jobApplicationStatusName === "Cancelled by Candidate"
                                                                                            ? CancelledByCandidateIcon :
                                                                                            item?.jobApplicationStatusName === "Candidate is not responding"
                                                                                            && candidateUnavailableIcon
                                        }
                                        title={item?.jobApplicationStatusName}
                                        alt="status"
                                        width={18}
                                        height={18}
                                        className='me-1'
                                    />
                                </div>
                                <div className={`Summary-Date-Line-${i} ms-2 mt-2}`}>
                                {item?.jobApplicationStatusName === "Shortlisted" || 
                                    item?.jobApplicationStatusName === "Under Review" || 
                                    item?.jobApplicationStatusName === "Selected" 
                                    ?
                                     <div className='NoDescription-Container'>
                                     </div>
                                    :
                                    <div className='d-flex decription-container shadow-sm p-3 mb-3 rounded ms-3'>

                                    {item?.noteFormattedText ?
                                        <div className={item?.noteFormattedText?.length > 180 ? 'CommentMessage' : 'text-break'}>
                                            <div dangerouslySetInnerHTML={{ __html: item?.noteFormattedText }} title={item?.notePlainText?.length > 180 ? item?.notePlainText : null} >
                                            </div>
                                        </div>
                                        :
                                        <div className='font-regular-14'>
                                            No Comment
                                        </div>
                                    }
                                </div>
                                }
                                </div>


                                <hr className='my-1'/>
                            </div>
                        </div>
                    )
                })}
                </>
                }
            </ViewMode>
        </>
    )
}

export default ViewComments

import { Button, Popup, ScrollView, SelectBox, Validator } from "devextreme-react";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import React, { useRef } from "react";
import StarRating, { popupAnimation, ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { RequiredRule } from "devextreme-react/validator";
import { JobOpeningControlPanelServices } from "../../api/services/JobOpeningControlPanelServices";
import CandidateCard from "./CandidateCard";
import { RejectApplication } from "../../api/VakencyAPI";
import { CandidateInteractionServices } from "../../api/services/CandidateInteractionServices";

const jobOpeningControlPanelServices = new JobOpeningControlPanelServices();
const candidateInteractionServices = new CandidateInteractionServices();

const JobApplicationStatusPopup = (props) => {
  const ValidationGroupName = "assessmentPopupValidation"

  const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall, isExLarge } = useScreenSize();
  const htmlEditorSecondAttributes = {
    class: "noteSecondHtml",
  };
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const htmlEditorNoteRef = useRef(null);

  const onClosePopup = () => {
    props.HideAssessmentPopup();
  };

  const getTitle = () => {
    if (props.actionName === "RejectApplication") {
      return "Reject";
    } else if (props.actionName === "CandidateCancelled") {
      return "Candidate Cancelled";
    }
    else if (props.actionName === "CandidateNotResponding") {
      return "Candidate Not Responding";
    }
    else if (props.actionName === "CandidateDidNotAttend") {
      return "Candidate Not Attend";
    }
    else if(props.actionName === "CancelledByCandidate") {
      return "Cancelled By Candidate";
    }
    else if(props.actionName === "InteractionCancelledByUs") {
      return "Cancelled Interaction";
    }
    else {
      return ""; // Or some default title if needed
    }
  };


  const PopupTitle = (e) => {
    return (
      <>
        <PopupHeader
          onClosePopup={onClosePopup}
          title={getTitle()}
          ValidationGroupName={ValidationGroupName}
          onSubmit={onSubmit}
          isWithoutValidationPopup={true}
        />
      </>
    );
  };


  const onSubmit = async () => {
    const dataToUpdate = {
      updatedOn: props?.jobApplicationUpdatedOn || null,
      jobApplicationID: props?.jobApplicationID,
      descrPlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
      descrFormattedText: htmlEditorNoteRef.current?.instance.option("value")
    };

    // Determine the action to perform based on props.actionName
    let actionToPerform;

    if (props.actionName === 'RejectApplication') {
      actionToPerform = jobOpeningControlPanelServices.RejectJobApplication;
    }
    else if (props.actionName === 'CandidateNotResponding') {
      actionToPerform = jobOpeningControlPanelServices.CandidateNotResponding;
    }
    else if (props.actionName === 'CandidateDidNotAttend') {
      actionToPerform = candidateInteractionServices.CandidateDidNotAttendInteraction;
    }
    else if (props.actionName === 'CandidateCancelled') {
      actionToPerform = jobOpeningControlPanelServices.CandidateCancelled;
    }
    else if (props.actionName === 'OfferRejectedByCandidate') {
      actionToPerform = jobOpeningControlPanelServices.OfferRejectedByCandidate;
    }
    else if (props.actionName === 'RevokeOffer') {
      actionToPerform = jobOpeningControlPanelServices.RevokeOffer;
    }
    else if (props.actionName === 'CancelledByCandidate') {
      actionToPerform = jobOpeningControlPanelServices.CandidateCancelled;
    }
    else if (props.actionName === 'CancelledByCandidate') {
      actionToPerform = jobOpeningControlPanelServices.CandidateCancelled;
    }
    else if (props.actionName === 'InteractionCancelledByUs') {
      actionToPerform = candidateInteractionServices.InteractionCancelledByUs;
    }

    if (actionToPerform) {
      try {
        const result = await actionToPerform(dataToUpdate);
        if (result.isOk) {
          props?.GetApplication();
          props?.GetHeader();
        } else {
          ShowAlert(result.data, "Vakency");
        }
      } catch (error) {
        ShowAlert("An error occurred while processing the action.", "Vakency");
      }
    } else {
      ShowAlert("Invalid action. Please try again.", "Vakency");
    }
    
    props.HideAssessmentPopup();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <>
      <Popup
        visible={props.showAssessmentPopup}
        onHiding={props.HideAssessmentPopup}
        width={
          isXSmall || isXXSmall || isExSmall
            ? "95vw"
            : isMedium || isSmall
              ? "70vw"
              : "50vw"
        }
        height={"auto"}
        maxHeight={"95vh"}
        titleRender={PopupTitle}
        wrapperAttr={{ class: "CustomPopup" }}
        animation={popupAnimation}
      >
        <ScrollView width={"100%"} height={"100%"}>
          <form onSubmit={handleSubmit}>
            <div className="row pt-2 px-1 mb-3">
              <div className="">
                <CandidateCard
                  candidateRating={props?.candidateRating}
                  candidateApplicationStatus={props?.candidateApplicationStatus}
                  candidateId={props?.candidateId}
                  PopupType={props.actionName}
                  jobProfileName={props?.jobProfileName}
                  lastRoundScheduleDate={props.lastRoundScheduleDate}
                  dsInteractionRounds={props.dsInteractionRounds}
                  lastInteractionRoundName={props.lastInteractionRoundName}
                />
              </div>
            </div>
            <div className="row">
              <div className="assessment-html-editor-section px-4">
                <HtmlEditor
                  ref={htmlEditorNoteRef}
                  id={"JobDescribtionHtmlEditor"}
                  elementAttr={htmlEditorSecondAttributes}
                  valueType="html"
                  // height={isExLarge ? "670px" :  "790px"
                  //     // : "1100px"
                  // }
                  width={"auto"}
                  height={"240px"}
                  className="CustomizeHtmlEditor"
                  placeholder="Overall Comments"
                >
                  <HtmlEditorToolbar multiline={false}>
                    <HtmlEditorItem name="link"></HtmlEditorItem>
                    <HtmlEditorItem
                      name="font"
                      acceptedValues={fontValues}
                    />
                    <HtmlEditorItem
                      name="size"
                      acceptedValues={sizeValues}
                    />
                    <HtmlEditorItem name="bold" />
                    <HtmlEditorItem name="italic" />
                    <HtmlEditorItem name="underline" />
                    <HtmlEditorItem name="background" />
                    <HtmlEditorItem name="color" />
                    <HtmlEditorItem name="orderedList" />
                    <HtmlEditorItem name="bulletList" />
                    <HtmlEditorItem name="clear" />
                  </HtmlEditorToolbar>
                </HtmlEditor>
              </div>
            </div>
            <div className="d-flex justify-content-end py-2 pe-3">
              <Button
                validationGroup={ValidationGroupName}
                text="Submit"
                type="default"
                height={33}
                width={120}
                className="rounded-3"
                useSubmitBehavior={true}
              />
            </div>
          </form>
        </ScrollView>
      </Popup>
    </>
  );
};

export default JobApplicationStatusPopup;

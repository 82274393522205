import React, { useEffect, useRef, useState } from "react";
import {
  BlankProfile,
  candidateUnavailableIcon,
  failedIcon,
  googleMeetingIcon,
  interviewCancelledIcon,
  notAttendIcon,
  pendingIcon,
  rejectedIcon,
  selectedIcon,
  shortlistedIcon,
  skypeIcon,
  teamsIcon,
  userAssessmentIcon,
  zoomIcon,
} from "../../utils/base-64-Icons";
import "./Assessment.scss";
import { Item } from "devextreme-react/tab-panel";
import TabPanel from "devextreme-react/tab-panel";
import { Button, DataGrid, LoadPanel, Popup, ScrollView, SelectBox } from "devextreme-react";
import { Column, Editing, Toolbar, Item as GridItem, RequiredRule, Paging, Scrolling, Selection } from "devextreme-react/data-grid";
import StarRating, { GetFormattedDate, GetFormattedTime, NewStarRating, ShowAlert, StarRatingClickable, handleNavigateToMap } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import HtmlEditor, {
  Toolbar as HtmlEditorToolbar,
  Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import { useNavigate, useParams } from 'react-router-dom';
import { CandidateAssessmentOpenServices } from "../../api/services/CandidateAssessmentOpenServices";
import AssessmentPopup from "./AssessmentPopup";
import AssessmentSuccessPopup from "./AssessmentSuccessPopup";
import FilePreviewer from "../../pages/candidate/FilePreviewer";

const htmlEditorSecondAttributes = {
  class: "noteSecondHtml",
};

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

const colors = [
  "#63439c",
  "#9c9b43",
  "#9c436e",
  "#9c4343",
  "#5f9c43",
  "#439c95",
  "#46439c",
  "#439c9b",
  "#89439c",
  "#436e9c",
  "#436e9c",
  "#7e9c43",
  "#006400",
  "#00008B",
  "#8B008B",
  "#2F4F4F",
  "#8B4513",
  "#4B0082",
  "#556B2F",
  "#FF8C00",
  "#9932CC",
  "#B22222",
];
const roundResult = [
  {
    type: "Pending",
    value: 0
  },
  {
    type: "Passed",
    value: 1
  },
  {
    type: "Failed",
    value: 2
  },
  {
    type: "CandidateDidNotAttend",
    value: 3
  },
  {
    type: "CancelledByCandidate",
    value: 4
  },
  {
    type: "CancelledByUs",
    value: 5
  }
]
const observationEditorOption = {
  maxLength: 100
}
const attitudeNameEditorOption = {
  maxLength: 50
}
const skillNameditorOption = {
  maxLength: 50
}

const candidateAssessmentOpenServices = new CandidateAssessmentOpenServices();

const Assessment = () => {

  const {
    isSmall,
    isXSmall,
    isExSmall,
    isXXSmall,
    isMedium,
    isLarge,
    isExLarge,
  } = useScreenSize();

  const { openaccesscode } = useParams();
  const NavigateTo = useNavigate();
  const [tabSelectedItemIndex, setTabSelectedItemIndex] = useState(null)
  const [candidateData, setCandidateData] = useState();
  const [profileImg, setProfileImg] = useState({
    uploadedFileUrl: null,
    attachedFile: null,
  });
  const [resume, setResume] = useState(null);
  const [dsAttitudeData, setDsAttitudeData] = useState([]);
  const [dsTechSkill, setDsTechSkill] = useState([]);
  const [otherAssessment, setOtherAssessment] = useState([]);
  const [otherInterViewer, setOtherInterViewer] = useState([]);
  const [showAssessmentPopup, setShowAssessmentPopup] = useState(false)
  const [overallRating, setOverallRating] = useState(0);
  const [roundResultId, setRoundResultId] = useState(null)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [showFilePreview, setShowFilePreview] = React.useState(false);
  const [showFilePreviewLoad, setShowFilePreviewLoad] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [newAttitudeRating, setNewAttitudeRating] = useState(null);
  const [newSkillRating, setNewSkillRating] = useState(null);
  const [skillExperince, setSkillExperince] = useState();
  const [isAddingSkill, setIsAddingSkill] = useState(false);
  const [isAddingAttitude, setIsAddingAttitude] = useState(false);
  const [focusedSkillRowKey, setFocusedSkillRowKey] = useState(0);
  const [focusedAttitudeRowKey, setFocusedAttitudeRowKey] = useState(0);
  const htmlEditorNoteRef = useRef(null);
  const attitudeGridRef = useRef();
  const skillsGridRef = useRef();

  const RatingCellRender = (e) => {
    const handleRatingChange = async (newRating) => {
      // newAttitudeRating = newRating;
      setNewAttitudeRating(newRating);
      const updatedItem = dsAttitudeData.find(item => item.attitudeID === e.row.data.attitudeID);
      if (updatedItem) {
        const updatedData = dsAttitudeData.map(item =>
          item.attitudeID === e.row.data.attitudeID ? { ...item, rating: newRating } : item
        );
        setDsAttitudeData(updatedData);
        if (e.row.data.attitudeID) {
          const dataToUpdate =
          {
            attitudeID: updatedItem.attitudeID,
            rating: newRating
          }

          const result = await candidateAssessmentOpenServices.RateAttitude(openaccesscode, dataToUpdate);
          if (!result.isOk) {
            if (result.statusCode === 400) {
              ShowAlert(result.data, "Vakency");
            } else if (result.statusCode === 409) {
              ShowAlert(result.data, "Vakency");
            }
          } else {
            setNewAttitudeRating(null);
            setOverallRating(result?.data?.responseData?.overallInteractionRating)
          }
        }
      }
    };
    return (
      <>
        <div className="font-medium-18">
          {(candidateData?.interactionResultID == 0) ? (<StarRatingClickable initialRating={e.data.rating} onRatingChange={handleRatingChange} />) : //Read Only when interaction status is not pending
            (<StarRating rating={e.data.rating} />)}
        </div>
      </>
    );
  };

  const TechSkillRatingCellRender = (e) => {
    const handleRatingChange = async (newRating) => {
      setNewSkillRating(newRating);
      const updatedItem = dsTechSkill.find(item => item.skillID === e.row.data.skillID);
      if (updatedItem) {
        const updatedData = dsTechSkill.map(item =>
          item.skillID === e.row.data.skillID ? { ...item, rating: newRating } : item
        );
        setDsTechSkill(updatedData);
        if (e.row.data.skillID) {
          const dataToUpdate = {
            skillID: updatedItem?.skillID,
            rating: newRating,
            experience: updatedItem?.experience,
            observation: updatedItem?.observation,
          }
          SkillUpdated(dataToUpdate)
        }
      };
    }
    return (
      <>
        <div className="font-medium-18">
          {(candidateData?.interactionResultID == 0) ? <StarRatingClickable initialRating={e.data.rating} onRatingChange={handleRatingChange} /> : //Read Only when interaction status is not pending
            <StarRating rating={e.data.rating} />}
        </div>
      </>
    );
  };

  const ExperienceCellRender = (e) => {
    const dsMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const dsYears = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    const handleExpYearChange = async (value) => {
      let newExperience = null;
      if ((skillExperince !== undefined) && (skillExperince !== null)) {
        const experience = skillExperince;
        const integerPart = parseInt(experience);
        const fractionalPart = parseFloat((experience - integerPart));
        newExperience = value + fractionalPart;
        setSkillExperince(newExperience);
      } else {
        newExperience = value;
        setSkillExperince(newExperience);
      }
    }
    const handleMonthChange = (value) => {
      let newValue = 0
      if (value < 10) {
        newValue = value / 100
      } else if (value == 10) {
        newValue = parseFloat(0.10)
      }
      else {
        newValue = 0.11
      }
      const experience = skillExperince ? (parseInt(skillExperince) + newValue) : (newValue);
      setSkillExperince(experience);
    }

    return (
      <>
        <div className="row">
          <div className="col pe-0">
            <SelectBox
              id="AsesmentPage_Years"
              items={dsYears}
              label="Years"
              labelMode="floating"
              stylingMode="underlined"
              className="border-0"
              width={80}
              dropDownOptions={{
                height: 200,
              //  hideOnParentScroll: true,
              //   container:  "#AsesmentPage_Years"
              }}
              value={parseInt(skillExperince)}
              onValueChange={(value) => handleExpYearChange(value)}
              disabled={!candidateData?.interactionResultID == 0} //disableed when Interaction Result is not Pending
            />
          </div>
          <div className="col ps-0">
            <SelectBox
              id="AsesmentPage_MonthBox"
              items={dsMonths}
              stylingMode="underlined"
              label="Months"
              labelMode="floating"
              className="border-0"
              dropDownOptions={{
                height: 200,
              //  hideOnParentScroll: true,
              //   container:  "#AsesmentPage_MonthBox"
              }}
              value={(skillExperince && skillExperince.toString().includes('.'))
                ? parseInt(skillExperince.toFixed(2).toString().split('.')[1])
                : ""
              }
              onValueChange={(value) => handleMonthChange(value)}
              disabled={!candidateData?.interactionResultID == 0} //disabled when Interaction Result is not Pending
            />
          </div>
        </div>
      </>
    );
  };

  const ExperienceYearMonthCellRender = (e) => {
    const experience = e.data.experience;
    const integerPart = parseInt(experience);
    const fractionalPart = ((experience - integerPart).toFixed(2));
    //  const   newExperience = value + fractionalPart;
    return (
      <>
        <div className="d-flex gap-2 pt-2">
          {experience == 0 || experience == null ? "-" : (
            <>
              <div>
                {(integerPart == null || isNaN(integerPart)) ? null : (integerPart + " Years ")}
              </div>
              <div>
                {(fractionalPart == 0) ? "0 Months" : (isNaN(fractionalPart) ? "-" : parseInt(fractionalPart.toString().split('.')[1]) + " Months")}
              </div>
            </>
          )}
        </div>
      </>

    );
  };


  const OnTechSkillUpdate = (e) => {
    if (isAddingSkill) return;
    const updatedItem = dsTechSkill.find(item => item.skillID === focusedSkillRowKey);
    if (updatedItem) {
      const updatedData = dsTechSkill.map(item =>
        item.skillID === focusedSkillRowKey ? { ...item, experience: skillExperince } : item
      );
      setDsTechSkill(updatedData);
      const dataToUpdate = {
        skillID: updatedItem?.skillID,
        rating: updatedItem?.rating,
        experience: skillExperince,
        observation: updatedItem.observation,
      }
      SkillUpdated(dataToUpdate);
    }
  }

  const SkillUpdated = async (dataToUpdate) => {
    const result = await candidateAssessmentOpenServices.RateSkill(openaccesscode, dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      setOverallRating(result?.data?.responseData?.overallInteractionRating);
      setNewSkillRating(null);
      setSkillExperince(null);
    }
  }

  const onCandidateItemChange = (e) => {
    setTabSelectedItemIndex(e)
  }

  useEffect(() => {
    GetCandidateData();
  }, []);

  const GetCandidateData = async () => {
    const result = await candidateAssessmentOpenServices.GetCandidateAssesmentDetails(openaccesscode);
    if (!result.isOk) {
      NavigateTo("/error-page", {
        state: {
          message: 'Assessment link is expired or invalid. Please contact to your hiring manager'
        }
      });
    } else {
      // if (result.data?.scheduleDateTime) {
      //   const currentDate = new Date();
      //   const scheduleDateObject = new Date(result.data?.scheduleDateTime);
      //   if (scheduleDateObject < currentDate) { 
      //     NavigateTo("/error-page", {
      //       state: {
      //         message: 'Assessment link is expired or invalid. Please contact to your hiring manager'
      //       }
      //     });
      //   }
      // }
      setCandidateData(result.data);
      setDsAttitudeData(result.data?.attitudes)
      setDsTechSkill(result.data?.technicalSkills)
      setOverallRating(result.data?.interactionRating)
      GetAssessment();
      GetOtherAssessment();
      GetProfileImage().then((res) => {
        setProfileImg({
          uploadedFile: {
            fileData: res?.size > 0 ? window.URL.createObjectURL(res) : null,
          },
          attachedFile: res?.size > 0 ? res : null,
        });
      });
      GetResume().then((res) => {
        if (res?.statusCode == 204) {
          setResume({
            uploadedFile: {
              fileData: null
            },
            attachedFile: null
          })
        }
        else {
          setResume({
            uploadedFile: {
              fileData: res?.size > 0 ? window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' })) : null
            },
            attachedFile: res?.size > 0 ? res : null,
          });
        }
      });
    }
  };

  const GetProfileImage = async () => {
    const result = await candidateAssessmentOpenServices.GetCandidateProfileImage(openaccesscode);
    if (result.isOk) {
      return result.data;
    } else {
      return;
    }
  };

  const GetResume = async () => {
    const result = await candidateAssessmentOpenServices.GetCandidateResume(openaccesscode);
    if (result.isOk) {
      let response = result.data;
      return response;
    }
    else {
      if (result.statusCode == 204) {
        return result
      }
    }

  }

  const GetAssessment = async () => {
    const result = await candidateAssessmentOpenServices.GetAssessmentNotes(openaccesscode);
    if (result.statusCode === 200) {
      const response = result.data;
      if (response.assessmentNoteFormattedText) {
        htmlEditorNoteRef.current?.instance.option("value", response.assessmentNoteFormattedText);
      } else {
        GetAssessmentTemplate().then((res) => {
          if (res?.assessmentNoteFormattedText) {
            htmlEditorNoteRef.current?.instance.option("value", res.assessmentNoteFormattedText);
          } else {
            return;
          }
        });
      }
    } else {
      return;
    }
  };

  const GetAssessmentTemplate = async () => {
    const result = await candidateAssessmentOpenServices.GetAssessmentNoteTemplate(openaccesscode);
    if (result.statusCode === 200) {
      const response = result.data;
      return response;
    } else {
      return;
    }
  };
  const GetOtherAssessment = async () => {
    const result = await candidateAssessmentOpenServices.GetOtherAssessmentNotes(openaccesscode);
    if (result.statusCode === 200) {
      const response = result.data;
      setOtherAssessment(response)
      const otherInterviewer = response.map((res, index) => {
        return {
          iterviewer: res.interviewerEmailID,
          bgColor: colors[(index <= 12) ? (index) : (index - 12)]
        }
      })
      setOtherInterViewer(otherInterviewer)
    } else {
      return;
    }
  };
  const handleSubmit = () => {
    UpdateAssessment();
  }

  const UpdateAssessment = async () => {
    const dataToUpdate = {
      assessmentNotePlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
      assessmentNoteFormattedText: htmlEditorNoteRef.current?.instance.option("value")

    }
    const result = await candidateAssessmentOpenServices.UpdateAssessmentNotes(openaccesscode, dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
    } else {
      if (candidateData?.interviewerTypeID == 0) {
        setShowAssessmentPopup(true);
      } else {
        setShowSuccessPopup(true);
      }
    }
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleContentChange = async (dataToUpdate) => {
    try {
      if (candidateData?.interactionResultID == 0) {
        const result = await candidateAssessmentOpenServices.UpdateAssessmentNotes(openaccesscode, dataToUpdate);
        if (!result.isOk) {
          if (result.statusCode === 400 || result.statusCode === 409) {
            ShowAlert(result.data, "Vakency");
          }
        }
      }
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  };
  const debouncedHandleContentChange = debounce(handleContentChange, 2000);

  useEffect(() => {
    const editorInstance = htmlEditorNoteRef.current?.instance;

    if (editorInstance) {
      const handleValueChanged = (e) => {
        const dataToUpdate = {
          assessmentNotePlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
          assessmentNoteFormattedText: htmlEditorNoteRef.current?.instance.option("value")
        }
        debouncedHandleContentChange(dataToUpdate);
      };

      editorInstance.on("valueChanged", handleValueChanged);

      // Cleanup
      return () => {
        editorInstance.off("valueChanged", handleValueChanged);
      };
    }
  }, [handleContentChange]);

  const HideAssessmentPopup = () => {
    setShowAssessmentPopup(!showAssessmentPopup)
  }
  const HideSuccessPopup = () => {
    setShowSuccessPopup(!showSuccessPopup)
  }
  const handleRoundResult = (e) => {
    setRoundResultId(e.value)
  }

  const onEmailButtonClick = () => {
    window.location.href = `mailto:${candidateData?.emailID}`;
  }
  const onPhoneButtonClick = () => {
    window.location.href = `tel:${candidateData?.candidateMobileNo}`;
  }

  const onPictureClick = (fileSrc) => {
    if (fileSrc) {
      setShowFilePreview(true);
      setSelectedFile({
        fileSrc: fileSrc,
      });
    }
  }

  const onFilePreviewClose = () => {
    setShowFilePreview(false)
  }
  const insertNewAttitudeRow = (e) => {
    attitudeGridRef.current.instance.addRow();
    attitudeGridRef.current.instance.deselectAll();
  }

  const insertNewSkillRow = (e) => {
    skillsGridRef.current.instance.addRow();
    skillsGridRef.current.instance.deselectAll();
  }

  const onInitNewAttitudeRow = () => {
    setIsAddingAttitude(true);
    setNewAttitudeRating(null);
  }
  const onNewAttitudeInserted = async (e) => {
    const dataToUpdate = {
      attitudeID: null,
      attitudeName: e.data.attitudeName,
      rating: newAttitudeRating,
    }
    const result = await candidateAssessmentOpenServices.RateAttitude(openaccesscode, dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      dsAttitudeData.pop();
      setDsAttitudeData([...dsAttitudeData]);
    } else {

      const existingData = dsAttitudeData.find(item => item.attitudeID === result.data?.responseData.attitudeID);
      if (existingData) {
        existingData.rating = newAttitudeRating;
        dsAttitudeData.pop();
        setDsAttitudeData([...dsAttitudeData]);
      } else {
        const updatedData = dsAttitudeData.map(item =>
          item.attitudeID === e.data.attitudeID ? { ...item, attitudeID: result.data?.responseData.attitudeID, rating: newAttitudeRating } : item
        );
        setDsAttitudeData(updatedData);
      }
      setOverallRating(result?.data?.responseData?.overallInteractionRating);
      setFocusedAttitudeRowKey(result.data?.responseData.attitudeID);
      setNewAttitudeRating(null);
    }
    setIsAddingAttitude(false);
  }
  const onAttitudeEditCanceled = () => {
    setIsAddingAttitude(false);
  }

  const onInitNewSkillRow = () => {
    setIsAddingSkill(true);
    setNewSkillRating(null);
    setSkillExperince(null);
  }

  const handleSkillEditingStart = (e) => {
    if (!e.data.isNewRow) {
      setIsAddingSkill(false);
      setSkillExperince(e.data.experience || null);
    }
  };

  const onNewSkillInserted = async (e) => {
    const dataToUpdate = {
      skillID: null,
      skillName: e.data.skillName,
      rating: newSkillRating,
      experience: skillExperince,
      observation: e.data.observation,
    }
    const result = await candidateAssessmentOpenServices.RateSkill(openaccesscode, dataToUpdate);
    if (!result.isOk) {
      if (result.statusCode === 400) {
        ShowAlert(result.data, "Vakency");
      } else if (result.statusCode === 409) {
        ShowAlert(result.data, "Vakency");
      }
      dsTechSkill.pop();
      setDsTechSkill([...dsTechSkill]);
    } else {
      const existingData = dsTechSkill.find(item => item.skillID === result.data?.responseData.skillID);
      if (existingData) {
        existingData.observation = dataToUpdate.observation;
        existingData.rating = newSkillRating;
        existingData.experience = skillExperince;
        dsTechSkill.pop();
        setDsTechSkill([...dsTechSkill]);
      } else {
        const updatedData = dsTechSkill.map(item =>
          item.skillID === e.data.skillID ? { ...item, skillID: result.data?.responseData.skillID, rating: newSkillRating, experience: skillExperince } : item
        );
        setDsTechSkill(updatedData);
      }
      setOverallRating(result?.data?.responseData?.overallInteractionRating);
      setFocusedSkillRowKey(result.data?.responseData.skillID);
      setNewSkillRating(null);
      setSkillExperince(null);
    }
  }

  function onFocusedSkillRowChanged(e) {
    setFocusedSkillRowKey(e.component.option("focusedRowKey"));
  }

  function onFocusedAttitudeRowChanged(e) {
    setFocusedAttitudeRowKey(e.component.option("focusedRowKey"));
  }

  return (
    <>
      <LoadPanel
        visible={showFilePreviewLoad}
        message='File is loading. We’ll display the preview shortly.'
      />
      <ScrollView height={'100%'} width={'100%'}>
        <div className="container-fluid assessment-section">
          <div className="row">
            <div className="col-xs-12 col-md assessment-card">
              <div className="row">
                <div className="col-auto pe-0">
                  <div className="left-user-img me-3">
                    <img src={userAssessmentIcon} alt="user" width={36} />
                  </div>
                </div>
                <div className="col ps-0">
                  <div className='row'>
                    <div className="col font-medium-22">
                      {candidateData?.interactionRoundName} <span className='text-gray'>for</span> {candidateData?.jobProfileName}
                    </div>
                    <div className="col-auto">
                      <img
                        src={candidateData?.interactionResultName === "Pending" ? pendingIcon : candidateData?.interactionResultName === "Passed" ? selectedIcon : candidateData?.interactionResultName === "Failed" ? failedIcon : candidateData?.interactionResultName === "Candidate did not attend" ? notAttendIcon : candidateData?.interactionResultName === "Cancelled by candidate" ? candidateUnavailableIcon : candidateData?.interactionResultName === "Cancelled by us" ? interviewCancelledIcon : rejectedIcon} //this is the status of interaction, it should be Pending, Passed, Failed, CandidateDidNotAttend,CancelledByCandidate, CancelledByUs   
                        title={candidateData?.interactionResultName}
                        alt="interaction status"
                        width={24}
                      />
                    </div>
                  </div>
                  <div className="row pt-1">
                    <div className="col-auto">
                      <span title="Interview Date ">
                        Interview Date:{" "}
                        {(candidateData?.scheduleDateTime) && <span className="font-semiBold-14">
                          {`${GetFormattedDate(candidateData?.scheduleDateTime)} - ${GetFormattedTime(candidateData?.scheduleDateTime)}`}
                        </span>}
                      </span>
                    </div>
                    <div className="col-auto px-0">
                      <span className="assessment-pipe px-0">|</span>
                    </div>
                    <div className="col-auto">
                      <span title="Application Date">
                        Application Date:{" "}
                        {(candidateData?.jobApplicationDate) && <span className="font-semiBold-14">
                          {new Date(candidateData?.jobApplicationDate).toLocaleDateString()}
                        </span>}
                      </span>
                    </div>
                    <div className="col-auto px-0">
                      <span className="assessment-pipe">|</span>
                    </div>

                    <div className="col-auto">
                      <a
                        href={`${candidateData?.meetingLink}`}
                        target="_blank"
                        rel="noreferrer"
                        className="join-meeting-section"
                      >
                        <span className="pe-1 font-medium-13 text-decoration-underline">
                          Meeting Link
                        </span>
                        {candidateData?.meetingLink?.includes("meet") ? (
                          <img
                            src={googleMeetingIcon}
                            alt="google meet"
                            width={20}
                          />
                        ) : candidateData?.meetingLink?.includes("teams") ? (
                          <img src={teamsIcon} alt="teams" width={20} />
                        ) : candidateData?.meetingLink?.includes("skype") ? (
                          <img src={skypeIcon} alt="skype" width={20} />
                        ) : candidateData?.meetingLink?.includes("zoom") ? (
                          <img src={zoomIcon} alt="zoom" width={24} />
                        ) : (
                          ""
                        )}
                      </a>
                    </div>
                  </div>
                </div>
                {/* {
                  candidateData?.interactionResultName && (
                    <div className="col-auto">
                      <img
                        src={candidateData?.interactionResultName === "Pending" ? pendingIcon : candidateData?.interactionResultName === "Passed" ? selectedIcon : candidateData?.interactionResultName === "Failed" ? failedIcon : candidateData?.interactionResultName === "Candidate did not attend" ? notAttendIcon : candidateData?.interactionResultName === "Cancelled by candidate" ? candidateUnavailableIcon : candidateData?.interactionResultName === "Cancelled by us" ? interviewCancelledIcon : rejectedIcon} //this is the status of interaction, it should be Pending, Passed, Failed, CandidateDidNotAttend,CancelledByCandidate, CancelledByUs   
                        title={candidateData?.interactionResultName}
                        alt="interaction status"
                        width={24}
                      />
                    </div>
                  )
                } */}
              </div>
            </div>
            <div className="col-xs-12 col-md assessment-card ms-xs-0 ms-md-2 mt-md-0 mt-2 pt-3">
              <div className="row">
                <div className="col font-medium-22 px-3">
                  <span className="interviewer-badge">{(candidateData?.interviewerTypeID == 0) ? "Interviewer" : "Co-Interviewer"}</span>
                  <div className="italic-text">{candidateData?.interviewerEmailID}</div>
                </div>
                <div className="col-auto d-flex align-items-end">
                  {otherInterViewer.map((iterviewer, index) =>
                    <div key={index} title={iterviewer.iterviewer} className="interviews-tag  ms-1" style={{ backgroundColor: `${iterviewer.bgColor}`, cursor: "pointer" }} >{iterviewer.iterviewer.slice(0, 1).toUpperCase()}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-6 px-0 ps-lg-0 pe-lg-1">
            <div className="Assement-both-card assessment-card mt-2">
              <div className="assessment-details-tab">
                <div className="font-semiBold-18 pb-2">Candidate{" "}
                  {tabSelectedItemIndex === 1 &&
                    <span className="candidate-details-top">
                      <span >- {candidateData?.candidateName}<span className="assessment-pipe mx-1">|</span><span title={candidateData?.candidateEmailID}>{candidateData?.candidateEmailID}</span>{candidateData?.candidateMobileNo && <span className="assessment-pipe mx-1">|</span>} {candidateData?.candidateMobileNo}
                      </span>
                    </span>}</div>
                <TabPanel
                  animationEnabled={true}
                  stylingMode={"secondary"}
                  onSelectedIndexChange={onCandidateItemChange}
                >
                  <Item title="Details">
                    <div className="tab-panel-item">
                      <div className="row pt-3">
                        <div className="col-auto">
                          <div onClick={() => onPictureClick(profileImg.uploadedFile?.fileData)}>
                            {(profileImg.uploadedFile?.fileData) ? <img
                              src={profileImg.uploadedFile?.fileData}
                              width={100}
                              height={100}
                              alt="candidate"
                              className="candidate-image"
                            /> :
                              <img
                                src={BlankProfile}
                                width={100}
                                height={100}
                                alt="candidate"
                                className="candidate-image"
                              />}
                          </div>
                        </div>
                        <div className="col mx-0">
                          <div className="d-flex font-medium-22 d-flex ">
                            <div className="NameSection" title={candidateData?.candidateName}>{candidateData?.candidateName}</div>
                            <div className="font-medium-20 ps-3">
                              <NewStarRating rating={candidateData?.candidateRating} />
                            </div>
                          </div>
                          <div className="row font-medium-16">
                            <div className="col-auto">
                              <Button
                                icon="email"
                                hint="Email"
                                stylingMode="text"
                                className="resume-edit-btn"
                                onClick={onEmailButtonClick}
                              />{" "}
                              {candidateData?.candidateEmailID ? candidateData?.candidateEmailID : ""}
                            </div>
                            {candidateData?.candidateEmailID && <div className="col-auto px-0 assessment-pipe">|</div>}
                            <div className="col-auto">
                              <span onClick={onPhoneButtonClick} className="cursor-pointer">
                                <i className="dx-icon-MobileIcon dx-custom-icon"></i>{" "}
                                {candidateData?.candidateMobileNo}
                              </span>
                            </div>
                            <div className="col-auto px-0 assessment-pipe">|</div>
                            <div className="col-auto"><span title="Age">{candidateData?.candidateAgeInYears ? (candidateData?.candidateAgeInYears + "yrs") : ""} </span></div>
                            {candidateData?.candidateAgeInYears && <div className="col-auto px-0 assessment-pipe">|</div>}
                            <div className="col-auto"><span title="Marital Status">{candidateData?.candidateMaritalStatusName}</span></div>
                          </div>
                          <div className="row mt-1 font-medium-16">
                            {candidateData?.currentCityName &&
                              <>
                                <div className="col-auto">
                                  <i className="dx-icon-locationIcon cursor-pointer dx-assesment-custom-icon mb-1" title={candidateData?.currentCityName} onClick={() => handleNavigateToMap(candidateData?.currentCityName)}></i>{" "}
                                  {candidateData?.currentCityName}
                                </div>
                                <div className="col-auto px-0 assessment-pipe">|</div>
                              </>
                            }
                            {candidateData?.permanentCityName &&
                              <>
                                <div className="col-auto">
                                  <i className="dx-icon-homeTownIcon cursor-pointer dx-assesment-custom-icon mb-1 me-1 " title={candidateData?.permanentCityName} onClick={() => handleNavigateToMap(candidateData?.permanentCityName)}></i>{" "}
                                  {candidateData?.permanentCityName}
                                </div>
                                <div className="col-auto px-0 assessment-pipe">|</div>
                              </>
                            }
                            <div className="col-auto ">
                              {candidateData?.candidateLinkedIn ? <a
                                href={`${candidateData?.candidateLinkedIn}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="dx-icon-LinkedInIcon dx-assesment-custom-icon-lg"></i>
                              </a> : <i className="dx-icon-LinkedInDisabledIcon dx-custom-icon" />}
                            </div>
                            <div className="col-auto px-0 assessment-pipe">|</div>
                            <div className="col-auto ">
                              {candidateData?.candidateSkypeID ? <a
                                href={`skype:${candidateData?.candidateSkypeID}?chat`}
                                rel="noreferrer"
                              >
                                <i className="dx-icon-skypeIcon dx-assesment-custom-icon-lg"></i>
                              </a> : <i className="dx-icon-skypeDisabledIcon dx-custom-icon" />}
                            </div>
                            <div className="col-auto px-0 assessment-pipe">|</div>
                            <div className="col-auto ">
                              {candidateData?.mobileNo2 ? <a
                                href={`https://wa.me/91${candidateData?.mobileNo2}`}
                                rel="noreferrer"
                                target='_blank'
                              >
                                <i className="dx-icon-WhatsappIcon dx-custom-icon"></i>
                              </a> : <i className="dx-icon-WhatsappDisabledIcon dx-custom-icon"></i>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mb-2" />
                      <span className="font-medium-14 text-decoration-underline">
                        Professional Details
                      </span>
                      <div className="row font-medium-14">
                        <div className="col-4">
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.lastAcademicQualification}>{(candidateData?.lastAcademicQualification) ? (candidateData?.lastAcademicQualification) : "-"}</div>
                          <div className="italic-text" title="Academic Qualification">Academic Qualification</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.totalExperience}>{(candidateData?.totalExperience) ? (candidateData?.totalExperience + " " + (candidateData?.totalExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                          <div className="italic-text " title="Total Experience">Total Experience</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.relevantExperience}>{(candidateData?.relevantExperience) ? (candidateData?.relevantExperience + " " + (candidateData?.relevantExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                          <div className="italic-text" title="Relevant Experience">Relevant Experience</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.requiredExperience}>{(candidateData?.requiredExperience) ? (candidateData?.requiredExperience + " " + (candidateData?.requiredExperience > 1 ? 'yrs' : 'yr')) : "-"}</div>
                          <div className="italic-text" title="Relevant Experience">Required Experience</div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.currentCTC?.toFixed(2)}>{(candidateData?.currentCTC) ? ((candidateData?.currentCTC)).toFixed(2) : "-"}</div>
                          <div className="italic-text" title="Current CTC(LPA)">Current CTC(LPA)</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.expectedCTC?.toFixed(2)}>{(candidateData?.expectedCTC) ? ((candidateData?.expectedCTC)).toFixed(2) : "-"}</div>
                          <div className="italic-text" title="Expected CTC(LPA)">Expected CTC(LPA)</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={(candidateData?.lastInteractionRoundName) ? candidateData?.lastInteractionRoundName + " " + new Date(candidateData.lastInteractionDateTime)?.toLocaleDateString() : ""}>
                            {(candidateData?.lastInteractionRoundName) ? (candidateData?.lastInteractionRoundName) : "-"} {candidateData?.lastInteractionDateTime
                              ? new Date(candidateData.lastInteractionDateTime).toLocaleDateString()
                              : "-"}
                          </div>
                          <div className="italic-text" title="Last Interaction">Last Interaction</div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.currentCompanyName}>{(candidateData?.currentCompanyName) ? (candidateData?.currentCompanyName) : "-"}</div>
                          <div className="italic-text" title={"Current Employer"}>Current Employer</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.noticePeriod}>{(candidateData?.noticePeriod) ? (candidateData?.noticePeriod + " days") : "-"}</div>
                          <div className="italic-text" title={"Notice Period"}>Notice Period</div>
                          </div>
                          <div className="row mb-2">
                          <div className="mt-2" title={candidateData?.leavingReason}>{(candidateData?.leavingReason) ? (candidateData?.leavingReason) : "-"}</div>
                          <div className="italic-text" title={"Leaving Reason"}>Leaving Reason</div>
                          </div>
                        </div>
                      </div>
                      <hr className="mb-2" />
                      <div className="row pb-1">
                        <div className={`col-auto ${isExLarge ? 'col-lg-4' : ""}`}>
                          <div className="custom-card-grid mt-1">
                            <DataGrid
                              keyExpr="attitudeID"
                              dataSource={dsAttitudeData}
                              className="TechSkillDatagrid p-0 mt-0"
                              showColumnLines={false}
                              showRowLines={true}
                              height={365}
                              wordWrapEnabled
                              ref={attitudeGridRef}
                              onRowInserted={onNewAttitudeInserted}
                              onInitNewRow={onInitNewAttitudeRow}
                              focusedRowKey={focusedAttitudeRowKey}
                              onFocusedRowChanged={onFocusedAttitudeRowChanged}
                              focusedRowEnabled={true}
                              autoNavigateToFocusedRow={true}
                              hoverStateEnabled={true}
                              onEditCanceled={onAttitudeEditCanceled}
                            >
                              <Editing
                                mode="row"
                                allowAdding={candidateData?.interactionResultID == 0}
                                allowUpdating={false}
                                allowDeleting={false}
                                useIcons={true}
                              />
                              <Column dataField="attitudeName" caption="Name" alignment="left" width={(isExLarge) ? '190px' : '100%'} editorOptions={attitudeNameEditorOption}>
                                <RequiredRule message="Attitude name is required" />
                              </Column>
                              <Column
                                alignment="left"
                                dataField="rating"
                                caption="Rating"
                                cellRender={RatingCellRender}
                                editCellRender={RatingCellRender}
                                width={100}
                                minWidth={90}
                                allowSorting={false}
                              />
                              <Column
                                type="buttons"
                                fixed={true}
                                fixedPosition="right"
                                visible={candidateData?.interactionResultID === 0 && isAddingAttitude}
                              />
                              <Scrolling mode={"standard"} />
                              <Paging enabled={false} />
                              <Toolbar>
                                <GridItem location='before'>
                                  <div className="font-medium-14 text-decoration-underline">
                                    Attitude
                                  </div>
                                </GridItem>
                                <GridItem
                                  location='after'
                                  locateInMenu='auto'
                                  name="addRowButton"
                                  showText="always"
                                  visible={candidateData?.interactionResultID === 0}
                                >
                                  <Button
                                    icon="plus"
                                    className="rounded-5 default-button grid-add-btn"
                                    id="Add"
                                    stylingMode="outlined"
                                    type="default"
                                    hint="Add Attitude"
                                    focusStateEnabled={false}
                                    activeStateEnabled={false}
                                    onClick={insertNewAttitudeRow}
                                  />
                                </GridItem>
                              </Toolbar>
                            </DataGrid>
                          </div>
                        </div>
                        <div className={`col ${isExLarge ? 'col-lg-8' : 'pt-2 mt-1'}`}>
                          <div className="custom-card-grid mt-1">
                            <DataGrid
                              keyExpr="skillID"
                              dataSource={dsTechSkill}
                              className="TechSkillDatagrid"
                              showColumnLines={false}
                              showRowLines={true}
                              height={365}
                              ref={skillsGridRef}
                              onRowInserted={onNewSkillInserted}
                              onInitNewRow={onInitNewSkillRow}
                              onEditingStart={handleSkillEditingStart}
                              onSaved={OnTechSkillUpdate}
                              focusedRowKey={focusedSkillRowKey}
                              onFocusedRowChanged={onFocusedSkillRowChanged}
                              focusedRowEnabled={true}
                              autoNavigateToFocusedRow={true}
                              hoverStateEnabled={true}
                            >
                              <Editing
                                mode="row"
                                allowAdding={candidateData?.interactionResultID == 0}
                                allowUpdating={candidateData?.interactionResultID == 0}
                                allowDeleting={false}
                                useIcons={true}
                              />
                              <Scrolling mode={"standard"} />
                              <Paging enabled={false} />
                              <Column
                                caption="Skill Name"
                                dataField="skillName"
                                alignment="left"
                                width={140}
                                minWidth={100}
                                allowEditing={isAddingSkill}
                                editorOptions={skillNameditorOption}
                              >
                                <RequiredRule message="Skill name is required" />
                              </Column>
                              <Column
                                caption="Rating"
                                dataField="rating"
                                width={120}
                                minWidth={90}
                                alignment="left"
                                allowEditing={isAddingSkill}
                                cellRender={TechSkillRatingCellRender}
                                editCellRender={TechSkillRatingCellRender}
                              />
                              <Column
                                caption="Experience "
                                dataField="experience"
                                width={210}
                                minWidth={210}
                                cssClass="experience-column"
                                editCellRender={ExperienceCellRender}
                                cellRender={ExperienceYearMonthCellRender}
                                allowEditing={true}
                                alignment="left"
                              >
                              </Column>
                              <Column
                                caption="Observation"
                                dataField="observation"
                                minWidth={100}
                                editorOptions={observationEditorOption}
                                allowEditing={candidateData?.interactionResultID === 0}
                              />
                              <Column
                                type="buttons"
                                fixed={true}
                                fixedPosition="right"
                                visible={candidateData?.interactionResultID === 0}
                              />
                              <Toolbar>
                                <GridItem location='before'>
                                  <div className="font-medium-14 text-decoration-underline">
                                    Technical Skills
                                  </div>
                                </GridItem>
                                <GridItem
                                  location='after'
                                  widget='dxButton'
                                  locateInMenu='auto'
                                  visible={candidateData?.interactionResultID === 0}
                                >
                                  <Button
                                    icon="plus"
                                    className="rounded-5 default-button grid-add-btn"
                                    id="Add"
                                    stylingMode="outlined"
                                    type="default"
                                    hint="Add Technical Skills"
                                    focusStateEnabled={false}
                                    activeStateEnabled={false}
                                    onClick={insertNewSkillRow}
                                  />
                                </GridItem>
                              </Toolbar>
                            </DataGrid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Item>
                  <Item title="Resume">
                    <div className="pt-2 tab-panel-item" >
                      {(resume?.uploadedFile?.fileData) ? <iframe
                        src={`${resume?.uploadedFile?.fileData}#navpanes=0`}
                        width="100%"
                        height={(isExLarge || isMedium) ? "670px" : "550px"}
                        title="Resume"
                      /> :
                        <p className="d-flex justify-content-center">No data for preview.</p>
                      }
                    </div>
                  </Item>
                </TabPanel>
              </div>
            </div>
            </div>
            <div className="col-xs-12 col-lg-6 ms-0 mt-1 mt-lg-0 px-0 ps-lg-1 pe-lg-0">
            <div className="Assement-both-card assessment-card mt-2">
              <div className="assessment-details-tab">
                <div className="font-semiBold-18 pb-2">Assessment</div>
                <TabPanel
                  animationEnabled={true}
                  swipeEnabled={false}
                  stylingMode={"secondary"}
                >
                  <Item title="Your Assessment">
                    <div className="pt-2">
                      <div className="assessment-html-editor-section mx-1">
                        <HtmlEditor
                          ref={htmlEditorNoteRef}
                          id={"JobDescribtionHtmlEditor"}
                          elementAttr={htmlEditorSecondAttributes}
                          valueType="html"
                          height={isExLarge ? "70vh" : tabSelectedItemIndex === 1 ? "790px" : "1100px"}
                          className="CustomizeHtmlEditor"
                          disabled={!candidateData?.interactionResultID == 0}
                        >
                          <HtmlEditorToolbar multiline={false}>
                            <HtmlEditorItem name="link"></HtmlEditorItem>
                            <HtmlEditorItem
                              name="font"
                              acceptedValues={fontValues}
                            />
                            <HtmlEditorItem
                              name="size"
                              acceptedValues={sizeValues}
                            />
                            <HtmlEditorItem name="bold" />
                            <HtmlEditorItem name="italic" />
                            <HtmlEditorItem name="underline" />
                            <HtmlEditorItem name="background" />
                            <HtmlEditorItem name="color" />
                            <HtmlEditorItem name="orderedList" />
                            <HtmlEditorItem name="bulletList" />
                            <HtmlEditorItem name="clear" />
                          </HtmlEditorToolbar>
                        </HtmlEditor>
                      </div>
                      <div className={`row pt-2 ps-2 ${(candidateData?.interviewerTypeID == 0) ? "d-flex justify-content-end" : ""}`}>
                        <div className="col pt-2 d-flex align-items-center">
                          <div className="pt-1 font-medium-14">
                            Over All Rating:
                          </div>

                          <div className=" font-medium-20 ps-2">
                            <NewStarRating rating={overallRating} />
                          </div>
                        </div>
                        <div className="col-auto">
                          {(candidateData?.interviewerTypeID == 0) ? <div className=" font-medium-14 d-flex">
                            <SelectBox
                               id={"Assesment_Page_RoundResult"}
                              dataSource={roundResult}
                              displayExpr="type"
                              valueExpr="value"
                              value={roundResultId}
                              placeholder="Select a status"
                              label="Round Result"
                              labelMode="floating"
                              searchEnabled={true}
                              className="ms-3"
                              onValueChanged={(e) => handleRoundResult(e)}
                              disabled={!candidateData?.interactionResultID == 0}
                              dropDownOptions={{
                                hideOnParentScroll: true,
                                container:  "#Assesment_Page_RoundResult"
                              }}
                            />
                          </div> : ""}
                        </div>
                        <div className="col-auto pt-2 pe-3">
                          <Button
                            text="Submit"
                            type="default"
                            height={33}
                            width={120}
                            className="rounded-3 "
                            onClick={handleSubmit}
                            disabled={!candidateData?.interactionResultID == 0}
                          />
                        </div>
                      </div>
                    </div>
                  </Item>
                  <Item title="Other Assessment">
                    <ScrollView
                      height={isExLarge ? "70vh" : tabSelectedItemIndex === 1 ? "790px" : "1100px"}
                    >
                      {otherAssessment.map((assessment, index) => (
                        assessment.assessmentNoteFormattedText && (
                          <div key={index} className="mt-3 other-assessment-card">
                            <div className="other-assessment-header d-flex">
                              {assessment.interviewerEmailID}
                            </div>
                            <div className='px-3 pt-3 pb-1' dangerouslySetInnerHTML={{ __html: assessment.assessmentNoteFormattedText || '-' }} />
                          </div>
                        )
                      ))}
                    </ScrollView>
                  </Item>
                </TabPanel>
              </div>
            </div>
            </div>
          </div>
        </div>
      </ScrollView>
      {showAssessmentPopup && <AssessmentPopup showAssessmentPopup={showAssessmentPopup} HideAssessmentPopup={HideAssessmentPopup} overallRating={overallRating} roundResult={roundResult} roundResultId={roundResultId} setRoundResultId={setRoundResultId} openaccesscode={openaccesscode} GetCandidateData={GetCandidateData} />}
      {showSuccessPopup && <AssessmentSuccessPopup showSuccessPopup={showSuccessPopup} HideSuccessPopup={HideSuccessPopup} />}
      {
        showFilePreview && (
          <FilePreviewer
            showFilePreview={showFilePreview}
            selectedFile={selectedFile}
            setShowFilePreviewLoad={setShowFilePreviewLoad}
            onFilePreviewClose={onFilePreviewClose}
            isImageOnly={true}
          />
        )
      }
    </>
  );
};

export default Assessment;

import { Button, LoadPanel, Popup, ScrollView } from "devextreme-react";
import HtmlEditor, {
    Toolbar as HtmlEditorToolbar,
    Item as HtmlEditorItem,
} from "devextreme-react/html-editor";
import React, { useRef } from "react";
import { ShowAlert } from "../../utils/common-methods";
import { useScreenSize } from "../../utils/media-query";
import PopupHeader from "../../layouts/popup-header-footer/PopupHeader";
import { JobManagementOpenServices } from "../../api/services/JobManagementOpenServices";

const jobManagementOpenServices = new JobManagementOpenServices()

const RejectOfferPopup = ({
    showPopup,
    HideRejectOfferPopup,
    openaccesscode,
    onOfferRejected,
}) => {

    const [showLoadPanel, setShowLoadPanel] = React.useState(false);
    const { isExSmall, isXXSmall, isXSmall, isMedium, isSmall, isExLarge } = useScreenSize();
    const htmlEditorSecondAttributes = {
        class: "noteSecondHtml",
    };
    const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
    const fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
    ];
    const htmlEditorNoteRef = useRef(null);

    const onClosePopup = () => {
        HideRejectOfferPopup();
    };

    const PopupTitle = (e) => {
        return (
            <>
                <PopupHeader
                    onClosePopup={onClosePopup}
                    title={"Reject Offer"}
                    isWithoutValidationPopup={true}
                    onSubmit={onSubmit}
                />
            </>
        );
    };

    const onSubmit = async () => {
        setShowLoadPanel(true);
        const dataToInsert = {
            notePlainText: htmlEditorNoteRef.current?.instance.getQuillInstance().getText(),
            noteFormattedText: htmlEditorNoteRef.current?.instance.option("value")
        }
        try {
            const result = await jobManagementOpenServices.OfferRejectedByCandidate(openaccesscode, dataToInsert);
            if (!result.isOk) {
                ShowAlert(result.data, "Vakency");
            } else {
                HideRejectOfferPopup();
                onOfferRejected();
            }
        } catch (err) {
            console.error(err);
        } finally {
            onHidingLoadPanel();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    }

    const onHidingLoadPanel = () => {
        setShowLoadPanel(false);
    }

    return (
        <>
            <Popup
                visible={showPopup}
                onHiding={HideRejectOfferPopup}
                width={
                    isXSmall || isXXSmall || isExSmall
                        ? "95vw"
                        : isMedium || isSmall
                            ? "70vw"
                            : "50vw"
                }
                height={"auto"}
                maxHeight={"95vh"}
                titleRender={PopupTitle}
                wrapperAttr={{ class: "CustomPopup" }}
            >
                <LoadPanel
                    visible={showLoadPanel}
                    onHiding={onHidingLoadPanel}
                    message="Processing your decision. This won’t take long."
                />
                <ScrollView width={"100%"} height={"100%"}>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="assessment-html-editor-section px-4">
                                <HtmlEditor
                                    ref={htmlEditorNoteRef}
                                    id={"JobDescribtionHtmlEditor"}
                                    elementAttr={htmlEditorSecondAttributes}
                                    valueType="html"
                                    width={"auto"}
                                    height={"300px"}
                                    className="CustomizeHtmlEditor"
                                    placeholder="Reject Offer Reason"
                                >
                                    <HtmlEditorToolbar multiline={false}>
                                        <HtmlEditorItem name="link"></HtmlEditorItem>
                                        <HtmlEditorItem
                                            name="font"
                                            acceptedValues={fontValues}
                                        />
                                        <HtmlEditorItem
                                            name="size"
                                            acceptedValues={sizeValues}
                                        />
                                        <HtmlEditorItem name="bold" />
                                        <HtmlEditorItem name="italic" />
                                        <HtmlEditorItem name="underline" />
                                        <HtmlEditorItem name="background" />
                                        <HtmlEditorItem name="color" />
                                        <HtmlEditorItem name="orderedList" />
                                        <HtmlEditorItem name="bulletList" />
                                        <HtmlEditorItem name="clear" />
                                    </HtmlEditorToolbar>
                                </HtmlEditor>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end py-2 pe-3">
                            <Button
                                text="Submit"
                                type="default"
                                height={33}
                                width={120}
                                className="rounded-3"
                                useSubmitBehavior={true}
                            />
                        </div>
                    </form>
                </ScrollView>
            </Popup>
        </>
    );
};

export default RejectOfferPopup;

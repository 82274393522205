import React, { useEffect, useRef, useState } from 'react';
import { CheckBox, NumberBox, Popup, ScrollView, SelectBox, TextArea, Validator } from 'devextreme-react';
import { CompareRule, EmailRule, RequiredRule, StringLengthRule } from 'devextreme-react/data-grid';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import openeye from '../../../images/openeye.png'
import closeeyeIcon from '../../../images/closeeyeIcon .png'
import UserProfileUploader from './UserProfileUploader';
import { useScreenSize } from '../../../utils/media-query';
import PopupHeader from '../../../layouts/popup-header-footer/PopupHeader';
import PopupFooter from '../../../layouts/popup-header-footer/PopupFooter';
import { ConflictPopup, PasswordRegex, onKeyDown } from '../../../utils/common-methods';
import { ShowAlert, eCRUDStatus } from '../../../utils/common-methods';
import { AsyncRule, CustomRule, PatternRule } from 'devextreme-react/validator';
import StateDropDown from '../state/StateDropDown';
import CityDropDown from '../city/CityDropDown';
import PopupSkeleton from '../../../layouts/templates/PopupSkeleton';
import { useAuth } from '../../../contexts/auth';
import { UsersServices } from '../../../api/services/UsersServices';



const usersServices = new UsersServices();
export default function UserPopup(props) {

    const ValidationGroupName = "userPopupValidation"

    const [data, setData] = useState({});
    const [originalRecord, setOriginalRecord] = useState({});
    const [PasswordMode, setPasswordMode] = useState("password")
    const [showPasswordIcon, setShowpasswordIcon] = useState(openeye)
    const [confirmPasswordMode, setConfirmPasswordMode] = useState("password")
    const [showConfirmPasswordIcon, setShowConfirmpasswordIcon] = useState(openeye)
    const [showChangePaswordOption, setShowChangePasswordOption] = useState(false)
    const [dropDownClick, setDropDownClick] = useState(false);
    const [addedCityStateID, setAddedCityStateID] = useState(0);
    const [addedCityID, setAddedCityID] = useState(0);
    const [zipCode, setZipCode] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const {getUserImage,setProfile} = useAuth();

    useEffect(() => {
        if (addedCityStateID !== 0) {
            setData({ ...data, cityStateID: addedCityStateID });
            setAddedCityStateID(0);
        }
    }, [addedCityStateID]);

    useEffect(() => {
        if (addedCityID !== 0) {
            setData({ ...data, cityID: addedCityID });
            setAddedCityID(0);
        }
    }, [addedCityID]);

    useEffect(() => {
        if (props.primaryKey !== null && props.primaryKey !== 0) {
            GetUserModel();
        } else {
            setTimeout(async () => {
                setData({});
                setShowSkeleton(false);
            }, 1000);
        }
    }, [props.primaryKey])

    useEffect(() => {
        if (data.cityID && data.cityID !== zipCode) {
            GetZip(data.cityID);
            setZipCode(undefined)
        }
        else if (data.cityID === zipCode) {
            setData({ ...data })
        }
        else if (!data.cityID) {
            setData({ ...data, zipCode: undefined })
        }
    }, [data.cityID])

    useEffect(()=>{
        !showSkeleton && FocusedFeild.current?.instance.focus()
      },[showSkeleton])


    const GetUserModel = async () => {
        setTimeout(async () => {
            const result = await usersServices.GetModelData(props.primaryKey);
            if (result.statusCode === 400 || result.statusCode === 409) {
                ShowAlert(result.data, "Vakency")
            }
            else {
                GetUserImage(props.primaryKey).then((res) => {
                    setData({
                        ...result.data,
                        uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                        attachedFile: (res?.size > 0 ? res : null)
                    })
                    setOriginalRecord({
                        ...result.data,
                        uploadedFile: { fileData: (res?.size > 0 ? window.URL.createObjectURL(res) : null) },
                        attachedFile: (res?.size > 0 ? res : null)
                    })
                })
                setZipCode(result.data.cityID);
                setShowSkeleton(false);
            }
        }, 1000);
    }

    const GetZip = async (CityID) => {
        const result = await usersServices.GetZipCode(CityID);
        let response = result.data
        setData({ ...data, zipCode: response ? parseInt(response) : undefined })
    }

    const GetUserImage = async (primaryKeyID) => {
        const result = await usersServices.GetUserProfilePicture(primaryKeyID);
        if (result.isOk) {
            return result.data;
        } else {
            return;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dropDownClick !== true) {
            props.primaryKey ? handleUpdateUser() : handleAddUser();



        }
    }

    const checkFields = () => {
        if (data.mobileNo !== undefined && data.mobileNo !== null && data.mobileNo !== "") {
            var mobileNoLength = String(data.mobileNo).length;
        }
        if (data.password !== undefined && data.password !== null && data.password !== "") {
            var passwordLength = data.password.length;
        }
        if (data.confirmPassword !== undefined && data.confirmPassword !== null && data.confirmPassword !== "") {
            var confirmPasswordLength = data.confirmPassword.length
        }

        if (data.firstName !== undefined && data.firstName !== null && data.firstName !== "" && data.lastName !== undefined && data.lastName !== null && data.lastName !== "" && data.cityID !== undefined && data.cityID !== null && data.cityID !== 0 && data.cityStateID !== undefined && data.cityStateID !== null && data.cityStateID !== 0 && data.mobileNo !== undefined && data.mobileNo !== null && data.mobileNo !== ""  && data.emailID !== undefined && data.emailID !== null && data.emailID !== "" && data.confirmPassword !== undefined && data.confirmPassword !== null && data.confirmPassword !== "" && data.password !== undefined && data.password !== null && data.password !== "" && passwordLength && passwordLength >= 8 && confirmPasswordLength && confirmPasswordLength >= 8 && data.confirmPassword === data.password) {

            return true
        }
        else {

            return false
        }
    }
    const checkUpdatedFields = () => {
        if (data.mobileNo !== undefined && data.mobileNo !== null && data.mobileNo !== "") {
            var mobileNoLength = String(data.mobileNo).length;
        }
        if (data.firstName !== undefined && data.firstName !== null && data.firstName !== "" && data.lastName !== undefined && data.lastName !== null && data.lastName !== "" && data.cityID !== undefined && data.cityID !== null && data.cityID !== 0 && data.cityStateID !== undefined && data.cityStateID !== null && data.cityStateID !== 0 && data.mobileNo !== undefined && data.mobileNo !== null && data.mobileNo !== ""  && data.emailID !== undefined && data.emailID !== null && data.emailID !== "" && data.userID !== undefined && data.userID !== null && data.userID !== 0 && data.userID !== "") {
            if (showChangePaswordOption === true) {

                if (data.password !== undefined && data.password !== null && data.password !== "") {
                    var passwordLength = data.password.length;
                }
                if (data.confirmPassword !== undefined && data.confirmPassword !== null && data.confirmPassword !== "") {
                    var confirmPasswordLength = data.confirmPassword.length
                }
                if (data.confirmPassword !== undefined && data.confirmPassword !== null && data.confirmPassword !== "" && data.password !== undefined && data.password !== null && data.password !== "" && passwordLength && passwordLength >= 8 && confirmPasswordLength && confirmPasswordLength >= 8 && data.confirmPassword === data.password) {

                    return true
                }
                else {

                    return false
                }
            }
            return true
        }
        else {
            return false
        }
    }

    const handleAddUser = async () => {
        let isValid = checkFields()
        if (isValid) {
            setShowSkeleton(true);
            const result = await usersServices.InsertData(data);
            if (!result.isOk) {
                if (result.statusCode === 400) {
                    ShowAlert(result.data, "Vakency")
                }
                setShowSkeleton(false);
            }
            else {
                if (data.attachedFile) {
                    UploadUserProfile(result.data.responseData)
                }
                props.setUserStatus({
                    popupStatus: eCRUDStatus.Inserted,
                    primaryKey: result.data.responseData,
                    updatedOn: null,
                })
                onClosePopup();
                setShowSkeleton(false);
            }
        }
    }
    const handleUpdateUser = async () => {
        let isValid = checkUpdatedFields()
        if (isValid) {
        if(originalRecord == data || (originalRecord !== data)){
                let dataObj = {
                    "updatedOn": data.updatedOn,
                    "userID": data.userID,
                    "firstName": data.firstName,
                    "lastName": data.lastName,
                    "address": data.address,
                    "cityID": data.cityID,
                    "cityStateID": data.cityStateID,
                    "zipCode": data.zipCode,
                    "emailID": data.emailID,
                    "mobileNo": data.mobileNo,
                    "userPermissionID": null,
                    "updatePassword": showChangePaswordOption,
                "password": showChangePaswordOption === false ? undefined: data.password,
                "confirmPassword":showChangePaswordOption === false ? undefined: data.confirmPassword,
                    "updateUserProfileImage": false
                }
                setShowSkeleton(true);
                const result = await usersServices.UpdateData(dataObj);
                if (result.statusCode === 400) {

                ShowAlert(result.data, "Vakency")
                setShowSkeleton(false);
            }
            else if (result.statusCode === 409) {
                ConflictPopup(result.data).then((res) => {
                    if (res) {
                        GetUserModel();
                    } else {
                      return;
                    }
                  });
                setShowSkeleton(false);
            }
            else {
                 // Remove image from context state 
                 if(!data.uploadedFile.fileData){
                        setProfile();
                    }
                    UploadUserProfile(data.primaryKeyID)
                    props.setUserStatus({
                        popupStatus: eCRUDStatus.Updated,
                        primaryKey: data.primaryKeyID,
                        updatedOn: result.data.responseData,
                    })

                    onClosePopup();
                    setShowSkeleton(false);
                }
            }
        }
    }

    const UploadUserProfile = async (primaryKeyID) => {
        var Datatoupload = { userID: primaryKeyID, attachments: data.attachedFile }
        const result = await usersServices.InsertUserProfilePicture(Datatoupload,primaryKeyID);

        if (result.isOk) {
            getUserImage();
            return;
        } else {
            ShowAlert(result.data)
        }
    }

    const PasswordOptions = {
        icon: showPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setPasswordMode(PasswordMode === 'text' ? 'password' : 'text');
            setShowpasswordIcon(showPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };
    const ConfirmPasswordOptions = {
        icon: showConfirmPasswordIcon,
        stylingMode: "text",
        hoverStateEnabled: false,
        activeStateEnabled: false,
        focusStateEnabled: false,
        onClick: () => {
            setConfirmPasswordMode(confirmPasswordMode === 'text' ? 'password' : 'text');
            setShowConfirmpasswordIcon(showConfirmPasswordIcon === openeye ? closeeyeIcon : openeye)
        }
    };

    const passwordComparison = () => {
        return data.password
    }


    const { isXSmall, isXXSmall, isExSmall, isSmall } = useScreenSize();

    const FocusedFeild = useRef(null);

    const onClosePopup = () => {
        props.setOpenPopup(false);
        setData({})
    }

    const PopupTitle = () => {
        return (
            <>
                <PopupHeader
                    ValidationGroupName={ValidationGroupName}
                    onSubmit={props.primaryKey ? handleUpdateUser : handleAddUser}
                    onClosePopup={onClosePopup}
                    title={[<span key={"header_title"} className="base-accent-text">{props.primaryKey ? "Edit" : "New"}</span>, " User"]}
                    hideSaveButton={showSkeleton ? true : false}
                />
            </>
        )
    }

    const asyncEmailIDValidation = async (e) => {
        try {
            const EmailID = e.value;
            const ExcludeID = props.primaryKey ? props.primaryKey : 0;
            const CheckDuplicateParams = `/CheckDuplicateEmailID?EmailID=${EmailID}&ExcludeID=${ExcludeID}`;
            const result = await usersServices.CheckDuplicateData_fullPath(CheckDuplicateParams);
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncEmailIDValidation:", error);
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    };

    const asyncMobileValidation = async (e) => {
        try {
            const MobileNo = e.value;
            const ExcludeID = props.primaryKey ? props.primaryKey : 0;
            const CheckDuplicateParams = `/CheckDuplicateMobileNo?MobileNo=${MobileNo}&ExcludeID=${ExcludeID}`;
            const result = await usersServices.CheckDuplicateData_fullPath(CheckDuplicateParams);
            return new Promise((resolve) => {
                resolve(result.isOk !== false);
            });
        } catch (error) {
            console.error("Error in asyncEmailIDValidation:", error);
            return new Promise((resolve) => {
                resolve(false);
            });
        }
    };


    return (
        <div>
            <div className='user_popup'>
                <Popup
                    visible={props.openPopup}
                    titleRender={PopupTitle}
                    width={(isXSmall || isXXSmall || isExSmall || isSmall) ? "80%" : 700}
                    height={"auto"}
                    maxHeight={"95%"}
                    deferRendering={false}
                    wrapperAttr={{ class: "CustomPopup" }}
                    onShown={() => FocusedFeild.current?.instance.focus()}
                >
                    <form onSubmit={handleSubmit}>
                        <div className='overflow-hidden'>
                            {(isXSmall || isXXSmall || isExSmall) &&
                                <div className='pb-3 mx-auto top-profileSection'>
                                    {showSkeleton ?
                                        <div className="mt-1">
                                            <div className='skeleton-View image-Skeleton-Candidateprofile mt-2'></div>
                                        </div>
                                        :
                                        <UserProfileUploader newRecord={data} setNewRecord={setData} />
                                    }
                                </div>
                            }
                            <div className='row px-3 pt-2'>

                                <div className='col-md'>
                                    {showSkeleton ?
                                        <div className=''>
                                            <PopupSkeleton />
                                            <PopupSkeleton />
                                        </div>
                                        :
                                        <>
                                            <div>
                                                <TextBox
                                                    label='First Name'
                                                    maxLength={50}
                                                    labelMode='floating'
                                                    value={data.firstName}
                                                    ref={FocusedFeild}
                                                    onValueChange={(e) => setData({ ...data, firstName: e })}
                                                    inputAttr={{
                                                        autocomplete: "new",
                                                    }}
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='First Name is required' />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                            <div className='mt-4'>
                                                <TextBox
                                                    label='Last Name'
                                                    maxLength={50}
                                                    labelMode='floating'
                                                    value={data.lastName}
                                                    onValueChange={(e) => setData({ ...data, lastName: e })}
                                                    inputAttr={{
                                                        autocomplete: "new",
                                                    }}
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='Last Name is required' />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                            <div className='mt-4'>
                                                <TextArea
                                                    label='Address'
                                                    maxLength={255}
                                                    labelMode='floating'
                                                    value={data.address}
                                                    onValueChange={(e) => setData({ ...data, address: e })}
                                                    inputAttr={{
                                                        autocomplete: "new",
                                                    }}
                                                >
                                                </TextArea>
                                            </div>
                                        </>
                                    }
                                </div>
                                {(!isXSmall && !isXXSmall && !isExSmall) &&
                                    <div className='col-md-auto'>
                                        {showSkeleton ?
                                            <div className="pt-1">
                                                <div className='skeleton-View image-Skeleton-Candidateprofile'></div>
                                            </div>
                                            :
                                            <UserProfileUploader newRecord={data} setNewRecord={setData} />
                                        }
                                    </div>
                                }
                            </div>
                            {showSkeleton ?
                                <div className='px-3'>
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                    <PopupSkeleton />
                                    {props.primaryKey && showChangePaswordOption === true ?<PopupSkeleton /> : ""}
                                </div>
                                :
                                <>
                                    <div className='row px-3'>
                                        <div className='col-md'>
                                            <div className='mt-4'>
                                                <StateDropDown
                                                    value={data.cityStateID}
                                                    onValueChange={(e) => setData({ ...data, cityStateID: e, cityID: null })}
                                                    setDropDownClick={setDropDownClick}
                                                    setAddedCityStateID={setAddedCityStateID}
                                                    dropdownHeight={180}
                                                    validationRequired={true}
                                                    validationGroup={ValidationGroupName}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md'>
                                            <div className='mt-4'>
                                                <CityDropDown
                                                    value={data.cityID}
                                                    cityStateID={data.cityStateID}
                                                    onValueChange={(e) => setData({ ...data, cityID: e })}
                                                    setDropDownClick={setDropDownClick}
                                                    setAddedCityID={setAddedCityID}
                                                    validationRequired={true}
                                                    dropdownHeight={180}
                                                    validationGroup={ValidationGroupName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='col-md'>
                                            <div className='mt-4'>
                                                <NumberBox
                                                    label='Zip Code'
                                                    labelMode='floating'
                                                    format="#"
                                                    step={0}
                                                    onKeyDown={onKeyDown}
                                                    inputAttr={{
                                                        autocomplete: "new",
                                                        maxLength: 6
                                                    }}
                                                    maxLength={6}
                                                    value={data.zipCode && parseInt(data.zipCode)}
                                                    onValueChange={(e) => setData({ ...data, zipCode: e })}
                                                >
                                                    <Validator validationGroup={ValidationGroupName}>
                                                        <StringLengthRule message='Zip Code must have 6 digits' min={6} ignoreEmptyValue={true} />
                                                    </Validator>
                                                </NumberBox>
                                            </div>

                                        </div>
                                        <div className='col-md'>
                                            <div className='mt-4'>
                                                <NumberBox
                                                    label='Mobile Number'
                                                    labelMode='floating'
                                                    format="#"
                                                    step={0}
                                                    onKeyDown={onKeyDown}
                                                    maxLength={15}
                                                    value={data.mobileNo && parseInt(data.mobileNo)}
                                                    inputAttr={{
                                                        autocomplete: "new",
                                                        maxLength: 15
                                                    }}
                                                    onValueChange={(value) => setData({ ...data, mobileNo: value })}
                                                >
                                                    <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                        <RequiredRule message='Mobile Number is required' />
                                                        <StringLengthRule message='Mobile Number must have 10 digits' min={10} />
                                                        <AsyncRule
                                                          reevaluate={false}
                                                          message="Can't accept duplicate Mobile Number"
                                                          validationCallback={asyncMobileValidation}
                                                       />
                                                    </Validator>
                                                </NumberBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='mt-3'>
                                            <TextBox
                                                label='Email ID'
                                                labelMode='floating'
                                                value={data.emailID}
                                                onValueChange={(e) => setData({ ...data, emailID: e })}
                                                inputAttr={{
                                                    autocomplete: "new"
                                                }}
                                                maxLength={50}
                                            >
                                                <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                    <EmailRule message='Email ID is invalid' />
                                                    <RequiredRule message='Email ID is required' />
                                                    {/* <CustomRule
                                                        ignoreEmptyValue={true}
                                                        message="Can't accept duplicate Email ID"
                                                        validationCallback={asyncEmailIDValidation}
                                                    /> */}
                                                    <AsyncRule
                                                        reevaluate={false}
                                                        message="Can't accept duplicate Email ID"
                                                        validationCallback={asyncEmailIDValidation}
                                                    />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='mt-3'>
                                            <SelectBox
                                                label='User Role'
                                                id="UserRole_DropDown"
                                                labelMode='floating'
                                                value={data.userPermissionID}
                                                onValueChange={(e) => setData({ ...data, userPermissionID: e })}
                                                dropDownOptions={{
                                                    height: showChangePaswordOption ? 160 : 100,
                                                    hideOnParentScroll: true,
                                                    container:"#UserRole_DropDown"
                                                }}
                                            >
                                                {/* <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                            <RequiredRule message='User Role is required' />
                                        </Validator> */}
                                            </SelectBox>
                                        </div>
                                    </div>
                                    {props.primaryKey ?
                                        <div className='px-3'>
                                            <CheckBox
                                                iconSize={15}
                                                className='pe-2 mt-4'
                                                value={showChangePaswordOption}
                                                onValueChange={(e) => setShowChangePasswordOption(e)}
                                            >
                                            </CheckBox>
                                            <span className='font-regular-15 show_change_password fw-semibold'>Do you want to change password?</span>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    {((props.primaryKey && showChangePaswordOption === true) || !props.primaryKey) &&
                                        <div className='row px-3'>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label='Password'
                                                        mode={PasswordMode}
                                                        labelMode='floating'
                                                        maxLength={16}
                                                        value={data.password}
                                                        onValueChange={(value) => setData({ ...data, password: value })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <TextBoxButton
                                                            name="password"
                                                            location="after"
                                                            options={PasswordOptions}
                                                        />
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}  >
                                                            <RequiredRule message='Password is required' />
                                                            <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                            </div>
                                            <div className='col-md'>
                                                <div className='mt-4'>
                                                    <TextBox
                                                        label="Confirm Password"
                                                        mode={confirmPasswordMode}
                                                        labelMode='floating'
                                                        maxLength={16}
                                                        value={data.confirmPassword}
                                                        onValueChange={(value) => setData({ ...data, confirmPassword: value })}
                                                        inputAttr={{
                                                            autocomplete: "new",
                                                        }}
                                                    >
                                                        <TextBoxButton
                                                            name="confirmPassword"
                                                            location="after"
                                                            options={ConfirmPasswordOptions}
                                                        />
                                                        <Validator validationGroup={ValidationGroupName} elementAttr={{ class: 'ForValidationAstrikMark' }}>
                                                            <RequiredRule message='Confirm Password is required' />
                                                            <PatternRule message="Password should contain minimum 8 & maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character" pattern={PasswordRegex} />
                                                            {data.password && <CompareRule
                                                                message="Password and Confirm Password does not match"
                                                                comparisonTarget={passwordComparison}
                                                            />
                                                            }
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {showSkeleton ?
                                <div className='d-flex justify-content-end pb-3 pe-3'>
                                    <div className='skeleton-View Skeleton-popup-footer'></div>
                                </div>
                                :
                                <PopupFooter
                                    ValidationGroupName={ValidationGroupName}
                                    openPopup={props.openPopup}
                                    setOpenPopup={props.setOpenPopup}
                                />
                            }
                        </div>
                    </form>
                </Popup>
            </div>
        </div >
    )
}
